import React, { useState } from "react";
import styles from "./users.module.scss";
import { Pagination } from "../global/pagination/pagination";
import { USERS_MOCK } from "./usersMock";
import { LeadItem } from "./item/leadItem";
import { ItemsPerPage } from "./itemsPerPage/itemsPerPage";
import { ButtonPlus } from "./adminParts/adminParts";
import delete_icon2 from "../../media/icons/Delete.svg";
import search_icon from "../../media/icons/leader_search.svg";
import Modal from "../global/modal/modal";
import { useCallback } from "react";
import close from "../../media/icons/close_icon.svg";
import warning from "../../media/icons/warning.svg";
import { SOLID } from "../../const";
import Button from "../global/button/button";
import {useTitle} from "../../hooks/useTitle";

export const Users = () => {
  useTitle("Users");
  const adminClass = " " + styles.admin + " ";
  const options = [
    { value: "10", label: "10 Rows" },
    { value: "20", label: "20 Rows" },
    { value: "30", label: "30 Rows" },
  ];
  const [itemsPerPage, setItemsPerPage] = useState(options[0]);
  const [currentItems, ] = useState(
    USERS_MOCK().slice(0, itemsPerPage.value)
  );
  const [itemOffset, setItemOffset] = useState(0);
  const changeHandler = (option) => {
    setItemsPerPage(option);
  };
  const [deleteList, setDeleteList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCheckBoxChange = (e, { id, name }, callBack) => {
    let _checked = e.target.checked;
    callBack(_checked);
    if (_checked) {
      setDeleteList((prev) => [...prev, { id, name }]);
    } else {
      setDeleteList((prev) => prev.filter((p) => p.id !== id));
    }
  };

  const handleDeleteClick = () => {
    if (!deleteList.length) return;
    setModalOpen(true);
  };

  const handleDeleteCallback = (id, name, callBack) => {
    callBack();
    setDeleteList([{ id, name }]);
    setModalOpen(true);
  };

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const _renderDeleteModal = () => {
    return (
      <Modal
        onClose={handleCloseModal}
        open={isModalOpen}
        className={styles.modalDelete}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.modalTitleContainer}>
            <button onClick={handleCloseModal}>
              <img src={close} alt="" />
            </button>
          </div>
          <div className={styles.modalBody}>
            <img src={warning} alt="" />
            <p>
              Are you sure that you want to{" "}
              <b>
                delete{" "}
                {deleteList.length > 1
                  ? `${deleteList.length} users`
                  : deleteList[0]?.name}{" "}
                ?
              </b>
            </p>
            <span>
              After deleting, it will be impossible to restore user data
            </span>
            <Button
              className={`${styles.deleteBtnModal}`}
              icon={delete_icon2}
              text={"Delete"}
              type={SOLID}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {_renderDeleteModal()}
      <div className={styles.headingContainer + adminClass}>
        <h1>Users</h1>
        <ButtonPlus className={"onlyMobile"} />
        <input type="text" className="input-search onlyMd" placeholder="Type here..."/>
      </div>

      <div className={styles.leaderboard + adminClass}>
        <div className={styles.frame + " " + false ? styles.adminMobile : ""}>
          <>
            <div className={styles.upContainer + " onlyMobile"}>
              <div className={styles.search}>
                <img src={search_icon} alt="" />
                <input placeholder="Search..." type="text" />
              </div>
              <button onClick={handleDeleteClick} className={styles.deleteBtn}>
                <img src={delete_icon2} alt="" />
              </button>
            </div>
            <div className="center">
              <div className="lead-grid titles">
                <span className="onlyMobile"></span>
                <span>ID</span>
                <span className={"profile_title"}>User Profile</span>
                <span>Email</span>
                <span>Access</span>
                <span className="onlyMobile"></span>
                <button
                  onClick={handleDeleteClick}
                  className={styles.deleteBtn + " onlyMd"}
                >
                  <img src={delete_icon2} alt="" />
                </button>
                <ButtonPlus className={"onlyMd"} />
              </div>
              {currentItems.map((item, i) => (
                <LeadItem
                  handleDelete={handleDeleteCallback}
                  handleCheckBoxChange={handleCheckBoxChange}
                  {...item}
                  key={i}
                />
              ))}
            </div>
          </>

          <div className="pagination">
            <Pagination
              itemsPerPage={Number(itemsPerPage.value)}
              {...{
                itemsPerPage:itemsPerPage.value,
                itemOffset,
                setItemOffset,
                length:20,
              }}
            />
            <ItemsPerPage
              options={options}
              changeHandler={changeHandler}
              value={itemsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
