import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCurrentUser,
    refreshGoogleToken,
} from "../redux/slices/authentication";
import { useLocation } from "react-router-dom";
import jwt from "jwt-decode";

const useDecodeToken = () => {
    const location = useLocation();
    const { token, isAdmin, google } = useSelector(
        (state) => state?.Authentication
    );
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) return;
        dispatch(getCurrentUser(token));
        // eslint-disable-next-line
    }, []);

    const handleJwtToken = () => {
        const decoded = jwt(token);
        if (Date.now() >= decoded.exp * 1000) {
            setIsLoggedIn(false);
            return;
        }
        setIsLoggedIn(true);
    };

    const handleGoogleToken = () => {
        const body = { refresh_token: google?.refreshToken };
        fetch(
            `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${google?.access_token}`
        )
            .then((res) => res.json())
            .then((res) => {
                if (res?.expires_in > 0) return;
                dispatch(refreshGoogleToken(body));
            })
            .catch((res) => dispatch(refreshGoogleToken(body)));
    };

    useEffect(() => {
        try {
            if (google?.access_token) handleGoogleToken();
            handleJwtToken();
        } catch (error) {
            setIsLoggedIn(false);
        }
    }, [token, location.pathname]);

    return { isLoggedIn, isAdmin };
};

export default useDecodeToken;
