import React from "react";
import styles from './CampaignWidget.module.scss'

// import icons
import facebook from '../../../media/icons/facebook_blue.svg';
import google from '../../../media/icons/google.svg';
import {useDispatch, useSelector} from "react-redux";
import Button from "../../global/button/button";
import {FACEBOOK, GLOSSY, GOOGLE} from "../../../const";

import {useGoogleLoginWrapper} from "../../../hooks/useGoogleLoginWrapper";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {linkFacebook} from "../../../services/UserService";
import {getCurrentUser} from "../../../redux/slices/authentication";
import {useMutation} from 'react-query';
import SwitchTab from "../../global/switchTab/switchTab";
import useSwitchTab from "../../../hooks/useSwitchTab";
import {useHistory, useNavigate} from "react-router-dom";


const SignIn = ({ type, g_token, f_token }) => {

    const dispatch = useDispatch();
    const googleLogin = useGoogleLoginWrapper();
    const currentUser = useSelector((state) => state.Authentication);

    const {mutate: linkFacebookMutation} = useMutation(
        (data) => linkFacebook(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );

    const handleFacebookResponse = async (response) => {
        linkFacebookMutation({accessToken: response.accessToken});
    };

    if (type === 'Google' && g_token) return null;
    if (type === 'Facebook' && f_token) return null;

    return (
        <div className={styles.signin}>
            {
                type === 'Google' && !g_token &&
                <>
                    <Button
                        onClick={googleLogin}
                        icon={google}
                        type={GOOGLE}
                        text={'Sign in with Google'}
                        className={styles.signin__button}
                    />
                    <p className={styles.signin__title}>You need to sign in if you want to see more information</p>
                </>
            }
            {
                type === 'Facebook' && !f_token &&
                <>
                    {
                        <FacebookLogin
                            appId={
                                process.env
                                    .REACT_APP_FB_APP_ID
                            }
                            fields="id,name,email,picture"
                            scope="public_profile,email,ads_read,ads_management"
                            callback={
                                handleFacebookResponse
                            }
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    className={styles.signin__button}
                                    type={FACEBOOK}
                                    icon={facebook}
                                    text={'Sign in with Facebook'}
                                />
                            )}
                        />
                    }{' '}
                    <p className={styles.signin__title}>You need to sign in if you want to see more information</p>
                </>
            }
        </div>
    )
}


const WidgetCard = ({ title, icon, g_token, f_token }) => {

    const metrics = [
        {
            name: 'Total',
            value: '12,91',
            prefix: '$'
        },
        {
            name: 'CTR',
            value: '3,32',
            prefix: '%'
        },
        {
            name: 'Clicks',
            value: '25',
        },
        {
            name: 'CPC',
            value: '3,45',
            prefix: '$'
        },
        {
            name: 'Total Cost',
            value: '4,56',
            prefix: '$'
        }
    ]


    return (
        <div className={styles.card}>
            <SignIn type={title}
                    g_token={g_token}
                    f_token={f_token} />

            <div className={styles.card__top}>
                <div className={styles.card__top__title}>
                    <img src={icon} alt="icon"/> {title}
                </div>
            </div>
            <div className={styles.card__metrics}>
                {
                    metrics.map((metric, index) => {
                        return (
                            <div className={styles.card__metrics__item} key={index}>
                                <div className={styles.card__metrics__item__name}>
                                    {metric.name}
                                </div>
                                <div className={styles.card__metrics__item__value}>
                                    {metric.prefix ? metric.prefix : ''}{metric.value}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const CampaignWidget = () => {

    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.Authentication);
    const [g_token, f_token] = [currentUser.google?.accessToken, currentUser.facebook?.accessToken];
    const { currentTab, handleTabClick } = useSwitchTab(0);

    return (
        <div className={styles.campaigns}>
            <div className={styles.top}>
                <div className={styles.top__title}>
                    Campaigns
                </div>
                <div className={styles.top__tabs}>
                    <SwitchTab
                        type={GLOSSY}
                        labels={[
                            { label: "Weekly" },
                            { label: "Monthly" },
                            { label: "Yearly" },
                        ]}
                        currentTab={currentTab}
                        handleTabClick={handleTabClick}
                        className={styles.SwitchTab}
                    />
                </div>
            </div>
            <div className={styles.cards}>
                <WidgetCard title='Google'
                            value='$12,91'
                            icon={google}
                            g_token={g_token}
                            f_token={f_token}
                />
                <WidgetCard title='Facebook'
                            value='$12,91'
                            icon={facebook}
                            g_token={g_token}
                            f_token={f_token}
                />
                <div className={styles.link} onClick={() => {
                    navigate('/campaigns')
                }}>
                    See All Campaigns
                </div>
            </div>
        </div>
    );
}

export default CampaignWidget;
