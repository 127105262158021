import { useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

export default function useFileUpload(
  inputChangeCallback = null,
  isMulti = true
) {
  const inputRef = useRef();
  const [files, setFiles] = useState([]);
  const handleInputClick = () => {
    inputRef.current?.click();
  };

  const handleInputChange = (e) => {
    if (!isMulti && files.length) return;
    let _filesListObject = e.target?.files;
    if (!_filesListObject.length) return;

    let _files = Object.entries(_filesListObject).map((f) => {
      let _file = f[1];
      _file.id = _uniqueId();
      _file.isLoaded = false;
      return _file;
    });

    let _modifiedFiles = [...files, ..._files];

    setFiles(_modifiedFiles);

    if (inputChangeCallback) {
      inputChangeCallback(_modifiedFiles);
    }

    _files.forEach((f, i) => {
      setTimeout(
        () => {
          setFiles((prev) =>
            prev.map((p) => {
              if (p.id === f.id) {
                p.isLoaded = true;
              }
              return p;
            })
          );
        },
        i ? 2000 : 1000
      );
    });
  };

  const handleFileDelete = () => {};

  const reset = () => {
    setFiles([]);
  };

  return {
    files,
    inputRef,
    handleInputClick,
    handleInputChange,
    handleFileDelete,
    reset,
  };
}
