import React from "react";
import Dashboard from "../components/dashboard/dashboard";
import Layout from "../components/global/layout/layout";
import Attachment from "../components/project/attachment/attachment";
import Brief from "../components/project/brief/brief";
import CreateProject from "../components/project/createProject/createProject";
import Note from "../components/project/note/note";
import Project from "../components/project/project";
import ProjectDetail from "../components/project/projectDetail/projectDetail";
import Task from "../components/project/task/task";
import CreateTask from "../components/project/task/createTask/createTask";
import TaskDetail from "../components/project/task/taskDetail/taskDetail";
import NoteDetail from "../components/project/note/noteDetail/noteDetail";
import CreateNote from "../components/project/note/createNote/createNote";
import EditNote from "../components/project/note/editNote/editNote";
import Calendar from "../components/dashboard/calendar/calendar";
import Profile from "../components/profile/profile";
import {Notifications} from "../components/notifications/notifications";
import {Leaderboard} from "../components/leaderboard/leaderboard";
import Forums from "../components/forums/forums";
import {Users} from "../components/users/users";
import {Navigate} from "react-router-dom";
import NotFound from "../components/notFound/notFound";
import Campaigns from "../components/campaigns/campaigns";

export const ROUTES = {
    projects: "/projects",
    profile: "/profile",
    notifications: "/notifications",
};

const Routes = (isLoggedIn) => {
    return {
        path: "/",
        element: isLoggedIn ? <Layout/> : <Navigate to={"/auth/login"}/>,
        children: [
            {
                path: "/",
                element: <Dashboard/>,
            },
            {
                path: "/*",
                element: <NotFound/>
            },
            {
                path: "calendar",
                element: <Calendar/>,
            },
            {
                path: "projects",
                element: <Project/>,
            },
            {
                path: "projects/create",
                element: <CreateProject/>,
            },
            {
                path: "campaigns",
                element: <Campaigns />,
            },
            {
                path: "projects/:id",
                element: <ProjectDetail/>,
                children: [
                    {
                        path: "brief",
                        element: <Brief/>,
                    },
                    {
                        path: "tasks",
                        element: <Task/>,
                    },
                    {
                        path: "attachments",
                        element: <Attachment/>,
                    },
                    {
                        path: "notes",
                        element: <Note/>,
                    },
                    {
                        path: "notes/create",
                        element: <CreateNote/>,
                    },
                    {
                        path: "notes/edit/:noteId",
                        element: <EditNote/>,
                    },
                    {
                        path: "notes/:noteId",
                        element: <NoteDetail/>,
                    },
                ],
            },
            {
                path: "projects/:id/tasks/create",
                element: <CreateTask/>,
            },
            {
                path: "projects/:id/tasks/:taskId",
                element: <TaskDetail/>,
            },
            {
                path: "leaderboard",
                element: <Leaderboard/>,
            },
            {
                path: "users",
                element: <Users/>,
            },
            {
                path: ROUTES.profile,
                element: <Profile/>,
            },
            {
                path: ROUTES.notifications,
                element: <Notifications/>,
            },

            {
                path: "forums",
                element: <Forums/>,
            },
            {
                path: "forums/:category",
                element: <Forums/>,
            },
            {
                path: "forums/:category/:id",
                element: <Forums/>,
            },
        ],
    };
};

export default Routes;
