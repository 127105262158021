import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TodoListService from '../../services/TodoListService';
// import { updateMessage } from './welcomeMessage';

export const getAll = createAsyncThunk('todoList/getAll', async () => {
  const res = await TodoListService.getAll();
  return res.data;
});

export const get = createAsyncThunk('todoList/get', async (id) => {
  const res = await TodoListService.get(id);
  return res.data;
});

export const create = createAsyncThunk('todoList/create', async (data) => {
  const res = await TodoListService.create(data);
  return res.data;
});

export const remove = createAsyncThunk('todoList/remove', async (id) => {
  const res = await TodoListService.remove(id);
  return res.data;
});

export const toggle = createAsyncThunk('todoList/toggle', async (id) => {
  const res = await TodoListService.toggle(id);
  return res.data;
});
// Todo List state slice
const todoListSlice = createSlice({
  name: 'todoList',
  initialState: {
    todos: [],
  },
  reducers: {},
  extraReducers: {
    [getAll.fulfilled]: (state, action) => {
      state.todos = action.payload;
    },
    [create.fulfilled]: (state, action) => {
      state.todos.push(action.payload.data);
    },
    [remove.fulfilled]: (state, action) => {
      state.todos = state.todos.filter(
        (todo) => todo._id !== action.payload.data._id
      );
    },
    [toggle.fulfilled]: (state, action) => {
      const index = state.todos.findIndex(
        (todo) => todo._id === action.payload._id
      );
      state.todos[index] = action.payload;
    },
  },
});

export const {
  addGroup,
  deleteGroup,
  addTask,
  deleteTask,
  toggleTask,
  editTask,
} = todoListSlice.actions;

export default todoListSlice.reducer;
