import React, {useCallback, useRef} from "react";
import {
    GLOSSY,
    SOLID,
    BACK,
    PRIORITIES,
    STATUSES,
    TAGS, PURPLE,
} from "../../../../const";
import Button from "../../../global/button/button";
import styles from "./taskDetail.module.scss";
import delete_icon from "../../../../media/icons/delete_black.svg";
import Select from "../../../global/select/select";
import tick from "../../../../media/icons/tick_white.svg";
import back from "../../../../media/icons/arrow_back.svg";
import CustomDatePicker from "../../../global/customDatePicker/customDatePicker";
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTitle} from "../../../../hooks/useTitle";
import {useSelector} from "react-redux";
import {PROJECT, PROJECTS, TASK, TASKS} from "../../../../rquery/queryKeys";
import TaskService from "../../../../services/TaskService";
import {useQuery, useQueryClient, useMutation} from "react-query";
import {useFormik} from "formik";
import TagSelect from "../../../global/select/tagSelect";
import TagService from "../../../../services/TagService";
import close from "../../../../media/icons/close_icon.svg";
import warning from "../../../../media/icons/red_warning.svg";
import Modal from "../../../global/modal/modal";

import {optionsColor} from "../../../../mock";
import ColorSelectModal from "../../colorSelectModal/colorSelectModal";
import cn from "classnames";
import {toast} from "react-toastify";
import CategorySelect from "../../../global/select/categorySelect";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import projectPostValidation from "../../../../validations/projects/projectPostValidation";

export default function TaskDetail() {
    useTitle("Task Details");
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const [categories, setCategories] = useState([{}]);
    const {token} = useSelector((state) => state.Authentication);
    const {id, taskId} = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [optionsColors, setOptionsColors] = useState(optionsColor);
    const [isClrModalOpen, setIsClrModalOpen] = useState(false);
    const [blockPickerColor, setBlockPickerColor] = useState('#8C6CED'); // default color

    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const categoryRef = useRef(null);

    const nameTimer = useRef(null);
    const descriptionTimer = useRef(null);
    const priorityTimer = useRef(null);

    const [editMode, setEditMode] = useState({
        title: false,
        description: false,
        category: false,
        priority: false,
    });

    const [fieldsText, setFieldsText] = useState({
        name: '',
        description: '',
    })

    const onEdit = (key, isEdit) => {
        setEditMode((prev) => ({
            ...prev,
            [key]: isEdit,
        }));
    }

    useOnClickOutside(nameRef, () => {
        onEdit('title', false)
    })

    useOnClickOutside(descriptionRef, () => {
        onEdit('description', false)
    })

    useOnClickOutside(categoryRef, () => {
        onEdit('category', false)
    })


    const debouncedSubmit = (field, value) => {
        if (!value && !field) {
            setTimeout(() => {
                formik.submitForm();
            }, 450)
            return;
        }

        clearTimeout(field === 'name' ? nameTimer.current : descriptionTimer.current)

        if (value.length === 0 || value === fieldsText[field]) {
            return
        }

        if (field === 'description' && value.length === 0) {
            return;
        }


        if (field === 'name') {
            nameTimer.current = setTimeout(() => {
                mutate({name: value})
            }, 450)
        }
        if (field === 'description') {
            descriptionTimer.current = setTimeout(() => {
                mutate({description: value})
            }, 450)
        }
    }

    const onFieldChange = ({field, value}) => {
        clearTimeout(field === 'name' ? nameTimer.current : descriptionTimer.current)
        formik.setFieldValue(field, value)
        setFieldsText({...fieldsText, [field]: value})

        if (value.length === 0 || value === fieldsText[field]) return
        if (formik.errors[field]) return;
        debouncedSubmit(field, value)
    }


    const {isLoading, data, refetch} = useQuery([TASK, token, id, taskId], () =>
            TaskService.getById(taskId, token),
        {
            onSuccess: (data) => {
                setFieldsText({
                    name: data.data.name,
                    description: data.data.description,
                })
                setBlockPickerColor(data.data.color)
                const baseColors = optionsColor.map(color => color.value)
                const colors = data.data.colors?.filter(color => !baseColors.includes(color))
                if (colors && colors.length > 0) {
                    setOptionsColors([...optionsColor, ...colors.map(color => ({value: color, label: color}))])
                }
                if (data.data.category) {

                    let category = {
                        label: data.data.category,
                        value: data.data.category,
                    }

                    setCategories([category]);
                }

                setOptionsColors((prev) => {
                    if (data.data.color) {
                        return [
                            ...prev.filter((item) => item.value !== data.data.color),
                            {
                                label: data.data.color,
                                value: data.data.color,
                            }
                        ]
                    } else return prev;
                })
            }
        }
    );


    const {data: tagsData} = useQuery([TAGS, token], () =>
        TagService.get(token)
    );

    const queryClient = useQueryClient();
    const handleCloseClrModal = useCallback(() => {
        setIsClrModalOpen(false);
    }, []);

    const {mutate: updateTaskColor} = useMutation(
        (data) => {
            return TaskService.updateTaskColor(taskId, {color: data.color}, token)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TASKS]);
                queryClient.invalidateQueries([TASK]);
                handleCloseClrModal();
                toast.success("Color updated successfully",)
            },
        },
        {
            onError: () => toast.error("Something went wrong")
        }
    );

    const {mutate: updateTaskColors} = useMutation(
        (data) => {
            return TaskService.updateTaskColors(taskId, {
                colors: data
            }, token)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([PROJECT]);
                queryClient.invalidateQueries([PROJECTS]);
            },
        },
        {
            onError: () => toast.error("Something went wrong")
        }
    );

    const {mutate} = useMutation(
        (data) => TaskService.put(taskId, data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TASKS]);
                queryClient.invalidateQueries([TASK]);
                toast.success("Task updated successfully")
            },
        },
        {
            onError: () => {
                toast.error("Something went wrong")
            }
        }
    );

    const {mutate: mutateTag} = useMutation(
        (id) => TagService.deleteById(id, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const handleTagDelete = (id) => {
        mutateTag(id)
        formik.setFieldValue("tag", formik.values.tag.filter(t => t !== id))
    }

    const {mutate: mutateTask} = useMutation(
        (id) => TaskService.deleteById(id, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TASK]);
                queryClient.invalidateQueries([TASKS]);
                setIsModalOpen(false);
                navigate(`/projects/${id}/tasks`);
            },
        }
    );

    const {mutate: mutatePostTag} = useMutation(
        (data) => TagService.post(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const {mutate: updateIsFavorite} = useMutation(
        (data) => TaskService.put(data.id, {
            isFavorite: data.isFavorite,
        }, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TASKS]);
                queryClient.invalidateQueries([TASK]);
                refetch();
                toast.success(data.data.isFavorite ? "Task added to Favorites" : "Task removed from Favorites")
            },
        },
        {
            onError: () => {
                toast.error("Something went wrong")
            }
        }
    );

    const onAddNewColor = (color) => {
        const baseColors = optionsColors.map(c => c.value);
        if (baseColors.includes(color)) return
        updateTaskColors([...baseColors, color]);
    }

    const handleCreateNewClick = (name, callBack) => {
        mutatePostTag({name: name});
        callBack();
    };

    const handleMarkAsCompleteClick = () => {
        mutate({completed: true, status: "done"});
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data?.data.name,
            description: data?.data.description,
            dueDate: data?.data.dueDate && new Date(data?.data.dueDate),
            status: data?.data.status,
            tag: data?.data.tag.map((t) => t._id),
            priority: data?.data.priority,
            color: data?.data.color,
            isFavorite: data?.data.isFavorite,
            category: data?.data.category,
        },
        validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const handeCategoryDelete = (value) => {
        let _categories = categories.filter(c => c.value !== value)
        setCategories(_categories)
        if (formik.values.category === value) {
            formik.setFieldValue("category", "")
        }
    }

    const handleCreateCategoryClick = (value) => {
        formik.setFieldValue("category", value);
        setCategories([...categories, {label: value, value: value}]);
    }

    let priority = PRIORITIES.find((p) => p.value === data?.data.priority);

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={() => setIsModalOpen(false)}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>Are you sure that you want to delete this task?</b>
                        </p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.noBtnModal}`}
                                text={"No"}
                                type={SOLID}
                                onClick={() => setIsModalOpen(false)}
                            />
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                onClick={() => mutateTask(taskId)}
                                type={SOLID}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        !isLoading && (
            <>
                <ColorSelectModal
                    isClrModalOpen={isClrModalOpen}
                    setIsClrModalOpen={setIsClrModalOpen}
                    optionsColors={optionsColors}
                    setOptionsColors={setOptionsColors}
                    blockPickerColor={blockPickerColor}
                    setBlockPickerColor={setBlockPickerColor}
                    updateColor={updateTaskColor}
                    onSave={onAddNewColor}
                    formik={formik}
                />
                {_renderDeleteModal()}
                <div className={styles.top}>
                    <div className={styles.title}>
                        <div className={styles.topLeft}>
                            <Button
                                type={BACK}
                                icon={back}
                                className={styles.backBtn}
                                onClick={handleGoBack}
                            />
                            <h2>Task</h2>
                        </div>
                    </div>
                </div>
                <div className={`border d-flex ai-end`}>
                    <div className={`backCard d-flex wrap `}>
                        <div className="whiteBg w-100">
                            <div className={styles.contentContainer}>
                                <div className={styles.container}>
                                    <div className={styles.left}>
                                        <div
                                            className={`formControls d-flex col ${
                                                formik.touched.name &&
                                                Boolean(formik.errors.name) &&
                                                "error"
                                            } ${styles.formControls}`}
                                        >
                                            <div>
                                                <input
                                                    className={cn(styles.field, styles.field__title, {
                                                        [styles.field__focus]: editMode.name,
                                                        [styles.field__error]: Boolean(formik.errors.name)
                                                    })}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        onFieldChange({field: 'name', value: e.target.value})
                                                    }}
                                                    onMouseLeave={() => {
                                                        nameTimer.current = setTimeout(() => {
                                                            onEdit('name', false)
                                                        }, 400);
                                                    }}
                                                    onMouseEnter={() => {
                                                        clearTimeout(nameTimer.current)
                                                        onEdit('name', true)
                                                    }}
                                                    value={fieldsText.name}
                                                    defaultValue={formik.initialValues.name}
                                                    onBlur={formik.handleBlur}
                                                    type="text"
                                                    ref={nameRef}
                                                />
                                            </div>
                                            <span className={styles.validationHelper}>
                                                 {formik.errors.name}
                                             </span>
                                        </div>
                                        <div
                                            className={`formControls d-flex col ${
                                                formik.touched.description &&
                                                Boolean(formik.errors.description) &&
                                                "error"
                                            }`}
                                        >
                                            <div>
                                                <span className={styles.label}>DESCRIPTION</span>
                                                <textarea
                                                    className={cn(styles.field, {
                                                        [styles.field__focus]: editMode.description,
                                                        [styles.field__error]: Boolean(formik.errors.description)
                                                    })}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        onFieldChange({field: 'description', value: e.target.value})
                                                    }}
                                                    onMouseEnter={() => {
                                                        clearTimeout(descriptionTimer.current)
                                                        onEdit('description', true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        descriptionTimer.current = setTimeout(() => {
                                                            onEdit('description', false)
                                                        }, 400);
                                                    }}
                                                    value={fieldsText.description}
                                                    defaultValue={formik.initialValues.description}
                                                    onBlur={formik.handleBlur}
                                                    ref={descriptionRef}
                                                >
                                </textarea>
                                            </div>
                                            <span className={styles.validationHelper}>
                                                {formik.errors.description}
                                            </span>
                                        </div>
                                        {data?.data.status !== "done" && (
                                            <Button
                                                className={styles.button}
                                                text={"Mark as Complete"}
                                                type={GLOSSY}
                                                onClick={handleMarkAsCompleteClick}
                                                icon={tick}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.right}>
                                        <div>
                                            <div className={styles.category}
                                                 onMouseEnter={() => {
                                                     clearTimeout(categoryRef.current)
                                                 }}
                                                 onMouseLeave={() => {
                                                     categoryRef.current = setTimeout(() => {
                                                         onEdit('category', false)
                                                     }, 1500);
                                                 }}>
                                                {
                                                    editMode['category'] ? (
                                                        <div>
                                                            <CategorySelect
                                                                options={categories}
                                                                defaultValue={{
                                                                    label: formik.values.category,
                                                                    value: formik.values.category,
                                                                }}
                                                                handleCreateNewClick={(value) => {
                                                                    handleCreateCategoryClick(value)
                                                                    debouncedSubmit()
                                                                }}
                                                                label={"Category"}
                                                                className={styles.select}
                                                                handleDeleteClick={handeCategoryDelete}
                                                                name="category"
                                                                onChange={(checked, value) => {
                                                                    formik.setFieldValue(
                                                                        "category",
                                                                        checked ? value : ""
                                                                    );
                                                                    debouncedSubmit()
                                                                }}
                                                                value={formik.values.category}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <span>Category</span>
                                                            <div className={styles.categoryBadge}
                                                                 style={{backgroundColor: blockPickerColor}}
                                                                 onClick={() => {
                                                                     onEdit('category', true)
                                                                 }}
                                                            >
                                                                {data?.data.category?.toUpperCase()}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div className={styles.priority} onMouseEnter={() => {
                                                clearTimeout(priorityTimer.current)
                                                onEdit('priority', true)
                                            }}>
                                                <span>Priority</span>
                                                {
                                                    editMode['priority'] ? (
                                                        <div
                                                            onMouseEnter={() => {
                                                                clearTimeout(priorityTimer.current)
                                                            }}
                                                            onMouseLeave={() => {
                                                                priorityTimer.current = setTimeout(() => {
                                                                    onEdit('priority', false)
                                                                }, 1500)
                                                            }}>
                                                            <Select
                                                                options={PRIORITIES}
                                                                tickColor={PURPLE}
                                                                defaultValue={{
                                                                    label: formik.values.priority,
                                                                    value: formik.values.priority,
                                                                    icon: priority.icon,
                                                                }}
                                                                className={styles.select}
                                                                name='priority'
                                                                onBlur={formik.handleBlur}
                                                                touched={formik.touched.priority}
                                                                error={formik.errors.priority}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('priority', e.value)
                                                                    debouncedSubmit()
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div onMouseEnter={() => {
                                                            onEdit('priority', true)
                                                        }}>
                                                            <img src={priority.icon} alt=""/>
                                                            <p style={{color: priority.color}}>
                                                                {priority.label}
                                                            </p>
                                                        </div>

                                                    )
                                                }
                                            </div>
                                            <Select
                                                onBlur={formik.handleBlur}
                                                onChange={(o) => {
                                                    formik.setFieldValue("color", o.value);
                                                    updateTaskColor({color: o.value});
                                                    setBlockPickerColor(o.value);
                                                }}
                                                value={{
                                                    label: optionsColor.find((o) => o.value === blockPickerColor)?.label || blockPickerColor,
                                                    value: blockPickerColor,
                                                }}
                                                options={optionsColors}
                                                tickColor={PURPLE}
                                                label={"Color"}
                                                className={styles.select}
                                                buttonText="+ Add custom color"
                                                buttonOnClick={() => setIsClrModalOpen(true)}
                                            />
                                            <Select
                                                name={"status"}
                                                onChange={(o) => {
                                                    formik.setFieldValue("status", o.value);
                                                    formik.handleSubmit();
                                                }}
                                                options={STATUSES}
                                                value={STATUSES.find(
                                                    (s) => s.value === formik.values.status
                                                )}
                                                label={"Status"}
                                                className={styles.select}
                                            />
                                            <TagSelect
                                                name={"tag"}
                                                options={tagsData?.data.map((d) => {
                                                    return {label: d.name, value: d._id};
                                                })}
                                                onChange={(checked, id) => {
                                                    if (checked) {
                                                        formik.setFieldValue("tag", [
                                                            ...formik.values.tag,
                                                            id,
                                                        ]);
                                                    } else {
                                                        formik.setFieldValue("tag", [
                                                            ...formik.values.tag.filter((t) => t !== id),
                                                        ]);
                                                    }
                                                    formik.handleSubmit();
                                                }}
                                                handleDeleteClick={handleTagDelete}
                                                value={formik.values.tag}
                                                label={"Tag"}
                                                className={styles.select}
                                                handleCreateNewClick={handleCreateNewClick}
                                            />
                                            <CustomDatePicker
                                                label={"Due date"}
                                                className={`${styles.date}`}
                                                name="dueDate"
                                                date={formik.values.dueDate}
                                                handleSelectDate={(d) => {
                                                    formik.setFieldValue("dueDate", d);
                                                    formik.handleSubmit();
                                                }}
                                                touched={formik.touched.dueDate}
                                                error={formik.errors.dueDate}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className={styles.favorite} onClick={() => {
                                                updateIsFavorite({
                                                    isFavorite: !data?.data.isFavorite,
                                                    id: data?.data._id
                                                })
                                            }}>
                                                <div className={cn(styles.favorite__iconStar, {
                                                    [styles.favorite__iconStar__active]: data?.data.isFavorite
                                                })}
                                                     onClick={() => {
                                                         updateIsFavorite({
                                                             isFavorite: !data?.data.isFavorite,
                                                             id: data?.data._id
                                                         })
                                                     }}
                                                >
                                                    <svg width="21" height="20" viewBox="-0.5 0 21 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15.9185 12.3201C15.6595 12.5711 15.5405 12.9341 15.5995 13.2901L16.4885 18.2101C16.5635 18.6271 16.3875 19.0491 16.0385 19.2901C15.6965 19.5401 15.2415 19.5701 14.8685 19.3701L10.4395 17.0601C10.2855 16.9781 10.1145 16.9341 9.93951 16.9291H9.66851C9.57451 16.9431 9.48251 16.9731 9.39851 17.0191L4.96851 19.3401C4.74951 19.4501 4.50151 19.4891 4.25851 19.4501C3.66651 19.3381 3.27151 18.7741 3.36851 18.1791L4.25851 13.2591C4.31751 12.9001 4.19851 12.5351 3.93951 12.2801L0.32851 8.78012C0.0265096 8.48712 -0.0784904 8.04712 0.0595096 7.65012C0.19351 7.25412 0.53551 6.96512 0.94851 6.90012L5.91851 6.17912C6.29651 6.14012 6.62851 5.91012 6.79851 5.57012L8.98851 1.08012C9.04051 0.980122 9.10751 0.888122 9.18851 0.810122L9.27851 0.740122C9.32551 0.688122 9.37951 0.645122 9.43951 0.610122L9.54851 0.570122L9.71851 0.500122H10.1395C10.5155 0.539122 10.8465 0.764122 11.0195 1.10012L13.2385 5.57012C13.3985 5.89712 13.7095 6.12412 14.0685 6.17912L19.0385 6.90012C19.4585 6.96012 19.8095 7.25012 19.9485 7.65012C20.0795 8.05112 19.9665 8.49112 19.6585 8.78012L15.9185 12.3201Z"
                                                            fill="#FFDA18"/>
                                                    </svg>
                                                </div>
                                                <div className={styles.favorite__title}>
                                                    {data?.data.isFavorite ? "Remove from Favorites" : "Add to favorites"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
}
