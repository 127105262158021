import React from "react";
import WelcomeMessage from "./welcomeMessage/welcomeMessage";
import TodoList from "./todoList/todoList";
import TimeZones from "./timeZones/timeZones";
import CalendarWidget from "./calendar/calendarWidget/calendarWidget";
import Results from "./results/results";
import {useTitle} from "../../hooks/useTitle";
import CampaignWidget from "../widgets/campaigns/CampaignWidget";

function Dashboard() {
  useTitle("Dashboard")
  return (
    <>
    <div className="border border-xs dashboard-border">
      <div className="backCard d-flex wrap dashboardBlock">
        <WelcomeMessage preloader={ true } />
        <TimeZones />
        <TodoList />
        <CalendarWidget />
        <CampaignWidget />
        <Results />
      </div>
    </div>
    </>
  );
}

export default Dashboard;
