import React from "react";
import { LOGIN, REGISTER } from "../../const";
import Button from "../global/button/button";
import styles from "./suggestionBar.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

export default function SuggestionBar() {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname.includes(LOGIN)) {
    return (
      <div className={styles.suggestionBar}>
        <div className={styles.mainText}>Dont Have an Account Yet?</div>
        <div className={styles.helperText}>
          Let`s get you all set up! You can get to work in under 5 minutes.
        </div>
        <Button
          onClick={() => navigate("register", { replace: true })}
          text={"Sign Up"}
        />
      </div>
    );
  } else if (location.pathname.includes(REGISTER)) {
    return (
      <div className={styles.suggestionBar}>
        <div className={styles.mainText}>Already have an account?</div>
        <div className={styles.helperText}>
          Log in and get to your work now.
        </div>
        <Button
          onClick={() => navigate("login", { replace: true })}
          text={"Log in"}
        />
      </div>
    );
  }
}
