import * as Yup from "yup";

const checkEmailValidation = Yup.object().shape({
    code: Yup.number()
        .test('length', 'Must be exactly 6 characters!', v => v?.toString()?.length === 6)
        .required("Required!")
        .typeError("Required!"),

    userId: Yup.string()
        .required("Required!")
        .typeError("Required!")
});

export default checkEmailValidation;
