import styles from './calendar.module.scss';
import { useEffect, useState } from 'react';

import cn from 'classnames';
import moment from 'moment';
import smallArrow from '../../../media/icons/small_arrow.svg';

const Calendar = ( { setOverlay, startDate, endDate, setStartDate, setEndDate } ) => {

    moment.updateLocale('en', { week: { dow: 1 } });

    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectCount, setSelectCount] = useState(0);
    const [today, setToday] = useState(moment());

    const startDay = today.clone().startOf('month').startOf('week');
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


    const onPrevMonth = () => {
        setToday(prev => prev.clone().subtract(1, 'month'));
    }

    const onNextMonth = () => {
        setToday(prev => prev.clone().add(1, 'month'));
    }

    const onCalendarOpen = () => {
        setOpenCalendar(!openCalendar);
        setOverlay(!openCalendar)
    }

    const onCloseCalendar = () => // close calendar when click on button cancel
    {
        setOpenCalendar(false);
        setOverlay(false)
    }

    const day = startDay.clone().subtract(1, 'day');
    const daysMap = [...Array(42)].map(() => { // create calendar days
        return (
            {
                day: day.add(1, 'day').clone(),
                date: day.date(),
                isCurrentMonth: day.month() === today.month(),
                isToday: day.isSame(new Date(), 'day'),
                isStartDate: day.isSame(startDate, 'day'),
                isEndDate: day.isSame(endDate, 'day'),
                isPeriod: day.isBetween(startDate, endDate, 'day', '[]'),
                periodDays: day.diff(endDate, 'days'),
            }
        )
    });

    const onClickOutside = (e) => // close calendar on click outside
    {
        e.preventDefault();
        const element = e.target;
        const isClickOutSide = !element.closest(`.${styles.container}`);
        isClickOutSide && onCloseCalendar(); // close calendar if click outside
    }

    const onPeriodSelect = (day) => { // select period, start date and end date
        if (selectCount === 0) {
            setStartDate(day);
            setEndDate(day);
            setSelectCount(1);
        } else {
            if (day.isBefore(startDate)) {
                setStartDate(day);
            } else {
                setEndDate(day);
            }
            setSelectCount(0);
        }
    }

    useEffect(() => {
        window.addEventListener('click', onClickOutside); // add event listener on click outside
        return () => window.removeEventListener('click', onClickOutside);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.container__period} onClick={onCalendarOpen}>
                <div>{startDate.format('MMM DD, YYYY')} - {endDate.format('MMM DD, YYYY')}</div>
                <button onClick={onCalendarOpen}>
                    <img className={cn(styles.icon, {
                        [styles.icon__down]: openCalendar,
                    })} src={smallArrow} alt='icon'/>
                </button>
            </div>
            <div className={cn(styles.calendar, {
                [styles.calendar__open]: openCalendar,
            })}>
                <div className={styles.calendar__top}>
                    <div className={styles.calendar__top__date}>
                        {today.format('MMMM YYYY')}
                    </div>
                    <div className={styles.calendar__top__controls}>
                        <button className={cn(styles.calendar__top__controls__arrow,
                            styles.calendar__top__controls__arrow__left)}
                                type={'button'}
                                onClick={onPrevMonth}>
                        </button>
                        <button className={cn(styles.calendar__top__controls__arrow,
                            styles.calendar__top__controls__arrow__right)}
                                type={'button'}
                                onClick={onNextMonth}>
                        </button>
                    </div>
                </div>
                <div className={styles.grid}>
                    {
                        weekDays.map((day, index) => {
                            return (
                                <div key={index} className={styles.grid__day}>
                                    {day.slice(0, 3)}
                                </div>
                            )
                        })
                    }
                    {
                        daysMap.map((_el) => {
                            return (
                                <div className={cn(styles.grid__cell, {
                                    [styles.grid__cell__startDate]: _el.isStartDate,
                                    [styles.grid__cell__endDate]: _el.isEndDate,
                                    [styles.grid__cell__period]: _el.isPeriod && _el.periodDays !== 0,
                                    [styles.grid__cell__notCurrentMonth]: !_el.isCurrentMonth,
                                })}
                                     onClick={() => {
                                         onPeriodSelect(_el.day)
                                     }}>
                                    {_el.day.format('D')}
                                </div>
                            )
                        })
                    }
                </div>
                <div className={styles.buttons}>
                    <button className={cn(styles.button, styles.button__cancel)}
                            onClick={onCloseCalendar}>
                        Cancel
                    </button>
                    <button className={cn(styles.button)} onClick={onCloseCalendar}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Calendar;
