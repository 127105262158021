import React from "react";
import ActionButtonsGroup from "../actionButtonsGroup/actionButtonsGroup";
import Edit from "../../../media/icons/edit.png";
import CustomClock from "../../global/customClock/customClock";
import styles from "./timeZones.module.scss";
import save from "../../../media/icons/save.svg";
import CheckBox from "../../global/checkBox/checkBox";
import { useState } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { MY_TIMEZONE_VALUES, TIMEZONE_VALUES } from "../../../rquery/queryKeys";
import TimeZoneService from "../../../services/TimeZoneService";
import Search from "../../global/search/search";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment-timezone";
import { useRef } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Preloader from "../../preloader/preloader";

function TimeZones() {
  const [isEdit, setIsEdit] = useState(false);
  const userId = useSelector((state) => state.Authentication.id);
  const [initialTimeZoneValues, setInitialTimeZoneValues] = useState([]);
  const [timeZoneValues, setTimeZoneValues] = useState([]);
  const [initialMyTimeZoneValues, setInitialMyTimeZoneValues] = useState([]);
  const [myTimeZoneValues, setMyTimeZoneValues] = useState([]);
  const timeZoneRef = useRef();
  useOnClickOutside(timeZoneRef, () => setIsEdit(false));

  const { token } = useSelector((state) => state.Authentication);
  const queryClient = useQueryClient();

  const results = useQueries({
    queries: [
      {
        queryKey: [TIMEZONE_VALUES, isEdit, token],
        queryFn: () => TimeZoneService.getAll(token),
        enabled: isEdit && !!token,
      },
      {
        queryKey: [MY_TIMEZONE_VALUES, isEdit, token],
        queryFn: () => TimeZoneService.getMyAll(userId, token),
        enabled: !!token,
      },
    ],
  });

  const timeZoneComparator = (a, b) => {
    return a.selected ? -1 : 1;
  };

  const handleSearchChange = (e) => {
    let _input = e.target.value.trim().toLowerCase();
    if (_input === "")
      setTimeZoneValues(initialTimeZoneValues.sort(timeZoneComparator));
    setTimeZoneValues(
      initialTimeZoneValues
        .filter((itv) => itv.name.toLowerCase().includes(_input))
        .sort(timeZoneComparator)
    );
  };

  const handleCheckBoxChange = (e, _id) => {
    const _checked = e.target.checked;

    if (_checked) {
      setMyTimeZoneValues((prev) => [...prev, { timezone: { _id } }]);
    } else {
      setMyTimeZoneValues((prev) => prev.filter((p) => p.timezone._id !== _id));
    }

    const _modifyExpression = (prev) =>
      prev.map((p) => (p._id === _id ? { ...p, selected: _checked } : p));

    setTimeZoneValues(_modifyExpression);
    setInitialTimeZoneValues(_modifyExpression);
  };

  const { mutate } = useMutation(
    ({ deleteIds, postIds }) => {
      if (deleteIds?.ids?.length && !postIds?.timezones?.length) {
        return TimeZoneService.deleteRange(deleteIds, token);
      } else if (postIds?.timezones?.length && !deleteIds?.ids?.length) {
        return TimeZoneService.post(postIds, token);
      } else if (!(postIds?.timezones?.length || deleteIds?.ids?.length)) {
        return;
      }
      return Promise.all([
        TimeZoneService.post(postIds, token),
        TimeZoneService.deleteRange(deleteIds, token),
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TIMEZONE_VALUES]);
        queryClient.invalidateQueries([MY_TIMEZONE_VALUES]);
        setIsEdit(false);
      },
    }
  );

  const handleSaveBtnClick = (e) => {
    e.stopPropagation();
    const deleteIds = {
      ids: initialMyTimeZoneValues
        .filter(
          (itm) =>
            !myTimeZoneValues.some(
              (mtv) => mtv.timezone._id === itm.timezone._id
            )
        )
        .map((itm) => itm._id),
    };

    const postIds = {
      timezones: myTimeZoneValues
        .filter(
          (mtv) =>
            !initialMyTimeZoneValues.some(
              (itm) => itm.timezone._id === mtv.timezone._id
            )
        )
        .map((mtv) => mtv.timezone._id),
    };

    mutate({ deleteIds, postIds });
  };

  const removeUnderline = (str) => {
    if (str.match(/_/g))
    {
      return str.replace(/_/g, " ");
    }
    return str;
  }

  const isLoading = results.some((q) => q.isLoading);

  useEffect(() => {
    if (isLoading) return;
    const _allTimeZoneValues = results[0]?.data?.data
    let _allMyTimeZoneValues = results[1]?.data?.data
    if (_allMyTimeZoneValues?.length) {
      const _mergedTimeZoneValues = _allTimeZoneValues?.map((atv) =>
        _allMyTimeZoneValues.some((amt) => amt.timezone._id === atv._id)
          ? { ...atv, selected: true }
          : { ...atv, selected: false }
      );
      setInitialTimeZoneValues(_mergedTimeZoneValues);
      setTimeZoneValues(_mergedTimeZoneValues.sort(timeZoneComparator));
      setInitialMyTimeZoneValues(_allMyTimeZoneValues);
      setMyTimeZoneValues(_allMyTimeZoneValues);
      return;
    }
    const _defaultTimeZoneValues = _allTimeZoneValues?.map((atv) => ({
      ...atv,
      selected: false,
    }));
    setInitialTimeZoneValues(_defaultTimeZoneValues);
    setTimeZoneValues(_defaultTimeZoneValues.sort(timeZoneComparator));
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div ref={timeZoneRef} className={`box d-flex col ${styles.container}`}>
      <Preloader isActive={ !results[1]?.data?.data.length > 0 } borderRadius />
      <div className={styles.header}>
        <div className={styles.up}>
          <h4 className="boxTitle">Timezones</h4>
          {!isEdit ? (
            <ActionButtonsGroup
              buttons={[
                {
                  icon: Edit,
                  cb: () => setIsEdit(true),
                },
              ]}
            />
          ) : (
            <div onClick={handleSaveBtnClick} className={styles.saveContainer}>
              <img src={save} alt="" />
              <span>Save</span>
            </div>
          )}
        </div>
      </div>
      {!isEdit ? (
        <div className={styles.timeZones}>
          {results[1]?.data?.data &&
            results[1]?.data?.data.map((t, i) => {
              return (
                <CustomClock
                  key={i}
                  name={t.timezone.name}
                  city={t.timezone.city}
                />
              );
            })}
        </div>
      ) : (
        <div className={styles.editTimeZones}>
          <Search
            onChange={handleSearchChange}
            placeholder="Find city"
            hasOptions={false}
            className={styles.search}
          />
          <div className={styles.timeZoneList}>
            {timeZoneValues &&
              timeZoneValues.map((t) => {
                return (
                  <div
                    key={t.name}
                    className={`${styles.timeZoneItem} ${
                      t.selected ? styles.active : ""
                    }`}
                  >
                    <CheckBox
                      onChange={(e) => handleCheckBoxChange(e, t._id)}
                      defaultChecked={t.selected}
                      label={removeUnderline(t.name) === 'Europe/Kiev' ? 'Europe/Kyiv' : removeUnderline(t.name)}
                    />
                    <span className={styles.currentTime}>
                      {moment().tz(t.name).format("LT")}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default TimeZones;
