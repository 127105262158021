export const USERS_MOCK = () => {
  const users = [
    {
      name: "Esthera Jackson",
      earn: "$4,45",
      roas: "42",
      img: "/mock/user6.png",
      id: 1,
      email: "Esthera@gmail.com",
    },
    {
      name: "Alexa Liras",
      earn: "$4,22",
      roas: "18",
      img: "/mock/user3.jpg",
      id: 2,
      email: "Alexa@gmail.com",
    },
    {
      name: "Reaaaallllly loooongName",
      earn: "$4,22",
      roas: "22",
      img: "/mock/user5.png",
      id: 3,
      email: "Laurent@gmail.com"
    },
    {
      name: "Freduardo Hill",
      earn: "$34,22",
      roas: "11",
      img: "/mock/user4.jpg",
      id: 4,
      email: "Freduardo@gmail.com",
    },
  ];

  for (let i = 6; i < 570; i++) {
    users.push({ ...users[Math.round(Math.random() * 4)], id: i });
  }
  return users;
};
