import React from 'react';
import styles from '../authenticationLayout.module.scss';
import stylesRegistration from './registration.module.scss';
import Logo from '../../../media/Mint_Media_Logo.webp';
import eye from '../../../media/icons/eye.svg';
import eye_close from '../../../media/icons/eye_close.svg';
import Button from '../../global/button/button';
import {GLOSSY} from '../../../const';
import {useNavigate} from 'react-router-dom';
import {useTitle} from '../../../hooks/useTitle';

import {useFormik} from 'formik';
import {useOpen} from '../../../hooks/useOpen';
import registrationValidation from '../../../validations/authentication/registrationValidation';
import authService from '../../../services/AuthenticationService';
import SocialLogins from '../socialLogins';

import {useMutation} from 'react-query';
import {toast} from 'react-toastify';

export default function Registration() {
    useTitle('Registration');
    let navigate = useNavigate();

    const {mutate} = useMutation((values) => authService.register(values), {
        onSuccess: (data) => {
            navigate('../login', {replace: true});
            toast.success(data.data.message);
            toast.info('Please confirm your account via email');
        },
        onError: (error) => {
            toast.error(error.response.data);
        },
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: null,
            email: null,
            password: null,
            agreedToTermsAndConditions: false,
        },
        validationSchema: registrationValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const {isOpen, onToggle} = useOpen();

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.logo}>
                <img alt={''} src={Logo}/>
            </div>
            <div className={styles.loginTitle}>
                <h1>
                    Create{' '}
                    <span className="d-none d-md-inline">your Account</span>
                    <span className="d-md-none">or</span>
                    <button
                        onClick={() => navigate('../login', {replace: true})}
                        className={`d-md-none ${stylesRegistration.linkBtn}`}
                    >
                        Log in
                    </button>
                </h1>
                <span className={styles.description}>
                    Let`s get you all set so you can start working more efficiently.
                </span>
            </div>

            <SocialLogins/>

            <div className={styles.separatorOR}></div>

            <div className="w-100">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formGroup">
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.name &&
                                Boolean(formik.errors.name) &&
                                'error'
                            }`}
                        >
                            <label>Name</label>
                            <input
                                name="name"
                                className={styles.input}
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.name}
                                onBlur={formik.handleBlur}
                                type="text"
                                placeholder=""
                            />
                            <span
                                className={stylesRegistration.validationHelper}
                            >
                                {formik.touched.name && formik.errors.name}
                            </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.email &&
                                Boolean(formik.errors.email) &&
                                'error'
                            }`}
                        >
                            <label>Email</label>
                            <input
                                name="email"
                                className={styles.input}
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.email}
                                onBlur={formik.handleBlur}
                                type="email"
                                placeholder=""
                            />
                            <span
                                className={stylesRegistration.validationHelper}
                            >
                                {formik.touched.email && formik.errors.email}
                            </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.password &&
                                Boolean(formik.errors.password) &&
                                'error'
                            }`}
                        >
                            <label>Password</label>
                            <div className={styles.inputBox}>
                                <input
                                    name="password"
                                    className={styles.input}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.password}
                                    onBlur={formik.handleBlur}
                                    type={isOpen ? 'text' : 'password'}
                                    placeholder=""
                                />
                                <div
                                    className={styles.btnEye}
                                    onClick={onToggle}
                                >
                                    <img
                                        src={isOpen ? eye : eye_close}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <span
                                className={stylesRegistration.validationHelper}
                            >
                                {formik.touched.password &&
                                    formik.errors.password}
                            </span>
                        </div>
                    </div>
                    <div className={`formBottom d-flex jc-sb ai-center`}>
                        <div
                            className={`customCheckbox ${
                                formik.touched.agreedToTermsAndConditions &&
                                Boolean(
                                    formik.errors.agreedToTermsAndConditions
                                ) &&
                                'error'
                            }`}
                        >
                            <label>
                                <input
                                    name="agreedToTermsAndConditions"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="checkbox"
                                />
                                <span></span>I agree to the Terms and Conditions
                            </label>
                        </div>
                    </div>
                    <Button
                        behaviour={'submit'}
                        className={stylesRegistration.button}
                        text={'Sign Up'}
                        type={GLOSSY}
                    />
                </form>
            </div>

            <div className={styles.dontHaveAcc}>
                Already have an account? <a href="./login">Log in</a>
            </div>
        </div>
    );
}
