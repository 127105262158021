import React from "react";
import Dashboard from "../../../media/icons/nav/dashboard.svg";
import Dashboard_active from "../../../media/icons/nav/dashboard_active.svg";

import Projects from "../../../media/icons/nav/projects.svg";
import Projects_active from "../../../media/icons/nav/projects_active.svg";

import Discussions from "../../../media/icons/nav/discussions.svg";
import Discussions_active from "../../../media/icons/nav/discussions_active.svg";

import Leaderboard from "../../../media/icons/nav/leaderboard.svg";
import Leaderboard_active from "../../../media/icons/nav/leaderboard_active.svg";

import Users from "../../../media/icons/nav/users.svg";
import Users_active from "../../../media/icons/nav/users_active.svg";
// Components
import MenuLink from "./menuLink/menuLink";
import {useOpen} from "../../../hooks/useOpen";
import {useDispatch, useSelector} from "react-redux";
import Button from "../button/button";
import {SOLID} from "../../../const";
import quit from "../../../media/icons/quit2.svg";
import {logOut} from "../../../redux/slices/authentication";

function Sidebar() {
    const {isOpen: min, onToggle} = useOpen();
    const isAdmin = useSelector((state) => state?.Authentication?.isAdmin);
    const dispatch = useDispatch();
    const handleLogOut = () => {
        dispatch(logOut());
    };

    return (
        <div className={"sidebar " + (min ? " minimized " : " ")}>
            <div className="sidebar-wrapper">
                <div className="navmenu">
                    {!isAdmin && (
                        <MenuLink
                            title="Dashboard"
                            path="/"
                            icon={Dashboard}
                            activeIcon={Dashboard_active}
                        />
                    )}
                    {!isAdmin && (
                        <MenuLink
                            title="Projects"
                            path="/projects"
                            icon={Projects}
                            activeIcon={Projects_active}
                        />
                    )}
                    {!isAdmin && (
                        <MenuLink
                            title="Campaigns"
                            path="/campaigns"
                            icon={Dashboard}
                            activeIcon={Dashboard_active}
                        />
                    )}
                    <MenuLink
                        title="Discussions"
                        path="/forums"
                        icon={Discussions}
                        activeIcon={Discussions_active}
                    />
                    <MenuLink
                        title="Leaderboard"
                        path="/leaderboard"
                        icon={Leaderboard}
                        activeIcon={Leaderboard_active}
                    />
                    {isAdmin && (
                        <MenuLink
                            title="Users"
                            path="/users"
                            icon={Users}
                            activeIcon={Users_active}
                        />
                    )}
                </div>
                <button onClick={onToggle} className={"sidebar-min"}>
                    {" "}
                    minimize nav
                    <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="17"
                            cy="17"
                            r="17"
                            transform="rotate(-180 17 17)"
                            fill="white"
                        />
                        <path d="M15 11.925L20 16.9625L15 22" stroke="#373D59"/>
                    </svg>
                </button>
            </div>
            <Button
                onClick={handleLogOut}
                icon={quit}
                text={"Log out"}
                type={SOLID}
                className="sidebarLogOut onlyMd"
            />
            <span className="ver">v1.0</span>
        </div>
    );
}

export default Sidebar;
