import React, {useCallback, useState} from "react";
import styles from "./users.module.scss";
import {Checkbox} from "../adminParts/adminParts";
import Button from "../../global/button/button";
import {GLOSSY, SOLID} from "../../../const/index";
import pencil from "../../../media/icons/pencil.svg";
import Modal from "../../global/modal/modal";
import close from "../../../media/icons/close_icon.svg";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {LEADERBOARD_ITEM, LEADERBOARD_ITEMS} from "../../../rquery/queryKeys";
import LeaderBoardService from "../../../services/LeaderBoardService";
import {useFormik} from "formik";
import leaderBoardPutValidation from "../../../validations/leaderBoard/leaderBoardPutValidation";
import {useSelector} from "react-redux";

export const LeadItem = ({
                             total,
                             id,
                             _id,
                             imageURL,
                             name,
                             roas,
                             handleCheckBoxChange,
                             deleteList,
                         }) => {
    let star = " ";
    let first3 = " ";
    if (id < 4) {
        star = " star" + id;
        first3 = styles.first;
    }
    const {isAdmin} = useSelector((state) => state?.Authentication);
    const [isModalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient();

    const {data} = useQuery(
        [LEADERBOARD_ITEM, _id, isModalOpen],
        () => LeaderBoardService.get(_id),
        {
            enabled: isModalOpen,
        }
    );

    const {mutate} = useMutation((data) => LeaderBoardService.put(data, _id), {
        onSuccess: () => {
            queryClient.invalidateQueries([LEADERBOARD_ITEM]);
            queryClient.invalidateQueries([LEADERBOARD_ITEMS]);
            setModalOpen(false);
        },
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data?.data[0]?.name,
            total: data?.data[0]?.total,
            roas: data?.data[0]?.roas,
        },
        validationSchema: leaderBoardPutValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleOpenViewModal = () => {
        setModalOpen(true);
    };

    const handleOpenEditModal = () => {
        setModalOpen(true);
    };

    const _renderUserModal = () => {
        return (
            <Modal
                className={styles.modal}
                onClose={handleCloseModal}
                open={isModalOpen}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>Edit user</p>
                        <button onClick={handleCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.name && Boolean(formik.errors.name) && "error"
                            } ${styles.textInput}`}
                        >
                            <label>Name:</label>
                            <input
                                type={"text"}
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.name}
                                onBlur={formik.handleBlur}
                                name="name"
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.name && formik.errors.name}
              </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.total && Boolean(formik.errors.total) && "error"
                            } ${styles.textInput}`}
                        >
                            <label>Total Earned:</label>
                            <input
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.total}
                                onBlur={formik.handleBlur}
                                name="total"
                                type="number"
                                placeholder=""
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.total && formik.errors.total}
              </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.roas && Boolean(formik.errors.roas) && "error"
                            } ${styles.textInput}`}
                        >
                            <label>ROAS:</label>
                            <input
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.roas}
                                name="roas"
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder=""
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.roas && formik.errors.roas}
              </span>
                        </div>
                        <Button
                            type={GLOSSY}
                            className={styles.saveBtn}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <div
            className={[
                "lead-grid ",
                styles.wrap,
                isAdmin ? styles.adminWrap : "",
                first3,
            ].join(" ")}
        >
            {_renderUserModal()}
            <>
                {isAdmin && (
                    <Checkbox
                        value={deleteList.find((d) => d._id === _id)}
                        onChange={(e) => handleCheckBoxChange(e, {_id, name})}
                        className={"onlyMobile"}
                        key={_id}
                    />
                )}
                <div>
                    <span className={star + " id "}> {id} </span>{" "}
                </div>
                <div className={styles.profile}>
                    {isAdmin ? (
                        <Button
                            onClick={handleOpenViewModal}
                            icon={imageURL || "/defaultUser.svg"}
                            className={styles.profileImg}
                        />
                    ) : (
                        <div className={styles.profileImg}>
                            <img src={imageURL || "/defaultUser.svg"} alt="user avatar"/>
                        </div>
                    )}
                    {isAdmin ? (
                        <Button
                            onClick={handleOpenViewModal}
                            className={styles.profileName}
                            text={name}
                        />
                    ) : (
                        <span className={styles.profileName}>{name}</span>
                    )}
                </div>
                <div>${total}</div>
                <div>{roas}</div>
                {isAdmin && (
                    <div className="onlyMobile">
                        <button
                            onClick={handleOpenEditModal}
                            className={styles.miniEditBtn}
                        >
                            <img src={pencil} alt=""/>
                        </button>
                    </div>
                )}
                {isAdmin && (
                    <div className={styles.checkBoxWrapper + " onlyMd"}>
                        <Checkbox
                            key={_id}
                            value={deleteList.find((d) => d._id === _id)}
                            onChange={(e) => handleCheckBoxChange(e, {_id, name})}
                        />
                    </div>
                )}
                {isAdmin && (
                    <div className={styles.editBtnWrapper + " onlyMd"}>
                        <Button
                            onClick={handleOpenEditModal}
                            className={styles.editBtn}
                            icon={pencil}
                            text={"Edit"}
                            type={SOLID}
                        />
                    </div>
                )}
            </>
        </div>
    );
};
