import styles from './timePicker.module.scss';
import {useEffect, useState} from "react";
import moment from "moment";

const TimePicker = ({ time, setFieldValue, onBlur, name }) => {

    const [showOptions, setShowOptions] = useState(false);
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinute, setSelectedMinute] = useState('');
    const [selectedTime, setSelectedTime] = useState(`${selectedHour}:${selectedMinute}`);

    useEffect(() => {
        let hour = time?.replace(/PM/g, '').replace(/AM/g, '').split(':')[0];
        let minute = time?.replace(/PM/g, '').replace(/AM/g, '').split(':')[1];

        hour = +hour < 10 ? `0${+hour}` : +hour;
        minute = +minute < 10 ? `0${+minute}` : +minute;

        if (`${hour}:${minute}` !== selectedTime) {
            setSelectedHour(hour);
            setSelectedMinute(minute);
        }

    }, [time]);


    const setFormikValues = (_value) => {
        const value = moment(_value, 'HH:mm A').isValid() ? moment(_value, 'HH:mm A').format('hh:mm A') : moment(_value, 'HH:mm').format('hh:mm A');
        if (name === 'startTime') {
            setFieldValue('startTime', value);
        } else if (name === 'endTime') {
            setFieldValue('endTime', value);
        }
    }

    useEffect(() => {
        if (selectedHour && selectedMinute) {
            setSelectedTime(`${selectedHour}:${selectedMinute}`);
        }

        if (selectedTime) {
            setFormikValues(selectedTime);
        }

    }, [selectedHour, selectedMinute])


    useEffect(() => {
        if (selectedTime) {
            setFormikValues(selectedTime);
        }
    }, [selectedTime])

    return (
        <div className={styles.timePicker} onClick={() => setShowOptions(!showOptions)}>
            <input className={styles.value}
                   type="time"
                   value={selectedTime}
                   name={name}
                   onBlur={onBlur}
                   onChange={(e) => {
                       setSelectedTime(e.target.value);
                   }}

            />
        </div>
    );
};

export default TimePicker;
