import React from "react";
// import { lazy } from "react";
import AuthenticationLayout from "../components/authentication/authenticationLayout";
import CheckEmail from "../components/authentication/checkEmail/checkEmail";
import CreatePassword from "../components/authentication/createPassword/createPassword";
import Login from "../components/authentication/logIn/login";
import Registration from "../components/authentication/registration/registration";
import ResetPassword from "../components/authentication/resetPassword/resetPassword";
import { Navigate } from "react-router-dom";

// eslint-disable-next-line
export default function (isLoggedIn) {
  return {
    path: "/auth",
    element: <AuthenticationLayout />,
    children: [
      {
        path: "login",
        element: !isLoggedIn ? <Login /> : <Navigate to={"/"} />,
      },
      {
        path: "register",
        element: !isLoggedIn ? <Registration /> : <Navigate to={"/"} />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "create-password",
        element: <CreatePassword />,
      },
      {
        path: "check-email",
        element: <CheckEmail />,
      },
      {
        path: "auth/*",
        element: <p>Not found</p>,
      },
    ],
  };
}
