import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./authenticationLayout.module.scss";
import Arrow from "../../media/icons/arrow.svg";
import SuggestionBar from "./suggestionBar";
import { useLocation } from "react-router-dom";
import { LOGIN, REGISTER } from "../../const/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AuthenticationLayout = () => {
  const location = useLocation();

  const _renderLayout = () => {
    if (location.pathname.includes(LOGIN)) {
      return (
        <div className={styles.auth}>
          <div className={styles.backLinkBlock}>
            <a href="./#" className={styles.backLink}>
              <img src={Arrow} alt="" title="" />
            </a>
          </div>
          <div className={`${styles.authWhite} ${styles.authWhiteLogin}`}>
            <Outlet />
          </div>
          <div
            className={`${styles.authPurpleSplit} ${styles.authPurpleSplitLogin}`}
          >
            <SuggestionBar type={LOGIN} />
          </div>
        </div>
      );
    } else if (location.pathname.includes(REGISTER)) {
      return (
        <div className={styles.auth}>
          <div className={styles.backLinkBlock}>
            <a href="./#" className={styles.backLink}>
              <img src={Arrow} alt="" title="" />
            </a>
          </div>
          <div
            className={`${styles.authPurpleSplit} ${styles.authPurpleSplitRegister}`}
          >
            <SuggestionBar type={REGISTER} />
          </div>
          <div className={`${styles.authWhite} ${styles.authWhiteRegister}`}>
            <Outlet />
          </div>
        </div>
      );
    } else {
      return <Outlet />;
    }
  };

  return (
    <>
      <ToastContainer />
      {_renderLayout()}
    </>
  );
};

export default AuthenticationLayout;
