import React from "react";
import styles from "./resetPassword.module.scss";
import logo from "../../../media/Mint_Media_Logo.webp";
import Button from "../../global/button/button";
import Arrow from "../../../media/icons/arrow.svg";
import {GLOSSY, TRANSPARENT} from "../../../const";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../../../hooks/useTitle";
import {useFormik} from "formik";
import requestResetPasswordValidation from "../../../validations/authentication/requestResetPasswordValidation";
import stylesLogin from "../logIn/login.module.scss";
import {useMutation} from "react-query";
import AuthenticationService from "../../../services/AuthenticationService";
import {useDispatch} from "react-redux";
import {setResetPasswordToken} from "../../../redux/slices/authentication";

export default function ResetPassword() {
    useTitle("ResetPassword");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {mutate} = useMutation(
        (data) => AuthenticationService.requestResetPassword(data),
        {
            onSuccess: (data) => {
                const {verificationCode, userId} = data?.data
                dispatch(setResetPasswordToken({verificationCode, userId}))
                navigate('../check-email')
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: null,
        },
        validationSchema: requestResetPasswordValidation,
        onSubmit: (values) => {
            mutate(values)
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.backLinkBlock}>
                <Button
                    onClick={() => navigate("/auth/login", {replace: true})}
                    icon={Arrow}
                    className={styles.backLink}
                />
            </div>
            <div className={styles.resetWrapper}>
                <div className={styles.logo}>
                    <img alt={''} src={logo}/>
                </div>
                <div className={styles.resetTitle}>
                    <h1>Reset Password</h1>
                </div>
                <div className={styles.resetSubtitle}>
                    Please enter the email address you’d like your password reset
                    information sent to
                </div>
                <form className="w-100">
                    <div className="formGroup">
                        <div
                            className={`formControls d-flex col ${formik.touched.email && Boolean(formik.errors.email) && "error"}`}>
                            <label>Email</label>
                            <input onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   name={'email'}
                                   type="email"
                                   defaultValue={formik.initialValues.email}
                                   placeholder=""/>
                            <span className={stylesLogin.validationHelper}>
                   {formik.touched.email && formik.errors.email}
               </span>
                        </div>
                    </div>
                </form>
                <div className={styles.btnGroup}>
                    <Button onClick={formik.handleSubmit} type={GLOSSY} text="Request reset link"/>
                    <Button
                        onClick={() => navigate("/auth/login", {replace: true})}
                        className={styles.backTo}
                        type={TRANSPARENT}
                        text="Back to log in"
                    />
                </div>
            </div>
        </div>
    );
}
