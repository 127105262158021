import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GLOSSY, TRANSPARENT } from "../../../../const";
import Button from "../../../global/button/button";
import TextEditor from "../../../global/textEditor/textEditor";
import styles from "./createNote.module.scss";
import { useTitle } from "../../../../hooks/useTitle";
import sanitize from "sanitize-html";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { NOTE, NOTES } from "../../../../rquery/queryKeys";
import NoteService from "../../../../services/NoteService";
import { useSelector } from "react-redux";
import notePostValidation from "../../../../validations/note/notePostValidation";

export default function CreateNote() {
  useTitle("New Note");
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const queryClient = useQueryClient();

  const { id } = useParams();
  const { token } = useSelector((state) => state.Authentication);

  const { mutate } = useMutation((data) => NoteService.post(id, data, token), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([NOTES]);
      queryClient.invalidateQueries([NOTE]);
      navigate(`/projects/${id}/notes`);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: null,
      description: null,
    },
    validationSchema: notePostValidation,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const handleEditorChange = (v) => {
    let _sanitizedText = sanitize(v);
    formik.setFieldValue("description", _sanitizedText);
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div
          className={`formControls d-flex col ${
            formik.touched.name && Boolean(formik.errors.name) && "error"
          }`}
        >
          <label>Title</label>
          <input
            name="name"
            onChange={formik.handleChange}
            defaultValue={formik.initialValues.name}
            onBlur={formik.handleBlur}
            type="text"
          />
          <span className={styles.validationHelper}>
            {formik.touched.name && formik.errors.name}
          </span>
        </div>
        <div className="formControls d-flex col">
          <label>Content</label>
          <TextEditor
            onChange={handleEditorChange}
            className={styles.editor}
          />
        </div>
      </div>

      <div className={styles.rightButtonGroup}>
        <Button text={"Cancel"} onClick={handleGoBack} type={TRANSPARENT} />
        <Button
          text={"Save"}
          onClick={formik.handleSubmit}
          type={GLOSSY}
          className={styles.btnSave}
        />
      </div>
    </div>
  );
}
