import http from '../http-common';

const get = (categoryId, page, limit, token) => {
    return http.get(
        `/forum/discussion?category=${categoryId}&page=${page}&limit=${limit}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

const getCount = (categoryId, token) => {
    return http.get(`forum/category/${categoryId}/count`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post(`forum/discussion`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`forum/discussion/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (id, data, token) => {
    return http.put(`forum/discussion/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`forum/discussion/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const postFile = (id, data, token) => {
    return http.post(`forum/discussion/${id}/files`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};

const deleteFile = (id, token) => {
    return http.delete(`forum/discussion/files/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const ForumService = {
    get,
    getCount,
    post,
    getById,
    put,
    deleteById,
    postFile,
    deleteFile,
};

export default ForumService;
