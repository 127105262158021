import React, {useEffect} from 'react';
import {
    GLOSSY,
    WEEK_DAYS,
    HOURS_IN_DAY,
    DAILY_EVENTS,
    DATE_ONLY,
    TRANSPARENT,
    MONTH,
    generateYears, SOLID,
} from '../../../const';
import BreadCrumb from '../../global/breadCrumb/breadCrumb';
import Button from '../../global/button/button';
import {BACK} from '../../../const';
import back from '../../../media/icons/arrow_back.svg';
import styles from './calendar.module.scss';
import miniCalendar from './miniCalendar/miniCalendar.module.scss';
import ActionButtonsGroup from '../actionButtonsGroup/actionButtonsGroup';
import Plus from '../../../media/icons/plus.png';
import left_arrow from '../../../media/icons/left_arrow.svg';
import {useNavigate} from 'react-router-dom';
import right_arrow from '../../../media/icons/right_arrow.svg';
import SelectBadge from '../../global/selectBadge/selectBadge';
import useSwitchTab from '../../../hooks/useSwitchTab';
import {generateCalendar} from '../../../utils/calendar';
import moment from 'moment';
import Modal from '../../global/modal/modal';
import {useCallback} from 'react';
import {useState} from 'react';
import close from '../../../media/icons/close_icon.svg';
import tick from '../../../media/icons/tick_white.svg';
import CustomDatePicker from '../../global/customDatePicker/customDatePicker';
import {useLayoutEffect} from 'react';
import MiniCalendar from './miniCalendar/miniCalendar';
import {useTitle} from '../../../hooks/useTitle';
import {useFormik} from 'formik';
import calendarPostValidation from '../../../validations/calendar/calendarPostValidation';
import {useMutation} from 'react-query';
import GoogleCalendarService from '../../../services/GoogleCalendarService';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryClient} from 'react-query';
import {EVENT, EVENTS} from '../../../rquery/queryKeys';
import {useQuery} from 'react-query';
import {COLORS} from '../../../mock';
import TimePicker from "./timePicker/timePicker";
import delete_icon from "../../../media/icons/delete_gray.svg";
import warning from "../../../media/icons/red_warning.svg";
import {toast} from "react-toastify";
import {updateToken} from "../../../redux/slices/authentication";

const Calendar = () => {
    const dispatch = useDispatch();

    useTitle('Calendar');
    let today = new Date();
    const {currentTab} = useSwitchTab(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentEventId, setCurrentEventId] = useState();
    const [currentEvent, setCurrentEvent] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [calendarDate, setCalendarDate] = useState({
        month: today.getMonth(),
        year: today.getFullYear(),
    });

    const {prevDates, currentDates, postDates} = generateCalendar(
        calendarDate.month,
        calendarDate.year
    );

    const allDates = [...prevDates, ...currentDates, ...postDates];
    const [isMobile, setMobile] = useState();
    const [isTablet, setIsTablet] = useState();
    const navigate = useNavigate();

    const { google } = useSelector(
        (state) => state?.Authentication
    );

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleOpenModal = (id) => {
        if (!id) {
            setCurrentEvent(null)
            setModalOpen(true);
            return
        }

        setCurrentEventId(id);
        setModalOpen(true);
    };


    const queryClient = useQueryClient();

    const {mutate} = useMutation(
        (data) => {
            if (currentEvent) {
                return GoogleCalendarService.put(currentEventId, data, google.accessToken)
            } else {
                return GoogleCalendarService.post(data, google.accessToken)
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([EVENTS]);
                queryClient.invalidateQueries([EVENT]);
                toast.success(currentEvent ? 'Event updated successfully' : 'Event created successfully');
                handleCloseModal();
            },
        }
    );

    const {data, refetch} = useQuery(
        [EVENTS, google.accessToken, calendarDate.month, calendarDate.year],
        () => GoogleCalendarService.get(
            new Date(calendarDate.year, calendarDate.month).toISOString(),
            new Date(calendarDate.year, calendarDate.month + 1).toISOString(),
            google.accessToken
        ),
        {
            onError: () => {
                    GoogleCalendarService.getNewToken(google.refreshToken).then((res) => {
                        dispatch(updateToken(res.data));
                        refetch();
                    });
            },
        },
        {
            enabled: !!google.accessToken,
        }
    );

    const {dataEvent} = useQuery(
        [EVENT, google.accessToken, currentEventId],
        () => GoogleCalendarService.getById(currentEventId, google.accessToken),
        {
            onSuccess: (data) => {
                setCurrentEvent(data?.data);
            },
            onError: () => {
                if (GoogleCalendarService.isTokenExpired(google.accessToken)) {
                    GoogleCalendarService.getNewToken(google.refreshToken).then((res) => {
                        dispatch(updateToken(res.data));
                    });
                }
            },
            enabled: !!currentEventId,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (currentEventId) {
            refetch();
        } else {
           refetch();
        }
    }, [currentEventId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            summary: currentEvent?.summary,
            description: currentEvent?.description,
            startDate: currentEvent?.start?.dateTime
                ? new Date(currentEvent?.start?.dateTime)
                : null,
            endDate: currentEvent?.start?.endTime || null,
            startTime: currentEvent?.start?.dateTime
                ? moment(currentEvent?.start?.dateTime).format('hh:mm A')
                : null,
            endTime: currentEvent?.end?.dateTime
                ? moment(currentEvent?.end?.dateTime).format('hh:mm A')
                : null,
            colorId: currentEvent?.colorId,
        },
        validationSchema: calendarPostValidation,
        onSubmit: (values) => {
            const {description, summary, startDate, endDate, colorId} = values;
            const data = {
                end: {
                    dateTime: endDate,
                },
                start: {
                    dateTime: startDate,
                },
                description,
                summary,
                colorId: colorId || 0,
            };
            mutate(data);
        },
    });

    const handleDateChange = (date) => {
        let _startTime = moment(formik.values.startTime, 'hh:mm A', true).isValid()
            ? moment(formik.values.startTime, 'hh:mm A', true)
            : null;
        let _endTime = moment(formik.values.endTime, 'hh:mm A', true).isValid()
            ? moment(formik.values.endTime, 'hh:mm A', true)
            : null;

        let _startDate =
            _startTime && date
                ? moment(date)
                    .set({
                        hour: _startTime.get('hours'),
                        minute: _startTime.get('minutes'),
                    })
                    .toDate()
                : date;

        let _endDate =
            _endTime && date
                ? moment(date)
                    .set({
                        hour: _endTime.get('hours'),
                        minute: _endTime.get('minutes'),
                    })
                    .toDate()
                : null;

        formik.setFieldValue('startDate', _startDate);
        formik.setFieldValue('endDate', _endDate);
    };

    const handleResize = () => {
        let width = window.innerWidth;

        if (width >= 768) {
            setIsTablet(false);
        } else {
            setIsTablet(true);
        }

        if (width >= 992) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        handleDateChange(formik.values.startDate);
        // eslint-disable-next-line
    }, [formik.values.startTime, formik.values.endTime]);

    const _renderDates = () => {
        let rows = [];
        for (let i = 1; i <= allDates[allDates.length - 1].row; i++) {
            rows.push(
                <tr key={i}>
                    {allDates
                        .filter((ad) => ad.row === i)
                        .map((d, index) => {
                            let _filteredEvents = data?.data.items
                                .filter(
                                    (e) =>
                                        new Date(e.start?.dateTime).getDate() === d.value &&
                                        new Date(e.start?.dateTime).getMonth() === d.date.getMonth()
                                )
                                .sort((a, b) =>
                                    moment.utc(
                                        moment(a.start?.dateTime).diff(
                                            moment.utc(b.start?.dateTime)
                                        )
                                    )
                                );

                            return (
                                <td key={index} className={styles.calendar}
                                    onMouseEnter={(event) => {
                                        event.stopPropagation();
                                        setCurrentEvent(null);
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        formik.setFieldValue('startDate', d.date)
                                        handleOpenModal(null)
                                    }}>
                                    <div className={styles.value}>{d.value}</div>

                                    <div className={styles.badgeContainer}>
                                        {_filteredEvents?.map((e) => {
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            COLORS.find((c) => c.id === e.colorId)?.color ??
                                                            COLORS[0].color,
                                                    }}
                                                    onMouseEnter={(event) => {
                                                        event.stopPropagation();
                                                        setCurrentEvent(e);
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleOpenModal(e.id)
                                                    }}
                                                    className={styles.badge}
                                                >
                                                    {e.summary}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </td>
                            );
                        })}
                </tr>
            );
        }
        return rows;
    };

    const _renderDays = () => {
        return HOURS_IN_DAY.map((h, i) => {
            return (
                <tr key={i} className={styles.days}>
                    <td className={`${styles.hourColumn} ${styles.calendar}`}>
                        <span>{h.label}</span>
                    </td>
                    {WEEK_DAYS.map((wd, i) => {
                        let event = DAILY_EVENTS.find(
                            (de) =>
                                moment(de.startTime).hour() === h.time && de.weekDay === wd
                        );
                        if (event) {
                            const duration = moment.duration(
                                moment(event.endTime).diff(moment(event.startTime))
                            );
                            const hours = parseInt(duration.asHours());
                            const minutes = parseInt(duration.asMinutes()) % 60;

                            var height = hours * 100 + (minutes / 60) * 100;
                        }

                        return (
                            <td className={styles.calendar} key={i}>
                                {height !== undefined && (
                                    <div
                                        className={styles.event}
                                        style={{height: `${height}%`}}
                                    >
                                        <div className={styles.borderTop}/>
                                        <p>Daily event</p>
                                    </div>
                                )}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    const _renderMonthly = () => {
        window.addEventListener('resize', function () {
            handleResize();
        });
        return (
            <>
                {isMobile ? (
                    <MiniCalendar
                        year={calendarDate.year}
                        month={calendarDate.month}
                        events={data?.data.items}
                    />
                ) : (
                    <table className={styles.calendar}>
                        <thead>
                        <tr>
                            {WEEK_DAYS.map((wd, i) => {
                                return (
                                    <th className={styles.calendar} key={i}>
                                        {wd.substring(0, 3).toUpperCase()}
                                    </th>
                                );
                            })}
                        </tr>
                        </thead>
                        <tbody>{_renderDates()}</tbody>
                    </table>
                )}
            </>
        );
    };

    const _renderAddHeaderBtn = () => {
        return (
            <>
                {!isMobile && (
                    <div
                        onMouseEnter={(event) => {
                            event.stopPropagation();
                            setCurrentEvent(null);
                        }}>
                        <Button
                            text={'+ Create New Event'}
                            type={GLOSSY}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleOpenModal(null)
                            }}
                            className="d-none d-lg-block"
                        />
                    </div>
                )}
            </>
        );
    };

    const _renderAddBodyBtn = () => {
        return (
            <>
                {isMobile && (
                    <div className={styles.actionBtn}>
                        <ActionButtonsGroup
                            buttons={[
                                {
                                    icon: Plus,
                                    cb: () => {
                                        setModalOpen(true);
                                    },
                                },
                            ]}
                        />
                    </div>
                )}
            </>
        );
    };

    const _renderWeekly = () => {
        return (
            <table className={`${styles.weeklyTable} ${styles.calendar}`}>
                <tbody>
                <tr className={styles.weeklyHeader}>
                    <th className={`${styles.weeklyHeader} ${styles.calendar}`}></th>
                    {WEEK_DAYS.map((wd, i) => {
                        let isToday = moment().weekday() === i + 1;
                        return (
                            <th
                                className={`${styles.weeklyHeader} ${styles.calendar} ${
                                    isToday ? styles.active : ''
                                }`}
                                key={i}
                            >
                                <div className={styles.weekDayContainer}>
                                    {wd.substring(0, 3).toUpperCase()}
                                    {isToday && <div className={styles.sphere}/>}
                                </div>
                            </th>
                        );
                    })}
                </tr>
                {_renderDays()}
                </tbody>
            </table>
        );
    };

    const _renderBody = () => {
        switch (currentTab) {
            case 0:
                return _renderMonthly();
            case 1:
                return _renderWeekly();
            default:
                break;
        }
    };

    const {mutate: mutateDelete} = useMutation(
        (id) => GoogleCalendarService.deleteById(currentEventId, google.accessToken),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([EVENTS]);
                queryClient.invalidateQueries([EVENT]);
                handleCloseDeleteModal();
                toast.success('Event deleted successfully');
            },
            onError: () => {
                toast.error('Something went wrong');
            },
        }
    );

    const handleOpenDeleteModal = () => {
        setModalOpen(false);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setCurrentEventId(null);
    };

    const handleDeleteClick = () => {
        mutateDelete(currentEventId);
    };

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseDeleteModal}
                open={isDeleteModalOpen}
                className={miniCalendar.modalDelete}
            >
                <div className={miniCalendar.deleteModalWrapper}>
                    <div className={miniCalendar.deleteModalTitleContainer}>
                        <button onClick={handleCloseDeleteModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={miniCalendar.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>
                                Are you sure that you want to delete this event?
                            </b>
                        </p>
                        <span>
                            After deleting, it will be impossible to restore
                            user data
                        </span>
                        <div className={miniCalendar.btnContainer}>
                            <Button
                                onClick={handleCloseDeleteModal}
                                className={`${miniCalendar.noBtnModal}`}
                                text={'No'}
                                type={SOLID}
                            />
                            <Button
                                className={`${miniCalendar.deleteBtnModal}`}
                                icon={delete_icon}
                                text={'Delete'}
                                type={SOLID}
                                onClick={handleDeleteClick}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderEventModal = () => {
        return (
            <Modal onClose={handleCloseModal} open={isModalOpen}>
                <div className={miniCalendar.modalWrapper}>
                    <div className={miniCalendar.modalTitleContainer}>
                        <p className={miniCalendar.modalTitle}>
                            {
                                currentEvent?.id ? 'Edit Event' : 'Add Event'
                            }
                        </p>
                        <button onClick={handleCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={miniCalendar.modalDivider}/>
                    <div className={miniCalendar.modalBodyContainer}>
                        <div className={miniCalendar.colorsContainer}>
                            <span>Color</span>
                            <div className={`formControls d-flex col ${miniCalendar.formControls}`}>
                                <div className={miniCalendar.colors}>
                                    {COLORS.map((c, i) => {
                                        return (
                                            <div
                                                onClick={() => formik.setFieldValue('colorId', c.id)}
                                                key={i}
                                                className={miniCalendar.sphere}
                                                style={{backgroundColor: c.color}}
                                            >
                                                {c.id === formik.values.colorId && (
                                                    <img src={tick} alt=""/>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.summary &&
                                    Boolean(formik.errors.summary) &&
                                    'error'
                                } ${miniCalendar.formControls}`}
                            >
                                <label>Title</label>
                                <input
                                    name="summary"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.summary}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                <span className={miniCalendar.validationHelper}>
                  {formik.touched.summary && formik.errors.summary}
                </span>
                            </div>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.description &&
                                    Boolean(formik.errors.description) &&
                                    'error'
                                } ${miniCalendar.formControls}`}
                            >
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.description}
                                    onBlur={formik.handleBlur}
                                ></textarea>
                                <span className={miniCalendar.validationHelper}>
                  {formik.touched.description && formik.errors.description}
                </span>
                            </div>
                            <div
                                className={`d-flex ${miniCalendar.formControls} ${miniCalendar.datePick}`}
                            >
                                <div className={miniCalendar.day}>
                                    <label>Day</label>
                                    <CustomDatePicker
                                        onBlur={formik.handleBlur}
                                        handleSelectDate={handleDateChange}
                                        className={miniCalendar.datePicker}
                                        date={formik.values.startDate}
                                        type={DATE_ONLY}
                                    />
                                    {formik.touched.startDate &&
                                        !isTablet &&
                                        Boolean(formik.errors.startDate) && (
                                            <span className={`${miniCalendar.validationHelper}`}>
                        {formik.errors.startDate}
                      </span>
                                        )}
                                </div>
                                {formik.touched.startDate &&
                                    isTablet &&
                                    Boolean(formik.errors.startDate) && (
                                        <span
                                            className={`${miniCalendar.validationHelper}  ${miniCalendar.mobile}`}
                                        >
                      {formik.errors.startDate}
                    </span>
                                    )}
                                <div
                                    className={`formControls ${miniCalendar.formControls} ${miniCalendar.durationWrapper}`}
                                >
                                    <label>Duration</label>
                                    <div className={miniCalendar.horizontalDates}>
                                        <TimePicker time={formik.values.startTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'startTime'}/>
                                        <div className={miniCalendar.divider}/>
                                        <TimePicker time={formik.values.endTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'endTime'}/>
                                    </div>
                                    <span className={miniCalendar.validationHelper}>
                    {formik.touched.startTime &&
                        formik.touched.endTime &&
                        (formik.errors.startTime ?? formik.errors.endTime)}
                  </span>
                                </div>
                            </div>
                        </div>
                        <div className={miniCalendar.rightButtonGroup}>
                            {
                                currentEvent?.id && (
                                    <div
                                        onClick={handleOpenDeleteModal}
                                        className={miniCalendar.leftButtonGroup}
                                    >
                                        <img src={delete_icon} alt=""/> Delete event
                                    </div>
                                )
                            }
                            <Button
                                onClick={handleCloseModal}
                                text={'Cancel'}
                                type={TRANSPARENT}
                                className={styles.btnCancel}
                            />
                            <Button
                                text={currentEvent?.id ? 'Save' : 'Create Event'}
                                onClick={formik.handleSubmit}
                                type={GLOSSY}
                                className={styles.btnCreate}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            {_renderEventModal()}
            {_renderDeleteModal()}
            <div className={styles.wrapper}>
                <div className="d-none d-sm-block">
                    <BreadCrumb/>
                </div>
                <div className={styles.title}>
                    <Button
                        text={'Back'}
                        type={BACK}
                        icon={back}
                        className={`d-sm-none ${styles.backBtn}`}
                        onClick={handleGoBack}
                    />
                    <h2>Calendar</h2>
                    {_renderAddHeaderBtn()}
                </div>

                <div className={`border`}>
                    <div className={`backCard h-100 d-flex wrap`}>
                        <div className={`whiteBg w-100 ${styles.container}`}>
                            <div className={styles.header}>
                                <div className={styles.left}>
                                    <div className={styles.monthSlider}>
                                        <div
                                            onClick={() =>
                                                setCalendarDate((prev) => ({
                                                    ...prev,
                                                    month: --prev.month < 0 ? 11 : prev.month,
                                                }))
                                            }
                                            className={styles.spehere}
                                        >
                                            <img src={left_arrow} alt=""/>
                                        </div>
                                        <div className={styles.month}>
                                            {MONTH[calendarDate.month].label}
                                        </div>
                                        <div
                                            onClick={() =>
                                                setCalendarDate((prev) => ({
                                                    ...prev,
                                                    month: ++prev.month > 11 ? 0 : prev.month,
                                                }))
                                            }
                                            className={styles.spehere}
                                        >
                                            <img src={right_arrow} alt=""/>
                                        </div>
                                    </div>
                                    {_renderAddBodyBtn()}
                                </div>
                                <div className={styles.right}>
                                    <SelectBadge
                                        className={`${styles.selectBadgeYear}`}
                                        options={generateYears()}
                                        onChange={(v) =>
                                            setCalendarDate((prev) => ({...prev, year: v.value}))
                                        }
                                        defaultValue={generateYears().find(
                                            (y) => y.value === calendarDate.year
                                        )}
                                    />
                                    {/*<SwitchTab*/}
                                    {/*    className={styles.calendarTab}*/}
                                    {/*    currentTab={currentTab}*/}
                                    {/*    handleTabClick={handleTabClick}*/}
                                    {/*    type={GLOSSY}*/}
                                    {/*    labels={[{label: "Month"}, {label: "Weekly"}]}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            <div className={styles.body}>{_renderBody()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Calendar;
