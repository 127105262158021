import React, { useCallback, useLayoutEffect, useState } from 'react';
import styles from './users.module.scss';
import { Checkbox } from '../adminParts/adminParts';

import { Toggle } from 'react-toggle-component';
import Button from '../../global/button/button';
import {
  BACK,
  EMPTY,
  FACEBOOK,
  GLOSSY,
  GOOGLE,
  SOLID,
  TIKTOK,
} from '../../../const/index';
import pencil from '../../../media/icons/pencil.svg';
import right_arrow from '../../../media/icons/right_purple_arrow.svg';
import Modal from '../../global/modal/modal';
import close from '../../../media/icons/close_icon.svg';
import profile_picture from '../../../media/icons/user.svg';
import Card from '../../global/card/card';
import CustomDatePicker from '../../global/customDatePicker/customDatePicker';
import PhoneSelect from '../../global/phoneSelect/phoneSelect';
import Select from '../../global/select/select';
import { countries } from '../../../mock';
import delete_icon2 from '../../../media/icons/Delete.svg';
import tiktok from '../../../media/icons/tiktok.svg';
import google from '../../../media/icons/google.svg';
import facebook from '../../../media/icons/facebook_blue.svg';
import back from '../../../media/icons/arrow_back.svg';
import photo from '../../../media/icons/photo.svg';

export const LeadItem = ({
  id,
  img,
  name,
  email,
  handleCheckBoxChange,
  handleDelete,
}) => {
  let star = ' ';
  // let first3 = " ";
  if (id < 4) {
    star = ' star' + id;
  }
  const [isModalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isMobile, setMobile] = useState();

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  useLayoutEffect(() => {
    handleResize();
  }, []);

  const handleResize = () => {
    let width = window.innerWidth;
    if (width >= 768) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  window.addEventListener('resize', function () {
    handleResize();
  });

  const handleOpenViewModal = () => {
    setIsEdit(false);
    setModalOpen(true);
  };

  const handleOpenEditModal = () => {
    setIsEdit(true);
    setModalOpen(true);
  };

  const _renderUserModal = () => {
    return (
      <Modal
        onClose={handleCloseModal}
        open={isModalOpen}
        className={`${styles.userModal} ${isEdit ? styles.edit : ''}`}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.modalTitleContainer}>
            <p className={styles.modalTitle}>
              {isEdit ? (
                <>
                  <Button
                    type={BACK}
                    icon={back}
                    className={styles.backBtn}
                    onClick={() => setIsEdit(false)}
                  />
                  Edit Profile
                </>
              ) : (
                'User Profile'
              )}
            </p>
            <button onClick={handleCloseModal}>
              <img src={close} alt="" />
            </button>
          </div>
          <div className={styles.modalDivider} />
          {isEdit ? (
            <div className={`${styles.modalBody} ${styles.modalBodyEdit}`}>
              {isMobile ? (
                <div className={`d-flex jc-center ${styles.card}`}>
                  <div
                    className={`${styles.profileCard} ${styles.mobileProfileCard}`}
                  >
                    <div className={styles.profileImg}>
                      <img src={profile_picture} alt="" />
                    </div>
                    <Button icon={photo} className={styles.photoBtn} />
                  </div>
                </div>
              ) : (
                <Card className={`${styles.card}`} title={'Profile Piсture'}>
                  <div className={styles.profileCard}>
                    <div className={styles.profileImg}>
                      <img src={profile_picture} alt="" />
                    </div>
                    <span>Upload/Change Your Profile Image</span>
                    <Button text={'Upload Avatar'} type={GLOSSY} />
                  </div>
                </Card>
              )}
              {isMobile ? (
                <div className={styles.bigCardWrapper}>
                  <div>
                    <div
                      className={`formControls required d-flex col ${styles.formControls}`}
                    >
                      <label>Name</label>
                      <input type="text" required />
                    </div>
                    <div
                      className={`formControls required d-flex col ${styles.formControls}`}
                    >
                      <label>Email</label>
                      <input type="text" required />
                    </div>
                    <div className={styles.compactInput}>
                      <div
                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                      >
                        <label>Company</label>
                        <input type="text" />
                      </div>
                      <Select
                        options={countries}
                        label={'Country'}
                        className={`formControls required d-flex col ${styles.halfInput} ${styles.formControls} ${styles.select}`}
                      />
                    </div>
                    <div className={styles.compactInput}>
                      <div
                        className={`formControls required d-flex col ${styles.halfInput} ${styles.formControls}`}
                      >
                        <label>Phone number</label>
                        <PhoneSelect dropdownClass={styles.compactInputPhone} />
                      </div>
                      <div
                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                      >
                        <label>Birthday</label>
                        <CustomDatePicker
                          date={date}
                          handleSelectDate={(d) => setDate(d)}
                          type={EMPTY}
                          placeholderText="mm-dd-yyyy"
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.viewInput} ${styles.bigViewInput} ${styles.bio}`}
                    >
                      <label>Bio</label>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cum facilisi hac eget sit vivamus donec. Dictumst
                        mauris pretium suscipit velit. Enim et morbi dui, diam.
                        Mauris et eget in a augue.
                      </p>
                    </div>
                  </div>
                  <Button
                    className={styles.saveBtn}
                    text={'Save'}
                    type={GLOSSY}
                  />
                </div>
              ) : (
                <Card
                  className={`${styles.card} ${styles.bigCard}`}
                  title={'Account Details'}
                >
                  <div className={styles.bigCardWrapper}>
                    <div>
                      <div
                        className={`formControls required d-flex col ${styles.formControls}`}
                      >
                        <label>Name</label>
                        <input type="text" required />
                      </div>
                      <div
                        className={`formControls required d-flex col ${styles.formControls}`}
                      >
                        <label>Email</label>
                        <input type="text" required />
                      </div>
                      <div className={styles.compactInput}>
                        <div
                          className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                        >
                          <label>Company</label>
                          <input type="text" />
                        </div>
                        <Select
                          options={countries}
                          label={'Country'}
                          className={`formControls required d-flex col ${styles.halfInput} ${styles.formControls} ${styles.select}`}
                        />
                      </div>
                      <div className={styles.compactInput}>
                        <div
                          className={`formControls required d-flex col ${styles.halfInput} ${styles.formControls}`}
                        >
                          <label>Phone number</label>
                          <PhoneSelect
                            dropdownClass={styles.compactInputPhone}
                          />
                        </div>
                        <div
                          className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                        >
                          <label>Birthday</label>
                          <CustomDatePicker
                            date={date}
                            handleSelectDate={(d) => setDate(d)}
                            type={EMPTY}
                            placeholderText="mm-dd-yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      className={styles.saveBtn}
                      text={'Save'}
                      type={GLOSSY}
                    />
                  </div>
                </Card>
              )}
            </div>
          ) : (
            <div className={`${styles.modalBody} ${styles.modalBodyView}`}>
              <div className={styles.leftProfile}>
                <img src={profile_picture} alt="" />
                <Button
                  onClick={() => setIsEdit(true)}
                  className={`${styles.editBtn} ${styles.viewBtn}`}
                  icon={pencil}
                  text={'Edit'}
                  type={SOLID}
                />
                <Button
                  onClick={() => handleDelete(id, name, handleCloseModal)}
                  className={`${styles.deleteBtn} ${styles.viewBtn}`}
                  icon={delete_icon2}
                  text={'Delete'}
                  type={SOLID}
                />
              </div>
              <div className={styles.rightProfile}>
                <div className={`${styles.viewInput} ${styles.col}`}>
                  <label>First Name</label>
                  <span>Mark</span>
                </div>
                <div className={`${styles.viewInput} ${styles.col}`}>
                  <label>Last Name</label>
                  <span>Wilson</span>
                </div>
                <div className={`${styles.viewInput} ${styles.bigViewInput}`}>
                  <label>Email</label>
                  <span>niyazibabayev@mail.ru</span>
                </div>
                <div className={styles.viewInput}>
                  <label>Company</label>
                  <span>Mint Media</span>
                </div>
                <div className={styles.viewInput}>
                  <label>Phone</label>
                  <span>+994502106331</span>
                </div>
                <div className={`${styles.viewInput} ${styles.col}`}>
                  <label>Country</label>
                  <span>Azerbaijan</span>
                </div>
                <div className={`${styles.viewInput} ${styles.col}`}>
                  <label>Birthday</label>
                  <span>16/03/2004</span>
                </div>
                <div
                  className={`${styles.viewInput} ${styles.bigViewInput} ${styles.bio}`}
                >
                  <label>Bio</label>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc cum facilisi hac eget sit vivamus donec. Dictumst
                    mauris pretium suscipit velit. Enim et morbi dui, diam.
                    Mauris et eget in a augue.
                  </p>
                </div>
                <div className={styles.socialGroup}>
                  <Button
                    className={`${styles.socialBtn}`}
                    icon={google}
                    type={GOOGLE}
                    text={'Signed in with Google'}
                  />
                  <Button
                    className={styles.socialBtn}
                    icon={facebook}
                    type={FACEBOOK}
                    text={'Sign in with Facebook'}
                  />
                  <Button
                    className={styles.socialBtn}
                    icon={tiktok}
                    type={TIKTOK}
                    text={'Sign in with TikTok'}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <div className={['lead-grid ', styles.wrap, styles.adminWrap].join(' ')}>
      {_renderUserModal()}
      <>
        <Checkbox
          onChange={(e) => handleCheckBoxChange(e, { id, name }, setIsChecked)}
          className={'onlyMobile'}
          value={isChecked}
        />
        <div>
          <span className={star + ' id '}> {id} </span>{' '}
        </div>
        <div className={styles.profile}>
          {' '}
          <Button
            onClick={handleOpenViewModal}
            icon={img || '/defaultUser.svg'}
            className={styles.profileImg}
          />{' '}
          <Button
            onClick={handleOpenViewModal}
            className={styles.profileName}
            text={name}
          />
        </div>
        <div> {email} </div>
        <div className={styles.toggle}>
          <Toggle
            knobColor="white"
            name={`toggle-${id}`}
            borderColor="transparent"
            leftBackgroundColor="#E2E8F0"
            rightBackgroundColor="#8C6CED"
          />
        </div>
        <div className="onlyMobile">
          <button onClick={handleOpenEditModal} className={styles.miniEditBtn}>
            <img src={pencil} alt="" />
          </button>
        </div>
        <div className={styles.checkBoxWrapper + ' onlyMd'}>
          <Checkbox
            value={isChecked}
            onChange={(e) =>
              handleCheckBoxChange(e, { id, name }, setIsChecked)
            }
          />
        </div>
        <div className={styles.editBtnWrapper + ' onlyMd'}>
          <Button
            onClick={handleOpenEditModal}
            className={styles.editBtn}
            icon={pencil}
            text={'Edit'}
            type={SOLID}
          />
          <Button
            onClick={handleOpenViewModal}
            icon={right_arrow}
            className={styles.rightArrowBtn}
          />
        </div>
      </>
    </div>
  );
};
