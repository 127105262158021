import React from "react";
import Sidebar from "../sidebar/sidebar";

import {Outlet} from "react-router-dom";
import {Navbar} from "../navbar/navbar";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Layout() {

    return <>
        <ToastContainer/>
        <Navbar/>
        <div className="layout d-flex f-full">
            <Sidebar/>
            <div className="mainArea">
                <div className="container">
                    <Outlet/>
                </div>
            </div>
        </div>
    </>

}

export default Layout;
