import http from "../http-common";

const get = (token) => {
    return http.get("/project", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`project/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post("/project", data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`/project/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (id, data, token) => {
    return http.put(`/project/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const updateProjectColor = (id, data, token) => {
    return http.put(`/project/color/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const updateProjectTasks = (id, data, token) => {
    return http.put(`/project/task/update/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const updateProjectColors = (id, data, token) => {
    return http.put(`/project/colors/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const updateProjects = (data, token) => {
    return http.put(`/project/tasks`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const ProjectService = {
    get,
    post,
    put,
    deleteById,
    getById,
    updateProjects,
    updateProjectColor,
    updateProjectTasks,
    updateProjectColors
};

export default ProjectService;
