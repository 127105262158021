import http from '../http-common';

const register = (data) => {
    return http.post('/users/register', data);
};

const login = (data) => {
    return http.post('/users/login', data);
};

const getCurrentUser = (token) => {
    return http.get('/users/current', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const requestResetPassword = (data) => {
    return http.post('/email/requestresetpassword', data);
};

const resetPassword = (data) => {
    return http.post('/email/passwordreset', data);
};

const refreshGoogleToken = (data) => {
    return http.post('/users/google_refresh', data);
};

const confirmVerificationCode = (data) => {
    return http.post('/email/confirmCode', data);
};
const isEmailConfirmed = (data) => {
    return http.post('/users/is-email-confirmed', data);
};

const sendConfirmEmailRequest = (data) => {
    return http.post('email/send-confirm-email-request', data);
};

const sendConfirmNewEmailRequest = (data, token) => {
    return http.post('/email/confirm-new-email', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
const AuthenticationService = {
    login,
    register,
    getCurrentUser,
    requestResetPassword,
    resetPassword,
    refreshGoogleToken,
    confirmVerificationCode,
    sendConfirmEmailRequest,
    isEmailConfirmed,
    sendConfirmNewEmailRequest,
};

export default AuthenticationService;
