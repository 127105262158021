import React from "react";
import {GRAY, PURPLE, RED, YELLOW} from "../../../const";
import styles from "./badge.module.scss";

export default function Badge({text, color, className}) {
    switch (color) {
        case RED:
            return (
                <div
                    style={{backgroundColor: "#FEEAE9", color: "#F85C56"}}
                    className={`${styles.badge} ${className}`}
                >
                    {text}
                </div>
            );
        case YELLOW:
            return (
                <div
                    style={{
                        backgroundColor: "rgba(255, 210, 121, 0.15)",
                        color: "#FFCF70",
                    }}
                    className={`${styles.badge} ${className}`}
                >
                    {text}
                </div>
            );
        case PURPLE:
            return (
                <div
                    style={{
                        backgroundColor: "rgba(91, 124, 242, 0.26)",
                        color: "#5B7CF2",
                    }}
                    className={`${styles.badge} ${className}`}
                >
                    {text}
                </div>
            );
        case GRAY:
            return (
                <div
                    style={{
                        backgroundColor: "#E2E8F0",
                        color: "#373D59",
                    }}
                    className={`${styles.badge} ${className}`}
                >
                    {text}
                </div>
            );
        default:
            break;
    }
}
