import React from "react";
import { Fragment } from "react";

function ActionButtonsGroup(props){

    const elements = props.buttons
  
    return (
        <div className={`ActionButtonsGroup ${props.className}`}>
        {elements.map((value, index) => {
          return <Fragment key={index}>
            <button onClick={value.cb} className="ActionButton d-flex jc-center ai-center">
                <img src={value.icon} alt='' />
            </button>
            </Fragment>
        })}
      </div>
    )
  }


export default ActionButtonsGroup;