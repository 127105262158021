import profile from './media/icons/user.svg';
import mountains from './media/icons/user.svg';

export const optionsColor = [
  { value: '#8C6CED', label: 'Purple' },
  { value: '#5B7CF2', label: 'Blue' },
  { value: '#10B521', label: 'Green' },
  { value: '#FFCF70', label: 'Yellow' },
];

export const options = [
  { value: 'To_do', label: 'To do' },
  { value: 'In_progress', label: 'In progress' },
  { value: 'Quality_check', label: 'Quality check' },
  { value: 'Done', label: 'Done' },
];

export const countries = [
  { value: 'ua', label: 'Ukraine' },
  { value: 'es', label: 'Spain' },
  { value: 'us', label: 'USA' },
  { value: 'en', label: 'Englang' },
];

export const category = [
  { value: 'General', label: 'General' },
  { value: 'News', label: 'News' },
  { value: 'Test', label: 'Test' },
  { value: 'Algorithm', label: 'Algorithm' },
  { value: 'Organization', label: 'Organization' },
  { value: 'Project', label: 'Project' },
];

export const DATA_TABLE_DATA = [
  {
    name: 'Tiktok',
    cpm: '$12.91',
    ctr: '3.32%',
    clicks: '25',
    cpc: '$3.45',
    totalCost: '$4.56',
    totalHours: '+ 4',
  },
  {
    name: 'Facebook',
    cpm: '$12.91',
    ctr: '3.32%',
    clicks: '25',
    cpc: '$3.45',
    totalCost: '$4.56',
    totalHours: '+ 4',
  },
  {
    name: 'Google',
    cpm: '$12.91',
    ctr: '3.32%',
    clicks: '25',
    cpc: '$3.45',
    totalCost: '$4.56',
    totalHours: '+ 4',
  },
];

export const DATA_TABLE_CAMPAIGNS_DATA = {
  'TikTok': [
    {
      companyName: 'Company 1',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    },
    {
      companyName: 'Company 2',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    }
  ],
  'Facebook': [
    {
      companyName: 'Facebook 1',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    },
    {
      companyName: 'Facebook 2',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    },
    {
      companyName: 'Company 3',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    },
  ],
  'Google': [
    {
      companyName: 'Google 1',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    },
    {
      companyName: 'Google 2',
      cpm: '$0.0',
      ctr: '$0.0',
      clicks: '$0.0',
      cpc: '$0.0',
      totalCost: '$0.0',
      totalHours: '$0.0',
    }
  ],
}

export const DISCUSSIONS = [
  {
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil impedit eos arch, saepe us in.',
    badge: 'NEWS',
    replies: '3,456',
    by: 'Niyazi',
    participants: 342,
    participantsImg: [{ img: profile }, { img: profile }, { img: profile }],
    img: mountains,
    ago: 10,
    byImg: profile,
  },
  {
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil impedit eos arch, saepe us in.',
    badge: 'NEWS',
    replies: '3,456',
    by: 'Niyazi',
    participants: 342,
    participantsImg: [{ img: profile }, { img: profile }, { img: profile }],
    img: mountains,
    ago: 10,
    byImg: profile,
  },
  {
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil impedit eos arch, saepe us in.',
    badge: 'NEWS',
    replies: '3,456',
    by: 'Niyazi',
    participants: 342,
    participantsImg: [{ img: profile }, { img: profile }, { img: profile }],
    img: mountains,
    ago: 10,
    byImg: profile,
  },
  {
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil impedit eos arch, saepe us in.',
    badge: 'NEWS',
    replies: '3,456',
    by: 'Niyazi',
    participants: 342,
    participantsImg: [{ img: profile }, { img: profile }, { img: profile }],
    img: mountains,
    ago: 10,
    byImg: profile,
  },
  {
    title:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil impedit eos arch, saepe us in.',
    badge: 'NEWS',
    replies: '3,456',
    by: 'Niyazi',
    participants: 342,
    participantsImg: [{ img: profile }, { img: profile }, { img: profile }],
    img: mountains,
    ago: 10,
    byImg: profile,
  },
];

export const SEARCH_RESULTS = ['Test', 'Hello', 'Another result'];

export const DURATION_VALUES = [
  { label: '15 minutes', value: 15 },
  {
    label: '30 minutes',
    value: 30,
  },
];

export const COLORS = [
  { id: "0", color: "#039be5" },
  { id: "1", color: "#7986cb" },
  { id: "2", color: "#33b679" },
  { id: "3", color: "#8e24aa" },
  { id: "4", color: "#e67c73" },
  { id: "5", color: "#f6c026" },
  { id: "6", color: "#f5511d" },
  { id: "7", color: "#039be5" },
  { id: "8", color: "#616161" },
  { id: "9", color: "#3f51b5" },
  { id: "10", color: "#0b8043" },
  { id: "11", color: "#d60000" },
];
