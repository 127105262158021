import React, {useRef, useState} from "react";
import {SEARCH_RESULTS} from "../../../mock";
import styles from "./search.module.scss";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

export default function Search({
                                   className,
                                   onFocusOut,
                                   onClick,
                                   onChange,
                                   hasOptions = true,
                                   placeholder = "Search..."
                               }) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => {
        setIsOpen(false);
        if (onFocusOut) {
            onFocusOut();
        }
    });
    const handleSearchClick = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div
            ref={ref}
            onClick={handleSearchClick}
            className={`${styles.container} ${className} ${isOpen ? "open" : ""}`}
        >
            <input
                onChange={onChange}
                onClick={onClick}
                className={`input-search ${isOpen ? "open" : ""}`}
                placeholder={placeholder}
                type="text"
            />
            {isOpen && hasOptions && (
                <div className={`${styles.options}`}>
                    {SEARCH_RESULTS.map((r) => {
                        return (
                            <div className={styles.option}>
                                <span>{r}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
