import React, {useState} from "react";
import styles from "./projectCard.module.scss";
import Select from "../../global/select/select";
import Badge from "../../global/badge/badge";
import {GRAY, STATUSES} from "../../../const";
import calendar from "../../../media/icons/calendar.svg";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import {useFormik} from "formik";
import {useMutation, useQueryClient} from "react-query";
import {PROJECT, PROJECTS} from "../../../rquery/queryKeys";
import ProjectService from "../../../services/ProjectService";
import {useSelector} from "react-redux";

export default function ProjectCard({
                                        title,
                                        description,
                                        dueDate,
                                        id: projectId,
                                        status,
                                        tags,
                                        color,
                                        isDragging,
                                        isMobile,
                                        className,
                                        category,
                                    }) {
    const {token} = useSelector((state) => state.Authentication);

    const queryClient = useQueryClient();

    const [isMouseDown, setIsMouseDown] = useState()

    const navigate = useNavigate();

    const {mutate} = useMutation(
        (data) => ProjectService.put(projectId, data, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([PROJECTS]);
                queryClient.invalidateQueries([PROJECT]);
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: status,
        },
        // validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const handleMouseDown = () => {
        if (isMobile) return
        setIsMouseDown(true)
    }
    const handleMouseUp = () => {
        if (isMobile) return
        setIsMouseDown(false)
    }

    const handleClick = () => {
        if (isDragging) return
        navigate(`/projects/${projectId}/brief`)
    }
    return (
        <div onMouseUp={handleMouseUp} onMouseDown={handleMouseDown} onClick={handleClick}
             className={`${styles.card} ${className}`}
             style={{borderColor: `${color}`, cursor: (isMouseDown && !isMobile) ? 'grabbing' : ''}}>
            <div className={styles.top}>
                <Select
                    value={STATUSES.find((s) => s.value === formik.values.status)}
                    options={STATUSES}
                    className={styles.select}
                    onChange={(d) => {
                        formik.setFieldValue("status", d.value);
                        formik.handleSubmit();
                    }}
                />
                <p className={styles.title + " enableSwiper"}>
                    <Link to={`./${projectId}/brief`}> {title} </Link>{" "}
                </p>
                <span className={styles.description}>{description}</span>
                <div className={styles.badgeContainer}>
                    {tags?.map((t, i) => {
                        return <Badge key={i} text={t.name} color={GRAY}/>;
                    })}
                </div>
            </div>

            {dueDate && (
                <div className={styles.group}>
                    <div className={styles.date}>
                        <img src={calendar} alt=""/>
                        <span>{moment(dueDate).format("DD/MM/yyyy")}</span>
                    </div>
                    <div className={styles.category} style={{backgroundColor: color}}>
                        { category }
                    </div>
                </div>
            )}
        </div>
    );
}
