import React from 'react';
import Settings from '../settings/settings';

const makeLighter = (color, percent) => {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = ((num >> 8) & 0x00ff) + amt,
        B = (num & 0x0000ff) + amt;
    return (
        '#' +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
            (B < 255 ? (B < 1 ? 0 : B) : 255)
        )
            .toString(16)
            .slice(1)
    );
};

const Folders = ({folders, setModalType, setEditId, deleteFolder, openFolder, styles}) => {
    const [settingsId, setSettingsId] = React.useState(null);

    return (
        <>
            {
                folders && folders.map((f, i) => {
                    const lighterColor = makeLighter(f.color, 20);
                    return (
                        <div key={i} className={styles.folder} onClick={() => {
                            openFolder(f._id, f.name);
                        }} style={{
                            animationDelay: `${i * 50}ms`,
                        }}>
                            <div className={styles.folder__background}>
                                <svg width='186' height='133' viewBox='0 0 186 133' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <rect opacity='0.6' x='5' y='1' width='32' height='131' rx='9' fill={lighterColor}
                                          stroke={lighterColor} strokeWidth='2'/>
                                    <circle cx='21' cy='12' r='2' fill='white'/>
                                    <circle cx='21' cy='18' r='2' fill='white'/>
                                    <circle cx='21' cy='24' r='2' fill='white'/>
                                    <path opacity='0.6'
                                          d='M46 51C41.0294 51 37 46.9706 37 42V19L161 19C165.971 19 170 23.0294 170 28V42C170 46.9706 165.971 51 161 51L46 51Z'
                                          fill={lighterColor} stroke={lighterColor} strokeWidth='2'
                                          style={{transform: 'translateX(1.9px)'}}/>
                                    <path
                                        d='M23.8484 47.6651C24.9047 43.7332 28.469 41 32.5403 41H174.25C179.794 41 184.02 45.9657 183.134 51.4393L171.374 124.026C170.668 128.385 166.905 131.587 162.49 131.587H13.0409C7.12075 131.587 2.81314 125.97 4.34904 120.252L23.8484 47.6651Z'
                                        fill={f.color} stroke={f.color} strokeWidth='2'/>
                                </svg>
                                <div className={styles.menu} onClick={(e) => {
                                    if (!settingsId) {
                                        e.stopPropagation(); // prevent opening folder
                                        setSettingsId(f._id)
                                    }
                                }}>
                                </div>
                                <div className={styles.name}>
                                    {f.name}
                                </div>
                                <div className={styles.category}>
                                    {f.category}
                                </div>
                                <Settings
                                    settingsId={settingsId}
                                    setSettingsId={setSettingsId}
                                    onDelete={() => {
                                        deleteFolder(f._id);
                                    }}
                                    onEdit={(id) => {
                                        setEditId(id);
                                    }}
                                    setModalType={setModalType}
                                    setEditId={setEditId}
                                    currentId={f._id}
                                    type={'folder'}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Folders;
