import http from "../http-common";

const get = (projectId, token, folderId) => {
    return http.get(`/project/${projectId}/files/${folderId ? folderId : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getAllAttachments = (projectId, token) => {
    return http.get(`/project/${projectId}/folders`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

const deleteById = (projectId, fileId, token) => {
    return http.delete(`project/${projectId}/files/${fileId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (projectId, data, folderId, token) => {
    return http.post(`project/${projectId}/file/${folderId ? folderId : ''}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'multipart/form-data',
        }
    })
}

const createFolder = (projectId, currentFolderId, data, token) => {
    return http.post(`project/${projectId}/folders/${currentFolderId ? currentFolderId : ''}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json',
        }
    })
}

const updateFolder = (projectId, folderId, data, token) => {
        return http.patch(`project/${projectId}/folders/${folderId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'application/json',
            }
        })
}

const deleteFolder = (projectId, folderId, token) => {
    return http.delete(`project/${projectId}/folders/${folderId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

const moveFile = (projectId, data, token) => {
    return http.patch(`project/${projectId}/files/${data.fileId}/move`, {
        moveTo: data.folderId,
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json',
        }
    });
}

const renameFile = (projectId, fileId, fileName, token) => {
    return http.patch(`project/${projectId}/files/${fileId}`, {
        name: fileName
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json',
        }
    });
}

const FileService = {
    get,
    getAllAttachments,
    post,
    deleteById,
    createFolder,
    updateFolder,
    deleteFolder,
    moveFile,
    renameFile,
};

export default FileService;
