import React, {useEffect, useMemo, useState} from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import styles from "./customClock.module.scss";
import moment from "moment-timezone";

const CustomClock = ({city, name}) => {
    let clockDate = useMemo(
        () => new Date(new Date().toLocaleString("en-US", {timeZone: name})),
        [name]
    );
    const [date, setDate] = useState(clockDate);

    let currentDate = useMemo(() => new Date(), []);

    let difference = useMemo(
        () =>
            moment
                .duration(moment(clockDate).diff(moment(currentDate)))
                .asHours()
                .toFixed(2),
        // eslint-disable-next-line
        [name]
    );
    const removeUnderline = (str) =>
    {
        if (str.match(/_/g)) {
            return str.replace(/_/g, " ");
        }
        return str;
    }

    useEffect(() => {
        const interval = setInterval(() =>
            setDate(
                new Date(new Date().toLocaleString("en-US", {timeZone: name})),
                1000
            )
        );

        return () => {
            clearInterval(interval);
        };
    }, [name]);

    return (
        <div className={styles.container}>
            <div className={`clock`}>
                <div className={`analog-clock`}>
                    <Clock
                        className={styles.clock}
                        value={date}
                        secondHandLength={45}
                        secondHandWidth={1}
                        secondHandOppositeLength={0}
                        hourHandLength={35}
                        hourHandWidth={2.5}
                        hourHandOppositeLength={0}
                        minuteHandLength={52}
                        minuteHandWidth={2}
                        minuteHandOppositeLength={0}
                        size={82}
                    />
                </div>
            </div>
            <div className={styles.description}>
                <div className={styles.city}>{removeUnderline(city) === 'Kiev' ? 'Kyiv' : removeUnderline(city)}</div>
                <div className={styles.time}>
                    {difference >= 0 ? "+" : "-"}
                    {Math.abs(Number.parseFloat(difference))}h
                </div>
            </div>
        </div>
    );
};

export default CustomClock;
