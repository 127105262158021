import http from "../http-common";

const get = (token) => {
    return http.get(`/tag`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`/tag/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post(`/tag`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`/tag/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const TagService = {
    get,
    getById,
    post,
    deleteById
};

export default TagService;
