import {CURRENT_DATE, POST_DATE, PREVIOUS_DATE} from "../const";

export function generateCalendar(month, year) {
    let today = new Date();

    let firstDay = new Date(year, month).getDay();
    let daysInMonth = 32 - new Date(year, month, 32).getDate();

    let _prevDates = [];
    let _currentDates = [];
    let _postDates = [];

    let date = 1;
    for (let i = 1; i <= 7; i++) {
        for (let j = 1; j <= 7; j++) {
            if (i === 1 && j < firstDay) {
                let daysInPrevMonth = 32 - new Date(year, month - 1, 32).getDate();
                _prevDates.unshift({
                    row: i,
                    value: daysInPrevMonth - j + 1,
                    type: PREVIOUS_DATE,
                    date: new Date(year, month - 1, daysInPrevMonth - j + 1),
                });
            } else if (date > daysInMonth) {
                break;
            } else {
                if (
                    date === today.getDate() &&
                    year === today.getFullYear() &&
                    month === today.getMonth()
                ) {
                    //today
                }
                _currentDates.push({
                    row: i,
                    value: date,
                    column: j,
                    type: CURRENT_DATE,
                    date: new Date(year, month, date)
                });
                date++;
            }
        }
    }

    _prevDates.forEach((pd, key) => (pd.column = ++key));

    const lastItem = [..._currentDates].pop();
    const futureDays = Math.abs(lastItem.column - 7);

    if (futureDays > 0) {
        for (let i = 1; i <= futureDays; i++) {
            _postDates.push({
                row: lastItem.row,
                value: i,
                column: lastItem.column + i,
                type: POST_DATE,
                date: new Date(year, month + 1, i)
            });
        }
    }

    return {
        prevDates: _prevDates,
        currentDates: _currentDates,
        postDates: _postDates,
    };
}
