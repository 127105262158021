import {React, useState} from "react";
import styles from "./todoList.module.scss";
import Badge from "../../global/badge/badge";
import { GLOSSY, GRAY } from "../../../const";
import calendar from "../../../media/icons/calendar.svg";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useQuery, useQueryClient, useMutation} from "react-query";
import {ALL_TASKS, TASK, TASKS} from "../../../rquery/queryKeys";
import TaskService from "../../../services/TaskService";
import moment from "moment";
import Preloader from "../../preloader/preloader";
import NothingHereYet from "../../nothingHereYet/nothingHereYet";
import {PRIORITIES} from '../../../const';
import tick_dashed from '../../../media/icons/tick_dashed.svg';
import Button from "../../global/button/button";
import {toast} from "react-toastify";
import cn from "classnames";


function TodoList() {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {token} = useSelector((state) => state.Authentication);
    const [tasksType, setTasksType] = useState(0); // 0 - all, 1 - favorites
    const [isTasksEmpty, setIsTasksEmpty] = useState(false);

    const prioritiesMap = {
        'high': PRIORITIES[0],
        'medium': PRIORITIES[1],
        'low': PRIORITIES[2]
    }

    const onChangeTasksType = (n) => {
        setTasksType(n);
    }

    const {data, refetch} = useQuery([ALL_TASKS, token], {
        queryFn: () => TaskService.getAllForUser(token),
        onSuccess: (data) => {
            console.log(data)
            if (!data?.data?.length) setIsTasksEmpty(true);
            if (data?.data?.length) {
                let _tasks = data.data;
                let filteredTasks = _tasks.filter((x) => x.status !== "done" && x.project != null)
                !filteredTasks.length ? setIsTasksEmpty(true) : setIsTasksEmpty(false)
            }
        },
        refetchOnWindowFocus: false,
    });

    const {mutate} = useMutation(
        (data) => TaskService.put(data.id, {
            completed: data.completed,
            isFavorite: data.isFavorite,
            status: data.status
        }, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TASKS]);
                queryClient.invalidateQueries([TASK]);
                refetch();
                toast.success(data.action === "complete" ? "Task Complete status updated" : "Task Favorite status updated");
            },
            onError: (error) => {
                toast.error(data.action === "complete" ? "Error while marking task as Complete" : "Error while marking task as Favorite");
            }
        }
    );

    const handleMarkAsCompleteClick = (id) => {
        mutate( {
            action: "complete",
            completed: true,
            status: "done",
            id
        });
    };

    const handleNameClick = (projectId, taskId) => {
        navigate(`/projects/${projectId}/tasks/${taskId}`);
    };

    const onFavoriteClick = (task) => {
        mutate(
            {
                action: "favorite",
                isFavorite: !task.isFavorite,
                completed: task.completed,
                status: task.status,
                id: task._id
            }
        );
    }


    const tasks = tasksType === 0 ? data?.data.filter((x) => x.status !== "done" && x.project != null)
        : data?.data.filter((x) => x.status !== "done" && x.project != null && x.isFavorite);

    return (
        <div className={`box d-flex col ${styles.container}`}>
            <Preloader isActive={!data?.data.length > 0 && !isTasksEmpty} borderRadius/>
            {
                isTasksEmpty ? <NothingHereYet/> : (
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <div className={styles.up}>
                                <h4 className="boxTitle">Recent Tasks</h4>
                                <div className={styles.radioButtons}>
                                    <label className="form-control" onClick={() => {
                                        onChangeTasksType(0)
                                    }}>
                                        <input type="radio" name="radio" checked={tasksType === 0}/>
                                        All
                                    </label>

                                    <label className="form-control" onClick={() => {
                                        onChangeTasksType(1)
                                    }}>
                                        <input type="radio" name="radio" checked={tasksType === 1}/>
                                        Favorites
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={styles.wrapper}>
                            {tasks
                                ?.sort((a, b) =>
                                    moment.utc(moment(a.dueDate).diff(moment.utc(b.dueDate)))
                                )
                                .map((item, i) => {
                                    return (
                                        <div key={i} className={styles.item}>
                                            <div className={styles.line} style={{backgroundColor: item.color}}/>
                                            <div className={styles.top}>
                                                <div className={styles.title}>
                                                    <p
                                                        onClick={() => handleNameClick(item?.project._id, item._id)}
                                                    >
                                                        {item.name}
                                                    </p>
                                                    <div className={cn(styles.iconStar, {
                                                        [styles.iconStar__active]: item.isFavorite
                                                    })}
                                                         onClick={() => {
                                                             onFavoriteClick(item)
                                                         }}
                                                    >
                                                        <svg width="21" height="20" viewBox="-0.5 0 21 20" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M15.9185 12.3201C15.6595 12.5711 15.5405 12.9341 15.5995 13.2901L16.4885 18.2101C16.5635 18.6271 16.3875 19.0491 16.0385 19.2901C15.6965 19.5401 15.2415 19.5701 14.8685 19.3701L10.4395 17.0601C10.2855 16.9781 10.1145 16.9341 9.93951 16.9291H9.66851C9.57451 16.9431 9.48251 16.9731 9.39851 17.0191L4.96851 19.3401C4.74951 19.4501 4.50151 19.4891 4.25851 19.4501C3.66651 19.3381 3.27151 18.7741 3.36851 18.1791L4.25851 13.2591C4.31751 12.9001 4.19851 12.5351 3.93951 12.2801L0.32851 8.78012C0.0265096 8.48712 -0.0784904 8.04712 0.0595096 7.65012C0.19351 7.25412 0.53551 6.96512 0.94851 6.90012L5.91851 6.17912C6.29651 6.14012 6.62851 5.91012 6.79851 5.57012L8.98851 1.08012C9.04051 0.980122 9.10751 0.888122 9.18851 0.810122L9.27851 0.740122C9.32551 0.688122 9.37951 0.645122 9.43951 0.610122L9.54851 0.570122L9.71851 0.500122H10.1395C10.5155 0.539122 10.8465 0.764122 11.0195 1.10012L13.2385 5.57012C13.3985 5.89712 13.7095 6.12412 14.0685 6.17912L19.0385 6.90012C19.4585 6.96012 19.8095 7.25012 19.9485 7.65012C20.0795 8.05112 19.9665 8.49112 19.6585 8.78012L15.9185 12.3201Z"
                                                                fill="#FFDA18"/>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <span>{item.description}</span>
                                            </div>
                                            <div className={styles.bottom}>
                                             <span className={styles.projectName}>
                                                 {item.project?.name}
                                             </span>
                                                {item.tag.length ? (
                                                    <div className={styles.badgeContainer}>
                                                        {item.tag.map((t, _i) => {
                                                            return (
                                                                <Badge
                                                                    key={_i}
                                                                    className={styles.badge}
                                                                    text={t.name}
                                                                    color={GRAY}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                {
                                                    prioritiesMap[item.priority] &&
                                                    <div className={styles.bottom__priority}>
                                                        <img src={prioritiesMap[item.priority].icon} alt="icon"/>
                                                    </div>
                                                }
                                                {item.dueDate &&
                                                    <div className={styles.dateContainer}>
                                                        <img src={calendar} alt=""/>
                                                        <span>{moment(item.dueDate).format("DD/MM/yyyy")}</span>
                                                    </div>
                                                }
                                                <div className={styles.checkMarkContainer}>
                                                    <Button
                                                        className={styles.checkMarkContainer__checkMark}
                                                        type={GLOSSY}
                                                        onClick={() => {
                                                            handleMarkAsCompleteClick(item._id);
                                                        }}
                                                        icon={tick_dashed}
                                                    />

                                                    <div className={styles.checkMarkContainer__hint}>
                                                        <p>
                                                            Mark as Complete
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            <Link to={"/projects"} className={styles.seeMore}>
                                See All
                            </Link>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default TodoList;
