import * as Yup from "yup";

const registrationValidation = Yup.object().shape({
    name: Yup.string()
        .min(3, "Name is too short - shoul be at least 3 chars!")
        .max(255, "Name is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    email: Yup.string()
        .email("Invalid email format!")
        .min(3, "Email is too short - should be at least 3 chars!")
        .max(255, "Email is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    password: Yup.string()
        .min(3, "Password is too short - should be at least 3 chars!")
        .max(255, "Password is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    agreedToTermsAndConditions: Yup.boolean()
        .isTrue()
        .required("Required")
        .typeError("Required!"),
});

export default registrationValidation;
