import { useState } from "react";

export default function useSwitchTab(defaultIndex) {
  const [currentTab, setCurrentTab] = useState(defaultIndex);
  const handleTabClick = (index) => {
    setCurrentTab(index);
  };

  return {
    currentTab,
    handleTabClick,
  };
}
