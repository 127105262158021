import styles from './colorSelectModal.module.scss';
import React, {useCallback, useState} from 'react';
import Modal from "../../global/modal/modal";
import close from "../../../media/icons/close_icon.svg";
import tick from "../../../media/icons/tick_white.svg";
import plus from "../../../media/icons/plus_icon.svg";
import {SketchPicker} from "react-color";
import Button from "../../global/button/button";
import {GLOSSY} from "../../../const";

const ColorSelectModal = ({
                              isClrModalOpen, setIsClrModalOpen,
                              optionsColors, setOptionsColors,
                              blockPickerColor, setBlockPickerColor,
                              updateColor,onSave, formik
                          }) => {

    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

    const closeAll = useCallback(() => {
        setIsClrModalOpen(false);
        setIsColorPickerOpen(false);
    }
    , []);

    return (
        <Modal
            onClose={closeAll}
            open={isClrModalOpen}
            className={styles.colorModal}
        >
            <div className={styles.modalTitleContainer}>
                <p className={styles.modalTitle}>Add custom color</p>
                <button onClick={closeAll} className="modalClose">
                    <img src={close} alt=""/>
                </button>
            </div>
            <div className={styles.modalDivider}/>
            <div className={styles.modalBodyContainer}>
                <div className={styles.colorsContainer}>
                    <span>Color</span>
                    <div className={styles.colors}>
                        {
                            optionsColors.map((color, index) => {
                                return (
                                    <div
                                        className={styles.sphere}
                                        style={{backgroundColor: color.value}}
                                        key={index}
                                        onClick={() => {
                                            formik.setFieldValue('color', color.value);
                                            updateColor && updateColor({ color: color.value });
                                            setBlockPickerColor(color.value);
                                        }}
                                    >
                                        {
                                            blockPickerColor === color.value && (
                                                <img src={tick} alt=""/>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className={`${styles.sphere} ${styles.add}`}
                             onClick={() => setIsColorPickerOpen(true)}>
                            <img src={plus} alt=""/>
                        </div>

                        {
                            isColorPickerOpen && (
                                <SketchPicker
                                    color={blockPickerColor}
                                    className={styles.colorPicker}
                                    disableAlpha={true}
                                    onChange={(color) => {
                                        setBlockPickerColor(color.hex);
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
                <Button
                    text={"Save color"}
                    className={styles.modalButton}
                    type={GLOSSY}
                    onClick={() => {
                        const filteredColors = optionsColors.filter(c => c.value !== blockPickerColor);
                        setOptionsColors([...filteredColors, {value: blockPickerColor, label: blockPickerColor}]);
                        updateColor && updateColor({ color: blockPickerColor });
                        onSave && onSave(blockPickerColor);
                        formik.setFieldValue("color", blockPickerColor);
                        setIsClrModalOpen(false);
                    }}
                />
            </div>
        </Modal>
    );
}

export default ColorSelectModal;
