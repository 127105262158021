import React, {useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";

const TextEditor = ({onChange, initialValue}) => {
    const editorRef = useRef(null);
    return (
        <Editor
            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={onChange}
            className="tinyMCEbox"
            value={initialValue}
            init={{
                height: 400,
                menubar: true,
                plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "wordcount",
                ],
                toolbar:
                    "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
        />
    );
};

export default TextEditor;
