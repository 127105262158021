import React from "react";
import ReactSelect, { components } from "react-select";
import styles from "./selectBadge.module.scss";


const Menu = (props) => {
    return (
        <>
            <components.Menu {...props}>
                <div>
                    {props.children}
                    {props.buttonText && (
                        <button
                            onClick={props.buttonOnClick}
                            className={styles.optionButton}
                        >
                            {props.buttonText}
                        </button>
                    )}
                </div>
            </components.Menu>
        </>
    );
};

const Option = (props) => {
    return (
        <components.Option className={styles.optionWrapper} {...props}>
            <div className={styles.optionContainer}>
                <div className={styles.option}>
                    {props.children}
                </div>
                {props.isSelected}
            </div>
        </components.Option>
    );
};

const SingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            <div className={styles.selectedOptionContainer}>
                <div>
                    {props.children}
                </div>
            </div>
        </components.SingleValue>
    );
};

const SelectBadge =({
    className,
    onChange,
    onBlur,
    buttonText,
    buttonOnClick,
    options,
    name,
    defaultValue,
    value,
    isMulti,
    error,
    touched,
}) => {
    const style = {
        control: (base) => ({
            ...base,
            border: touched && Boolean(error) && "1px solid red !important",
            boxShadow: "none",
        }),
        menu: (base) => ({
            ...base,
            left: 0,
        }),
    };

    const stop = (e) => e.stopPropagation();

    return (
        <>
            <div
               /* onClick={stop}
                onTouchStart={stop}
                onPointerDown={stop}
                onPointerEnter={stop}
                onPointerMove={stop}
                onTouchMove={stop}
                onBlur={stop}*/
                className={`${styles.container}`}
            >
                <div className={styles.selectWrapper}>
                    <ReactSelect
                        className={`${styles.selectBadge} ${className}`}
                        classNamePrefix="b"
                        options={options}
                        value={value}
                        defaultValue={defaultValue}
                        isSearchable={false}
                        onChange={onChange}
                        name={name}
                        onBlur={onBlur}
                        isMulti={isMulti}
                        // menuIsOpen={true}
                        components={{
                            SingleValue: (props) => SingleValue({ ...props }),
                            IndicatorSeparator: () => null,
                            Menu: (props) => Menu({ ...props, buttonText, buttonOnClick }),
                            Option: (props) => Option({ ...props}),
                            DropdownIndicator: () => (
                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.22852 1L5.23613 5L1.22852 9" stroke="#373D59" stroke-linecap="round"/>
                                </svg>
                            ),
                        }}
                        styles={style}
                        showNewOptionAtTop={false}
                    />
                </div>
            </div>
            {touched && error && (
                <span className={styles.validationHelper}>{error}</span>
            )}
        </>
    );
}

export default React.memo(SelectBadge);
