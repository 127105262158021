import {useEffect, useState} from "react";

const useSmartPosition = (relativeElementRef, targetElementRef, dependency) => {
    let initialState = {left: 0, right: 0, bottom: 0}
    const [offset, setOffset] = useState(initialState)

    const reset = () => setOffset(initialState)

    useEffect(() => {
        if (!targetElementRef?.current) {
            setOffset(initialState)
            return;
        }

        let _relativeElement = relativeElementRef.current.getBoundingClientRect()
        let _targetElement = targetElementRef.current.getBoundingClientRect()

        let relativeElementBottom = _relativeElement.bottom
        let targetElementBottom = _targetElement.bottom

        let relativeElementLeft = _relativeElement.left
        let targetElementLeft = _targetElement.left

        let relativeElementRight = _relativeElement.right
        let targetElementRight = _targetElement.right

        let leftOffset = (relativeElementLeft > targetElementLeft ? Math.abs(targetElementLeft - relativeElementLeft) : 0)
        let rightOffset = (targetElementRight > relativeElementRight ? targetElementRight - relativeElementRight : 0)
        let bottomOffset = (targetElementBottom > relativeElementBottom ? targetElementBottom - relativeElementBottom : 0)

        setOffset({
            left: leftOffset,
            right: rightOffset,
            bottom: bottomOffset
        })

        // eslint-disable-next-line
    }, [dependency])

    return {offset, reset}
};

export default useSmartPosition;
