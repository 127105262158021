import http from '../http-common';

const get = (token) => { // Receiving a message only works for new users
    return http.get(`/message/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const update = (message, token) => {
    return http.put(`/message/`, { message }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const MessageService = {
    get,
    update,
};
export default MessageService;
