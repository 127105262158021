import React from "react";
import PhoneInput from "react-phone-input-2";
import styles from "./phoneSelect.module.scss";
import "react-phone-input-2/lib/high-res.css";

export default function PhoneSelect({ value, handlePhoneChange }) {
    return (
        <PhoneInput
            country={"es"}
            value={value}
            onChange={(value) => handlePhoneChange(value)}
            buttonClass={styles.buttonClass}
            dropdownClass={styles.dropdownClass}
            inputClass={styles.inputClass}
        />
    );
}
