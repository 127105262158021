import React, {useEffect, useRef, useState} from "react";
import styles from "./tagSelect.module.scss";
import ReactSelect, {components} from "react-select";
import purple_tick from "../../../media/icons/purple_tick.svg";
import gray_tick from "../../../media/icons/gray_tick.svg";
import {GRAY, PURPLE} from "../../../const";
import delete_gray from "../../../media/icons/delete_gray.svg";
import close_gray from "../../../media/icons/close_gray.svg";
import delete_icon from "../../../media/icons/Delete.svg";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import RadioButton from "../radioButton/checkBox";

const Menu = ({props, handleButtonClick, isClose, inputValue}) => {
    return (
        <>
            <components.Menu {...props}>
                <div style={{marginTop: '14px'}}>
                    {props.children}
                    {!inputValue && <div className={styles.buttonWrapper}>
                        <img src={isClose ? close_gray : delete_gray} alt=""/>
                        <button onClick={handleButtonClick} className={styles.optionButton}>
                            {isClose ? "Close" : "Delete tags"}
                        </button>
                    </div>}

                </div>
            </components.Menu>
        </>
    );
};

const Option = ({
                    props,
                    handleChange,
                    selected,
                    isClose,
                    handleDeleteClick,
                    selectRef,
                    setInputValue,
                }) => {

    return (
        <div ref={selectRef} className={styles.optionWrapper} {...props}>
            <div className={styles.optionContainer}>
                <div className={styles.option}>
                    {isClose ? (
                        <div className={styles.deleteContainer}>
                            <img
                                onClick={() => handleDeleteClick(props.value)}
                                src={delete_icon}
                                alt=""
                            />
                            {props.children}
                        </div>
                    ) : (
                        <RadioButton
                            onChange={(e) => {
                                handleChange(e.target.checked, props.value)
                                setInputValue(props.value)
                            }}
                            label={props.children}
                            checked={selected === props.value}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const NoOptionsMessage = ({
                              props,
                              handleCreateNewClick,
                              inputValue,
                          }) => {
    return (
        <components.NoOptionsMessage className={styles.createNew} {...props}>
            {
                inputValue.length ? (<div
                    style={{marginTop: '-14px'}}
                    onClick={() =>
                        handleCreateNewClick(inputValue)
                    }
                >
                    <span>Create "{inputValue}"</span>
                </div
                >) : null
            }

        </components.NoOptionsMessage>
    );
};

export default function CategorySelect({
                                      label,
                                      className,
                                      onChange,
                                      onBlur,
                                      handleCreateNewClick,
                                      handleDeleteClick,
                                      options,
                                      name,
                                      tickColor,
                                      hasIndicatorBackground = false,
                                      defaultValue,
                                      value,
                                      onFocusOut,
                                  }) {
    const [isClose, setIsClose] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const selectRef = useRef();

    useOnClickOutside(selectRef, () => {
        if (onFocusOut && isMenuOpen) {
            return onFocusOut();
        }
    });

    const handleButtonClick = () => {
        setIsClose((prev) => !prev);
    };

    const style = {
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "#373D59" : "#373D59",
            background: state.isSelected ? "white" : "white",
        }),
        menu: (base) => ({
            ...base,
            left: 0,
        }),
    };

    const _renderTick = () => {
        switch (tickColor) {
            case PURPLE:
                return <img src={purple_tick} alt=""/>;
            default:
                return <img src={gray_tick} alt=""/>;
        }
    };

    const stop = (e) => e.stopPropagation();

    const handleInputValueChange = (value) => {
        setInputValue(value);
    };

    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue.value)
        }
    }, [defaultValue])

    return (
        <>
            <div
                onClick={stop}
                onTouchStart={stop}
                onPointerDown={stop}
                onPointerEnter={stop}
                onPointerMove={stop}
                onTouchMove={stop}
                onBlur={stop}
                className={`${styles.container} enableSwiper ${className}`}
            >
                {label && <label>{label}</label>}
                {hasIndicatorBackground && (
                    <div className={styles.indicatorBackground}></div>
                )}

                <div onClick={() => setIsMenuOpen(true)}>
                    <ReactSelect
                        className={`${styles.select}`}
                        classNamePrefix="b"
                        options={options}
                        inputValue={inputValue}
                        onInputChange={handleInputValueChange}
                        defaultValue={defaultValue}
                        isSearchable={true}
                        name={name}
                        onBlur={onBlur}
                        isMulti={false}
                        menuIsOpen={(options?.length > 0 || inputValue?.length > 0) && isMenuOpen}
                        onMenuClose={() =>  {
                            setInputValue(inputValue)
                            setIsMenuOpen(false)
                        }}
                        closeMenuOnSelect={false}
                        placeholder={"Create Category"}
                        components={{
                            SingleValue: () => null,
                            IndicatorSeparator: () => null,
                            Menu: (props) => Menu({props, handleButtonClick, isClose, inputValue}),
                            NoOptionsMessage: (props) =>
                                NoOptionsMessage({
                                    props,
                                    handleCreateNewClick,
                                    inputValue,
                                    setInputValue,
                                }),
                            Option: (props) =>
                                Option({
                                    props,
                                    _renderTick,
                                    handleChange: onChange,
                                    selected: value,
                                    isClose,
                                    handleDeleteClick,
                                    selectRef,
                                    setInputValue,
                                    defaultValue,
                                }),
                            DropdownIndicator: () => (
                                <svg
                                    width="16"
                                    height="10"
                                    viewBox="0 0 16 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 1.5L8 8.5L1 1.5"
                                        stroke="#130F26"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            ),
                        }}
                        styles={style}
                        showNewOptionAtTop={false}
                    />
                </div>
            </div>
            {/*{value?.length ? <div className={styles.badgeContainer}>{value?.map((v, i) => {
                let _badge = options?.find(o => o.value === v)?.label
                return <Badge className={styles.badge} key={i} text={_badge} color={GRAY}/>;
            })}</div> : null}*/}
        </>
    );
}
