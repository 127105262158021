import React, {useEffect, useMemo, useRef} from 'react';
import {useState} from 'react';
import {
    DATE_ONLY,
    GLOSSY,
    POST_DATE,
    PREVIOUS_DATE,
    SOLID,
    WEEK_DAYS,
} from '../../../../const';
import {generateCalendar} from '../../../../utils/calendar';
import styles from './miniCalendar.module.scss';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {EVENT, EVENTS} from '../../../../rquery/queryKeys';
import {useDispatch, useSelector} from 'react-redux';
import GoogleCalendarService from '../../../../services/GoogleCalendarService';
import {useFormik} from 'formik';
import calendarPostValidation from '../../../../validations/calendar/calendarPostValidation';
import Modal from '../../../global/modal/modal';
import close from '../../../../media/icons/close_icon.svg';
import tick from '../../../../media/icons/tick_white.svg';
import CustomDatePicker from '../../../global/customDatePicker/customDatePicker';
import Button from '../../../global/button/button';
import delete_icon from '../../../../media/icons/delete_gray.svg';
import {useLayoutEffect} from 'react';
import moment from 'moment';
import warning from '../../../../media/icons/red_warning.svg';
import {COLORS} from '../../../../mock';
import useSmartPosition from '../../../../hooks/useSmartPosition';
import {toast} from "react-toastify";
import TimePicker from '../timePicker/timePicker';
import {refreshGoogleToken} from "../../../../redux/slices/authentication";


export default function MiniCalendar({month, year, events}) {

    const { google } = useSelector(
        (state) => state?.Authentication
    );
    const [currentEventId, setCurrentEventId] = useState();
    const isHaveEvent = events?.some((e) => e.id === currentEventId) || false;

    const {prevDates, currentDates, postDates} = useMemo(
        () => generateCalendar(month, year),
        [month, year]
    );
    let today = new Date();

    const [calendarDate, setCalendarDate] = useState({
        month: today.getMonth(),
        year: today.getFullYear(),
    });

    const calendarRef = useRef();
    const dropDownRef = useRef();
    const allDates = [...prevDates, ...currentDates, ...postDates];
    const [activeDate, setActiveDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState();
    let {offset, reset} = useSmartPosition(
        calendarRef,
        dropDownRef,
        activeDate
    );

    const handleResize = () => {
        let width = window.innerWidth;

        if (width >= 768) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    };

    const handleDateMouseEnter = (day, month) => {
        setActiveDate({day, month});
    };

    const handleDateMouseLeave = () => {
        setActiveDate(null);
        reset();
    };


    const {data} = useQuery(
        [EVENT, google.accessToken, currentEventId],
        () => GoogleCalendarService.getById(currentEventId, google.accessToken),
        {
            refetchOnWindowFocus: false,
        }
    );

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentEventId(null);
    };

    const handleOpenDeleteModal = () => {
        setIsModalOpen(false);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setCurrentEventId(null);
    };

    const handleOpenModal = (id) => {
        setCurrentEventId(id);
        setIsModalOpen(true);
    };

    const queryClient = useQueryClient();

    const {mutate} = useMutation(
        (data) => {
            if (isHaveEvent) {
                return GoogleCalendarService.put(currentEventId, data, google.accessToken)
            } else {
                return GoogleCalendarService.post(data, google.accessToken)
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([EVENTS]);
                queryClient.invalidateQueries([EVENT]);
                handleCloseModal();
                toast.success(isHaveEvent ? 'Event updated successfully' : 'Event created successfully');
            },
        }
    );

    const {mutate: mutateDelete} = useMutation(
        (id) => GoogleCalendarService.deleteById(currentEventId, google.accessToken),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([EVENTS]);
                queryClient.invalidateQueries([EVENT]);
                toast('Event deleted successfully');
                handleCloseDeleteModal();
            },
            onError: () => {
                toast.error('Something went wrong');
            }
        }
    );


    const handleDeleteClick = () => {
        mutateDelete(currentEventId);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            summary: data?.data.summary,
            description: data?.data.description,
            startDate: data?.data.start?.dateTime
                ? new Date(data?.data.start?.dateTime)
                : null,
            endDate: data?.data.start.endTime,
            startTime: data?.data.start?.dateTime
                ? moment(data?.data.start?.dateTime).format('hh:mm A')
                : null,
            endTime: data?.data.end?.dateTime
                ? moment(data?.data.end?.dateTime).format('hh:mm A')
                : null,
            colorId: data?.data.colorId,
        },
        validationSchema: calendarPostValidation,
        onSubmit: (values) => {
            const {description, summary, startDate, endDate, colorId} = values;

            const data = {
                end: {
                    dateTime: endDate,
                },
                start: {
                    dateTime: startDate,
                },
                description,
                summary,
                colorId: colorId || 0,
            };

            mutate(data);
        },
    });

    const handleDateChange = (date) => {
        let _startTime = moment(
            formik.values.startTime,
            'hh:mm A',
            true
        ).isValid()
            ? moment(formik.values.startTime, 'hh:mm A', true)
            : null;
        let _endTime = moment(formik.values.endTime, 'hh:mm A', true).isValid()
            ? moment(formik.values.endTime, 'hh:mm A', true)
            : null;

        let _startDate =
            _startTime && date
                ? moment(date)
                    .set({
                        hour: _startTime.get('hours'),
                        minute: _startTime.get('minutes'),
                    })
                    .toDate()
                : date;

        let _endDate =
            _endTime && date
                ? moment(date)
                    .set({
                        hour: _endTime.get('hours'),
                        minute: _endTime.get('minutes'),
                    })
                    .toDate()
                : null;

        formik.setFieldValue('startDate', _startDate);
        formik.setFieldValue('endDate', _endDate);
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        handleDateChange(formik.values.startDate);
        // eslint-disable-next-line
    }, [formik.values.startTime, formik.values.endTime]);


    const _renderEventModal = () => {
        return (
            <Modal onClose={handleCloseModal} open={isModalOpen}>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>
                            {
                                isHaveEvent ? "Edit Event" : "Add Event"
                            }
                        </p>
                        <button
                            onClick={handleCloseModal}
                            className="modalClose"
                        >
                            <img src={close} alt=""/>
                        </button>
                    </div>

                    <div className={styles.modalDivider}/>

                    <div className={styles.modalBodyContainer}>
                        <div className={styles.colorsContainer}>
                            <span>Color</span>
                            <div
                                className={`formControls d-flex col ${styles.formControls}`}
                            >
                                <div className={styles.colors}>
                                    {COLORS.map((c, i) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    formik.setFieldValue(
                                                        'colorId',
                                                        c.id
                                                    )
                                                }
                                                key={i}
                                                className={styles.sphere}
                                                style={{
                                                    backgroundColor: c.color,
                                                }}
                                            >
                                                {c.id ===
                                                    formik.values.colorId && (
                                                        <img src={tick} alt=""/>
                                                    )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.summary &&
                                    Boolean(formik.errors.summary) &&
                                    'error'
                                } ${styles.formControls}`}
                            >
                                <label>Title</label>
                                <input
                                    name="summary"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.summary}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                <span className={styles.validationHelper}>
                                    {formik.touched.summary &&
                                        formik.errors.summary}
                                </span>
                            </div>

                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.description &&
                                    Boolean(formik.errors.description) &&
                                    'error'
                                } ${styles.formControls}`}
                            >
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    onChange={formik.handleChange}
                                    defaultValue={
                                        formik.initialValues.description
                                    }
                                    onBlur={formik.handleBlur}
                                ></textarea>
                                <span className={styles.validationHelper}>
                                    {formik.touched.description &&
                                        formik.errors.description}
                                </span>
                            </div>
                            <div
                                className={`d-flex ${styles.formControls} ${styles.datePick}`}
                            >
                                <div className={styles.day}>
                                    <label>Day</label>
                                    <CustomDatePicker
                                        onBlur={formik.handleBlur}
                                        handleSelectDate={handleDateChange}
                                        className={styles.datePicker}
                                        date={formik.values.startDate}
                                        type={DATE_ONLY}
                                    />
                                    {formik.touched.startDate &&
                                        !isMobile &&
                                        Boolean(formik.errors.startDate) && (
                                            <span
                                                className={`${styles.validationHelper}`}
                                            >
                                                {formik.errors.startDate}
                                            </span>
                                        )}
                                </div>
                                {formik.touched.startDate &&
                                    isMobile &&
                                    Boolean(formik.errors.startDate) && (
                                        <span
                                            className={`${styles.validationHelper}  ${styles.mobile}`}
                                        >
                                            {formik.errors.startDate}
                                        </span>
                                    )}
                                <div
                                    className={`formControls d-flex col
                                     ${styles.formControls} ${styles.durationWrapper}`}
                                >
                                    <label>Duration</label>
                                    <div className={styles.horizontalDates}>
                                        <TimePicker time={formik.values.startTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'startTime'}/>
                                        {/*  <input
                                            defaultValue={
                                                formik.values.startTime
                                            }
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type="text"
                                            name={'startTime'}
                                        />*/}
                                        <div className={styles.divider}/>
                                        <TimePicker time={formik.values.endTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'endTime'}/>
                                        {/*  <input
                                            defaultValue={formik.values.endTime}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type="text"
                                            name={'endTime'}
                                        />*/}
                                    </div>
                                    <span className={styles.validationHelper}>
                                        {formik.touched.startTime &&
                                            formik.touched.endTime &&
                                            (formik.errors.startTime ??
                                                formik.errors.endTime)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.bottom}
                             style={{justifyContent: isHaveEvent ? 'space-between' : 'flex-end'}}>
                            <div className={styles.rightButtonGroup}>
                                {
                                    isHaveEvent && (
                                        <div
                                            onClick={handleOpenDeleteModal}
                                            className={styles.leftButtonGroup}
                                        >
                                            <img src={delete_icon} alt=""/> Delete event
                                        </div>
                                    )
                                }
                                <Button
                                    text={isHaveEvent ? "Save" : "Create"}
                                    onClick={formik.handleSubmit}
                                    type={GLOSSY}
                                    className={styles.btnCreate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };


    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseDeleteModal}
                open={isDeleteModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={handleCloseDeleteModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>
                                Are you sure that you want to delete this event?
                            </b>
                        </p>
                        <span>
                            After deleting, it will be impossible to restore
                            user data
                        </span>
                        <div className={styles.btnContainer}>
                            <Button
                                onClick={handleCloseDeleteModal}
                                className={`${styles.noBtnModal}`}
                                text={'No'}
                                type={SOLID}
                            />
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={'Delete'}
                                type={SOLID}
                                onClick={handleDeleteClick}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderEvents = (day, month) => {
        let _filteredEvents = events
            ?.filter(
                (e) =>
                    new Date(e.start?.dateTime).getDate() === day &&
                    new Date(e.start?.dateTime).getMonth() === month
            )
            .sort((a, b) =>
                moment.utc(
                    moment(a.start?.dateTime).diff(
                        moment.utc(b.start?.dateTime)
                    )
                )
            );

        return (
            _filteredEvents?.length > 0 && (
                <>
                    <div className={styles.events}>
                        {_filteredEvents?.slice(0, 3).map((e, i) => {
                            return (
                                <div
                                    className={styles.sphere}
                                    style={{
                                        backgroundColor: COLORS[e.colorId]?.color || COLORS[0].color
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                    {activeDate?.day === day && activeDate?.month === month && (
                        <div
                            ref={dropDownRef}
                            style={{
                                left: `calc(50% ${
                                    offset.left
                                        ? `+ ${offset.left}px`
                                        : `- ${offset.right}px`
                                })`,
                                top: offset.bottom ? '' : '100%',
                                transform: offset.bottom
                                    ? `translate(-50%, -50%)`
                                    : '',
                            }}
                            className={styles.eventContainer}
                        >
                            {_filteredEvents?.map((e) => {
                                return (
                                    <div
                                        onClick={() => {
                                            handleOpenModal(e.id)
                                        }}
                                        style={{
                                            backgroundColor: COLORS[e.colorId]?.color || COLORS[0].color
                                        }}
                                        className={styles.event}
                                    >
                                        {e.summary}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )
        );
    };

    const _renderDates = () => {
        let rows = [];
        for (let i = 1; i <= allDates[allDates.length - 1].row; i++) {
            rows.push(
                <tr key={i} className={styles.dateRow}>
                    {allDates
                        .filter((ad) => ad.row === i)
                        .map((d, index) => {
                            return (
                                <td
                                    onClick={(e) => {
                                        console.log(e.target)
                                        if (e.target.classList.contains(styles.events) ||
                                            e.target.classList.contains(styles.event) ||
                                            e.target.classList.contains(styles.sphere)) {
                                            return;
                                        }
                                        formik.setFieldValue('startDate', d.date)
                                        setIsModalOpen(true)
                                    }}
                                    onMouseEnter={
                                        events?.some(
                                            (e) =>
                                                new Date(
                                                    e.start?.dateTime
                                                ).getDate() ===
                                                d.date.getDate() &&
                                                new Date(
                                                    e.start?.dateTime
                                                ).getMonth() ===
                                                d.date.getMonth()
                                        )
                                            ? () =>
                                                handleDateMouseEnter(
                                                    d.date.getDate(),
                                                    d.date.getMonth()
                                                )
                                            : null
                                    }
                                    onMouseLeave={
                                        events?.some(
                                            (e) =>
                                                new Date(
                                                    e.start?.dateTime
                                                ).getDate() ===
                                                d.date.getDate() &&
                                                new Date(
                                                    e.start?.dateTime
                                                ).getMonth() ===
                                                d.date.getMonth()
                                        )
                                            ? () => handleDateMouseLeave()
                                            : null
                                    }
                                    key={index}
                                    className={`
                                    ${
                                        d.type === PREVIOUS_DATE ||
                                        d.type === POST_DATE
                                            ? styles.disabled
                                            : ''
                                    } ${styles.miniCalendar}
                                    `}
                                >
                                    {_renderEvents(
                                        d.date.getDate(),
                                        d.date.getMonth()
                                    )}
                                    <div className={styles.date}>{d.value}</div>
                                </td>
                            );
                        })}
                </tr>
            );
        }
        return rows;
    };

    return (
        <>
            {_renderEventModal()}
            {_renderDeleteModal()}
            <div>
                <table ref={calendarRef} className={styles.miniCalendar}>
                    <tbody>
                    <tr>
                        {WEEK_DAYS.map((wd, i) => {
                            return (
                                <th
                                    className={`${styles.header} ${styles.miniCalendar}`}
                                    key={i}
                                >
                                    {wd.substring(0, 3)}
                                </th>
                            );
                        })}
                    </tr>
                    {_renderDates()}
                    </tbody>
                </table>
            </div>
        </>
    );
}
