import http from '../http-common';

export const editUser = (data, token) => {
    return http.put('/users/update', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const changePassword = (data, token) => {
    return http.put('/users/changepassword', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const linkGoogle = (data, token) => {
    return http.post('/users/link_google', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const unlinkGoogle = (token) => {
    return http.post(
        '/users/unlink_google',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const linkFacebook = (data, token) => {
    return http.post('/users/link_facebook', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const unlinkFacebook = (token) => {
    return http.post(
        '/users/unlink_facebook',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
export const deleteUser = (data, token) => {
    return http.delete('/users/delete', {
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateAvatar = (data, token) => {
    return http.put('users/updateAvatar', data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};
