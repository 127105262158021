import React from "react";
import styles from "./forumAnswerCard.module.scss";
import profile from "../../../media/icons/user.svg";
import reply from "../../../media/icons/reply.svg";
import {useCallback} from "react";
import {useState} from "react";
import TextEditor from "../../global/textEditor/textEditor";
import plus from "../../../media/icons/plus_violet.svg";
import Modal from "../../global/modal/modal";
import {GLOSSY, SOLID} from "../../../const";
import Button from "../../global/button/button";
import close from "../../../media/icons/close_icon.svg";
import pencil from "../../../media/icons/pencil.svg";
import delete_icon from "../../../media/icons/Delete.svg";
import warning from "../../../media/icons/red_warning.svg";
import {useSelector} from "react-redux";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ReplyService from "../../../services/ReplyService";
import {REPLIES, REPLY} from "../../../rquery/queryKeys";
import moment from "moment";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
import sanitize from "sanitize-html";
import useFileUpload from "../../../hooks/useFileUpload";

export default function ForumAnswerCard({
                                            name,
                                            email,
                                            text,
                                            images,
                                            time,
                                            userImg,
                                            id,
                                            comments,
                                        }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const currentUser = useSelector((state) => state.Authentication);
    const {id: discussionId} = useParams();
    const {
        token,
        id: userId,
        isAdmin,
    } = useSelector((state) => state.Authentication);
    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
        setReplyToId(false);
    }, []);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [replyToId, setReplyToId] = useState();

    const handleCloseEditModal = useCallback(() => {
        setIsEditModalOpen(false);
    }, []);

    const handleOpenReplyModal = (replyToId) => {
        setReplyToId(replyToId);
        setModalOpen(true);
    };

    const handleCloseDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);

    const queryClient = useQueryClient();

    const {data} = useQuery(
        [REPLY, token, replyToId, id],
        () => ReplyService.getById(replyToId, token),
        {
            enabled: !!replyToId,
        }
    );

    const {mutate} = useMutation((id) => ReplyService.deleteById(id, token), {
        onSuccess: (data) => {
            queryClient.invalidateQueries([REPLIES]);
            handleCloseDeleteModal();
        },
    });

    const handleDeleteClick = () => {
        mutate(id);
    };

    const {mutate: mutatePost} = useMutation(
        (data) => ReplyService.post(data, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([REPLIES]);
                handleCloseModal();
            },
        }
    );

    const {mutate: mutatePut} = useMutation(
        (data) => ReplyService.put(id, data, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([REPLIES]);
                handleCloseEditModal();
            },
        }
    );

    const formikPostReply = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: null,
            user: userId,
            discussion: discussionId,
            reply: replyToId,
        },
        // validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutatePost(values);
        },
    });

    const formikPutReply = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: text,
            user: userId,
            discussion: discussionId,
        },
        // validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutatePut(values);
        },
    });

    const handlePostEditorChange = (v) => {
        let _sanitizedText = sanitize(v);
        formikPostReply.setFieldValue("message", _sanitizedText);
    };

    const handlePutEditorChange = (v) => {
        let _sanitizedText = sanitize(v);
        formikPutReply.setFieldValue("message", _sanitizedText);
    };

    const UserImg = () => {
        return <img src={userImg || profile} alt="user-image" />
    }

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseDeleteModal}
                open={isDeleteModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={handleCloseDeleteModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>Are you sure that you want to delete this discussion?</p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                type={SOLID}
                                onClick={handleDeleteClick}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderEditModal = () => {
        return (
            <Modal
                className={styles.modal}
                onClose={handleCloseEditModal}
                open={isEditModalOpen}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>Edit reply </p>
                        <button onClick={handleCloseEditModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Message:</label>
                            <TextEditor
                                initialValue={text}
                                onChange={handlePutEditorChange}
                            />
                        </div>
                        <Button
                            type={GLOSSY}
                            onClick={formikPutReply.handleSubmit}
                            className={styles.createBtn}
                            text={"Edit"}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderReplyModal = () => {
        return (
            <Modal
                onClose={handleCloseModal}
                open={isModalOpen}
                className={styles.modalReply}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <div className={styles.left}>
                            <div className={styles.replyContainer}>
                                <div className={styles.replyContainer__replyIcon}>
                                    <img src={reply} alt=""/>
                                </div>
                                <div className={styles.profileContainer}>
                                    <div className={styles.up}>
                                        <UserImg />
                                        <span>{data?.data?.data.user.name}</span>
                                    </div>
                                    <div className={styles.down}>
                                        <p>
                                            {data?.data?.data.message.replace(/<\/?[^>]+(>|$)/g, "")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button onClick={handleCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Message</label>
                            <TextEditor onChange={handlePostEditorChange}/>
                        </div>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Attachments</label>
                            <div className={styles.fileZone}>
                                <img src={plus} alt=""/>
                            </div>
                        </div>
                        <Button
                            onClick={formikPostReply.handleSubmit}
                            className={styles.saveBtn}
                            text={"Send"}
                            type={GLOSSY}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderComments = (replies) => {
        if (!replies?.length) return;
        return replies.map((r, i) => {
            return (
                <div key={i} className={styles.replyBlock}>
                    <div className={styles.header}>
                        <div className={styles.avatarWrapper}>
                            <div className={styles.avatar}>
                                <UserImg />
                                <div className={styles.online}/>
                            </div>
                            <div className={styles.description}>
                                <span className={styles.name}>{r.user.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.text}>
                        <p dangerouslySetInnerHTML={{__html: r.message}}></p>
                    </div>
                   {/* <div className={styles.attachments}>
                        {images.map((i, _i) => {
                            return (
                                <div key={_i} className={styles.imgContainer}> // attached images
                                    <img src={i.img} alt=""/>
                                </div>
                            );
                        })}
                    </div>*/}
                    <div className={styles.footer}>
                        <div
                            onClick={() => handleOpenReplyModal(r._id)}
                            className={styles.reply}
                        >
                            <img src={reply} alt=""/>
                            <span>Reply</span>
                        </div>
                        <span>{moment(r.createdAt).format("HH:mm")}</span>
                    </div>
                    {_renderComments(r.replies)}
                </div>
            );
        });
    };

    return (
        <div className={styles.container}>
            {_renderReplyModal()}
            {_renderEditModal()}
            {_renderDeleteModal()}
            <div className={styles.header}>
                <div className={styles.avatarWrapper}>
                    <div className={styles.avatar}>
                        <UserImg />
                        <div className={styles.online}/>
                    </div>
                    <div className={styles.description}>
                        <span className={styles.name}>{name}</span>
                        <span className={styles.email}>{email}</span>
                    </div>
                </div>
                {isAdmin && (
                    <div className={styles.btnGroup}>
                        <div
                            onClick={() => setIsEditModalOpen(true)}
                            className={styles.adminBtn}
                        >
                            <img src={pencil} alt=""/>
                        </div>
                        <div
                            onClick={() => setIsDeleteModalOpen(true)}
                            className={styles.adminBtn}
                        >
                            <img src={delete_icon} alt=""/>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.text}>
                <p dangerouslySetInnerHTML={{__html: text}}></p>
            </div>
            {/*<div className={styles.attachments}>
                {images.map((i, _i) => {
                    return (
                        <div key={_i} className={styles.imgContainer}> // attached images
                            <img src={i.img} alt=""/>
                        </div>
                    );
                })}
            </div>*/}
            <div className={styles.footer}>
                <div onClick={() => handleOpenReplyModal(id)} className={styles.reply}>
                    <img src={reply} alt=""/>
                    <span>Reply</span>
                </div>
                <span>{time}</span>
            </div>
            {_renderComments(comments)}
        </div>
    );
}
