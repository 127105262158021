import React from "react";
import styles from "./card.module.scss";

export default function Card({title, children, className}) {
    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.header}>
                <span>{title}</span>
            </div>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
}
