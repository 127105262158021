import React from "react";
import ReactApexChart from "react-apexcharts";
import {chartOptions} from "./config";

export default function Chart() {
    return (
        <ReactApexChart
            options={chartOptions.options}
            type="area"
            series={chartOptions.series}
            height="260"
            markers={
                {show: true}
            }
        />
    );
}
