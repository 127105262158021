import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SOLID, URL_BASED, BACK} from "../../../const";
import useSwitchTab from "../../../hooks/useSwitchTab";
import SwitchTab from "../../global/switchTab/switchTab";
import styles from "./projectDetail.module.scss";
import document from "../../../media/icons/folded_document.svg";
import tasks from "../../../media/icons/tasks.svg";
import notes from "../../../media/icons/notes.svg";
import back from "../../../media/icons/arrow_back.svg";
import attachments from "../../../media/icons/attachments.svg";
import Button from "../../global/button/button";
import pen from "../../../media/icons/pen.svg";
import delete_icon from "../../../media/icons/delete_black.svg";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {ROUTES} from "../../../routes/mainRoutes";
import {useTitle} from "../../../hooks/useTitle";
import {useRef} from "react";
import {useState} from "react";

export default function ProjectDetail() {
    useTitle("View Project");
    const navigate = useNavigate();
    const location = useLocation();
    const _pathArray = location.pathname.split("/");
    const path = _pathArray.pop();
    const penultimatePath = _pathArray.pop();
    const {currentTab, handleTabClick} = useSwitchTab(path);
    const [isEdit, setIsEdit] = useState();

    const editRef = useRef();
    const deleteRef = useRef();

    const handleEditClick = () => {
        if (!editRef?.current) return;
        setIsEdit((prev) => !prev);
        editRef.current();
    };

    const handleDeleteClick = () => {
        if (!deleteRef?.current) return;
        deleteRef.current();
    };

    const handleGoBack = () => {
        navigate(ROUTES.projects);
    };

    const _handleTabClick = (route) => {
        switch (route) {
            case "notes":
                navigate("notes", {replace: true});
                handleTabClick("notes");
                break;
            case "attachments":
                navigate("attachments", {replace: true});
                handleTabClick("attachments");
                break;
            case "tasks":
                navigate("tasks", {replace: true});
                handleTabClick("tasks");
                break;
            default:
                const _route = route.split("/");
                _route.pop();
                if (_route.pop() === "notes") {
                    navigate("notes", {replace: true});
                    handleTabClick("notes");
                    break;
                }
                navigate("brief", {replace: true});
                handleTabClick("brief");
                break;
        }
    };

    useEffect(() => {
        let _path = location.pathname.split("/");
        _path.pop();
        const _lastPath = _path.pop();
        if (!isNaN(path) && !(_lastPath === "notes" || _lastPath === "edit")) {
            navigate("brief", {replace: true});
        }
    }, [path, navigate, location.pathname]);


    return (
        <>
            <div className={styles.top}>
                <div className={styles.title}>
                    <Button
                        type={BACK}
                        icon={back}
                        className={styles.backBtn}
                        onClick={handleGoBack}
                    />
                    <h2>
                        {currentTab === "brief" || !isNaN(currentTab)
                            ? "Overview"
                            : currentTab === "create" ||
                            penultimatePath === "edit" ||
                            penultimatePath === "notes"
                                ? "Notes"
                                : currentTab === "attachments"
                                    ? "Attachments"
                                    : currentTab}
                    </h2>
                </div>
                <SwitchTab
                    path={location.pathname}
                    routingType={URL_BASED}
                    className={styles.switchTab}
                    type={SOLID}
                    labels={[
                        {label: "Brief", icon: document, index: "brief"},
                        {label: "Tasks", icon: tasks, index: "tasks"},
                        {label: "Notes", icon: notes, index: "notes"},
                        {label: "Attachments", icon: attachments, index: "attachments"},
                    ]}
                    currentTab={currentTab}
                    handleTabClick={_handleTabClick}
                />
                {(currentTab === "brief" ||
                    currentTab === "tasks/2" ||
                    penultimatePath === "notes") &&
                location.pathname.split("/").pop() !== "create" ? (
                    <div className={styles.buttonGroup}>
                        {currentTab !== "brief" && <Button
                            onClick={handleEditClick}
                            ref={editRef}
                            text={isEdit ? "Save" : "Edit"}
                            type={SOLID}
                            icon={pen}
                        />}
                        <Button
                            onClick={handleDeleteClick}
                            ref={deleteRef}
                            text={"Delete"}
                            type={SOLID}
                            icon={delete_icon}
                            className={styles.delete}
                        />
                    </div>
                ) : (
                    <div className={styles.buttonGroup}></div>
                )}
            </div>
            {currentTab === "tasks" ? (
                <Outlet/>
            ) : (
                <div className={`border ${styles.detailsBlock}`}>
                    <div className={`backCard d-flex wrap ${styles.container}`}>
                        <div className="whiteBg w-100">
                            <div className={styles.contentContainer}>
                                {currentTab === "notes" || penultimatePath === "edit" ? (
                                    <div className={styles.smallTitle}>Notes</div>
                                ) : currentTab === "brief" ||
                                !isNaN(currentTab) ? null : currentTab === "attachments" ? (
                                    <div className={styles.smallTitle}>Attachment</div>
                                ) : currentTab === "create" ||
                                penultimatePath === "notes" ? null : (
                                    currentTab
                                )}
                                <Outlet context={{deleteRef, editRef, isEdit}}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
