import React, {useRef, useCallback, useState, useEffect} from "react";
import {GLOSSY, PURPLE, SOLID, STATUSES, TAGS} from "../../../const";

import styles from "./brief.module.scss";

import Modal from "../../global/modal/modal";
import Select from "../../global/select/select";
import Button from "../../global/button/button";
import TagSelect from "../../global/select/tagSelect";

import CustomDatePicker from "../../global/customDatePicker/customDatePicker";

import {useTitle} from "../../../hooks/useTitle";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useQuery, useMutation, useQueryClient} from "react-query";

import {PROJECT, PROJECTS} from "../../../rquery/queryKeys";
import ProjectService from "../../../services/ProjectService";
import TagService from "../../../services/TagService";

// Icons
import tick from "../../../media/icons/tick.svg";
import close from "../../../media/icons/close_icon.svg";
import warning from "../../../media/icons/red_warning.svg";
import delete_icon from "../../../media/icons/delete_black.svg";

import {optionsColor} from "../../../mock";

import {toast} from "react-toastify";
import ColorSelectModal from "../colorSelectModal/colorSelectModal";
import CategorySelect from "../../global/select/categorySelect";
import projectPostValidation from "../../../validations/projects/projectPostValidation";
import cn from "classnames";

export default function Brief() {
    useTitle('Brief');

    const nameRef = useRef();
    const descriptionRef = useRef()
    const categoryRef = useRef()

    const nameTimer = useRef();
    const descriptionTimer = useRef();
    const {deleteRef} = useOutletContext();

    const {id} = useParams();
    const {token} = useSelector((state) => state.Authentication);

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [categories, setCategories] = useState([{}]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [optionsColors, setOptionsColors] = useState(optionsColor);
    const [isClrModalOpen, setIsClrModalOpen] = useState(false);
    const [blockPickerColor, setBlockPickerColor] = useState('#fff');

    const [editMode, setEditMode] = useState({
        title: false,
        description: false,
        category: false,
        priority: false,
    });

    const [fieldsText, setFieldsText] = useState({
        name: '',
        description: '',
    })

    const onEdit = (key, isEdit) => {
        setEditMode((prev) => ({
            ...prev,
            [key]: isEdit,
        }));
    }

    const {isLoading, data} = useQuery([PROJECT, token, id], () => ProjectService.getById(id, token), {
        onSuccess: (data) => {
            setBlockPickerColor(data.data.color)
            const baseColors = optionsColor.map(color => color.value)
            const colors = data.data.colors?.filter(color => !baseColors.includes(color))
            setFieldsText({
                name: data.data.name,
                description: data.data.description,
            });
            if (colors?.length > 0) {
                setOptionsColors([...optionsColor, ...colors.map(color => ({value: color, label: color}))])
            }

            if (data.data.category) {

                let category = {
                    label: data.data.category,
                    value: data.data.category,
                }

                setCategories([category]);
            }

        },
        refetchOnWindowFocus: false,
    });

    const {data: tagsData} = useQuery([TAGS, token], () =>
        TagService.get(token)
    );

    const {mutate: updateProjectColor} = useMutation(
        (data) => {
            return ProjectService.updateProjectColor(id, {
                color: data.color
            }, token)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([PROJECT]);
                queryClient.invalidateQueries([PROJECTS]);
                handleCloseClrModal();
                toast.success("Color updated successfully",)
            },
        },
        {
            onError: () => toast.error("Something went wrong")
        }
    );

    const {mutate: updateProjectColors} = useMutation(
        (data) => {
            return ProjectService.updateProjectColors(id, {
                colors: data
            }, token)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([PROJECT]);
                queryClient.invalidateQueries([PROJECTS]);
            },
        },
        {
            onError: () => toast.error("Something went wrong")
        }
    );

    const onAddNewColor = (color) => {
        const baseColors = optionsColors.map(c => c.value);
        if (baseColors.includes(color)) return
        updateProjectColors([...baseColors, color]);
    }

    const {mutate} = useMutation(
        (data) => {
            return ProjectService.put(id, data, token)
        },
        {
            onSuccess: () => {
                toast.success("Project updated successfully",)
                queryClient.invalidateQueries([PROJECT]);
                queryClient.invalidateQueries([PROJECTS]);
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: data?.data.name,
            description: data?.data.description,
            dueDate: data?.data.dueDate && new Date(data?.data.dueDate),
            status: data?.data.status,
            tag: data?.data.tag?.map((t) => t._id),
            color: data?.data.color,
            category: data?.data.category,
        },
        validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const debouncedSubmit = (field, value) => {
        if (!value && !field) {
            setTimeout(() => {
                formik.submitForm();
            }, 450)
            return;
        }

        clearTimeout(field === 'name' ? nameTimer.current : descriptionTimer.current)

        if (value.length === 0 || value === fieldsText[field]) {
            return
        }

        if (field === 'description' && value.length === 0) {
            return;
        }


        if (field === 'name') {
            nameTimer.current = setTimeout(() => {
                mutate({name: value})
            }, 450)
        }
        if (field === 'description') {
            descriptionTimer.current = setTimeout(() => {
                mutate({description: value})
            }, 450)
        }
    }

    const onFieldChange = ({field, value}) => {
        clearTimeout(field === 'name' ? nameTimer.current : descriptionTimer.current)
        formik.setFieldValue(field, value)
        setFieldsText({...fieldsText, [field]: value})

        if (value.length === 0 || value === fieldsText[field]) return
        if (formik.errors[field]) return;
        debouncedSubmit(field, value)
    }

    const {mutate: mutateTag} = useMutation(
        (id) => TagService.deleteById(id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const {mutate: mutateProject} = useMutation(
        (id) => ProjectService.deleteById(id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([PROJECT]);
                queryClient.invalidateQueries([PROJECTS]);
                setIsModalOpen(false);
                navigate("/projects");
            },
        }
    );

    const {mutate: mutatePostTag} = useMutation(
        (data) => TagService.post(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );


    useOnClickOutside(nameRef, () => {
        onEdit('name', false)
    });

    useOnClickOutside(descriptionRef, () => {
        onEdit('description', false)
    });

    useOnClickOutside(categoryRef, () => {
        onEdit('category', false)
    })

    const handleTagDelete = (id) => {
        mutateTag(id)
        formik.setFieldValue("tag", formik.values.tag.filter(t => t !== id))
    };

    const handleCreateNewClick = (name, callBack) => {
        mutatePostTag({name: name});
        callBack();
    };

    const handleMarkAsCompleteClick = () => {
        mutate({completed: true, status: "done"});
    };

    const handeCategoryDelete = (value) => {
        let _categories = categories.filter(c => c.value !== value)
        setCategories(_categories)
        if (formik.values.category === value) {
            formik.setFieldValue("category", "")
        }
    }

    const handleCreateCategoryClick = (value) => {
        formik.setFieldValue("category", value);
        setCategories([...categories, {label: value, value: value}]);
    }

    const handleAddCustomColorClick = useCallback(() => {
        setIsClrModalOpen(true);
    }, []);

    const handleCloseClrModal = useCallback(() => {
        setIsClrModalOpen(false);
    }, []);

    useEffect(() => {
        deleteRef.current = () => setIsModalOpen(true);
        // eslint-disable-next-line
    }, [deleteRef]);

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={() => setIsModalOpen(false)}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>Are you sure that you want to delete this project?</b>
                        </p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.noBtnModal}`}
                                text={"No"}
                                type={SOLID}
                                onClick={() => setIsModalOpen(false)}
                            />
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                type={SOLID}
                                onClick={() => mutateProject(id)}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <div className={styles.container}>
            {!isLoading && (
                <>
                    <ColorSelectModal
                        isClrModalOpen={isClrModalOpen}
                        setIsClrModalOpen={setIsClrModalOpen}
                        optionsColors={optionsColors}
                        setOptionsColors={setOptionsColors}
                        blockPickerColor={blockPickerColor}
                        setBlockPickerColor={setBlockPickerColor}
                        updateColor={updateProjectColor}
                        onSave={onAddNewColor}
                        formik={formik}
                    />
                    {_renderDeleteModal()}
                    <div className={styles.left}>
                        <>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.name &&
                                    Boolean(formik.errors.name) &&
                                    "error"
                                } ${styles.formControls}`}
                                onMouseEnter={() => {
                                    clearTimeout(nameTimer.current)
                                }}
                            >
                                <div>
                                    <input
                                        className={cn(styles.field, styles.field__title,  {
                                            [styles.field__focus]: editMode.name,
                                            [styles.field__error]: Boolean(formik.errors.name)
                                        })}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            onFieldChange({field: 'name', value: e.target.value})
                                        }}
                                        onMouseLeave={() => {
                                            nameTimer.current = setTimeout(() => {
                                                onEdit('name', false)
                                            }, 400);
                                        }}
                                        onMouseEnter={() => {
                                            clearTimeout(nameTimer.current)
                                            onEdit('name', true)
                                        }}
                                        value={fieldsText.name}
                                        defaultValue={formik.initialValues.name}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                        ref={nameRef}
                                    />
                                </div>
                                <span className={styles.validationHelper}>
                                    {formik.errors.name}
                                </span>
                            </div>
                        </>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.description &&
                                Boolean(formik.errors.description) &&
                                "error"
                            }`}
                        >
                            <div>
                                <span className={styles.label}>DESCRIPTION</span>
                                <textarea
                                    className={cn(styles.field, {
                                        [styles.field__focus]: editMode.description,
                                        [styles.field__error]: Boolean(formik.errors.description)
                                    })}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        onFieldChange({field: 'description', value: e.target.value})
                                    }}
                                    onMouseEnter={() => {
                                        clearTimeout(descriptionTimer.current)
                                        onEdit('description', true)
                                    }}
                                    onMouseLeave={() => {
                                        descriptionTimer.current = setTimeout(() => {
                                            onEdit('description', false)
                                        }, 400);
                                    }}
                                    value={fieldsText.description}
                                    defaultValue={formik.initialValues.description}
                                    onBlur={formik.handleBlur}
                                    ref={descriptionRef}
                                >
                                </textarea>
                            </div>
                            <span className={styles.validationHelper}>
                                {formik.errors.description}
                            </span>
                        </div>

                        {data?.data.status !== "done" && (
                            <Button
                                className={styles.button}
                                text={"Mark as complete"}
                                type={GLOSSY}
                                icon={tick}
                                onClick={handleMarkAsCompleteClick}
                            />
                        )}
                    </div>
                    <div className={styles.right}>
                        <div>
                            <div className={styles.category}>
                                {
                                    editMode.category ? (
                                        <div className={styles.category}
                                             onMouseEnter={() => {
                                                 clearTimeout(categoryRef.current)
                                             }}
                                             onMouseLeave={() => {
                                                 categoryRef.current = setTimeout(() => {
                                                     onEdit('category', false)
                                                 }, 1500);
                                             }}>
                                            <CategorySelect
                                                options={categories}
                                                defaultValue={{
                                                    label: formik.values.category,
                                                    value: formik.values.category,
                                                }}
                                                handleCreateNewClick={(value) => {
                                                    handleCreateCategoryClick(value)
                                                    debouncedSubmit()
                                                }}
                                                label={"Category"}
                                                className={styles.select}
                                                handleDeleteClick={handeCategoryDelete}
                                                name="category"
                                                onChange={(checked, value) => {
                                                    formik.setFieldValue(
                                                        "category",
                                                        checked ? value : ""
                                                    );
                                                    debouncedSubmit()
                                                }}
                                                value={formik.values.category}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span>Category</span>
                                            <div className={styles.categoryBadge}
                                                 style={{backgroundColor: blockPickerColor}}
                                                 onClick={() => {
                                                     onEdit('category', true)
                                                 }}
                                            >
                                                {data?.data.category?.toUpperCase()}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <Select
                                onBlur={formik.handleBlur}
                                onChange={(o) => {
                                    formik.setFieldValue("color", o.value);
                                    updateProjectColor({color: o.value});
                                    setBlockPickerColor(o.value);
                                }}
                                defaultValue={{
                                    label: optionsColor.find((o) => o.value === blockPickerColor)?.label || blockPickerColor,
                                    value: blockPickerColor,
                                }}
                                value={{
                                    label: optionsColor.find((o) => o.value === blockPickerColor)?.label || blockPickerColor,
                                    value: blockPickerColor,
                                }}
                                options={optionsColors}
                                tickColor={PURPLE}
                                label={"Color"}
                                className={styles.select}
                                buttonText="+ Add custom color"
                                buttonOnClick={handleAddCustomColorClick}
                            />
                            <Select
                                name={"status"}
                                onChange={(o) => {
                                    formik.setFieldValue("status", o.value);
                                    formik.handleSubmit();
                                }}
                                options={STATUSES}
                                value={STATUSES.find((s) => s.value === formik.values.status)}
                                label={"Status"}
                                className={styles.select}
                            />
                            <TagSelect
                                name={"tag"}
                                options={tagsData?.data.map((d) => {
                                    return {label: d.name, value: d._id};
                                })}
                                onChange={(checked, id) => {
                                    if (checked) {
                                        formik.setFieldValue("tag", [...formik.values.tag, id]);
                                    } else {
                                        formik.setFieldValue("tag", [
                                            ...formik.values.tag.filter((t) => t !== id),
                                        ]);
                                    }
                                    debouncedSubmit()
                                }}
                                onFocusOut={debouncedSubmit}
                                handleCreateNewClick={handleCreateNewClick}
                                handleDeleteClick={handleTagDelete}
                                value={formik.values.tag}
                                label={"Tag"}
                                className={styles.select}
                            />
                            <CustomDatePicker
                                label={"Due date"}
                                className={`${styles.date}`}
                                name="dueDate"
                                date={formik.values.dueDate}
                                handleSelectDate={(d) => {
                                    formik.setFieldValue("dueDate", d);
                                    debouncedSubmit()
                                }}
                                touched={formik.touched.dueDate}
                                error={formik.errors.dueDate}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
