import * as Yup from "yup";

const requestResetPasswordValidation = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email format!")
        .min(3, "Email is too short - should be at least 3 chars!")
        .max(255, "Email is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),
});

export default requestResetPasswordValidation;
