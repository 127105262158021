import { useRef } from 'react';
import cn from 'classnames';
import styles from '../attachment.module.scss';
import moveIcon from '../../../../media/icons/move_icon.svg';
import editIcon from '../../../../media/icons/rename_icon.svg';
import deleteFolderIcon from '../../../../media/icons/delete_bold.svg';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const Settings = ({
    settingsId, setSettingsId, currentId, onDelete,
    type = 'file', setModalType, setEditId
}) => {
    const settingsRef = useRef(null);

    useOnClickOutside(settingsRef, (e) => {
        if (e.target.closest(`.${styles.attachment__settings__item}`)) return;
        setSettingsId(null)
    });

    return (
        <div className={cn(styles.attachment__settings, {
            [styles.attachment__settings__opened]: settingsId === currentId && type === 'file',
            [styles.attachment__settings__openedFolder]: settingsId === currentId && type === 'folder'
        })} ref={settingsRef}>
            {
                type === 'file' ?
                    <>
                        <div className={styles.attachment__settings__item} onClick={(e) => {
                            setModalType('rename');
                            setEditId(currentId);
                        }}>
                            <img src={editIcon} alt='icon'/> Rename
                        </div>
                        <div className={styles.attachment__settings__item} onClick={() => {
                            setModalType('move');
                            setEditId(currentId);
                        }}>
                            <img src={moveIcon} alt='icon'/> Move
                        </div>
                        <div className={styles.attachment__settings__item} onClick={() => {
                            onDelete && onDelete(currentId);
                        }}>
                            <img src={deleteFolderIcon} alt='icon'/> Delete
                        </div>
                    </> :
                    <>
                        <div className={styles.attachment__settings__item} onClick={(e) => {
                            e.stopPropagation();
                            setModalType('edit');
                            setEditId(currentId);
                        }}>
                            <img src={editIcon} alt='icon'/> Edit
                        </div>
                        <div className={styles.attachment__settings__item} onClick={(e) => {
                            e.stopPropagation();
                            onDelete && onDelete(currentId);
                            setSettingsId(null);
                        }}>
                            <img src={deleteFolderIcon} alt='icon'/> Delete
                        </div>
                    </>
            }
        </div>
    )
}

export default Settings;
