import * as Yup from "yup";
import moment from "moment";

const isEndTimeAfterStartTime = (startTime, endTime) => {
    if (startTime ^ endTime) return true

    return moment(endTime, "hh:mm A", true)
        .isAfter(moment(startTime, "hh:mm A", true))
}

const calendarPostValidation = Yup.object().shape({
    summary: Yup.string()
        .max(160, "Title is too long - should not exceed 160 characters!")
        .required("Required!")
        .typeError("Required!"),

    description: Yup.string()
        .max(8000, "Description is too long - should not exceed 8000 chars!"),

    startDate: Yup.date().required("Required!").typeError("Required!"),
    endDate: Yup.date().required("Required!").typeError("Required!"),

    startTime: Yup.string().test(
        "startTime",
        "Time is invalid!",
        (value, context) => {
            return moment(value, "hh:mm A", true).isValid()
                && isEndTimeAfterStartTime(value, context.parent.endTime)
        }
    ).required('Required!').typeError("Required!"),

    endTime: Yup.string().test(
        "endTime",
        "Time is invalid!",
        (value, context) => {
            return moment(value, "hh:mm A", true).isValid()
                && isEndTimeAfterStartTime(context.parent.startTime, value)
        }
    ).required('Required!').typeError("Required!"),
});

export default calendarPostValidation;
