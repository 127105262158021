import http from '../http-common';

const removeBreaks = (str) => {
    // remove line breaks and spaces, for optimal display
    return str.replace(/(\r\n|\n|\r|\s)/gm, '');
};

// GOOGLE

export const get_g_campaigns = (g_params, token) => {
    return http.get(
        removeBreaks(`/data/g_campaigns?customer_id=${g_params.g_customer_id}
        &login_customer_id=${g_params.g_login_customer_id}
        &from_date=${g_params.from_date}
        &to_date=${g_params.to_date}
        &refresh_token=${g_params.g_refresh_token}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_g_adssets = (g_params, token) => {
    return http.get(
        removeBreaks(`/data/g_adssets?customer_id=${g_params.g_customer_id}
        &login_customer_id=${g_params.g_login_customer_id}
        &from_date=${g_params.from_date}
        &to_date=${g_params.to_date}
        &refresh_token=${g_params.g_refresh_token}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_g_ads = (g_params, token) => {
    return http.get(
        removeBreaks(`/data/g_ads?customer_id=${g_params.g_customer_id}
        &login_customer_id=${g_params.g_login_customer_id}
        &from_date=${g_params.from_date}
        &to_date=${g_params.to_date}
        &refresh_token=${g_params.g_refresh_token}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_g_accounts = (
    g_customer_id,
    g_login_customer_id,
    g_refresh_token,
    token
) => {
    return http.get(
        removeBreaks(`/data/g_accounts?customer_id=${g_customer_id}
        &login_customer_id=${g_login_customer_id}
        &refresh_token=${g_refresh_token}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_g_child_accounts = (
    g_customer_id,
    g_login_customer_id,
    g_refresh_token,
    token
) => {
    return http.get(
        `/data/g_child_accounts?customer_id=${g_customer_id}&login_customer_id=${g_login_customer_id}&refresh_token=${g_refresh_token}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
export const g_health = (g_params, token) => {
    return http.get(
        removeBreaks(`/data/g_health?customer_id=${g_params.g_customer_id}
        &login_customer_id=${g_params.g_login_customer_id}
        &refresh_token=${g_params.g_refresh_token}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
// FACEBOOK

export const get_f_campaigns = (f_params, token) => {
    return http.get(
        removeBreaks(`/data/f_campaigns/?id=${f_params.f_id}
        &account=${f_params.f_account}
        &from_date=${f_params.from_date}
        &to_date=${f_params.to_date}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_f_adsSets = (f_params, token) => {
    return http.get(
        removeBreaks(`/data/f_adssets/?id=${f_params.f_id}
        &account=${f_params.f_account}
        &from_date=${f_params.from_date}
        &to_date=${f_params.to_date}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export const get_f_ads = (f_params, token) => {
    return http.get(
        removeBreaks(`/data/f_ads/?id=${f_params.f_id}
        &from_date=${f_params.from_date}
        &to_date=${f_params.to_date}
        &account=${f_params.f_account}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};
export const f_health = (f_params, token) => {
    return http.get(
        removeBreaks(`/data/f_health/?id=${f_params.f_id}
        &account=${f_params.f_account}`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

const AddService = {
    get_g_campaigns,
    get_g_adssets,
    get_g_ads,
    get_g_accounts,
    get_g_child_accounts,
    get_f_campaigns,
    get_f_adsSets,
    get_f_ads,
    f_health,
    g_health,
};

export default AddService;
