import React from 'react';
import styles from './select.module.scss';

import ReactSelect, { components } from 'react-select';
import purple_tick from '../../../media/icons/purple_tick.svg';
import gray_tick from '../../../media/icons/gray_tick.svg';
import { PURPLE } from '../../../const';

const Menu = (props) => {
    return (
        <>
            <components.Menu {...props}>
                <div>
                    {props.children}
                    {props.buttonText && (
                        <button
                            onClick={props.buttonOnClick}
                            className={styles.optionButton}
                        >
                            {props.buttonText}
                        </button>
                    )}
                </div>
            </components.Menu>
        </>
    );
};

const Option = (props, label, name) => {
    return (
        <components.Option className={styles.optionWrapper} {...props}>
            <div className={styles.optionContainer}>
                {
                    label === 'Color' && <div className={styles.circle} style={{backgroundColor: props?.data?.value}}/>
                }
                {
                    name === 'priority' &&  <img className={styles.icon} src={props?.data?.icon} alt='' />
                }
                <div
                    style={{color: props?.data?.color ? `${props.data.color}` : ''}}
                    className={styles.option}
                >
                    {props.children}
                </div>
                {props.isSelected && props._renderTick()}
            </div>
        </components.Option>
    );
};

const SingleValue = (props, label, name) => {
    return (
        <components.SingleValue {...props}>
            <div className={styles.selectedOptionContainer}>
                {
                    label === 'Color' && <div className={styles.circle} style={{backgroundColor: props?.data?.value}}/>
                }
                {
                    name === 'priority' &&  <img src={props?.data?.icon} alt='' />
                }
                <div style={{color: props?.data?.color ? `${props.data.color}` : ''}}>
                    {props.children}
                </div>
            </div>
        </components.SingleValue>
    );
};

export default function Select({
                                   label,
                                   className,
                                   onChange,
                                   onBlur,
                                   buttonText,
                                   buttonOnClick,
                                   options,
                                   name,
                                   tickColor,
                                   hasIndicatorBackground = false,
                                   defaultValue,
                                   value,
                                   isMulti,
                                   error,
                                   touched,
                               }) {
    const style = {
        control: (base) => ({
            ...base,
            border: touched && Boolean(error) && "1px solid red !important",
            boxShadow: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "#373D59" : "#373D59",
            background: state.isSelected ? "white" : "white",
        }),
        /*menu: (base) => ({
            ...base,
            left: 0,
        }),*/
    };

    const _renderTick = () => {
        switch (tickColor) {
            case PURPLE:
                return <img src={purple_tick} alt=""/>;
            default:
                return <img src={gray_tick} alt=""/>;
        }
    };

    const stop = (e) => e.stopPropagation();

    return (
        <>
            <div
                onClick={stop}
                onTouchStart={stop}
                onPointerDown={stop}
                onPointerEnter={stop}
                onPointerMove={stop}
                onTouchMove={stop}
                onBlur={stop}
                className={`${styles.container} enableSwiper ${className}`}
            >
                {label && (
                    <label className={touched && Boolean(error) && styles.error}>
                        {label}
                    </label>
                )}
                {hasIndicatorBackground && (
                    <div className={styles.indicatorBackground}></div>
                )}
                <div className={styles.selectWrapper}>
                    <ReactSelect
                        className={`${styles.select}`}
                        classNamePrefix='b'
                        onChange={onChange}
                        name={name}
                        value={value}
                        defaultValue={defaultValue}
                        options={options}
                        onBlur={onBlur}
                        isMulti={isMulti}
                        isSearchable={false}
                        components={{
                            Menu: (props) => Menu({...props, buttonText, buttonOnClick}),
                            Option: (props) => Option({...props, _renderTick}, label, name),
                            SingleValue: (props) => SingleValue({...props}, label, name),
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => (
                                <svg width='16' height='10'
                                     viewBox='0 0 16 10' fill='none'
                                     xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M15 1.5L8 8.5L1 1.5' stroke='#130F26'
                                        strokeWidth='1.5' strokeLinecap='round'
                                        strokeLinejoin='round'/>
                                </svg>
                            ),
                        }}
                        styles={style}
                        showNewOptionAtTop={false}
                    />
                </div>
            </div>
            {(touched && error) && <span className={styles.validationHelper}>{error}</span>}
        </>
    );
}
