import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from 'axios';
import styles from './index.module.scss';
import IconFacebook from '../../../media/icons/loginIcon/facebook.svg';
//import IconTiktok from '../../../media/icons/loginIcon/tiktok.svg';
import IconGoogle from '../../../media/icons/loginIcon/google.svg';
import { useDispatch } from 'react-redux';
import { loginWithSocial } from '../../../redux/slices/authentication';

function SocialLogins() {
    const dispatch = useDispatch();
    const googleLogin = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/adwords',
        onSuccess: async ({ code }) => {
            const tokens = await axios.post(
                process.env.REACT_APP_BACK_END + '/users/sso',
                {
                    googleCode: code,
                }
            );
            // JWT TOKEN
            const jwtToken = tokens.data.jwtToken;

            dispatch(loginWithSocial({ token: jwtToken, service: 'google' }));
            // dispatch(login({ token: jwtToken }));

            // fetching userinfo can be done on the client or the server
            // const userInfo = await axios
            //     .get(
            //         "https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=2013-03-01T00:00:00-04:00&timeMax=2023-03-28T23:59:59-04:00",
            //         {
            //             headers: {Authorization: `Bearer ${access_token}`},
            //         }
            //     )
            //     .then((res) => res.data);
        },
        flow: 'auth-code',
    });

    const facebookLogin = async (response) => {
        const tokens = await axios.post(
            process.env.REACT_APP_BACK_END + '/users/sso',
            {
                access_token: response.accessToken,
            }
        );
        dispatch(
            loginWithSocial({
                token: tokens.data.jwtToken,
                service: 'facebook',
            })
        );
    };

    return (
        <>
            <div className={styles.loginWithContainer}>
                <button
                    onClick={() => googleLogin()}
                    className={styles.loginWith}
                >
                    <img src={IconGoogle} alt="" title="Log in with Google" />
                </button>
                {/* <a href="./#" className={styles.loginWith}>
                <img src={IconFacebook} alt="" title="Log in with Facebook" />
                </a> */}
                <FacebookLogin
                    appId={process.env.REACT_APP_FB_APP_ID}
                    fields="id,name,email,picture"
                    callback={facebookLogin}
                    scope="public_profile,email,ads_read,ads_management"
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            className={styles.loginWith}
                        >
                            <img
                                src={IconFacebook}
                                alt="Facebook"
                                title="Log in with Facebook"
                            />
                        </button>
                    )}
                    //  redirectUri={process.env.REACT_APP_BACK_END + '/users/facebook_login'}
                />
                {/* <button className={styles.loginWith}>
          <img src={IconTiktok} alt="" title="Log in with Tiktok" />
        </button> */}
            </div>
        </>
    );
}

export default SocialLogins;
