import http from "../http-google";
import jwt from "jwt-decode";
import apiHttp from "../http-common";

const post = async (data, gToken) => {
    return http.post('primary/events', data, {
        headers: {
            Authorization: `Bearer ${gToken}`
        }
    })
}

const put = async (id, data, gToken) => {
    if (id === undefined) return null
    return http.put(`primary/events/${id}`, data, {
        headers: {
            Authorization: `Bearer ${gToken}`
        }
    })
}

const get = async (minDate, maxDate, gToken) => {
    return http.get(`primary/events/?timeMin=${minDate}&timeMax=${maxDate}&singleEvents=True`, {
        headers: {
            Authorization: `Bearer ${gToken}`,
        }
    })
}

const getById = async (id, gToken) => {
    if (id === undefined) return null
    return http.get(`primary/events/${id}`, {
        headers: {
            Authorization: `Bearer ${gToken}`
        }
    })
}

const deleteById = async (id, gToken) => {
    if (id === undefined) return null

    return http.delete(`primary/events/${id}`, {
        headers: {
            Authorization: `Bearer ${gToken}`
        }
    })
}

const isTokenExpired = (token) => {
    const decoded = jwt(token);
    return Date.now() < decoded.exp * 1000;
}

const getNewToken = async (refreshToken) => {
    if(!refreshToken) return null

    return await apiHttp.post('/users/google_refresh', {
        refresh_token: refreshToken
    })
}

const GoogleCalendarService = {
    post,
    get,
    deleteById,
    getById,
    put,
    isTokenExpired,
    getNewToken
};

export default GoogleCalendarService;
