import http from "../http-common";

const get = (discussionId, token) => {
    return http.get(`/forum/discussion/${discussionId}/replies`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`forum/reply/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post(`forum/reply`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (id, data, token) => {
    return http.put(`forum/reply/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`forum/reply/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const postFile = (id, data, token) => {
    return http.post(`forum/reply/${id}/files`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'multipart/form-data',
        }
    })
}

const deleteFile = (id, token) => {
    return http.delete(`forum/reply/files/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

const ReplyService = {
    get,
    post,
    deleteById,
    put,
    getById,
    postFile,
    deleteFile
};

export default ReplyService;
