import styles from "./preloader.module.scss";
import loader from "../../media/icons/loader.svg";
import { useState, useEffect} from "react";

const Preloader = ( { isActive, borderRadius } ) => { // isActive - boolean, true - show preloader, false - hide preloader, borderRadius - boolean, for border-radius for preloader

    useEffect(() =>
    {
        // setTimeout(() =>
        // {
        //     !isActive ? setDeleteNode(true) : setDeleteNode(false)
        // }, 3000)

    }, [isActive]);

    return (
        <div className={`${styles.preloader} ${borderRadius && styles.borderRadius} ${isActive ? styles.show : styles.hide }`}>
            <img className={styles} src={ loader } alt="arrow"/>
        </div>
    )
}

Preloader.props = {
    borderRadius: false,
}

export default Preloader
