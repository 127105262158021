import * as Yup from "yup";

const loginValidation = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email format!")
        .min(3, "Email is too short - should be at least 3 chars!")
        .max(255, "Email is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    password: Yup.string()
        .min(3, "Password is too short - should be at least 3 chars!")
        .max(255, "Password is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    rememberMe: Yup.boolean().required("Required").typeError("Required!"),
});

export default loginValidation;
