import http from "../http-common";

const get = (projectId, token) => {
    return http.get(`project/${projectId}/tasks`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getAllForUser = (token) => {
    return http.get(`project/tasks`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`project/task/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (projectId, data, token) => {
    return http.post(`/project/${projectId}/task`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (id, data, token) => {
    return http.put(`/project/task/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const updateTaskColor = (id, data, token) => {
    return http.put(`/project/task/color/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const updateTaskColors = (id, data, token) => {
    return http.put(`/project/task/colors/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

const deleteById = (id, token) => {
    return http.delete(`/project/task/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

const TaskService = {
    get,
    getById,
    post,
    put,
    updateTaskColor,
    updateTaskColors,
    getAllForUser,
    deleteById
};

export default TaskService;
