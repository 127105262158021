import {useEffect} from "react";

const DEFAULT_TITLE ="Brandflow"
export const useTitle = title => {
   useEffect(() => {
        if ( typeof document === 'undefined') return;
        if (document.title !== title) document.title =title+ " | "+ DEFAULT_TITLE;
        return () => {
            document.title = DEFAULT_TITLE;
        };
    }, [title]);
};