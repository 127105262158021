import React, { useEffect, useRef, useState } from 'react';
import styles from './campaigns.module.scss';

import cn from 'classnames';
import Preloader from '../preloader/preloader';
import EmptyState from './emptyState/emptyState';
import Button from '../global/button/button';
import Calendar from './calendar/calendar';
import useSwitchTab from '../../hooks/useSwitchTab';
import CircleProgressBar from './circleProgressBar/circleProgressBar';
import { toast } from 'react-toastify';
import { useTitle } from '../../hooks/useTitle';
import { useDispatch, useSelector } from 'react-redux';
import { linkGoogle, linkFacebook } from '../../services/UserService';
import { Toggle } from 'react-toggle-component';
import moment from 'moment';
import CampaignsTable from './campaignsTable/campaignsTable';
import AddService from '../../services/AddService';
import { useGoogleLogin } from '@react-oauth/google';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../redux/slices/authentication';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FACEBOOK, GOOGLE } from '../../const';

import updateIcon from '../../media/icons/update.svg';

const Tab = ({ name, currentTab, isActive, handleTabClick }) => {
    const onHandleTabClick = () => {
        handleTabClick(currentTab);
    };

    return (
        <div
            className={cn(styles.tabs__tab, {
                [styles.active]: isActive,
            })}
            onClick={onHandleTabClick}
        >
            {name}
        </div>
    );
};

const Campaigns = () => {
    useTitle('Campaigns');
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(
        moment().clone().subtract(10, 'days')
    );
    const [endDate, setEndDate] = useState(moment());

    const [isOverlay, setOverlay] = useState(false); // overlay for calendar
    const [isPreloader, setPreloader] = useState(true); // preloader for update data
    const [selectedCompany, setSelectedCompany] = useState({
        Facebook: false,
        Google: false,
    }); // Google, Facebook

    const { currentTab, handleTabClick } = useSwitchTab(0); // 0 - campaigns, 1 - adsets, 2 - ads

    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.Authentication);
    const [g_account, f_account] = [
        currentUser.google?.adsAccount,
        currentUser.facebook?.adsAccount,
    ];

    // filter ids for campaigns, adsets, ads
    const [filterIds, setFilterIds] = useState({
        campaign: [],
        adset: [],
    });

    const [data, setData] = useState({
        // data for campaigns, adsets, ads
        Facebook: {
            0: [], // campaigns
            1: [], // adsets
            2: [], // ads
        },
        Google: {
            0: [], // campaigns
            1: [], // adsets
            2: [], // ads
        },
    });

    const [dataTemp, setDataTemp] = useState(data); // temp data for filtering

    const filteringById = () => {
        if (filterIds.campaign.length && !filterIds.adset.length) {
            // filter by campaign
            setDataTemp({
                ...data,
                Facebook: {
                    ...data['Facebook'], // campaigns
                    1: data['Facebook'][1].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // adsets
                    2: data['Facebook'][2].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // ads
                },
                Google: {
                    ...data['Google'], // campaigns
                    1: data['Google'][1].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // adsets
                    2: data['Google'][2].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // ads
                },
            });
        }
        if (!filterIds.campaign.length && filterIds.adset.length) {
            // filter by adset
            setDataTemp({
                ...data,
                Facebook: {
                    ...data['Facebook'], // campaigns, adsets
                    2: data['Facebook'][2].filter(
                        (item) => filterIds.adset.includes(item.addset_id)
                    ), // ads
                },
                Google: {
                    ...data['Google'], // campaigns, adsets
                    2: data['Google'][2].filter(
                        (item) => filterIds.adset.includes(item.addset_id)
                    ), // ads
                },
            });
        }
        if (filterIds.campaign.length && filterIds.adset.length) {
            // filter by campaign and adset
            setDataTemp({
                ...data,
                Facebook: {
                    ...data['Facebook'], // campaigns
                    1: data['Facebook'][1].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // adsets
                    2: data['Facebook'][2].filter(
                        (item) => filterIds.adset.includes(item.addset_id)
                    ), // ads
                },
                Google: {
                    ...data['Google'], // campaigns
                    1: data['Google'][1].filter(
                        (item) => filterIds.campaign.includes(item.campaign_id)
                    ), // adsets
                    2: data['Google'][2].filter(
                        (item) => filterIds.adset.includes(item.addset_id)
                    ), // ads
                },
            });
        }
        if (!filterIds.campaign.length && !filterIds.adset.length) { // reset because filterIds is empty
            setDataTemp(data);
        }
    };

    const parentRef = useRef(null);

    const g_params = {
        g_customer_id: currentUser.google?.adsAccount,
        g_login_customer_id: currentUser.google?.adsAccount,
        from_date: startDate.format('YYYY-MM-DD'),
        to_date: endDate.format('YYYY-MM-DD'),
        g_refresh_token: currentUser.google?.refreshToken,
    };

    const f_params = {
        f_id: currentUser.id,
        f_account: currentUser.facebook?.adsAccount,
        from_date: startDate.format('YYYY-MM-DD'),
        to_date: endDate.format('YYYY-MM-DD'),
    };

    const { mutate: linkFacebookMutation } = useMutation(
        (data) => linkFacebook(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );
    const handleFacebookResponse = async (response) => {
        linkFacebookMutation({ accessToken: response.accessToken });
    };
    const { mutate: linkGoogleMutation } = useMutation(
        (data) => linkGoogle(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );

    const linkGoogleAccount = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/calendar.events',
        onSuccess: async ({ code }) => {
            linkGoogleMutation({ googleCode: code }, currentUser.token);
        },
        flow: 'auth-code',
    });

    const { isLoading, refetch } = useQuery({
        queryFn: () => {
            setPreloader(true);
            return {
                Facebook: {
                    campaigns:
                        f_account &&
                        AddService.get_f_campaigns(f_params, currentUser.token),
                    adset:
                        f_account &&
                        AddService.get_f_adsSets(f_params, currentUser.token),
                    ads:
                        f_account &&
                        AddService.get_f_ads(f_params, currentUser.token),
                },
                Google: {
                    campaigns:
                        g_account &&
                        AddService.get_g_campaigns(g_params, currentUser.token),
                    adset:
                        g_account &&
                        AddService.get_g_adssets(g_params, currentUser.token),
                    ads:
                        g_account &&
                        AddService.get_g_ads(g_params, currentUser.token),
                },
            };
        },
        onSuccess: async (res) => {
            // Facebook
            const f_campaigns = f_account
                ? (await res.Facebook.campaigns).data
                : [];
            const f_adsSets = f_account ? (await res.Facebook.adset).data : [];
            const f_ads = f_account ? (await res.Facebook.ads).data : [];

            // Google
            const g_campaigns = g_account
                ? (await res.Google.campaigns).data
                : [];
            const g_adsSets = g_account ? (await res.Google.adset).data : [];
            const g_ads = g_account ? (await res.Google.ads).data : [];

            const model = {
                // model for data
                Facebook: {
                    0: f_campaigns,
                    1: f_adsSets,
                    2: f_ads,
                },
                Google: {
                    0: g_campaigns,
                    1: g_adsSets,
                    2: g_ads,
                },
            };
            setData(model);
            setDataTemp(model);
            setPreloader(false);

            setFilterIds({ // reset filter ids
                campaign: [],
                adset: [],
            })
        },
        onError: (err) => {
            toast.error(err.message);
        },
        refetchOnWindowFocus: false,
        // staleTime: Infinity
    });

    const onChangeCompany = (e) => {
        e.preventDefault();
        const name = e.target.control.name;

        setSelectedCompany((prev) => {
            // set selected company
            return {
                ...prev,
                [name]: !prev[name],
            };
        });
    };

    const getExistedData = () => {
        // get existed data from temp data for render

        if (selectedCompany.Facebook && selectedCompany.Google) {
            return dataTemp['Facebook'][currentTab].concat(
                dataTemp['Google'][currentTab]
            );
        }
        if (selectedCompany.Facebook) {
            return dataTemp['Facebook'][currentTab];
        }
        if (selectedCompany.Google) {
            return dataTemp['Google'][currentTab];
        }

        return [];
    };

    const onHandleUpdate = () => {
        refetch();
    };

    const circlesDataMap = {
        // mock data for CircleProgressBar
        Facebook: {
            from: 0,
            to: 68,
            duration: 2000,
        },
        Google: {
            from: 0,
            to: 82,
            duration: 2000,
        },
    };

    useEffect(() => {
        filteringById();
    }, [filterIds]);

    useEffect(() => {
        refetch();
    }, [startDate, endDate, g_account, f_account]);

    useEffect(() => {
        setSelectedCompany({
            Facebook: f_account,
            Google: g_account,
        });
    }, [g_account, f_account]);

    return (
        <>
            <div
                className={cn(styles.container, {
                    [styles.overlay]: isOverlay,
                })}
                ref={parentRef}
            >
                <div className={styles.top}>
                    <h2>Campaigns</h2>
                    <div className={styles.toggles}>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                className={styles.toggle}
                                checked={
                                    selectedCompany['Facebook'] && f_account
                                }
                                disabled={!f_account}
                                controlled={true}
                                knobColor="white"
                                name={`Facebook`}
                                borderColor="transparent"
                                leftBackgroundColor="#E2E8F0"
                                rightBackgroundColor="#8C6CED"
                                onClick={onChangeCompany}
                            />
                            <p className={styles.toggle__name}>Facebook</p>
                            {!currentUser.facebook?.adsAccount && (
                                <div className={styles.toggleContainer__hint}>
                                    <p>
                                        You need to add Ads Account Id in{' '}
                                        {
                                            <Button
                                                onClick={() =>
                                                    navigate('/profile')
                                                }
                                                text={'Configuration'}
                                                className={
                                                    styles.toggleContainer__hint__link
                                                }
                                                type={FACEBOOK}
                                            />
                                        }{' '}
                                    </p>
                                </div>
                            )}
                            {!currentUser.facebook?.accessToken && (
                                <div className={styles.toggleContainer__hint}>
                                    <p>
                                        You need to sign in with{' '}
                                        {
                                            <FacebookLogin
                                                appId={
                                                    process.env
                                                        .REACT_APP_FB_APP_ID
                                                }
                                                fields="id,name,email,picture"
                                                scope="public_profile,email,ads_read,ads_management"
                                                callback={
                                                    handleFacebookResponse
                                                }
                                                render={(renderProps) => (
                                                    <Button
                                                        onClick={
                                                            renderProps.onClick
                                                        }
                                                        className={
                                                            styles.toggleContainer__hint__link
                                                        }
                                                        type={FACEBOOK}
                                                        text={'facebook'}
                                                    />
                                                )}
                                            />
                                        }{' '}
                                        if you want to see more information
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                className={styles.toggle}
                                checked={selectedCompany['Google'] && g_account}
                                disabled={!g_account}
                                controlled={true}
                                knobColor="white"
                                name={`Google`}
                                borderColor="transparent"
                                leftBackgroundColor="#E2E8F0"
                                rightBackgroundColor="#8C6CED"
                                onClick={
                                    currentUser.google?.accessToken &&
                                    onChangeCompany
                                }
                            />
                            <p className={styles.toggle__name}>Google</p>
                            {!currentUser.google?.adsAccount && (
                                <div className={styles.toggleContainer__hint}>
                                    <p>
                                        You need to add Ads Account Id in{' '}
                                        {
                                            <Button
                                                onClick={() =>
                                                    navigate('/profile')
                                                }
                                                type={GOOGLE}
                                                text={'configuration'}
                                                className={
                                                    styles.toggleContainer__hint__link
                                                }
                                            />
                                        }{' '}
                                        if you want to see more information
                                    </p>
                                </div>
                            )}
                            {!currentUser.google?.accessToken && (
                                <div className={styles.toggleContainer__hint}>
                                    <p>
                                        You need to sign in with{' '}
                                        {
                                            <Button
                                                onClick={linkGoogleAccount}
                                                type={GOOGLE}
                                                text={'google'}
                                                className={
                                                    styles.toggleContainer__hint__link
                                                }
                                            />
                                        }{' '}
                                        if you want to see more information
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <Calendar
                        setOverlay={setOverlay}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                </div>
                <div className={styles.campaignsContainer}>
                    <div
                        className={styles.updateButton}
                        onClick={onHandleUpdate}
                    >
                        <img src={updateIcon} alt="update" />
                    </div>
                    <div className={styles.circles}>
                        <CircleProgressBar
                            from={circlesDataMap['Google'].from}
                            to={circlesDataMap['Google'].to}
                            duration={circlesDataMap['Google'].duration}
                            title="Total revenue"
                            value={'$8,460.00'}
                            percent={16}
                            color="#895BF1"
                        />
                        <CircleProgressBar
                            from={circlesDataMap['Google'].from}
                            to={circlesDataMap['Google'].to}
                            duration={circlesDataMap['Google'].duration}
                            title="Amount spent"
                            value={'$8,460.00'}
                            percent={16}
                            color="#FFCF70"
                        />
                        <CircleProgressBar
                            from={circlesDataMap['Google'].from}
                            to={circlesDataMap['Google'].to}
                            duration={circlesDataMap['Google'].duration}
                            title="Profit"
                            value={'$8,460.00'}
                            percent={16}
                            color="#4FD1C5"
                        />
                    </div>
                    <div className={styles.tabs}>
                        {' '}
                        {/* Render tabs */}
                        <Tab
                            name={'Campaings'}
                            currentTab={0}
                            isActive={currentTab === 0}
                            handleTabClick={handleTabClick}
                        />
                        <Tab
                            name={'Adset'}
                            currentTab={1}
                            isActive={currentTab === 1}
                            handleTabClick={handleTabClick}
                        />
                        <Tab
                            name={'Ads'}
                            currentTab={2}
                            isActive={currentTab === 2}
                            handleTabClick={handleTabClick}
                        />
                    </div>
                    <div className={styles.campaignsContainer__overflow}>
                        <Preloader isActive={isPreloader} />
                        {getExistedData() && getExistedData().length ? (
                            <CampaignsTable
                                data={getExistedData()}
                                startDate={startDate}
                                endDate={endDate}
                                filterIds={filterIds}
                                setFilterIds={setFilterIds}
                            />
                        ) : (
                            <EmptyState />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(Campaigns);
