import React from "react";
import styles from "../project/project.module.scss";
import nothingHereYet from "../../media/icons/nothing_here.svg";
import { useNavigate } from "react-router-dom";




const NothingHereYet = () => {
    let navigate = useNavigate();

    const handleCreateProjectClick = () => {
        navigate("/projects/create");
    };

    return (
        <div className={`border d-flex ai-end ${styles.nothingHereBorder}`}>
            <div
                className={`backCard jc-center ai-center d-flex wrap ${styles.container}`}
            >
                <div className={styles.nothingHere}>
                    <img src={nothingHereYet} alt=""/>
                    <div className={styles.nothingHereDescription}>
                        <h3>Nothing here yet</h3>
                        <span className={styles.helperText}>
                Let's begin the journey. Start by
              </span>
                        {/*eslint-disable-next-line*/}
                        <a
                            onClick={handleCreateProjectClick}
                            className={styles.helperLink}
                        >
                            adding new project +
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NothingHereYet;
