import * as Yup from "yup";

const categoryPostValidation = Yup.object().shape({
    name: Yup.string()
        .min(3, "Name is too short - shoul be at least 3 chars!")
        .max(20, "Name is too long - should not exceed 20 chars!")
        .required("Required!")
        .typeError("Required!"),
});

export default categoryPostValidation;
