import React, {useState} from 'react';
import ActionButtonsGroup from '../../actionButtonsGroup/actionButtonsGroup';
import Plus from '../../../../media/icons/plus.png';
import Resize from '../../../../media/icons/resize.svg';
import styles from './calendarWidget.module.scss';
import miniCalendar from '../miniCalendar/miniCalendar.module.scss';
import MiniCalendar from '../miniCalendar/miniCalendar';
import SelectBadge from '../../../global/selectBadge/selectBadge';
import {
    DATE_ONLY,
    generateYears,
    GLOSSY,
    GOOGLE,
    MONTH,
    TRANSPARENT,
} from '../../../../const';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import {EVENTS} from '../../../../rquery/queryKeys';
import GoogleCalendarService from '../../../../services/GoogleCalendarService';
import Modal from '../../../global/modal/modal';
import close from '../../../../media/icons/close_icon.svg';
import {COLORS} from '../../../../mock';
import tick from '../../../../media/icons/tick_white.svg';
import CustomDatePicker from '../../../global/customDatePicker/customDatePicker';
import Button from '../../../global/button/button';
import {useFormik} from 'formik';
import calendarPostValidation from '../../../../validations/calendar/calendarPostValidation';
import {useCallback, useLayoutEffect, useEffect} from 'react';
import moment from 'moment';
import {useGoogleLoginWrapper} from '../../../../hooks/useGoogleLoginWrapper';
import TimePicker from "../timePicker/timePicker";
import {toast} from "react-toastify";

import {
    updateToken,
} from "../../../../redux/slices/authentication";

export default function CalendarWidget() {
    const dispatch = useDispatch();

    let today = new Date();
    const googleLogin = useGoogleLoginWrapper();

    const { token, google } = useSelector(
        (state) => state?.Authentication
    );

    const [isModalOpen, setModalOpen] = useState(false);
    const [isTablet, setIsTablet] = useState();

    const [calendarDate, setCalendarDate] = useState({
        month: today.getMonth(),
        year: today.getFullYear(),
    });

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {mutate} = useMutation(
        (data) => GoogleCalendarService.post(data, google.accessToken),
        {
            onSuccess: () => {
                toast.success('Event created successfully');
                queryClient.invalidateQueries([EVENTS]);
                handleCloseModal();
            },
            onError: (error) => {
                toast.error('Something went wrong');
            }
        }
    );

    const handleResize = () => {
        let width = window.innerWidth;

        if (width >= 768) {
            setIsTablet(false);
        } else {
            setIsTablet(true);
        }
    };

    const {data, refetch} = useQuery(
        [EVENTS, google.accessToken, calendarDate.month, calendarDate.year],
        () => {
           return GoogleCalendarService.get(
                new Date(calendarDate.year, calendarDate.month).toISOString(),
                new Date(calendarDate.year, calendarDate.month + 1).toISOString(),
               google.accessToken
            );
        },
        {
            onError: () => {
             if (GoogleCalendarService.isTokenExpired(token)) {
                    GoogleCalendarService.getNewToken(google.refreshToken).then((res) => {
                        dispatch(updateToken(res.data));
                        refetch();
                    });
                }
            },
        },
        {
            enabled: !!google.accessToken,
        }
    );

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            summary: '',
            description: '',
            startDate: null,
            endDate: null,
            startTime: '',
            endTime: '',
            colorId: null,
        },
        validationSchema: calendarPostValidation,
        onSubmit: (values) => {
            const {description, summary, startDate, endDate, colorId} = values;
            const data = {
                end: {
                    dateTime: endDate,
                },
                start: {
                    dateTime: startDate,
                },
                description,
                summary,
                colorId: colorId,
            };
            mutate(data);
        },
    }, []);

    const handleDateChange = (date) => {
        let _startTime = moment(formik.values.startTime, 'hh:mm A', true).isValid()
            ? moment(formik.values.startTime, 'hh:mm A', true)
            : null;
        let _endTime = moment(formik.values.endTime, 'hh:mm A', true).isValid()
            ? moment(formik.values.endTime, 'hh:mm A', true)
            : null;

        let _startDate =
            _startTime && date
                ? moment(date)
                    .set({
                        hour: _startTime.get('hours'),
                        minute: _startTime.get('minutes'),
                    })
                    .toDate()
                : date;

        let _endDate =
            _endTime && date
                ? moment(date)
                    .set({
                        hour: _endTime.get('hours'),
                        minute: _endTime.get('minutes'),
                    })
                    .toDate()
                : null;

        formik.setFieldValue('startDate', _startDate);
        formik.setFieldValue('endDate', _endDate);
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        handleDateChange(formik.values.startDate);
        // eslint-disable-next-line
    }, [formik.values.startTime, formik.values.endTime]);

    const CalendarHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.up}>
                    <h4 className="boxTitle">Calendar</h4>
                    <ActionButtonsGroup
                        buttons={[
                            {
                                icon: Plus,
                                cb: () => {
                                    setModalOpen(true);
                                },
                            },
                            {
                                icon: Resize,
                                cb: () => {
                                    navigate('calendar');
                                },
                            },
                        ]}
                    />
                </div>
                <div className={styles.down}>
                    <SelectBadge
                        className={`${styles.selectBadgeMonth}`}
                        options={MONTH}
                        onChange={(v) =>
                            setCalendarDate((prev) => ({
                                ...prev,
                                month: v.value,
                            }))
                        }
                        defaultValue={MONTH[calendarDate.month]}
                    />
                    <SelectBadge
                        className={`${styles.selectBadgeYear}`}
                        options={generateYears()}
                        onChange={(v) =>
                            setCalendarDate((prev) => ({...prev, year: v.value}))
                        }
                        defaultValue={generateYears().find(
                            (y) => y.value === calendarDate.year
                        )}
                    />
                </div>
            </div>
        )
    }

    const _renderEventModal = () => {
        return (
            <Modal onClose={handleCloseModal} open={isModalOpen}>
                <div className={miniCalendar.modalWrapper}>
                    <div className={miniCalendar.modalTitleContainer}>
                        <p className={miniCalendar.modalTitle}>Add Event</p>
                        <button onClick={handleCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={miniCalendar.modalDivider}/>
                    <div className={miniCalendar.modalBodyContainer}>
                        <div className={miniCalendar.colorsContainer}>
                            <span>Color</span>
                            <div className={`formControls d-flex col ${miniCalendar.formControls}`}>
                                <div className={miniCalendar.colors}>
                                    {COLORS.map((c, i) => {
                                        return (
                                            <div
                                                onClick={() => formik.setFieldValue('colorId', c.id)}
                                                key={i}
                                                className={miniCalendar.sphere}
                                                style={{backgroundColor: c.color}}
                                            >
                                                {c.id === formik.values.colorId && (
                                                    <img src={tick} alt=""/>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.summary &&
                                    Boolean(formik.errors.summary) &&
                                    'error'
                                } ${miniCalendar.formControls}`}
                            >
                                <label>Title</label>
                                <input
                                    name="summary"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.summary}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                <span className={miniCalendar.validationHelper}>
                  {formik.touched.summary && formik.errors.summary}
                </span>
                            </div>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.description &&
                                    Boolean(formik.errors.description) &&
                                    'error'
                                } ${miniCalendar.formControls}`}
                            >
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.description}
                                    onBlur={formik.handleBlur}
                                ></textarea>
                                <span className={miniCalendar.validationHelper}>
                  {formik.touched.description && formik.errors.description}
                </span>
                            </div>
                            <div
                                className={`d-flex ${miniCalendar.formControls} ${miniCalendar.datePick}`}
                            >
                                <div className={miniCalendar.day}>
                                    <label>Day</label>
                                    <CustomDatePicker
                                        onBlur={formik.handleBlur}
                                        handleSelectDate={handleDateChange}
                                        className={miniCalendar.datePicker}
                                        date={formik.values.startDate}
                                        type={DATE_ONLY}
                                    />
                                    {formik.touched.startDate &&
                                        !isTablet &&
                                        Boolean(formik.errors.startDate) && (
                                            <span className={`${miniCalendar.validationHelper}`}>
                        {formik.errors.startDate}
                      </span>
                                        )}
                                </div>
                                {formik.touched.startDate &&
                                    isTablet &&
                                    Boolean(formik.errors.startDate) && (
                                        <span
                                            className={`${miniCalendar.validationHelper}  ${miniCalendar.mobile}`}
                                        >
                      {formik.errors.startDate}
                    </span>
                                    )}
                                <div
                                    className={`formControls d-flex col
                                     ${miniCalendar.formControls} ${miniCalendar.durationWrapper}`}
                                >
                                    <label>Duration</label>
                                    <div className={miniCalendar.horizontalDates}>
                                        <TimePicker time={formik.values.startTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'startTime'}/>
                                        <div className={miniCalendar.divider}/>
                                        <TimePicker time={formik.values.endTime}
                                                    setFieldValue={formik.setFieldValue}
                                                    onBlur={formik.handleBlur}
                                                    name={'endTime'}/>
                                    </div>
                                    <span className={miniCalendar.validationHelper}>
                    {formik.touched.startTime &&
                        formik.touched.endTime &&
                        (formik.errors.startTime ?? formik.errors.endTime)}
                  </span>
                                </div>
                            </div>
                        </div>
                        <div className={miniCalendar.rightButtonGroup}>
                            <Button
                                onClick={handleCloseModal}
                                text={'Cancel'}
                                type={TRANSPARENT}
                                className={styles.btnCancel}
                            />
                            <Button
                                text={'Create'}
                                onClick={() => formik.handleSubmit()}
                                type={GLOSSY}
                                className={styles.btnCreate}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderSocial = () => {
        return (
            <div className={styles.socialContainer}>
                <div className={styles.social}>
                    <p>You need to sign in if you want to see more information</p>
                    <Button
                        onClick={googleLogin}
                        icon={google}
                        type={GOOGLE}
                        text={'Sign in with Google'}
                    />
                </div>
                <div>
                    <CalendarHeader/>
                    <MiniCalendar
                        events={data?.data.items}
                        month={calendarDate.month}
                        year={calendarDate.year}
                    />
                </div>
            </div>
        );
    };


    return (
        <div className={`box d-flex col ${styles.container}`}>
            {/*<Preloader isActive={!data?.data.items && gToken} borderRadius/>*/}
            {google?.accessToken ? (
                <>
                    {_renderEventModal()}
                    <CalendarHeader/>
                    <MiniCalendar
                        events={data?.data.items}
                        month={calendarDate.month}
                        year={calendarDate.year}
                    />
                </>
            ) : (
                _renderSocial()
            )}
        </div>
    );
}
