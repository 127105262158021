import * as Yup from "yup";

const createPasswordValidation = Yup.object().shape({
    password: Yup.string()
        .min(3, "Password is too short - should be at least 3 chars!")
        .max(255, "Password is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    confirmPassword: Yup.string()
        .required("Required!")
        .typeError("Required!")
        .oneOf([Yup.ref("password"), null], "Passwords must match!"),

    userId: Yup.string()
        .required("Required!")
        .typeError("Required!"),

    token: Yup.string()
        .required("Required!")
        .typeError("Required!")
});

export default createPasswordValidation;
