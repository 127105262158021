import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./customDatePicker.module.scss";
import calendar from "../../../media/icons/calendar.svg";
import purple_calendar from "../../../media/icons/purple_calendar.svg";
import {DATE_ONLY, EMPTY, FULL} from "../../../const";

export default function CustomDatePicker({
                                             date,
                                             handleSelectDate,
                                             onBlur,
                                             error,
                                             touched,
                                             label,
                                             className,
                                             name,
                                             type = FULL,
                                         }) {
    const dateRef = useRef();
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const _renderDatePicker = () => {
        switch (type) {
            case FULL:
                return (
                    <>
                        <div className={`${styles.container} ${className}`}>
                            {label && (
                                <label
                                    className={(touched && Boolean(error)) ? styles.errorLabel : ''}
                                >
                                    {label}
                                </label>
                            )}
                            <div
                                onClick={() => dateRef.current.input.click()}
                                className={`${styles.datePickerWrapper} ${
                                    touched && Boolean(error) ? styles.error : ''
                                }`}
                            >
                                <DatePicker
                                    onBlur={onBlur}
                                    name={name}
                                    ref={dateRef}
                                    className={styles.datePicker}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="__/__/___"
                                    selected={date}
                                    onChange={handleSelectDate}
                                    minDate={new Date()}
                                    todayButton={"Today"}
                                    onChangeRaw={handleDateChangeRaw}
                                />
                                <img
                                    onClick={() => dateRef.current.input.click()}
                                    src={calendar}
                                    alt=""
                                />
                            </div>
                        </div>
                        {touched && error && (
                            <span className={styles.validationHelper}>{error}</span>
                        )}
                    </>
                );
            case DATE_ONLY:
                return (
                    <div className={`${styles.container} ${className}`}>
                        {label && <label>{label}</label>}
                        <div
                            onClick={() => dateRef.current.input.click()}
                            className={styles.datePickerWrapper}
                        >
                            <DatePicker
                                ref={dateRef}
                                className={styles.datePicker}
                                dateFormat="dd MMMM"
                                selected={date}
                                onChange={handleSelectDate}
                                minDate={new Date()}
                                todayButton={"Today"}
                                onChangeRaw={handleDateChangeRaw}
                            />
                            <img
                                onClick={() => dateRef.current.input.click()}
                                src={purple_calendar}
                                alt=""
                            />
                            <div className={styles.rightBackground}/>
                        </div>
                        {touched && error && (
                            <span className={styles.validationHelper}>{error}</span>
                        )}
                    </div>
                );
            case EMPTY:
                return (
                    <DatePicker
                        ref={dateRef}
                        className={styles.datePicker}
                        dateFormat="dd/MM/yyyy"
                        selected={date}
                        placeholderText="mm-dd-yyyy"
                        onChange={handleSelectDate}
                        minDate={new Date('1922-01-01')}
                        maxDate={new Date(new Date('2006-01-01'))}
                        todayButton={"Today"}
                        onChangeRaw={handleDateChangeRaw}
                    />
                );

            default:
                break;
        }
    };

    return <>{_renderDatePicker()}</>;
}
