import React from 'react';
import styles from "./notFound.module.scss"
import not_found from "../../media/icons/not_found.svg"
import Button from "../global/button/button";
import {GLOSSY} from "../../const";
import {useNavigate} from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="border border-xs h-100 dashboard-border">
            <div className={`backCard h-100 d-flex wrap dashboardBlock ${styles.container}`}>
                <div className={styles.notFoundContainer}>
                    <img src={not_found} alt=""/>
                    <div className={styles.verbalContainer}>
                        <p>Oops! That page can't be found.</p>
                        <span>It looks like nothing was found at this location.</span>
                    </div>
                    <Button onClick={() => navigate(-1)} className={styles.btn} text={'GO BACK'} type={GLOSSY}/>
                </div>
            </div>
        </div>
    );
}

export default NotFound;