import React from 'react';
import {NavLink} from "react-router-dom";

function MenuLink(props) {
    return (
        <NavLink to={props.path} className="navlink">
            <span className="navlink__icon">
                <img className={'icon'} src={props.icon} alt={""}/>
                <img className={'icon_active'} src={props.activeIcon} alt={""}/>
            </span>
            <span className="text">
           {props.title}
           </span>

        </NavLink>

    );
}

MenuLink.defaultProps = {
    title: "title",
    path: "/404",
    icon: false
}

export default MenuLink;
