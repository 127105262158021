import { useSearchParams } from "react-router-dom";

export const NOTIFICATIONS =  [

    {
        name: "John Watson ",
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        icon:null,
        time: '8:08',
        counter:'3'
    }  ,  {
        name: "Alexa Olivera",
        message:"Lorem Ipsum is simply Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy  dummy text of the printing and typesetting industry.",
        icon:"/mock/user2.jpg",
        time: '00:01',
        counter:'1'
    } ,  {
        name: "  Oliver   Fio",
        message:" m Ipsum is simply dummy  dummy text of the printing and typesetting industry.",
        icon:"/mock/user5.png",
        time: '6:33',
        counter:'0'
    },  {
        name: "  Oliver   Fio",
        message:" m Ipsum is simply dummy  dummy text of the printing and typesetting industry.",
        icon:null,
        time: '12:43',
        counter:'0'
    }

]
for (let i =0 ; i<100 ; i++){
    NOTIFICATIONS.push(NOTIFICATIONS[Math.ceil(Math.random()*4)])
}

export const useNotificationMock= ()=>{
    const [searchParams]= useSearchParams({notifications: 65});
    const slice = searchParams.get("notifications");
    return  NOTIFICATIONS.slice(0, slice);
}