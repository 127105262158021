import styles from './emptyState.module.scss';

const EmptyState = () => {
    return (
        <div className={styles.emptyState}>
            No data found
        </div>
    )
}

export default EmptyState;
