import http from "../http-common";

const getAll = (page, limit) => {
    return http.get(`/leaderBoard?page=${page}&limit=${limit}`);
};

const get = (id) => {
    return http.get(`/leaderBoard/${id}`);
};

const deleteRange = (ids) => {
    //todo:  refactor this
    return http.delete("/leaderBoard", {
        data: {
            ids: ids,
        },
    });
};

const getAllCount = () => {
    return http.get("/leaderBoard/rows");
};

const put = (data, id) => {
    return http.put(`/leaderBoard/${id}`, data);
};

const post = (data) => {
    return http.post('/leaderBoard', data)
}

const LeaderBoardService = {
    getAll,
    get,
    deleteRange,
    getAllCount,
    put,
    post
};

export default LeaderBoardService;
