import React from "react";
import {
    GLOSSY,
    TRANSPARENT,
    BACK,
    PURPLE,
    TAGS,
} from "../../../const";
import Button from "../../global/button/button";
import Select from "../../global/select/select";
import styles from "./createProject.module.scss";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import back from "../../../media/icons/arrow_back.svg";
import CustomDatePicker from "../../global/customDatePicker/customDatePicker";
import {optionsColor} from "../../../mock";
import {STATUSES} from "../../../const";
import {ROUTES} from "../../../routes/mainRoutes";
import {useTitle} from "../../../hooks/useTitle";
import ProjectService from "../../../services/ProjectService";
import {useMutation, useQueryClient, useQuery} from "react-query";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import projectPostValidation from "../../../validations/projects/projectPostValidation";
import {PROJECT, PROJECTS} from "../../../rquery/queryKeys";
import TagService from "../../../services/TagService";
import TagSelect from "../../global/select/tagSelect";
import ColorSelectModal from "../colorSelectModal/colorSelectModal";
import CategorySelect from "../../global/select/categorySelect";

export default function CreateProject() {
    useTitle("New Project");
    let navigate = useNavigate();
   
    const { token } = useSelector((state) => state.Authentication);
    const [optionsColors, setOptionsColors] = useState(optionsColor);
    const [categories, setCategories] = useState([]);

    const [isClrModalOpen, setIsClrModalOpen] = useState(false);
    const [blockPickerColor, setBlockPickerColor] = useState('#8C6CED');

    const handleGoBack = () => {
        navigate(ROUTES.projects);
    };

    const queryClient = useQueryClient();

    const {mutate} = useMutation((data) => ProjectService.post(data, token), {
        onSuccess: (data) => {
            queryClient.invalidateQueries([PROJECTS]);
            queryClient.invalidateQueries([PROJECT]);
            navigate("/projects");
        },
    });

    const {mutate: mutateTag} = useMutation(
        (id) => TagService.deleteById(id, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const handleTagDelete = (id) => {
        mutateTag(id)
        formik.setFieldValue("tag", formik.values.tag.filter(t => t !== id))
    }

    const handeCategoryDelete = (value) => {
        let _categories = categories.filter(c => c.value !== value)
        setCategories(_categories)
        if (formik.values.category === value) {
            formik.setFieldValue("category", "")
        }
    }

    const {mutate: mutatePostTag} = useMutation(
        (data) => TagService.post(data, token),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const handleCreateNewClick = (name, callBack) => {
        mutatePostTag({name: name});
        callBack();
    };

    const handleCreateCategoryClick = (value) => {
        formik.setFieldValue("category", value);
        setCategories([...categories, {label: value, value: value}]);
    }

    const {data} =
        useQuery([TAGS, token], () => TagService.get(token));

    var formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: null,
            description: null,
            dueDate: null,
            category: "",
            status: "",
            color: "#8C6CED", // default color
            tag: [],
        },
        validationSchema: projectPostValidation,
        onSubmit: (values) => {
            console.log(values)
            mutate(values);
        },
    });

    return (
        <>
            <ColorSelectModal
                isClrModalOpen={isClrModalOpen}
                setIsClrModalOpen={setIsClrModalOpen}
                optionsColors={optionsColors}
                setOptionsColors={setOptionsColors}
                blockPickerColor={blockPickerColor}
                setBlockPickerColor={setBlockPickerColor}
                formik={formik}
            />
            <div className={styles.top}>
                <div className={styles.title}>
                    <Button
                        type={BACK}
                        icon={back}
                        onClick={handleGoBack}
                        className={styles.backBtn}
                    />
                    <h2>New Project</h2>
                </div>
            </div>

            <div className="border">
                <div className={`backCard d-flex wrap ${styles.container}`}>
                    <div className="whiteBg">
                        <div className={styles.formContainer}>
                            <div className={styles.left}>
                                <span className={styles.miniTitle}>BASIC INFO</span>
                                <div
                                    className={`formControls d-flex col ${
                                        formik.touched.name &&
                                        Boolean(formik.errors.name) &&
                                        "error"
                                    } ${styles.formControls}`}
                                >
                                    <label>Title</label>
                                    <input
                                        name="name"
                                        onChange={formik.handleChange}
                                        defaultValue={formik.initialValues.name}
                                        onBlur={formik.handleBlur}
                                        type="text"
                                    />
                                    <span className={styles.validationHelper}>
                    {formik.touched.name && formik.errors.name}
                  </span>
                                </div>
                                <div
                                    className={`formControls d-flex col ${
                                        formik.touched.description &&
                                        Boolean(formik.errors.description) &&
                                        "error"
                                    }`}
                                >
                                    <label>Description</label>
                                    <textarea
                                        name="description"
                                        onChange={formik.handleChange}
                                        defaultValue={formik.initialValues.description}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                    <span className={styles.validationHelper}>
                    {formik.touched.description && formik.errors.description}
                  </span>
                                    <span className={styles.helperInfo}>
                    You will be able to edit this info later
                  </span>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <div className={styles.rightForm}>
                                    <div
                                        className={`formControls d-flex col ${styles.smallForm} ${
                                            formik.touched.category &&
                                            Boolean(formik.errors.category) &&
                                            "error"
                                        } ${styles.formControls}`}
                                    >
                                        <div className={styles.inputWrapper}>
                                            <CategorySelect
                                                options={categories}
                                                handleCreateNewClick={handleCreateCategoryClick}
                                                label={"Category"}
                                                className={styles.select}
                                                handleDeleteClick={handeCategoryDelete}
                                                name="category"
                                                onBlur={formik.handleBlur}
                                                onChange={(checked, value) => {
                                                    formik.setFieldValue(
                                                        "category",
                                                        checked ? value : ""
                                                    );
                                                }}
                                                value={formik.values.category}
                                            />
                                        </div>
                                        {formik.touched.category &&
                                            Boolean(formik.errors.category) && (
                                                <span className={styles.validationHelper}>
                          {formik.errors.category}
                        </span>
                                            )}
                                    </div>
                                    <Select
                                        onBlur={formik.handleBlur}
                                        onChange={(o) => {
                                            formik.setFieldValue("color", o.value);
                                            setBlockPickerColor(o.value);
                                        }}
                                        value={{
                                            label: optionsColor.find((o) => o.value === blockPickerColor)?.label || blockPickerColor,
                                            value: blockPickerColor,
                                        }}
                                        options={optionsColors}
                                        tickColor={PURPLE}
                                        label={"Color"}
                                        className={styles.select}
                                        buttonText="+ Add custom color"
                                        buttonOnClick={() => setIsClrModalOpen(true)}
                                    />
                                    <TagSelect
                                        options={data?.data.map((d) => {
                                            return {label: d.name, value: d._id};
                                        })}
                                        handleCreateNewClick={handleCreateNewClick}
                                        label={"Tags"}
                                        className={styles.select}
                                        handleDeleteClick={handleTagDelete}
                                        name="tag"
                                        onBlur={formik.handleBlur}
                                        onChange={(checked, id) => {
                                            if (checked) {
                                                formik.setFieldValue("tag", [...formik.values.tag, id]);
                                            } else {
                                                formik.setFieldValue("tag", [
                                                    ...formik.values.tag.filter((t) => t !== id),
                                                ]);
                                            }
                                        }}
                                        value={formik.values.tag}
                                    />
                                    <Select
                                        onBlur={formik.handleBlur}
                                        onChange={(o) => formik.setFieldValue("status", o.value)}
                                        options={STATUSES}
                                        name={"status"}
                                        label={"Status"}
                                        className={styles.select}
                                        touched={formik.touched.status}
                                        error={formik.errors.status}
                                    />
                                    <CustomDatePicker
                                        label={"Due date"}
                                        className={`${styles.date}`}
                                        date={formik.values.dueDate}
                                        touched={formik.touched.dueDate}
                                        error={formik.errors.dueDate}
                                        name="dueDate"
                                        onBlur={formik.handleBlur}
                                        handleSelectDate={(d) => formik.setFieldValue("dueDate", d)}
                                    />
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.rightButtonGroup}>
                                    <Button
                                        onClick={handleGoBack}
                                        text={"Cancel"}
                                        type={TRANSPARENT}
                                        className={styles.btnCancel}
                                    />
                                    <Button
                                        onClick={formik.handleSubmit}
                                        text={"Create"}
                                        type={GLOSSY}
                                        className={styles.btnCreate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
