import * as Yup from "yup";

const notePostValidation = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name is too short - shoul be at least 3 chars!")
    .max(255, "Name is too long - should not exceed 255 chars!")
    .required("Required!")
    .typeError("Required!"),

  description: Yup.string()
    .min(3, "Description is too short - shoul be at least 3 chars!")
    .max(500, "Description is too long - should not exceed 255 chars!")
    .required("Required!")
    .typeError("Required!"),
});

export default notePostValidation;
