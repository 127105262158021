import http from "../http-common";

const getAll = (token) => {
    return http.get("/timezone/values", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getMyAll = (id, token) => {
    return http.get(`/timezone/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post("/timezone", data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteRange = (data, token) => {
    return http.delete("/timezone", {
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const TimeZoneService = {
    getAll,
    getMyAll,
    post,
    deleteRange
};

export default TimeZoneService;
