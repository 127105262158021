import React, {useLayoutEffect, useState} from "react";
import styles from "./project.module.scss";
import Button from "../global/button/button";
import {GLOSSY, PLUS} from "../../const";

import ProjectCard from "./projectCard/projectCard";
import "swiper/css/bundle";

import SwiperCore, {Controller, Navigation, Pagination, Thumbs} from "swiper";
import {useTitle} from "../../hooks/useTitle";
import {PROJECTS} from "../../rquery/queryKeys";
import ProjectService from "../../services/ProjectService";
import {useSelector} from "react-redux";
import {GridContextProvider, GridDropZone, GridItem, swap,} from "react-grid-dnd";
import NothingHereYet from "../nothingHereYet/nothingHereYet";
import Preloader from "../preloader/preloader";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {useQuery, useMutation} from "react-query";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

const Project = () => {
    useTitle("Projects");

    const {token} = useSelector((state) => state.Authentication);

    const [items, setItems] = useState([]);

    const {isLoading} = useQuery([PROJECTS, token], () => {
        return ProjectService.get(token)
    }, {
        onSuccess: (data) => {
            const _items = data.data.sort((a, b) => a.order - b.order);
            setItems(_items);
        },
    });

    const {mutate: updateProjects} = useMutation(
        (data) => {
            return ProjectService.updateProjects({projects: data}, token)
        },
    );

    let navigate = useNavigate();
    const [isMobile, setMobile] = useState();
    const [isProjectsMobile, setIsProjectsMobile] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const isEmpty = items?.length === 0;

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 576) {
            setMobile(false);
        } else {
            setMobile(true);
        }

        if (width >= 992) {
            setIsProjectsMobile(false);
        } else {
            setIsProjectsMobile(true);
        }
    };


    const handleCreateProjectClick = () => {
        navigate("create");
    };

    const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
        setIsDragging(true)
        setTimeout(() => setIsDragging(false), 0)
        const nextState = swap(items, sourceIndex, targetIndex);
        nextState.forEach((p, i) => {
            p.order = i
        });

        setItems(nextState.sort((a, b) => a.order - b.order));
        updateProjects(nextState)
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    const _renderProjects = () => {
        window.addEventListener("resize", function () {
            handleResize();
        });

        const rowHeight = 347;

        return (
            <GridDropZone
                id="projects"
                boxesPerRow={isProjectsMobile ? 2 : 3}
                rowHeight={rowHeight}
                className={`sliderProjects ${styles.projectsContainer}`}
                style={
                    isProjectsMobile
                        ? {height: `${Math.ceil(items.length / 2) * rowHeight}px`}
                        : {height: `${Math.ceil(items.length / 3) * rowHeight}px`}
                }
            >
                {isMobile ? (
                    <Swiper
                        centeredSlides={true}
                        noSwipingClass={"enableSwiper"}
                        pagination
                        spaceBetween={10}
                        slidesPerView={1}
                    >
                        {!isLoading &&
                            items.map((p, i) => {
                                return (
                                    <SwiperSlide className={styles.swiperSlide} key={i}>
                                        <ProjectCard
                                            className={styles.mobileCard}
                                            isMobile={isMobile}
                                            isDragging={isDragging}
                                            key={i}
                                            id={p._id}
                                            title={p.name}
                                            description={p.description}
                                            dueDate={p.dueDate}
                                            status={p.status}
                                            tags={p.tag}
                                            color={p.color}
                                            category={p.category}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                ) : (
                    !isLoading &&
                    items.map((p, i) => {
                        return (
                            <GridItem
                                className={styles.card}
                                key={p._id}
                                style={{padding: "10px"}}
                            >
                                <ProjectCard
                                    isDragging={isDragging}
                                    key={i}
                                    id={p._id}
                                    title={p.name}
                                    description={p.description}
                                    dueDate={p.dueDate}
                                    status={p.status}
                                    tags={p.tag}
                                    color={p.color}
                                    category={p.category}
                                />
                            </GridItem>
                        );
                    })
                )}
            </GridDropZone>
        );
    };

    return (
        <GridContextProvider onChange={onChange}>
            <div className={styles.title}>
                <h2>Projects</h2>
                <Button
                    text={"+ New Project"}
                    type={GLOSSY}
                    onClick={handleCreateProjectClick}
                    className="d-none d-sm-block"
                />
            </div>
            <Preloader isActive={isLoading}/>
            {isEmpty && !isLoading ? <NothingHereYet/> : _renderProjects()}
            <Button
                type={PLUS}
                onClick={handleCreateProjectClick}
                className="d-sm-none as-center"
            />
        </GridContextProvider>
    );
};

export default Project;
