import * as Yup from "yup";

const discussionPostValidation = Yup.object().shape({
    title: Yup.string()
        .min(3, "Name is too short - shoul be at least 3 chars!")
        .max(255, "Name is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),
    category: Yup.string().required("Required!").typeError("Required!"),
    user: Yup.string().required("Required!").typeError("Required!"),
});

export default discussionPostValidation;
