import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MessageService from '../../services/MessageService';

const initialState = {
  textAreaDisabled: true,
  message: "Welcome to Brandflow!🎉\nLet's get started.",
};
export const updateMessage = createAsyncThunk(
  'message/update',
  async ({ id, data }) => {
    const res = await MessageService.update(id, data);
    return res.data;
  }
);

export const retriveMessage = createAsyncThunk(
  'message/retrieve',
  async (id) => {
    const res = await MessageService.get(id);
    return res.data;
  }
);

// Welcome Message state slice
const welcomeMessageSlice = createSlice({
  name: 'welcomeMessage',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [retriveMessage.fulfilled]: (state, action) => {
      return action.payload;
    },
    [updateMessage.fulfilled]: (state, action) => {
      return action.payload.data;
    },
  },
});

export const { toggleTextArea, setMessage } = welcomeMessageSlice.actions;

export default welcomeMessageSlice.reducer;
