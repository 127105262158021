import React, {useMemo} from "react";
import styles from "./taskContainer.module.scss";
import plus_violet from "../../../../media/icons/plus_violet.svg";
import TaskCard from "../taskCard/taskCard";
import { Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

export default function TaskContainer({ id, title, cards, isDnd = true, refetch }) {
  const navigate = useNavigate();

  const handlePlusClick = () => {
    navigate("create");
  };


  return (
    <div className={styles.taskBorder}>
      <div className={styles.taskContainer}>
        <div className={styles.title}>
          <p>{title}</p>
          {/* <img src={more_dots} alt="" /> */}
        </div>
        <div className={styles.body}>
          <div onClick={handlePlusClick} className={styles.newCard}>
            <img src={plus_violet} alt="" />
          </div>
          {isDnd ? (
            <Droppable droppableId={id}>
              {(provided) => (
                <div
                  className={styles.droppable}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {cards?.map((c, i) => {
                    return (
                      <TaskCard
                        isDnd={isDnd}
                        key={i}
                        index={i}
                        id={c._id}
                        title={c.name}
                        description={c.description}
                        tags={c.tag}
                        dueDate={c.dueDate}
                        category={c.category}
                        color={c.color}
                        priority={c.priority}
                        isFavorite={c.isFavorite}
                        refetch={refetch}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <div className={styles.droppable}>
              {cards?.map((c, i) => {
                return (
                  <TaskCard
                    key={i}
                    index={i}
                    id={c._id}
                    title={c.name}
                    description={c.description}
                    isDnd={isDnd}
                    tags={c.tag}
                    dueDate={c.dueDate}
                    category={c.category}
                    color={c.color}
                    priority={c.priority}
                    isFavorite={c.isFavorite}
                    refetch={refetch}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
