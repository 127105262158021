import React from "react";
import {GLOSSY, SOLID} from "../../../const";
import Button from "../../global/button/button";
import styles from "./note.module.scss";
import plus from "../../../media/icons/plus_violet.svg";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTitle} from "../../../hooks/useTitle";
import deleteIcon from "../../../media/icons/delete_note.svg";
import editIcon from "../../../media/icons/pen_note.svg";
import {useState} from "react";
import {useLayoutEffect} from "react";
import {NOTE, NOTES} from "../../../rquery/queryKeys";
import {useSelector} from "react-redux";
import NoteService from "../../../services/NoteService";
import {useQuery, useMutation, useQueryClient} from "react-query";
import close from "../../../media/icons/close_icon.svg";
import warning from "../../../media/icons/red_warning.svg";
import delete_icon from "../../../media/icons/delete_black.svg";
import Modal from "../../global/modal/modal";

export default function Note() {
    useTitle("Note");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({isEmpty: false});
    const isEmpty = searchParams.get("isEmpty") === "true";
    const [isMobile, setMobile] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteableId, setDeleteableId] = useState();

    const {token} = useSelector((state) => state.Authentication);
    const {id} = useParams();

    const handlePlusClick = () => {
        navigate("create");
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setDeleteableId(null);
    };

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 900) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    const queryClient = useQueryClient();

    const {data} =
        useQuery([NOTES, id, token], () => NoteService.get(id, token));

    const {mutate} = useMutation((id) => NoteService.deleteById(id, token), {
        onSuccess: (data) => {
            queryClient.invalidateQueries([NOTES]);
            queryClient.invalidateQueries([NOTE]);
            setIsModalOpen(false);
        },
    });

    const handleDeleteClick = (id) => {
        mutate(id);
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseModal}
                open={isModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={handleCloseModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>Are you sure that you want to delete this Note?</b>
                        </p>
                        <span>
                            This action cannot be undone.
                        </span>
                        <div className={styles.btnContainer}>
                            <Button
                                onClick={handleCloseModal}
                                className={`${styles.noBtnModal}`}
                                text={"Cancel"}
                                type={SOLID}
                            />
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                type={SOLID}
                                onClick={() => handleDeleteClick(deleteableId)}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderWhenEmpty = () => {
        return (
            <div className={styles.containerEmpty}>
                <Button
                    className={styles.centralButton}
                    text={"Add new note"}
                    onClick={handlePlusClick}
                    type={GLOSSY}
                />
            </div>
        );
    };

    const _renderNote = () => {
        return data?.data.map((p, i) => {
            return (
                <>
                    <div key={i} onClick={() => {
                        navigate(p._id);
                    }} className={styles.note}>
                        <div className={styles.noteWrapp}>
                            <p
                                className={styles.title}

                            >
                                {p.name}
                            </p>
                            <p className={styles.content}>
                                {p.description.replace(/<\/?[^>]+(>|$)/g, "")}
                            </p>
                        </div>
                        <div className={styles.btnGroup}>
                            <Button
                                icon={editIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`edit/${p._id}`)
                                }}
                            />
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsModalOpen(true);
                                    setDeleteableId(p._id);
                                }}
                                icon={deleteIcon}
                            />
                        </div>
                    </div>
                </>
            );
        });
    };

    const _renderNotes = () => {
        window.addEventListener("resize", function () {
            handleResize();
        });
        return (
            <div className={styles.container}>
                {_renderDeleteModal()}
                {_renderNote()}
                {isMobile ? (
                    <Button
                        onClick={handlePlusClick}
                        type={GLOSSY}
                        text="Add new note"
                        className={styles.noteBtnMobile}
                    />
                ) : (
                    <Button
                        onClick={handlePlusClick}
                        className={`${styles.note} ${styles.new}`}
                        icon={plus}
                    />
                )}
            </div>
        );
    };

    return <>{isEmpty ? _renderWhenEmpty() : _renderNotes()}</>;
}
