import http from "../http-common";

const get = (projectId, token) => {
    return http.get(`/project/${projectId}/notes`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getById = (id, token) => {
    return http.get(`/project/note/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (projectId, data, token) => {
    return http.post(`/project/${projectId}/note`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (id, data, token) => {
    return http.put(`/project/note/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`/project/note/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const NoteService = {
    get,
    getById,
    post,
    put,
    deleteById
};

export default NoteService;
