import React from "react";
import ReactSelect from "react-select";
import styles from "./select.module.scss";
export const ItemsPerPage = ({ value, changeHandler, options }) => {
  const test=()=>{
    setTimeout(()=>{
        debugger
    },300)
  
  };

  return (
    <>
      <ReactSelect
        classNamePrefix="s"
        className={styles.select + " select__container "}
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={false}
        value={value}
        onChange={changeHandler}
        options={options}
        onMenuOpen={test}
      />
    </>
  );
};
