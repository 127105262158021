import React from 'react';
import styles from './checkEmail.module.scss';
import logo from '../../../media/Mint_Media_Logo.webp';
import Button from '../../global/button/button';
import { GLOSSY } from '../../../const';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useTitle } from '../../../hooks/useTitle';
import { useFormik } from 'formik';
import checkEmailValidation from '../../../validations/authentication/checkEmailValidation';
import { useSelector } from 'react-redux';
import AuthenticationService from '../../../services/AuthenticationService';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CheckEmail() {
    const { toResetUserId } = useSelector((state) => state.Authentication);

    const navigate = useNavigate();

    const { mutate } = useMutation(
        (data) => AuthenticationService.confirmVerificationCode(data),
        {
            onSuccess: (data) => {
                if (!data?.data.valid) return;
                navigate('../create-password');
            },
            onError: (data) => {
                toast.error(data?.response?.data?.message);
                localStorage.removeItem('verificationCode');
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: null,
            userId: toResetUserId,
        },
        validationSchema: checkEmailValidation,
        onSubmit: (values) => {
            localStorage.setItem('verificationCode', values.code);
            mutate(values);
        },
    });

    useTitle('CheckEmail');
    return (
        <div className={styles.container}>
            <div className={styles.backLinkBlock}></div>
            <div className={styles.checkWrapper}>
                <div className={styles.logo}>
                    <img alt={''} src={logo} />
                </div>
                <div className={styles.checkTitle}>
                    <h1>Check your email</h1>
                </div>
                <div className={styles.checkSubtitle}>
                    We have sent a recover code to your email
                </div>
                <form className="w-100" onSubmit={formik.handleSubmit}>
                    <div className={'verification-input'}>
                        <ReactInputVerificationCode
                            length={6}
                            placeholder=""
                            onChange={(e) => formik.setFieldValue('code', e)}
                        />
                    </div>
                    <div className={styles.btnGroup}>
                        <Button
                            type={GLOSSY}
                            behaviour={'submit'}
                            text="Send"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
