import styles from './configurationSection.module.scss';
import React from 'react';
import cn from 'classnames';

const ButtonSave = ( { text, callback, isDisabled } ) => {
    return (
        <button className={cn(styles.button, {
            [styles.disabled]: isDisabled
        })}
                onClick={ callback }>
            { text }
        </button>
    )
}

const ConfigurationSection = ({ children, callback, ...props } ) => {

    const onButtonClick = () => {
        typeof callback === 'function' && callback(props.companyName);
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.top__company}>
                    <img src={props.companyLogo} alt='logo'/>
                    <div className={styles.top__company__name}>
                        <h3>{props.companyName}</h3>
                    </div>
                </div>
                <ButtonSave
                    text="Save"
                    callback={ onButtonClick }
                    isDisabled={ props.isButtonDisabled } />
            </div>
            <div className={styles.bottom}>
                {children}
                <ButtonSave
                    text="Save"
                    callback={ onButtonClick }
                    isDisabled={ props.isButtonDisabled } />
            </div>
        </div>
    );
}

export default ConfigurationSection;
