import {useState} from "react";
import styles from "../authenticationLayout.module.scss";
import Logo from "../../../media/Mint_Media_Logo.webp";
import eye from "../../../media/icons/eye.svg";
import eye_close from "../../../media/icons/eye_close.svg";
import {GLOSSY} from "../../../const";
import Button from "../../global/button/button";
import stylesLogin from "./login.module.scss";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../../../hooks/useTitle";
import {useOpen} from "../../../hooks/useOpen";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import loginValidation from "../../../validations/authentication/loginValidation";
import {login} from "../../../redux/slices/authentication";
import SocialLogins from "../socialLogins";
import authService from "../../../services/AuthenticationService";
import {toast} from "react-toastify";
import {useMutation} from "react-query";

export default function Login() {
    useTitle("Login");

    const [showConfirmLink, setShowConfirmLink] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const loginCheck = async (email, password) => {
        const response = await fetch("https://brandflow.herokuapp.com/api/users/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                email,
                password,
            }),
        });

        return await response.json();
    }

    const {mutate} = useMutation(
        (values) => authService.isEmailConfirmed(values),
        {
            onSuccess: (data) => {
                dispatch(login(formik.values));
            },
            onError: (error) => {
                setShowConfirmLink(true);
            },
        }
    );

    const {mutate: sendLink} = useMutation(
        (values) => authService.sendConfirmEmailRequest(values),
        {
            onSuccess: (data) => {
                toast.success(data.data.message);
            },
            onError: (error) => {
                toast.error(error.response.data.message);
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: null,
            password: null,
            rememberMe: false,
        },
        validationSchema: loginValidation,
        onSubmit: async (values) => {

            const userAuth = await loginCheck(values.email, values.password);
            if (userAuth.token) { // if user is authenticated
                mutate(values);
            } else {
                toast.error("Invalid Credentials");
            }

        },
    });

    const {isOpen, onToggle} = useOpen();

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.logo}>
                <img alt={""} src={Logo}/>
            </div>
            <div className={styles.loginTitle}>
                <h1>
                    Sign in
                    <span className="d-md-none">or</span>
                    <button
                        onClick={() => navigate("../register", {replace: true})}
                        className={`d-md-none ${stylesLogin.linkBtn}`}
                    >
                        Sign up
                    </button>
                </h1>
                <span className={styles.description}>
                  Log in to your account and dive into the work!
                </span>
            </div>

            <SocialLogins />

            <div className={styles.separatorOR}></div>

            <div className="w-100">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formGroup">
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.email &&
                                Boolean(formik.errors.email) &&
                                "error"
                            }`}
                        >
                            <label>Email</label>
                            <input
                                name="email"
                                className={styles.input}
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.email}
                                onBlur={formik.handleBlur}
                                type="email"
                                placeholder=""
                            />
                            <span className={stylesLogin.validationHelper}>
                                {formik.touched.email && formik.errors.email}
                            </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.password &&
                                Boolean(formik.errors.password) &&
                                "error"
                            }`}
                        >
                            <label>Password</label>
                            <div className={styles.inputBox}>
                                <input
                                    name="password"
                                    className={styles.input}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.password}
                                    onBlur={formik.handleBlur}
                                    type={isOpen ? "text" : "password"}
                                    placeholder=""
                                />
                                <div
                                    className={styles.btnEye}
                                    onClick={onToggle}
                                >
                                    <img
                                        src={isOpen ? eye : eye_close}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <span className={stylesLogin.validationHelper}>
                                {formik.touched.password &&
                                    formik.errors.password}
                            </span>
                        </div>
                    </div>
                    {showConfirmLink && (
                        <div className="formBottom d-flex jc-sb ai-center">
                            <a
                                href="/#"
                                style={{cursor: "pointer"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    sendLink(formik.values);
                                }}
                                className={styles.forgot}
                            >
                                Resend Confirm Email
                            </a>
                        </div>
                    )}
                    <div className="formBottom d-flex jc-sb ai-center">
                        <div className="customCheckbox">
                            <label>
                                <input
                                    name="rememberMe"
                                    onChange={formik.handleChange}
                                    type="checkbox"
                                />
                                <span></span>
                                Remember me
                            </label>
                        </div>
                        {/*eslint-disable-next-line*/}
                        <a
                            onClick={() => navigate("../reset-password")}
                            className={styles.forgot}
                        >
                            Forgot password?
                        </a>
                    </div>
                    <Button
                        className={stylesLogin.button}
                        text={"Log In"}
                        behaviour={"submit"}
                        type={GLOSSY}
                    />
                </form>
            </div>

            <div className={styles.dontHaveAcc}>
                Dont have an account? <a href="./register">Sign up</a>
            </div>
        </div>
    );
}
