import React from "react";
import styles from "./noteDetail.module.scss";
import { useTitle } from "../../../../hooks/useTitle";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NOTE, NOTES } from "../../../../rquery/queryKeys";
import NoteService from "../../../../services/NoteService";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function NoteDetail() {
  useTitle("NoteDetail");
  const { id, noteId } = useParams();
  const { token } = useSelector((state) => state.Authentication);
  const { deleteRef, editRef } = useOutletContext();
  const navigate = useNavigate();

  const { data } = useQuery([NOTE, id, noteId, token], () =>
    NoteService.getById(noteId, token)
  );

  const queryClient = useQueryClient();

  const { mutate } = useMutation((id) => NoteService.deleteById(id, token), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([NOTES]);
      queryClient.invalidateQueries([NOTE]);
      navigate(`/projects/${id}/notes`);
    },
  });

  useEffect(() => {
    editRef.current = () => navigate(`/projects/${id}/notes/edit/${noteId}`);
    deleteRef.current = () => mutate(noteId);
    // eslint-disable-next-line
  }, [editRef, deleteRef]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <p className={styles.title}>{data?.data.name}</p>
        <span>DESCRIPTION</span>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: data?.data.description }}
        ></p>
      </div>
    </div>
  );
}
