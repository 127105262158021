export let chartOptions = {
  series: [
    {
      name: "",
      data: [11, 32, 45, 32, 34, 52, 41, 32, 32, 12, 56, 67],
    },
  ],
  options: {
    grid: {
      show: true,
      borderColor: "#E2E8F0",
      strokeDashArray: 3,
      position: "back",
      padding: {
        right: 5,
        left: 0,
        top: 0,
        bottom: 0,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    chart: {
      height: 196,
      toolbar: {
        show: false,
      },
    },
    markers: {
      show: true,
      size: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "date",
      fontFamily: 'Noto Sans',
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '16px',
        fontFamily: 'Noto Sans',
        justifyContent: "center"
      },
      x: {
       show:false
      },
      y:{
        formatter: function(value, ){
          return `$${value}`
        },
      },
      marker: {
        show: false
      }
    },
    colors: ["#8C6CED"],
  },
};
