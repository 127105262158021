import React, {useCallback, useState} from "react";
import {GLOSSY} from "../../../const";
import ForumAnswerCard from "../forumAnswerCard/forumAnswerCard";
import ForumPostCard from "../forumPostCard/forumPostCard";
import styles from "./forumBodyOne.module.scss";
import chat from "../../../media/icons/forum_chat.svg";
import Button from "../../global/button/button";
import {DISCUSSIONS} from "../../../mock";
import Modal from "../../global/modal/modal";
import close from "../../../media/icons/close_icon.svg";
import red_close from "../../../media/icons/red_close_icon.svg";
import plus from "../../../media/icons/plus_violet.svg";
import art from "../../../media/mock/art.jpeg";
import TextEditor from "../../global/textEditor/textEditor";
import BreadCrumb from "../../global/breadCrumb/breadCrumb";
import Search from "../../global/search/search";
import useFileUpload from "../../../hooks/useFileUpload";
import file_icon from "../../../media/icons/file.svg";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {FORUM, REPLIES} from "../../../rquery/queryKeys";
import {useSelector} from "react-redux";
import ReplyService from "../../../services/ReplyService";
import ForumService from "../../../services/ForumService";
import {useParams} from "react-router-dom";
import moment from "moment";
import profile from "../../../media/icons/user.svg";
import ukraine from "../../../media/mock/ukraine.jpeg";
import sanitize from "sanitize-html";
import {useFormik} from "formik";

export default function ForumBodyOne({tab, categories}) {
    const post = DISCUSSIONS[0];
    const [isModalOpen, setModalOpen] = useState(false);
    const {
        files,
        inputRef,
        handleInputClick,
        handleInputChange,
    } = useFileUpload();

    const {
        token,
        id: userId,
    } = useSelector((state) => state.Authentication);

    const {id} = useParams();

    const {data: repliesData} = useQuery([REPLIES, token, tab, id], () =>
        ReplyService.get(id, token)
    );

    const {
        data: forumData,
    } = useQuery([FORUM, token, tab, id], () => ForumService.getById(id, token));
    const additionalBreadCrumbs = [
        {
            match: {
                isActive: false,
                pathname: tab,
                route: {
                    breadcrumb: `${
                        categories.find((c) => c.name.replaceAll(/\s/g, "") === tab)?.name
                    }`,
                },
            },
        },
        {
            match: {
                isActive: true,
                pathname: forumData?.data?.data.title ?? "",
                route: {breadcrumb: forumData?.data?.data.title},
            },
        },
    ];

    const queryClient = useQueryClient();

    const {mutate} = useMutation((data) => ReplyService.post(data, token), {
        onSuccess: (data) => {
            queryClient.invalidateQueries([REPLIES]);
            handleCloseModal();
        },
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: null,
            user: userId,
            discussion: id,
        },
        // validationSchema: projectPostValidation,
        onSubmit: (values) => {
            mutate(values);
        },
    });

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleEditorChange = (v) => {
        let _sanitizedText = sanitize(v);
        formik.setFieldValue("message", _sanitizedText);
    };
    const _renderAnswerModal = () => {
        return (
            <Modal
                locked={true}
                onClose={handleCloseModal}
                open={isModalOpen}
                className={styles.modalAnswer}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <div className={styles.left}>
                            <div className={styles.badge}>
                                {categories?.find((c) => c.isActive)?.name}
                            </div>
                            <p className={styles.modalTitle}>{forumData?.data.data.title}</p>
                        </div>
                        <button onClick={handleCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Message</label>
                            <TextEditor onChange={handleEditorChange}/>
                        </div>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Attachments</label>
                            <div className={styles.attachments}>
                                <div className={styles.imgContainer}>
                                    <img src={art} alt=""/>
                                    <div className={styles.description}>
                                        <span>file.png</span>
                                    </div>
                                    <button type="button" className={styles.deleteContainer}>
                                        <img src={red_close} alt=""/>
                                    </button>
                                </div>
                                {files.map((f, i) =>
                                    f.isLoaded ? (
                                        <div key={i} className={styles.imgContainer}>
                                            <img src={art} alt=""/>
                                            <div className={styles.description}>
                                                <span>file.png</span>
                                            </div>
                                            <button type="button" className={styles.deleteContainer}>
                                                <img src={red_close} alt=""/>
                                            </button>
                                        </div>
                                    ) : (
                                        <div key={i} className={styles.loadingContainer}>
                                            <div className={styles.info}>
                                                <div className={styles.fileInfo}>
                                                    <img src={file_icon} alt=""/>
                                                    <span>{f.name}</span>
                                                </div>
                                            </div>
                                            <div className={styles.empty}>
                                                <span>40%</span>
                                                <div
                                                    className={styles.loaded}
                                                    style={{width: "40%"}}
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                           {/* <div onClick={handleInputClick} className={styles.fileZone}> // Uncomment to enable file upload
                                <input
                                    multiple
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleInputChange}
                                    hidden
                                    type="file"
                                    ref={inputRef}
                                />
                                <img src={plus} alt=""/>
                            </div>*/}
                        </div>
                        <Button
                            onClick={formik.handleSubmit}
                            className={styles.saveBtn}
                            text={"Send"}
                            type={GLOSSY}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            <BreadCrumb
                blackList={[2, 3]}
                additionalBreadCrumbs={additionalBreadCrumbs}
                hasDefaultRoute={false}
                className={styles.breadCrumb}
            />

            <div className={styles.body}>
                {_renderAnswerModal()}
                <div className={styles.header}>
                    <ForumPostCard
                        id={forumData?.data.data._id}
                        categoryId={forumData?.data.data.category._id}
                        title={forumData?.data.data.title}
                        ago={moment().diff(forumData?.data.data.createdAt, "days")}
                        badge={categories?.find((c) => c.isActive)?.name}
                        by={forumData?.data.data.user.name}
                        replies={forumData?.data.data.replies.length}
                        byImg={forumData?.data.data.user.picture?.url || profile}
                        img={post.img}
                        // participants={post.participants}
                        // participantsImg={post.participantsImg}
                        className={styles.headerCard}
                    />
                </div>
                <div className={styles.top}>
                    <Search/>
                    <Button
                        className={styles.chatBtn}
                        type={GLOSSY}
                        icon={chat}
                        text={"New reply"}
                        onClick={() => setModalOpen(true)}
                    />
                </div>
                <div className={styles.asnwerContainer}>
                    {repliesData?.data.map((r, i) => {
                        return (
                            <ForumAnswerCard
                                key={i}
                                id={r._id}
                                comments={r.replies}
                                name={r.user.name}
                                email={r.user.email}
                                userImg={r.user.picture?.url || ""}
                                text={r.message}
                                time={moment(r.createdAt).format("HH:mm")}
                                images={[ukraine]}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}
