import * as Yup from "yup";

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const leaderBoardPutValidation = Yup.object().shape({
    name: Yup.string()
        .min(3, "Name is too short - shoul be at least 3 chars!")
        .max(255, "Name is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    total: Yup.number()
        .test(
            "is-decimal",
            "Number can have only 2 decimals after point!",
            (val) => {
                if (val !== undefined) {
                    return patternTwoDigisAfterComma.test(val);
                }
                return true;
            }
        )
        .min(0, "Total can't be less than 0!")
        .max(1000000000, "Total can't be more than 1000000000!")
        .required("Required!")
        .typeError("Required!"),

    roas: Yup.number()
        .test(
            "is-decimal",
            "Number can have only 2 decimals after point!",
            (val) => {
                if (val !== undefined) {
                    return patternTwoDigisAfterComma.test(val);
                }
                return true;
            }
        )
        .min(0, "ROAS can't be less than 0!")
        .max(1000000000, "ROAS can't be more than 1000000000!")
        .required("Required!")
        .typeError("Required!"),
});

export default leaderBoardPutValidation;
