import React from "react";
import "wicg-inert";
import styled from "styled-components";
import Portal from "./portal";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(21, 21, 21, 0.56);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px 12px;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    margin: auto;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`;

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0 20px;
  max-width: 740px;
  background-color: white;
  border-radius: 14px;
`;

export default function Modal(props) {
    const [active, setActive] = React.useState(false);
    const {open, onClose, locked} = props;
    const backdropRef = React.useRef(null);
    const contentRef = React.useRef(null);

    useOnClickOutside(contentRef, (e) =>
        !locked && onClose()
    );

    React.useEffect(() => {
        const {current} = backdropRef;

        const transitionEnd = () => setActive(open);

        const keyHandler = (e) =>
            !locked && [27].indexOf(e.which) >= 0 && onClose();

        if (current) {
            current.addEventListener("transitionend", transitionEnd);
            window.addEventListener("keyup", keyHandler);
        }

        if (open) {
            window.setTimeout(() => {
                setActive(open);
                document.querySelector("#root")
            }, 10);
        }

        return () => {
            if (current) {
                current.removeEventListener("transitionend", transitionEnd);
            }

            document.querySelector("#root").removeAttribute("inert");
            window.removeEventListener("keyup", keyHandler);
        };
    }, [open, locked, onClose]);

    return (
        <>
            {(open || active) && (
                <Portal className="modal-portal">
                    <Backdrop ref={backdropRef} className={active && open && "active"}>
                        <Content
                            ref={contentRef}
                            className={`modal-content ${props.className}`}
                        >
                            {props.children}
                        </Content>
                    </Backdrop>
                </Portal>
            )}
        </>
    );
}
