import React, { useEffect, useState, useRef, memo } from 'react';
import styles from './createFolderModal.module.scss';
import Modal from '../../global/modal/modal';

import cn from 'classnames';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { optionsColor } from '../../../mock';
import { SketchPicker } from 'react-color';

import plus from '../../../media/icons/plus_icon.svg';
import tick from '../../../media/icons/tick_white.svg';
import close from '../../../media/icons/close_icon.svg';
import arrow_icon from '../../../media/icons/arrow_icon.svg';

import Button from '../../global/button/button';
import FileTree from "../attachment/fileTree/fileTree";


const Select = ({ type, options, setOptions, label, placeholder, callback, _value }) => {
    const isMulti = options?.length > 1 || type === 'file'
    const [value, setValue] = useState(_value);
    const [isShowOptions, setIsShowOptions] = useState(false);

    const optionsRef = useRef(null);
    const _options = type === 'folder' ? options : options?.filter(option => option !== value);
    const changeTimer = useRef(null);

    useOnClickOutside(optionsRef, (e) => {
        if (e.target.classList.contains(styles.input__icon)) return
        setIsShowOptions(false)
    })

    const onChange = (value, id) => {
        setValue(value)
        if (callback) {
            type === 'file' ? callback(value) : callback(value, id)
        }
        if (isMulti && value) {
            if (changeTimer.current) {
                clearTimeout(changeTimer.current)
            }
            changeTimer.current = setTimeout(() => {
                setOptions((prev) => {
                    return [...prev.filter(option => option !== value), value]
                })
            }, 1500)
        }
    }

    const handleShowOptions = () => {
        setIsShowOptions(!isShowOptions)
    }

    useEffect(() => {
        setValue(_value)
    }, [_value])

    return (
        <div className={styles.input}>
            <span className={styles.input__label}>{label}</span>
            <div className={styles.input__container}>
                <input type='text'
                       value={value}
                       placeholder={placeholder || 'Select'}
                       className={styles.input__select}
                       onChange={(e) => {
                           onChange(e.target.value)
                       }}/>
                {
                    isMulti && <img className={cn(styles.input__icon, {
                        [styles.input__icon__open]: isShowOptions
                    })}
                                    src={arrow_icon}
                                    alt='arrow'
                                    onClick={handleShowOptions}
                    />
                }
            </div>
            {
                isMulti &&
                <div className={cn(styles.input__options, {
                    [styles.input__options__open]: isShowOptions
                })} ref={optionsRef}>
                    {
                        _options.map((option, index) => {
                            return (
                                <div key={index} className={styles.input__options__option} onClick={() => {
                                    if (type === 'file') {
                                        onChange(option)
                                    } else {
                                        onChange(option)
                                    }
                                    setIsShowOptions(false)
                                }}>
                                    {
                                        type === 'file' ? option.name : option
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

const Modals = ({
                    editId, setEditId, modalType,
                    folders, files, createFolder,
                    updateFolder, moveFile,
                    renameFile, currentFolderId,
                    getAttacmentsForCurrentFolder
                }) => {

    const currentFolder = folders?.find(folder => folder._id === editId) || null;
    const [colors, setColors] = useState(optionsColor.map(color => color.value));
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

    const [name, setName] = useState(currentFolder?.name);
    const [options, setOptions] = useState(['Main']);
    const [category, setCategory] = useState(currentFolder?.category);
    const [blockPickerColor, setBlockPickerColor] = useState(currentFolder?.color || colors[0]);



    const handleNameChange = (value) => {
        setName(value)
    }

    const handleCategoryChange = (value) => {
        setCategory(value)
    }

    const closeModal = () => {
        setEditId(null)
        resetForm()
    }

    const addFolder = () => {
        const data = {
            name,
            color: blockPickerColor,
            category,
        }

        createFolder({data})
        setEditId(null)
    }

    const onEditFolder = () => {
        updateFolder({
            folderId: editId,
            name: name,
            color: blockPickerColor,
            category,
        })
        setEditId(null)
    }

    const resetForm = () => {
        setName('')
        setCategory('')
        setBlockPickerColor(colors[0])
        setIsColorPickerOpen(false)
    }

    let submitBtnText

    switch (modalType) {
        case 'create':
            submitBtnText = 'Create'
            break
        case 'edit':
            submitBtnText = 'Save'
            break
        case 'move':
            submitBtnText = 'Move'
            break
        case 'rename':
            submitBtnText = 'Rename'
            break
        default:
            submitBtnText = 'Create'
    }

    const onSubmit = () => {
        if (modalType === 'create') {
            addFolder()
            resetForm()
        } else if (modalType === 'edit') {
            setIsColorPickerOpen(false)
            onEditFolder()
            resetForm()
        } else if (modalType === 'move') {
            moveFile()
        } else if (modalType === 'rename') {
            renameFile({
                fileId: editId,
                name
            })
        }
    }

    useEffect(() => {
        if (modalType === 'edit') {
            setName(currentFolder?.name)
            setCategory(currentFolder?.category)
            setBlockPickerColor(currentFolder?.color)
        }
    }, [editId, modalType, currentFolder])

    useEffect(() => {
        if (modalType === 'rename') {
            setName(files?.find(file => file._id === editId)?.name)
        }
    }, [editId])

    return (
        <Modal
            onClose={closeModal}
            open={editId !== null}
            className={styles.createFolderModal}
        >
            <div className={styles.container}>
                <div className={styles.container__top}>
                    <span className={styles.title}>
                        {
                            modalType === 'create' && 'Add folder'
                        }
                        {
                            modalType === 'edit' && 'Edit folder'
                        }
                        {
                            modalType === 'move' && 'Move file'
                        }
                        {
                            modalType === 'rename' && 'Rename file'
                        }
                    </span>
                    <button onClick={() => {
                        closeModal()
                    }}>
                        <img className={styles.closeIcon} src={close} alt=''/>
                    </button>
                </div>
                <div className={styles.container__body}>
                    <div className={cn(styles.container__body__flex, {
                        [styles.container__body__flex__reverse]: modalType === 'edit'
                    })}>
                        <div className={styles.container__body__form}>
                            {
                                modalType === 'edit' || modalType === 'create' ?
                                    <>
                                        <Select
                                            type='folder'
                                            _value={name}
                                            label='Name'
                                            placeholder='Folder name'
                                            callback={handleNameChange}
                                        />
                                        <Select
                                            type={'folder'}
                                            label='Category'
                                            _value={category}
                                            placeholder='Choose category'
                                            options={options}
                                            setOptions={setOptions}
                                            callback={handleCategoryChange}
                                        >
                                        </Select>
                                    </> :
                                    <>
                                    {
                                        modalType === 'move' &&
                                        <FileTree
                                            closeModal={closeModal}
                                            currentFolderId={currentFolderId}
                                            currentFileId={editId}
                                            getAttacmentsForCurrentFolder={getAttacmentsForCurrentFolder}
                                        />
                                    }
                                    {
                                        modalType === 'rename' &&
                                        <Select
                                            type='rename'
                                            label='Rename file'
                                            _value={files.find(file => file._id === editId)?.name}
                                            placeholder='File name'
                                            callback={handleNameChange}
                                        />
                                    }
                                    </>
                            }
                        </div>
                        {
                            modalType !== 'move' && modalType !== 'rename' &&
                            <div className={cn(styles.container__body__colors, {
                                [styles.container__body__colors__edit]: modalType === 'edit'
                            })}>
                                <span className={styles.title}>Color</span>
                                {
                                    colors.map((color, index) => {
                                        return (
                                            <div
                                                className={styles.sphere}
                                                style={{backgroundColor: color}}
                                                key={index}
                                                onClick={() => {
                                                    setBlockPickerColor(color)
                                                }}
                                            >
                                                {
                                                    blockPickerColor === color && (
                                                        <img src={tick} alt=''/>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {
                                    isColorPickerOpen && modalType !== 'rename' && (
                                        <div className={styles.sketchPicker}>
                                            <SketchPicker
                                                color={blockPickerColor}
                                                disableAlpha={true}
                                                onChange={(color) => {
                                                    setBlockPickerColor(color.hex);
                                                }}
                                            />
                                            <button onClick={() => {
                                                setColors((prev) => {
                                                    return [...prev.filter(color => color !== blockPickerColor), blockPickerColor]
                                                })
                                                setIsColorPickerOpen(false)
                                            }}>
                                                save color
                                            </button>
                                        </div>
                                    )
                                }
                                <div className={`${styles.sphere} ${styles.add}`}
                                     onClick={() => setIsColorPickerOpen(true)}>
                                    <img src={plus} alt=''/>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        modalType !== 'move' &&
                        <Button
                            className={styles.submitBtn}
                            text={submitBtnText}
                            onClick={onSubmit}
                        />
                    }
                </div>
            </div>
        </Modal>
    )
}

export default memo(Modals);
