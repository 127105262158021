import React, {useCallback, useState} from "react";
import styles from "./leaderboard.module.scss";
import {Pagination} from "../global/pagination/pagination";
import {LeadItem} from "./item/leadItem";
import {ItemsPerPage} from "./itemsPerPage/itemsPerPage";
import {ButtonPlus} from "./adminParts/adminParts";
import delete_icon2 from "../../media/icons/Delete.svg";
// import search_icon from "../../media/icons/leader_search.svg";
import Modal from "../global/modal/modal";
import close from "../../media/icons/close_icon.svg";
import warning from "../../media/icons/warning.svg";
import {GLOSSY, SOLID} from "../../const";
import Button from "../global/button/button";
import LeaderBoardService from "../../services/LeaderBoardService";
import {LEABERBOARD_ITEMS_COUNT, LEADERBOARD_ITEMS,} from "../../rquery/queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useFormik} from "formik";
import {useTitle} from "../../hooks/useTitle";
import leaderBoardPostValidation from "../../validations/leaderBoard/leaderBoardPostValidation";
import {useSelector} from "react-redux";

export const Leaderboard = () => {
    useTitle("Leaderboard");
    const {isAdmin} = useSelector((state) => state?.Authentication);
    const adminClass = isAdmin ? " " + styles.admin + " " : " ";
    const options = [
        {value: "10", label: "10 Rows"},
        {value: "20", label: "20 Rows"},
        {value: "30", label: "30 Rows"},
    ];
    const [itemsPerPage, setItemsPerPage] = useState(options[0]);
    const [itemOffset, setItemOffset] = useState(0);
    const [deleteList, setDeleteList] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    // const [switchCheckboxesOff, setSwitchCheckboxesOff] = useState(false);

    const currentPage = Math.floor(itemOffset / itemsPerPage.value + 1);

    const queryClient = useQueryClient();

    const {isLoading, data} =
        useQuery([LEADERBOARD_ITEMS, itemOffset, itemsPerPage], () =>
            LeaderBoardService.getAll(currentPage, itemsPerPage.value)
        );

    const {data: itemsCount} = useQuery([LEABERBOARD_ITEMS_COUNT], () =>
        LeaderBoardService.getAllCount()
    );

    const {mutate: mutateDelete} = useMutation(
        (ids) => LeaderBoardService.deleteRange(ids),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([LEADERBOARD_ITEMS]);
                queryClient.invalidateQueries([LEABERBOARD_ITEMS_COUNT]);
                if (currentPage > 1 && data?.data?.length === 1) {
                    setItemOffset(
                        (prev) => prev - itemsPerPage.value * (currentPage - 1)
                    );
                }
                setDeleteList([]);
                // setSwitchCheckboxesOff(true);
                // setTimeout(() => setSwitchCheckboxesOff(false));
                setDeleteModalOpen(false);
            },
        }
    );

    const changeHandler = (option) => {
        setItemsPerPage(option);
    };

    const {mutate: mutateCreate} = useMutation(
        (data) => LeaderBoardService.post(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([LEADERBOARD_ITEMS]);
                queryClient.invalidateQueries([LEABERBOARD_ITEMS_COUNT]);
                setUserModalOpen(false);
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            total: null,
            roas: null,
        },
        validationSchema: leaderBoardPostValidation,
        onSubmit: (values) => {
            mutateCreate(values);
        },
    });

    const handleDeleteLeaderBoardItems = () => {
        mutateDelete(deleteList.map((x) => x._id));
    };

    const handleCheckBoxChange = (e, {_id, name}) => {
        let _checked = e.target.checked;
        // callBack(_checked);
        if (_checked) {
            setDeleteList((prev) => [...prev, {_id, name}]);
        } else {
            setDeleteList((prev) => prev.filter((p) => p._id !== _id));
        }
    };

    const handleDeleteClick = () => {
        if (!deleteList.length) return;
        setDeleteModalOpen(true);
    };

    const handleDeleteCloseModal = useCallback(() => {
        setDeleteModalOpen(false);
    }, []);

    const handleUserCloseModal = useCallback(() => {
        setUserModalOpen(false);
    }, []);

    const handleDeleteCallback = (_id, name, callBack) => {
        callBack();
        setDeleteList([{_id, name}]);
        setDeleteModalOpen(true);
    };

    const _renderCreateUserModal = () => {
        return (
            <Modal
                className={styles.userModal}
                onClose={handleUserCloseModal}
                open={isUserModalOpen}
            >
                <div className={styles.userModalWrapper}>
                    <div className={styles.userModalTitleContainer}>
                        <p className={styles.userModalTitle}>Add user</p>
                        <button onClick={handleUserCloseModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.userModalDivider}/>
                    <div className={styles.userModalBodyContainer}>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.name && Boolean(formik.errors.name) && "error"
                            }`}
                        >
                            <label>Name:</label>
                            <input
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.name}
                                name="name"
                                onBlur={formik.handleBlur}
                                type="text"
                                placeholder=""
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.name && formik.errors.name}
              </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.total && Boolean(formik.errors.total) && "error"
                            } `}
                        >
                            <label>Total Earned:</label>
                            <input
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.total}
                                name="total"
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder=""
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.total && formik.errors.total}
              </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.roas && Boolean(formik.errors.roas) && "error"
                            }`}
                        >
                            <label>ROAS:</label>
                            <input
                                onChange={formik.handleChange}
                                defaultValue={formik.initialValues.roas}
                                name="roas"
                                onBlur={formik.handleBlur}
                                type="number"
                                placeholder=""
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.roas && formik.errors.roas}
              </span>
                        </div>
                        <Button
                            type={GLOSSY}
                            className={styles.saveBtn}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleDeleteCloseModal}
                open={isDeleteModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <button onClick={handleDeleteCloseModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            Are you sure that you want to{" "}
                            <b>
                                delete{" "}
                                {deleteList.length > 1
                                    ? `${deleteList.length} users`
                                    : deleteList[0]?.name}{" "}
                                ?
                            </b>
                        </p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <Button
                            onClick={handleDeleteLeaderBoardItems}
                            className={`${styles.deleteBtnModal}`}
                            icon={delete_icon2}
                            text={"Delete"}
                            type={SOLID}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            {_renderDeleteModal()}
            {_renderCreateUserModal()}
            <div className={styles.headingContainer + adminClass}>
                <h1> Leaderboard</h1>
                {isAdmin && (
                    <>
                        <ButtonPlus
                            onClick={() => setUserModalOpen(true)}
                            className={"onlyMobile"}
                        />
                        {/* <input
              type="text"
              className="input-search onlyMd"
              placeholder="Type here..."
            /> */}
                    </>
                )}
            </div>

            <div className={styles.leaderboard + adminClass}>
                <div className={styles.frame + " " + isAdmin ? styles.adminMobile : ""}>
                    <>
                        {isAdmin ? (
                            <div className={styles.upContainer + " onlyMobile"}>
                                {/* <div className={styles.search}>
                  <img src={search_icon} alt="" />
                  <input placeholder="Type here..." type="text" />
                </div> */}
                                <button
                                    onClick={handleDeleteClick}
                                    className={styles.deleteBtn}
                                >
                                    <img src={delete_icon2} alt=""/>
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="center">
                            <div className="lead-grid titles">
                                {isAdmin && <span className="onlyMobile"></span>}
                                <span>ID</span>
                                <span className={"profile_title"}>User</span>
                                <span> Total Earned </span>
                                <span> ROAS </span>
                                {isAdmin && <span className="onlyMobile"></span>}
                                {isAdmin && (
                                    <button
                                        onClick={handleDeleteClick}
                                        className={styles.deleteBtn + " onlyMd"}
                                    >
                                        <img src={delete_icon2} alt=""/>
                                    </button>
                                )}
                                {isAdmin && (
                                    <ButtonPlus
                                        onClick={() => setUserModalOpen(true)}
                                        className={"onlyMd"}
                                    />
                                )}
                            </div>
                            {isLoading ? (
                                <div className={styles.loaderWrapper}>
                                    <div className={styles.spinLoader} aria-hidden="true"></div>
                                </div>
                            ) : (
                                !isLoading &&
                                data?.data?.map((item, i) => (
                                    <LeadItem
                                        deleteList={deleteList}
                                        // switchCheckboxesOff={switchCheckboxesOff}
                                        handleDelete={handleDeleteCallback}
                                        handleCheckBoxChange={handleCheckBoxChange}
                                        {...item}
                                        id={currentPage === 1 ? i + 1 : itemOffset + (i + 1)}
                                        key={i}
                                    />
                                ))
                            )}
                        </div>
                    </>

                    {itemsCount && (
                        <div className="pagination">
                            <Pagination
                                itemsPerPage={Number(itemsPerPage.value)}
                                {...{
                                    itemOffset,
                                    setItemOffset,
                                    length: itemsCount.data,
                                }}
                            />
                            <ItemsPerPage
                                options={options}
                                changeHandler={changeHandler}
                                value={itemsPerPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
