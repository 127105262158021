import * as Yup from "yup";

const projectPostValidation = Yup.object().shape({
    name: Yup.string()
        .min(3, "Name is too short - shoul be at least 3 chars!")
        .max(255, "Name is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    description: Yup.string()
        .min(3, "Description is too short - shoul be at least 3 chars!")
        .max(500, "Description is too long - should not exceed 255 chars!")
        .required("Required!")
        .typeError("Required!"),

    category: Yup.string()
        .min(3, "Category is too short - should be at least 3 chars!")
        .max(20, "Description is too long - should not exceed 20 chars!")
        .required("Required!")
        .typeError("Category should be in a text format!"),

    status: Yup.string()
        .min(3, "Status is too short - shoul be at least 3 chars!")
        .max(20, "Status is too long - should not exceed 20 chars!")
        .required("Required!")
        .typeError("Satus should be in a text format!"),
});

export default projectPostValidation;
