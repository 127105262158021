import React, {useState} from "react";
import styles from "./createTask.module.scss";
import Select from "../../../global/select/select";
import {
    BACK,
    GLOSSY,
    PRIORITIES,
    PURPLE,
    STATUSES,
    TAGS,
    TRANSPARENT,
} from "../../../../const";
import Button from "../../../global/button/button";
import back from "../../../../media/icons/arrow_back.svg";
import CustomDatePicker from "../../../global/customDatePicker/customDatePicker";
import {useNavigate, useParams} from "react-router-dom";
import {useTitle} from "../../../../hooks/useTitle";
import {useFormik} from "formik";
import taskPostValidation from "../../../../validations/tasks/taskPostValidation";
import {useMutation, useQuery, useQueryClient} from "react-query";
import TaskService from "../../../../services/TaskService";
import {TASK, TASKS} from "../../../../rquery/queryKeys";
import {useSelector} from "react-redux";
import TagService from "../../../../services/TagService";
import TagSelect from "../../../global/select/tagSelect";
import Preloader from "../../../preloader/preloader";
import {optionsColor} from "../../../../mock";
import ColorSelectModal from "../../colorSelectModal/colorSelectModal";
import CategorySelect from "../../../global/select/categorySelect";

export default function CreateTask() {
    useTitle("New Task");
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const {id} = useParams();

    const queryClient = useQueryClient();

    const [isPreloaderActive, setPreloaderActive] = useState(false);

    const { token } = useSelector((state) => state?.Authentication);

    const { data } = useQuery([TAGS, token], () => TagService.get(token));

    const [optionsColors, setOptionsColors] = useState(optionsColor);
    const [isClrModalOpen, setIsClrModalOpen] = useState(false);
    const [blockPickerColor, setBlockPickerColor] = useState('#8C6CED');


    const [categories, setCategories] = useState([{}]);


    const {mutate} = useMutation((data) => TaskService.post(id, data, token), {
        onSuccess: () => {
            queryClient.invalidateQueries([TASK]);
            queryClient.invalidateQueries([TASKS]);
            navigate(`/projects/${id}/tasks`);
        },
    });

    const {mutate: mutatePostTag} = useMutation(
        (data) => TagService.post(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const handleCreateNewClick = (name, callBack) => {
        mutatePostTag({name: name});
        callBack();
    };

    const handeCategoryDelete = (value) => {
        let _categories = categories.filter(c => c.value !== value)
        setCategories(_categories)
        if (formik.values.category === value) {
            formik.setFieldValue("category", "")
        }
    }

    const handleCreateCategoryClick = (value) => {
        formik.setFieldValue("category", value);
        setCategories([...categories, {label: value, value: value}]);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            description: "",
            category: "",
            dueDate: null,
            status: "",
            tag: [],
            priority: "",
            color: '#8C6CED', // default color
        },

        validationSchema: taskPostValidation,
        onSubmit: (values) => {
            setPreloaderActive(true)
            mutate(values);
        },
    });

    const {mutate: mutateTag} = useMutation(
        (id) => TagService.deleteById(id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([TAGS]);
            },
        }
    );

    const handleTagDelete = (id) => {
        mutateTag(id)
        formik.setFieldValue("tag", formik.values.tag.filter(t => t !== id))
    }

    return (
        <>
            <ColorSelectModal
                isClrModalOpen={isClrModalOpen}
                setIsClrModalOpen={setIsClrModalOpen}
                optionsColors={optionsColors}
                setOptionsColors={setOptionsColors}
                blockPickerColor={blockPickerColor}
                setBlockPickerColor={setBlockPickerColor}
                formik={formik}
            />
            <div className={styles.top}>
                <div className={styles.title}>
                    <Button
                        text={""}
                        type={BACK}
                        icon={back}
                        className={styles.backBtn}
                        onClick={handleGoBack}
                    />
                    <h2>New Task</h2>
                </div>
            </div>

            <div className="border">
                <div className={`backCard d-flex wrap ${styles.container}`}>
                    <Preloader isActive={ isPreloaderActive } borderRadius/>
                    <div className={styles.formContainer}>
                        <div className={styles.left}>
                            <span className={styles.miniTitle}>BASIC INFO</span>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.name && Boolean(formik.errors.name) && "error"
                                } ${styles.formControls}`}
                            >
                                <label>Title</label>
                                <input
                                    name="name"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.name}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                <span className={styles.validationHelper}>
                  {formik.touched.name && formik.errors.name}
                </span>
                            </div>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.description &&
                                    Boolean(formik.errors.description) &&
                                    "error"
                                }`}
                            >
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.description}
                                    onBlur={formik.handleBlur}
                                ></textarea>
                                <span className={styles.validationHelper}>
                  {formik.touched.description && formik.errors.description}
                </span>
                                <span className={styles.helperInfo}>
                  You will be able to edit this info later
                </span>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.rightForm}>
                                <Select
                                    options={PRIORITIES}
                                    tickColor={PURPLE}
                                    label={"Priority"}
                                    className={styles.select}
                                    name="priority"
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.priority}
                                    error={formik.errors.priority}
                                    onChange={(o) => formik.setFieldValue("priority", o.value)}
                                />
                                <Select
                                    onBlur={formik.handleBlur}
                                    onChange={(o) => {
                                        formik.setFieldValue("color", o.value);
                                        setBlockPickerColor(o.value);
                                    }}
                                    value={{
                                        label: optionsColor.find((o) => o.value === blockPickerColor)?.label || blockPickerColor,
                                        value: blockPickerColor,
                                    }}
                                    options={optionsColors}
                                    tickColor={PURPLE}
                                    label={"Color"}
                                    className={styles.select}
                                    buttonText="+ Add custom color"
                                    buttonOnClick={() => setIsClrModalOpen(true)}
                                />
                                <div
                                    className={`formControls d-flex col ${styles.smallForm} ${
                                        formik.touched.category &&
                                        Boolean(formik.errors.category) &&
                                        "error"
                                    } ${styles.formControls}`}
                                >
                                    <div className={styles.inputWrapper}>
                                        <CategorySelect
                                            options={categories}
                                            handleCreateNewClick={handleCreateCategoryClick}
                                            label={"Category"}
                                            className={styles.select}
                                            handleDeleteClick={handeCategoryDelete}
                                            name="category"
                                            onBlur={formik.handleBlur}
                                            onChange={(checked, id) => {
                                                formik.setFieldValue(
                                                    "category",
                                                    checked ? id : ""
                                                );
                                            }}
                                            value={formik.values.category}
                                        />
                                    </div>
                                    {formik.touched.category &&
                                        Boolean(formik.errors.category) && (
                                            <span className={styles.validationHelper}>
                        {formik.errors.category}
                      </span>
                                        )}
                                </div>
                                <TagSelect
                                    options={data?.data.map((d) => {
                                        return {label: d.name, value: d._id};
                                    })}
                                    label={"Tag"}
                                    handleCreateNewClick={handleCreateNewClick}
                                    className={styles.select}
                                    handleDeleteClick={handleTagDelete}
                                    name="tag"
                                    onBlur={formik.handleBlur}
                                    onChange={(checked, id) => {
                                        if (checked) {
                                            formik.setFieldValue("tag", [...formik.values.tag, id]);
                                        } else {
                                            formik.setFieldValue("tag", [
                                                ...formik.values.tag.filter((t) => t !== id),
                                            ]);
                                        }
                                    }}
                                    value={formik.values.tag}
                                />
                                <Select
                                    onBlur={formik.handleBlur}
                                    onChange={(o) => formik.setFieldValue("status", o.value)}
                                    options={STATUSES}
                                    name={"status"}
                                    label={"Status"}
                                    className={styles.select}
                                    touched={formik.touched.status}
                                    error={formik.errors.status}
                                />
                                <CustomDatePicker
                                    label={"Due date"}
                                    className={`${styles.date}`}
                                    date={formik.values.dueDate}
                                    touched={formik.touched.dueDate}
                                    error={formik.errors.dueDate}
                                    name="dueDate"
                                    onBlur={formik.handleBlur}
                                    handleSelectDate={(d) => formik.setFieldValue("dueDate", d)}
                                />
                            </div>
                        </div>
                        <div className={styles.bottom}>
                            <div className={styles.rightButtonGroup}>
                                <Button
                                    text={"Cancel"}
                                    onClick={handleGoBack}
                                    type={TRANSPARENT}
                                />
                                <Button
                                    text={"Create"}
                                    onClick={formik.handleSubmit}
                                    type={GLOSSY}
                                    className={styles.btnCreate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
