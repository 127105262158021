import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes/routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_FRONT_OAUTH_CLIENT_ID}
    >
      <Router>
        <Routes />
      </Router>
    </GoogleOAuthProvider>
  );
}
