import React from "react";
import {BACK, FACEBOOK, GLOSSY, GOOGLE, PLUS, SOLID, TIKTOK, TRANSPARENT,} from "../../../const";
import styles from "./button.module.scss";

export default function Button({
                                   behaviour,
                                   text,
                                   type,
                                   onClick,
                                   className = " ",
                                   icon,
                                   ref = null,
                               }) {
    switch (type) {
        case GLOSSY:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.glossyButton} ${className}`}
                >
                    <div className={styles.textContainer}>
                        {icon && <img src={icon} alt=""/>}
                        {text}
                    </div>
                </button>
            );
        case TRANSPARENT:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.transparentButton} ${className}`}
                >
                    <div className={styles.textContainer}>
                        {icon && <img src={icon} alt=""/>}
                        <span>{text}</span>
                    </div>
                </button>
            );
        case SOLID:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.solidButton} ${className}`}
                >
                    <div className={styles.textContainer}>
                        {icon && <img src={icon} alt=""/>}
                        <span>{text}</span>
                    </div>
                </button>
            );
        case BACK:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.backButton} ${className}`}
                >
                    {icon && <img src={icon} alt=""/>}
                    <span>{text}</span>
                </button>
            );
        case PLUS:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.plusButton} ${className}`}
                ></button>
            );
        case TIKTOK:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.tiktokButton} ${className}`}
                >
                    {icon && <img src={icon} alt=""/>}
                    {text}
                </button>
            );
        case FACEBOOK:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.facebookButton} ${className}`}
                >
                    {icon && <img src={icon} alt=""/>}
                    {text}
                </button>
            );
        case GOOGLE:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.googleButton} ${className}`}
                >
                    {icon && <img src={icon} alt=""/>}
                    {text}
                </button>
            );
        default:
            return (
                <button
                    ref={ref}
                    type={behaviour}
                    onClick={onClick}
                    className={`${styles.button} ${className}`}
                >
                    <div className={styles.textContainer}>
                        {icon && <img src={icon} alt=""/>}
                        {text}
                    </div>
                </button>
            );
    }
}
