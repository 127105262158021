import React from 'react';
import { useSelector } from 'react-redux';
import styles from './navbar.module.scss';
import { Link } from 'react-router-dom';
import user from '../../../media/icons/user.svg';
import { ROUTES } from '../../../routes/mainRoutes';

export const Navbar = () => {
    const currentUser = useSelector((state) => state?.Authentication);
    return (
        <header className={styles.navbar}>
            <div className="grid-logo">
                <Link to={'/'}>
                    <img src="/logo.webp" alt="logo" />
                </Link>
            </div>
            <div className="grid-center">
                {/* TODO: SEARCH, NOTIFICATIONS */}
                {/* <Search className={styles.search} />
        <Notification number={notifications} /> */}
            </div>
            <div className="grid-user">
                <Link to={ROUTES.profile}>
                    <span className={'text'}>{currentUser?.name}</span>
                    <img src={currentUser?.picture?.url || user} alt="user  " />
                </Link>
            </div>
        </header>
    );
};
