import React from "react";

export default function RadioButton({
                                     label,
                                     className,
                                     onChange,
                                     checked,
                                     defaultChecked,
                                     name,
                                     onBlur,
                                 }) {
    return (
        <div className={`customCheckbox ${className}`}>
            <label>
                <input
                    name={name || ""}
                    onBlur={onBlur}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    type="radio"
                />
                <span></span>
                <p>{label}</p>
            </label>
        </div>
    );
}
