import React, {useEffect, useRef, useState} from 'react';
import styles from './campaignsTable.module.scss';

// import icons
import facebook from '../../../media/icons/facebook_blue.svg';
import google from '../../../media/icons/google.svg';

import cn from 'classnames';

import { v4 as uuidv4 } from 'uuid';

const Campaign = ({
    data,
    filterIds,
    setFilterIds,
    highlightedRows,
    setHighlightedRows,
}) => {
    const [uuid, setUuid] = useState(uuidv4());

    useEffect(() => {
        setUuid(uuidv4());
    }, [data]);

    const iconsMap = {
        facebook,
        google,
    };

    const metricsKeys = Object.keys(data.metrics);

    const highlightRow = (name, level) => {
        setHighlightedRows((prev) => {
            const newCompanyNames = { ...prev };
            if (newCompanyNames[level].includes(name)) {
                newCompanyNames[level] = newCompanyNames[level].filter((_el) => _el !== name);
                level === 'campaign' && (newCompanyNames['adset'] = []);
                return newCompanyNames;
            }

            newCompanyNames[level].push(name);
            level === 'campaign' && (newCompanyNames['adset'] = []);
            return newCompanyNames;
        });
    };

    const _setFilterIds = (id, level) => {
        setFilterIds((prev) => {
            const newFilterIds = { ...prev };

            if (newFilterIds[level].includes(id))
            {
                newFilterIds[level] = newFilterIds[level].filter((_el) => _el !== id);
                level === 'campaign' && (newFilterIds['adset'] = []);
                return newFilterIds;
            }

            newFilterIds[level].push(id)
            level === 'campaign' && (newFilterIds['adset'] = []);
            return newFilterIds;
        })

    }

    const callback = () => {
        if (data.level === 'ad') {
            return;
        }

        highlightRow(data.name, data.level);
        _setFilterIds(data.id, data.level);
    };

    const decimalAdjust = (type, value, exp) => {
        type = String(type);
        if (!['round', 'floor', 'ceil'].includes(type)) {
            throw new TypeError(
                "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'."
            );
        }
        exp = Number(exp);
        value = Number(value);
        if (exp % 1 !== 0 || Number.isNaN(value)) {
            return NaN;
        } else if (exp === 0) {
            return Math[type](value);
        }
        const [magnitude, exponent = 0] = value.toString().split('e');
        const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
        // Shift back
        const [newMagnitude, newExponent = 0] = adjustedValue
            .toString()
            .split('e');
        return Number(`${newMagnitude}e${+newExponent + exp}`);
    };

    const formatting = (value, key, provider) => {
        const PREFIX = '€';

        switch (key) {
            case 'impressions':
                return provider === 'google'
                    ? value
                        ? `${value.toLocaleString()}`
                        : '0'
                    : value
                    ? value
                    : '0';
            case 'cpm':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value / 1_000_000).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${PREFIX}${(+value).toFixed(2)}`
                    : `${PREFIX}0`;
            case 'ctr':
                return provider === 'google'
                    ? value
                        ? `${(+value * 100).toFixed(2)}%`
                        : '0%'
                    : value
                    ? `${(+value).toFixed(2)}%`
                    : '0%';
            case 'clicks':
                return value ? value : '0';
            case 'cpc':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value / 1_000_000).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${PREFIX}${decimalAdjust('ceil', value, -2)}`
                    : `${PREFIX}0`;
            case 'pur':
                return value ? value : '0';
            case 'cpp':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value / 1_000_000).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${PREFIX}${decimalAdjust('ceil', value, -2)}`
                    : `${PREFIX}0`;
            case 'totalCost':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value / 1_000_000).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${PREFIX}${value}`
                    : `${PREFIX}0`;
            case 'roas':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${decimalAdjust('ceil', value, -2)}`
                    : `${PREFIX}0`;
            case 'totalRevenue':
                return provider === 'google'
                    ? value
                        ? `${PREFIX}${(+value).toFixed(2)}`
                        : `${PREFIX}0`
                    : value
                    ? `${PREFIX}${value}`
                    : `${PREFIX}0`;
            default:
                return;
        }
    };

    return (
        <div
            className={cn(styles.row, styles.row__main, {
                [styles.row__main__selected]: highlightedRows[
                    data.level
                ].includes(data.name),
            })}
            onClick={callback}
            key={uuid}
        >
            <div className={cn(styles.cell, styles.companyIcon)}>
                <img src={iconsMap[data.provider]} alt="icon" />
            </div>
            <div className={cn(styles.cell, styles.value, styles.name)}>
                {data.name?.length >= 7
                    ? `${data.name.slice(0, 12)}...`
                    : data.name || 'unnamed'}
                <div className={styles.hint}>{data.name || 'no name'}</div>
            </div>
            {data &&
                metricsKeys.map((key, idx) => {
                    return (
                        <div
                            className={cn(styles.cell, styles.value)}
                            key={uuid}
                        >
                            {formatting(data.metrics[key], key, data.provider)}
                        </div>
                    );
                })}
        </div>
    );
};

const CampaignsTable = ({ data, startDate, endDate, filterIds, setFilterIds }) => {
    const tableRef = useRef(null);

    const [highlightedRows, setHighlightedRows] = useState({
        campaign: [],
        adset: [],
        ad: [],
    });

    const calculateElemsWidth = (elems) => {
        let totalWidth = 0;
        elems.forEach((el) => {
            totalWidth += el.offsetWidth;
        });
        return totalWidth;
    }

    useEffect(() => {
        const row = tableRef.current.querySelector(`.${styles.row__main}`);
        const cells = row.querySelectorAll(`.${styles.cell}`);

        const cellsWidth = calculateElemsWidth(cells);
        tableRef.current.style.setProperty('--cells-width', `${cellsWidth}px`)
    }, []);

    useEffect(() => {
       setHighlightedRows({
           campaign: [],
           adset: [],
           ad: [],
       })
    }, [startDate, endDate]);

    const metricsKeys = [
        'impressions',
        'cpm',
        'ctr',
        'clicks',
        'cpc',
        'pur',
        'cpp',
        'total cost',
        'total revenue',
        'roas',
    ];

    return (
        <div className={styles.campaigns}>
            <div className={styles.customTable} ref={tableRef}>
                <div className={cn(styles.row, styles.row__top)}>
                    <div className={styles.emptyCell} />
                    <div className={styles.cell}>name</div>
                    {metricsKeys.map((key) => {
                        return (
                            <div className={styles.cell} key={key}>
                                {key}
                            </div>
                        );
                    })}
                </div>
                {data.length &&
                    data.map((data) => {
                        return (
                            <Campaign
                                data={data}
                                setFilterIds={setFilterIds}
                                filterIds={filterIds}
                                highlightedRows={highlightedRows}
                                setHighlightedRows={setHighlightedRows}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default React.memo(CampaignsTable);
