import React from 'react';
import styles from './createPassword.module.scss';
import logo from '../../../media/Mint_Media_Logo.webp';
import Button from '../../global/button/button';
import { GLOSSY } from '../../../const';
import { useTitle } from '../../../hooks/useTitle';
import eye from '../../../media/icons/eye.svg';
import eye_close from '../../../media/icons/eye_close.svg';
import { useOpen } from '../../../hooks/useOpen';
import { useFormik } from 'formik';
import createPasswordValidation from '../../../validations/authentication/createPasswordValidation';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticationService from '../../../services/AuthenticationService';
import { clearResetPasswordToken } from '../../../redux/slices/authentication';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CreatePassword() {
    useTitle('CreatePassword');

    const navigate = useNavigate();

    const { isOpen: isOpenConfirm, onToggle: onToggleConfirm } = useOpen();
    const { isOpen: isOpenPass, onToggle: onTogglePass } = useOpen();

    const dispatch = useDispatch();
    const { toResetUserId } = useSelector(
        (state) => state.Authentication
    );

    const verificationCode = localStorage.getItem("verificationCode");

    const { mutate } = useMutation(
        (data) => AuthenticationService.resetPassword(data),
        {
            onSuccess: () => {
                toast.success('Password successfully changed');
                navigate('../login');
                dispatch(clearResetPasswordToken());
            },
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: null,
            confirmPassword: null,
            userId: toResetUserId,
            token: verificationCode,
        },
        validationSchema: createPasswordValidation,
        onSubmit: (values) => {
            delete values.confirmPassword;
            mutate(values);
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.backLinkBlock}></div>
            <div className={styles.createWrapper}>
                <div className={styles.logo}>
                    <img alt={''} src={logo} />
                </div>
                <div className={styles.createTitle}>
                    <h1>Create new password</h1>
                </div>
                <div className={styles.createSubtitle}>
                    Your new password must be different from previous used
                    passwords.
                </div>
                <form className="w-100">
                    <div className="formGroup">
                        <div
                            className={`formControls d-flex col ${
                                styles.input
                            } ${
                                formik.touched.password &&
                                Boolean(formik.errors.password) &&
                                'error'
                            }`}
                        >
                            <label>Password</label>
                            <div className={styles.inputBox}>
                                <input
                                    name="password"
                                    onChange={formik.handleChange}
                                    defaultValue={formik.initialValues.password}
                                    onBlur={formik.handleBlur}
                                    type={isOpenPass ? 'text' : 'password'}
                                    placeholder=""
                                />
                                <div
                                    className={styles.btnEye}
                                    onClick={onTogglePass}
                                >
                                    <img
                                        src={isOpenPass ? eye : eye_close}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <span className={styles.validationHelper}>
                                {formik.touched.password &&
                                    formik.errors.password}
                            </span>
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                styles.input
                            } ${
                                formik.touched.confirmPassword &&
                                Boolean(formik.errors.confirmPassword) &&
                                'error'
                            }`}
                        >
                            <label>Confirm Password</label>
                            <div className={styles.inputBox}>
                                <input
                                    name="confirmPassword"
                                    onChange={formik.handleChange}
                                    defaultValue={
                                        formik.initialValues.confirmPassword
                                    }
                                    onBlur={formik.handleBlur}
                                    type={isOpenConfirm ? 'text' : 'password'}
                                    placeholder=""
                                />
                                <div
                                    className={styles.btnEye}
                                    onClick={onToggleConfirm}
                                >
                                    <img
                                        src={isOpenConfirm ? eye : eye_close}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <span className={styles.validationHelper}>
                                {formik.touched.confirmPassword &&
                                    formik.errors.confirmPassword}
                            </span>
                        </div>
                    </div>
                    <div className={styles.btnGroup}>
                        <Button
                            onClick={formik.handleSubmit}
                            type={GLOSSY}
                            text="Reset password"
                            behaviour={'submit'}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
