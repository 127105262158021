import React from 'react';
import style from './notifications.module.scss'
import EmptyIcon from '../../media/icons/notification_empty.svg'
import {   useNotificationMock} from "./mock";
import {useTitle} from "../../hooks/useTitle";


const Empty = () => {

    return <div className={style.empty}>
        <div className="center">
            <div className={style.emptyImg}>
                <img src={EmptyIcon} alt=""/>
            </div>
            
            <h2 className={'title'}>
                No unread messages
            </h2>
            <p className="subtitle">
                No messages. Check back later, more answers coming soon.
            </p>
        </div>

    </div>
}

const User = ({icon, name, message, time, counter}) => {
    return (
        <div className={style.gridUser}>
               <figure className="img">
                     <img src={icon||'/defaultUser.svg'} alt="user  "/>
               </figure>
            <span className="name">{name}</span>
            <span className="message">{message}</span>
            <span className="time"> {time}</span>
            <span className="counter"> {counter>0  ?  <span className="full">{counter} </span> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.62 6.21C16.5165 6.12804 16.3977 6.0674 16.2707 6.03156C16.1436 5.99573 16.0107 5.98542 15.8796 6.00122C15.7485 6.01703 15.6218 6.05864 15.5069 6.12365C15.392 6.18866 15.2911 6.27579 15.21 6.38L8.20999 15.38L4.77999 11.2C4.69954 11.0929 4.59839 11.0031 4.48255 10.9358C4.3667 10.8686 4.23853 10.8253 4.10563 10.8085C3.97273 10.7917 3.83782 10.8018 3.70891 10.8382C3.57999 10.8746 3.4597 10.9365 3.35516 11.0203C3.25063 11.104 3.16399 11.2079 3.10039 11.3258C3.03679 11.4437 2.99752 11.5732 2.98491 11.7065C2.9723 11.8399 2.98661 11.9744 3.027 12.1021C3.06738 12.2298 3.13301 12.3481 3.21999 12.45L7.38999 17.63C7.48411 17.746 7.60303 17.8395 7.73801 17.9035C7.87299 17.9675 8.0206 18.0005 8.16999 18C8.32857 18.0071 8.48656 17.9763 8.63088 17.9102C8.77521 17.8442 8.90174 17.7447 8.99999 17.62L16.83 7.62C16.9098 7.51403 16.9678 7.39323 17.0004 7.26464C17.0331 7.13605 17.0399 7.00224 17.0203 6.87101C17.0008 6.73978 16.9553 6.61376 16.8866 6.50027C16.8179 6.38679 16.7272 6.28811 16.62 6.21ZM21.62 6.21C21.5165 6.12804 21.3977 6.0674 21.2707 6.03156C21.1436 5.99573 21.0107 5.98542 20.8796 6.00122C20.7485 6.01703 20.6218 6.05864 20.5069 6.12365C20.392 6.18866 20.2911 6.27579 20.21 6.38L13.21 15.38L12.6 14.63L11.34 16.25L12.44 17.62C12.5341 17.736 12.653 17.8295 12.788 17.8935C12.923 17.9575 13.0706 17.9905 13.22 17.99C13.3702 17.9893 13.5183 17.9548 13.6534 17.889C13.7884 17.8232 13.9069 17.7279 14 17.61L21.83 7.61C21.9083 7.50434 21.9649 7.38427 21.9967 7.25668C22.0285 7.1291 22.0349 6.99649 22.0154 6.86645C21.9959 6.73641 21.9509 6.61149 21.8831 6.49885C21.8152 6.38621 21.7258 6.28805 21.62 6.21Z" fill="#373D59"/>
                <path d="M8.70982 13.06L9.99982 11.44L9.79982 11.2C9.72087 11.0906 9.62061 10.9983 9.50506 10.9287C9.38952 10.8591 9.26109 10.8136 9.12749 10.7949C8.9939 10.7762 8.8579 10.7848 8.72769 10.82C8.59748 10.8552 8.47575 10.9165 8.36982 11C8.26709 11.0825 8.18169 11.1845 8.11852 11.3002C8.05534 11.4158 8.01564 11.5428 8.0017 11.6738C7.98777 11.8048 7.99986 11.9373 8.03729 12.0637C8.07472 12.19 8.13675 12.3077 8.21982 12.41L8.70982 13.06Z" fill="#373D59"/>
            </svg>
            }  </span>
        </div>

    )

}

export const Notifications = ( ) => {
    useTitle("Notifications");
    const mock =useNotificationMock();

    return (<>
            <h1 className={style.h1}>Notifications</h1>
            <div className={style.notifications}>

                {mock.length   ?   mock.map((item , i) => {
                  return  <User key={i} {...item} />
                }) : <Empty/>}

            </div>
        </>

    );
};
