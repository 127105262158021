import styles from './circleProgressBar.module.scss';
import percentIcon from '../../../media/icons/percent.svg';
import { useEffect, useRef, useState } from 'react';

const CircleProgressBar = ( { from, to, duration, title, value, percent, color } ) => {

    const circleRef = useRef(100);
    const [progress, setProgress] = useState(0);

    // animation of progress bar
    const progressAnimation = () => {
        let start = performance.now();
        requestAnimationFrame(function animate(time) {
            let timeFraction = (time - start) / duration;
            if (timeFraction > 1) timeFraction = 1;
            let progress = from + timeFraction * (to - from);
            circleRef.current.style?.setProperty('--progress', 100 + progress);
            setProgress(Math.round(progress));
            if (timeFraction < 1)
            {
                requestAnimationFrame(animate);
            }
        })
    }

    // Set color for progress bar
    const setProgressColor = (color) =>
    {
        circleRef.current.style.setProperty('--color', color);
    }

    useEffect(() =>
    {
        setProgressColor(color);
        progressAnimation();
    }, [from, to]);

    return (
        <div className={styles.container}>
            <div className={styles.circle}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3.5 -3.5 40 40">
                    <circle cx="16" cy="16" r="15.9155" className={styles.background} />
                    <circle cx="16" cy="16" r="15.9155" className={styles.progress } ref={circleRef} />
                </svg>
                <div className={styles.progressText}>
                    {progress}%
                </div>
            </div>
            <div className={styles.data}>
                <div className={styles.data__title}>
                    {title}
                </div>
                <div className={styles.data__value}>
                    {value}
                </div>
                <div className={styles.data__percent}>
                    <img src={percentIcon} alt="icon"/> <p>+{percent} %</p>
                </div>
            </div>
        </div>
    );
}

export default CircleProgressBar;
