import React, {useEffect, useState} from "react";
import styles from "./forumPostCard.module.scss";
import pencil from "../../../media/icons/pencil.svg";
import delete_icon from "../../../media/icons/Delete.svg";
import close from "../../../media/icons/close_icon.svg";
import Button from "../../global/button/button";
import {CATEGORIES, GLOSSY, SOLID} from "../../../const";
import Select from "../../global/select/select";
import Modal from "../../global/modal/modal";
import {useCallback} from "react";
import warning from "../../../media/icons/red_warning.svg";
import {useFormik} from "formik";
import {useSelector} from "react-redux";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ForumService from "../../../services/ForumService";
import {FILES, FORUM, FORUMS, FORUMS_COUNT} from "../../../rquery/queryKeys";
import discussionPostValidation from "../../../validations/forums/discussionPostValidation";
import CategoryService from "../../../services/CategoryService";
import {useNavigate} from "react-router-dom";
import red_close from "../../../media/icons/red_close_icon.svg";
import file_icon from "../../../media/icons/file.svg";
import plus from "../../../media/icons/plus_violet.svg";
import useFileUpload from "../../../hooks/useFileUpload";
import FileService from "../../../services/FileService";

export default function ForumPostCard({
                                          className,
                                          title,
                                          img,
                                          // participants,
                                          // participantsImg,
                                          ago,
                                          by,
                                          replies,
                                          badge,
                                          byImg,
                                          onClick,
                                          categories,
                                          id,
                                          categoryId,
                                          currentPage,
                                          itemsPerPage,
                                          setItemOffset,
                                          length,
                                          isThread
                                      }) {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [_categories, _setCategories] = useState(categories);
    const navigate = useNavigate();
    const {
        token,
        id: userId,
        isAdmin,
    } = useSelector((state) => state.Authentication);

    const handleCloseEditModal = useCallback(() => {
        setIsEditModalOpen(false);
    }, []);

    const handleCloseDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);

    const queryClient = useQueryClient();

    const {mutate: mutateDelete} = useMutation(
        (id) => ForumService.deleteById(id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([FORUMS]);
                queryClient.invalidateQueries([FORUMS_COUNT]);
                if (currentPage > 1 && length === 1) {
                    setItemOffset((prev) => prev - itemsPerPage * (currentPage - 1));
                }
                handleCloseDeleteModal();
                navigate(
                    `/forums/${_categories
                        ?.find((c) => c._id === categoryId)
                        .name.replaceAll(/\s/g, "")}`
                );
            },
        }
    );

    const {mutate: mutatePut} = useMutation(
        (data) => ForumService.put(id, data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([FORUMS]);
                queryClient.invalidateQueries([FORUM]);
                handleCloseEditModal();
            },
        }
    );

    const {mutate: postFile} = useMutation((data) => ForumService.postFile(id, data, token), {
        onSuccess: (data) => {
            queryClient.invalidateQueries([FORUMS]);
            queryClient.invalidateQueries([FORUM]);
        },
    });

    useQuery(
        [CATEGORIES, token],
        () => CategoryService.get(token),
        {
            onSuccess: (data) => _setCategories(data?.data),
        }
    );

    const handleDeleteClick = () => {
        mutateDelete(id);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            user: userId,
            category: categoryId,
            description: "dsada",
        },
        validationSchema: discussionPostValidation,
        onSubmit: (values) => {
            mutatePut(values);
        },
    });

    const handleFileUpload = (_files) => {
        let formData = new FormData();

        _files.forEach(f => {
            formData.append('files', f)
        })

        if (formData.getAll('files').length <= 0) return;

        postFile(formData)
        // reset();
    }

    const {
        files,
        inputRef,
        handleInputClick,
        handleInputChange,
        reset
    } = useFileUpload(handleFileUpload, false);

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseDeleteModal}
                open={isDeleteModalOpen}
                className={styles.modalDelete}
            >
                <div onClick={e => e.stopPropagation()} className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={handleCloseDeleteModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>Are you sure that you want to delete this discussion?</p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                type={SOLID}
                                onClick={handleDeleteClick}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderEditModal = () => {
        return (
            <Modal
                className={styles.modal}
                onClose={handleCloseEditModal}
                open={isEditModalOpen}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>Edit discussion</p>
                        <button onClick={handleCloseEditModal} className="modalClose">
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Category:</label>
                            <Select
                                touched={formik.touched.category}
                                error={formik.errors.category}
                                className={styles.select}
                                options={_categories?.map((c) => ({
                                    label: c.name,
                                    value: c._id,
                                }))}
                                defaultValue={{
                                    label: _categories?.find(
                                        (c) => c._id === formik.initialValues.category
                                    )?.name,
                                    value: formik.initialValues.category,
                                }}
                                onChange={(c) => {
                                    formik.setFieldValue("category", c.value);
                                }}
                            />
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.title && Boolean(formik.errors.title) && "error"
                            } ${styles.textInput}`}
                        >
                            <label>Name</label>
                            <input
                                defaultValue={formik.initialValues.title}
                                name="title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="text"
                            />
                            <span className={styles.validationHelper}>
                {formik.touched.title && formik.errors.title}
              </span>
                        </div>
                        <div className={`formControls d-flex col ${styles.textInput}`}>
                            <label>Attachments</label>
                            <div className={styles.attachments}>
                                {files.map((f, i) =>
                                    f.isLoaded ? (
                                        <div key={i} className={styles.imgContainer}>
                                            <img src={URL.createObjectURL(f)} alt=""/>
                                            <div className={styles.description}>
                                                <span>{f.name}</span>
                                            </div>
                                            <button type="button" className={styles.deleteContainer}>
                                                <img src={red_close} alt=""/>
                                            </button>
                                        </div>
                                    ) : (
                                        <div key={i} className={styles.loadingContainer}>
                                            <div className={styles.info}>
                                                <div className={styles.fileInfo}>
                                                    <img src={file_icon} alt=""/>
                                                    <span>{f.name}</span>
                                                </div>
                                            </div>
                                            <div className={styles.empty}>
                                                <span>40%</span>
                                                <div
                                                    className={styles.loaded}
                                                    style={{width: "40%"}}
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            <div onClick={handleInputClick} className={styles.fileZone}>
                                <input
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    onChange={handleInputChange}
                                    hidden
                                    type="file"
                                    ref={inputRef}
                                />
                                <img src={plus} alt=""/>
                            </div>
                        </div>
                        <Button
                            onClick={formik.handleSubmit}
                            type={GLOSSY}
                            className={styles.createBtn}
                            text={"Edit"}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    const handleGeneralClick = (e) => {
        if (e.currentTarget !== e.target) return
        onClick()
    }

    return (
        <div onClick={handleGeneralClick} className={`${styles.container} ${className}`}>
            {_renderEditModal()}
            {_renderDeleteModal()}
            <div onClick={handleGeneralClick} className={styles.left}>
                {/*<div onClick={handleGeneralClick} className={styles.avatarContainer}>
                     TODO image
                    <img src={img} alt=""/>
                </div>*/}
                <div className={styles.top}>
                    <p onClick={handleGeneralClick} className={styles.title}>
                        {title}
                    </p>
                    <div onClick={handleGeneralClick} className={styles.badgeContainer}>
                        <div className={styles.badge}>{badge}</div>
                    </div>
                </div>
                <div onClick={handleGeneralClick} className={styles.bottom}>
                    <div onClick={handleGeneralClick} className={styles.replies}>
                        <span>{replies}</span>
                        <span>Replies</span>
                    </div>
                    {/* TODO participants */}
                    {/* <div className={styles.participants}>
            <span>{participants}</span>
            <span>participants</span>
            <div>
              {participantsImg.map((pi) => {
                return <img src={pi.img} alt="" />;
              })}
            </div>
          </div> */}
                    <div onClick={handleGeneralClick} className={styles.byWhom}>
                        <img src={byImg} alt='image' />
                        <span>By</span>
                        <span>{by}</span>
                    </div>
                    <div className={styles.ago}>
                        {ago === 0 ? (<span>Today</span>) : ago === 1 ? (<span>Yesterday</span>) : (
                            <span><span className={styles.days}>{ago}</span> days ago</span>)}
                    </div>
                </div>
            </div>
            <div onClick={handleGeneralClick} className={styles.right}>
                {isAdmin ? (
                    <div className={styles.btnGroup}>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsEditModalOpen(true)
                            }}
                            className={styles.adminBtn}
                        >
                            <img src={pencil} alt=""/>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsDeleteModalOpen(true)
                            }}
                            className={styles.adminBtn}
                        >
                            <img src={delete_icon} alt=""/>
                        </div>
                    </div>
                ) : (
                    <div className={styles.btnGroup}></div>
                )}
            </div>
        </div>
    );
}
