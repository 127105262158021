import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import styles from './profile.module.scss';
import profile from '../../media/icons/profile.svg';
import profile_active from '../../media/icons/profile_active.svg';
import lock_active from '../../media/icons/lock_active.svg';
import volume_active from '../../media/icons/volume_active.svg';
import lock from '../../media/icons/lock.svg';
import volume from '../../media/icons/volume.svg';
import configuration from '../../media/icons/configuration.svg';
import configuration_active from '../../media/icons/configuration_active.svg';
import useSwitchTab from '../../hooks/useSwitchTab';
import Card from '../global/card/card';
import Button from '../global/button/button';
import {
    EMPTY,
    FACEBOOK,
    GLOSSY,
    GOOGLE,
    SOLID /*TIKTOK */,
} from '../../const';
// import axios from 'axios';
import delete_icon from '../../media/icons/delete_bold.svg';
import profile_picture from '../../media/icons/user.svg';
// import tiktok from '../../media/icons/tiktok.svg';
import { useGoogleLogin } from '@react-oauth/google';
import facebook from '../../media/icons/loginIcon/facebook.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import google from '../../media/icons/google.svg';
import tiktok from '../../media/icons/tiktok.svg';
import photo from '../../media/icons/photo.svg';
import { Toggle } from 'react-toggle-component';
import CheckBox from '../global/checkBox/checkBox';
import PhoneSelect from '../global/phoneSelect/phoneSelect';
import CustomDatePicker from '../global/customDatePicker/customDatePicker';
import cn from 'classnames';
// import { countries } from '../../mock'; // old countries
import Select from '../global/select/select';
import Modal from '../global/modal/modal';
import delete_icon2 from '../../media/icons/Delete.svg';
import warning from '../../media/icons/red_warning.svg';
import close from '../../media/icons/close_icon.svg';
import quit from '../../media/icons/quit.svg';
import { useTitle } from '../../hooks/useTitle';
import { useDispatch } from 'react-redux';
import { getCurrentUser, logOut } from '../../redux/slices/authentication';
import { useSelector } from 'react-redux';
import {
    editUser,
    changePassword,
    linkGoogle,
    unlinkGoogle,
    linkFacebook,
    unlinkFacebook,
    updateAvatar,
    deleteUser,
} from '../../services/UserService';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import profileValidationSchema from '../../validations/profile/profileValidation';
import authService from '../../services/AuthenticationService';
import useFileUpload from '../../hooks/useFileUpload';
import Preloader from '../preloader/preloader';
import { useCountries } from '../../hooks/useCountries';
import ConfigurationSection from './configurationSection/configurationSection';
import ConfigurationField from './configurationSection/configurationField/configurationField';
import AddService, {
    get_g_accounts,
    get_g_child_accounts,
} from '../../services/AddService';
import { useQuery } from 'react-query';
import { useGoogleLoginWrapper } from '../../hooks/useGoogleLoginWrapper';
import { current } from '@reduxjs/toolkit';

export default function Profile() {
    useTitle('Profile');

    const { countries } = useCountries(true);

    const countriesList = countries.map((country) => {
        return {
            value: country.name,
            label: country.name,
        };
    });

    const { currentTab, handleTabClick } = useSwitchTab(0);
    const tabs = [
        { title: 'Profile', img: profile, imgActive: profile_active },
        { title: 'Security', img: lock, imgActive: lock_active },
        { title: 'Notifications', img: volume, imgActive: volume_active },
        {
            title: 'Сonfiguration',
            img: configuration,
            imgActive: configuration_active,
        },
    ];

    const dispatch = useDispatch();
    const [isMobile, setMobile] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const currentUser = useSelector((state) => state.Authentication);

    const [name, setName] = useState(currentUser.name);
    const [email, setEmail] = useState(currentUser.email);
    const [phone, setPhone] = useState(currentUser.phone);
    const [company, setCompany] = useState(currentUser.company);
    const [country, setCountry] = useState(currentUser.country);
    const [birthday, setBirthday] = useState(currentUser.birthday);
    const [isPhotoUploaded, setPhotoUploaded] = useState(false);
    const [isUserProfileUpdated, setUserProfileUpdated] = useState(false);

    const [facebookApiWorks, setFacebookApiWorks] = useState(false);
    const [googleApiWorks, setGoogleApiWorks] = useState(false);

    const { mutate: passwordMutation } = useMutation(
        (data) => changePassword(data, currentUser.token),
        {
            onSuccess: () => {
                toast.success('Password changed successfully');
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );
    const { mutate: linkForEmailConfirm } = useMutation(
        (data) =>
            authService.sendConfirmNewEmailRequest(data, currentUser.token),
        {
            onSuccess: (data) => {
                toast.success(data.data.message);
            },
        }
    );

    const { mutate } = useMutation({
        mutationFn: (data) => {
            setUserProfileUpdated(true);
            return editUser(
                { name, phone, company, country, birthday },
                currentUser.token
            );
        },
        onSuccess: (data) => {
            if (email !== currentUser.email) {
                linkForEmailConfirm({ email });
                setUserProfileUpdated(false);
            }
            dispatch(getCurrentUser(currentUser.token));
            setUserProfileUpdated(false);
            toast.success('Profile updated successfully.');
        },
    });

    /*const { mutate } = useMutation(
        () =>
            editUser(
                { name, phone, company, country, birthday },
                currentUser.token
            ),
        {
            onSuccess: (data) => {
                if (email !== currentUser.email) {
                    linkForEmailConfirm({ email });
                    setUserProfileUpdated(false);
                }
                dispatch(getCurrentUser(currentUser.token));
                toast.success('Profile updated successfully.');
            },
        }
    );*/
    const { mutate: linkGoogleMutation } = useMutation(
        (data) => linkGoogle(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );

    const { mutate: unlinkGoogleMutation } = useMutation(
        () => unlinkGoogle(currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );

    const { mutate: linkFacebookMutation } = useMutation(
        (data) => linkFacebook(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const { mutate: unlinkFacebookMutation } = useMutation(
        () => unlinkFacebook(currentUser.token),
        {
            onSuccess: () => {
                dispatch(getCurrentUser(currentUser.token));
            },
        }
    );
    const { mutate: deleteAccountMutation } = useMutation(
        (data) => deleteUser(data, currentUser.token),
        {
            onSuccess: () => {
                dispatch(logOut());
            },
        }
    );

    const { mutate: updateAvatarMutation } = useMutation(
        (data) => updateAvatar(data, currentUser.token),
        {
            onSuccess: () => {
                setTimeout(() => {
                    setPhotoUploaded(false);
                    toast.success('Profile image updated successfully');
                    dispatch(getCurrentUser(currentUser.token));
                }, 1000);
            },
        }
    );

    const handleFacebookResponse = async (response) => {
        linkFacebookMutation({ accessToken: response.accessToken });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: profileValidationSchema,
        onSubmit: (values) => {
            passwordMutation(values);
            formik.resetForm({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
        },
    });

    const handleNameChange = useCallback((e) => {
        setName(e.target.value);
    }, []);

    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const handlePhoneChange = (value) => {
        setPhone(value);
    };

    const handleCompanyChange = useCallback((e) => {
        setCompany(e.target.value);
    }, []);

    const handleCountryChange = useCallback((e) => {
        setCountry(e.value);
    }, []);

    const handleAccountDelete = useCallback(() => {
        deleteAccountMutation({ id: currentUser.id });
    }, [deleteAccountMutation, currentUser.id]);

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseModal}
                open={isModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <button onClick={handleCloseModal}>
                            <img src={close} alt="" />
                        </button>
                    </div>
                    <div className={styles.modalBody}>
                        <img src={warning} alt="" />
                        <p>
                            <b>Are you sure you want to delete your profile?</b>
                        </p>
                        <span>
                            After deleting, it will be impossible to restore
                            user data
                        </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.noBtnModal}`}
                                text={'No'}
                                type={SOLID}
                                onClick={handleCloseModal}
                            />
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon2}
                                text={'Delete'}
                                onClick={handleAccountDelete}
                                type={SOLID}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const handleLogOut = () => {
        dispatch(logOut());
    };

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 992) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);

    const linkGoogleAccount = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/calendar.events',
        onSuccess: async ({ code }) => {
            linkGoogleMutation({ googleCode: code }, currentUser.token);
        },
        flow: 'auth-code',
    });

    const handleFileUpload = (_files) => {
        let formData = new FormData();

        _files.forEach((f) => {
            formData.append('files', f);
        });

        if (formData.getAll('files').length <= 0) return;

        setPhotoUploaded(true);
        updateAvatarMutation(formData);
        reset();
    };

    const {
        //files,
        inputRef,
        reset,
        handleInputChange,
    } = useFileUpload(handleFileUpload);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const _renderProfileHead = () => {
        return (
            <>
                {!isMobile ? (
                    <Card
                        className={`${styles.card}`}
                        title={'Profile Piсture'}
                    >
                        <div className={styles.profileCard}>
                            <div className={styles.profileImg}>
                                <Preloader isActive={isPhotoUploaded} />
                                <img
                                    src={
                                        currentUser?.picture?.url ||
                                        profile_picture
                                    }
                                    alt=""
                                />
                            </div>
                            <span>Upload/Change Your Profile Image</span>
                            <input
                                hidden
                                multiple
                                ref={inputRef}
                                onChange={handleInputChange}
                                type="file"
                            />
                            <Button
                                text={'Upload Avatar'}
                                onClick={() => inputRef.current.click()}
                                type={GLOSSY}
                            />
                        </div>
                    </Card>
                ) : (
                    <div className={styles.profileCard}>
                        <div className={styles.mobileProfileCard}>
                            <div className={styles.profileImg}>
                                <img
                                    src={
                                        currentUser?.picture?.url ||
                                        profile_picture
                                    }
                                    alt=""
                                />
                            </div>
                            <Button icon={photo} className={styles.photoBtn} />
                        </div>
                    </div>
                )}
            </>
        );
    };

    const _renderProfile = () => {
        return (
            <>
                <div className={`${styles.content} ${styles.contentProfile}`}>
                    <div className={styles.profileLeftWrapper}>
                        {_renderProfileHead()}
                    </div>

                    <Card
                        className={`${styles.card} ${styles.bigCard}`}
                        title={'Account Details'}
                    >
                        <div className={styles.bigCardWrapper}>
                            <div>
                                <div
                                    className={`formControls d-flex col ${styles.formControls}`}
                                >
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        onChange={handleNameChange}
                                        value={name}
                                    />
                                </div>
                                <div
                                    className={`formControls d-flex col ${styles.formControls}`}
                                >
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        onChange={handleEmailChange}
                                        value={email}
                                    />
                                </div>
                                <div className={styles.compactInput}>
                                    <div
                                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                                    >
                                        <label>Company</label>
                                        <input
                                            type="text"
                                            onChange={handleCompanyChange}
                                            value={company}
                                        />
                                    </div>
                                    <Select
                                        options={countriesList}
                                        label={'Country'}
                                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls} ${styles.select}`}
                                        onChange={handleCountryChange}
                                        value={countriesList.find(
                                            (c) => c.value === country
                                        )}
                                    />
                                </div>
                                <div className={styles.compactInput}>
                                    <div
                                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                                    >
                                        <label>Phone number</label>
                                        <PhoneSelect
                                            handlePhoneChange={
                                                handlePhoneChange
                                            }
                                            dropdownClass={
                                                styles.compactInputPhone
                                            }
                                            value={phone}
                                        />
                                    </div>
                                    <div
                                        className={`formControls d-flex col ${styles.halfInput} ${styles.formControls}`}
                                    >
                                        <label>Birthday</label>
                                        <CustomDatePicker
                                            handleSelectDate={(d) =>
                                                setBirthday(d)
                                            }
                                            type={EMPTY}
                                            placeholderText="mm-dd-yyyy"
                                            date={
                                                birthday
                                                    ? new Date(birthday)
                                                    : null
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.btnWrapper}>
                                <Preloader isActive={isUserProfileUpdated} />
                                <Button
                                    className={styles.saveBtn}
                                    text={'Save'}
                                    type={GLOSSY}
                                    onClick={mutate}
                                />
                            </div>
                        </div>
                    </Card>

                    <div className={styles.socialBtnGroup}>
                        {/* couldn't disable account if it is the last option to sign in*/}
                        {(currentUser?.google?.email !== currentUser?.email ||
                            currentUser?.facebook?.email ===
                                currentUser?.email ||
                            currentUser?.password) && (
                            <Button
                                className={
                                    currentUser.google?.accessToken
                                        ? `${styles.socialBtn} ${styles.socialBtnActive}`
                                        : `${styles.socialBtn}`
                                }
                                icon={google}
                                type={GOOGLE}
                                text={
                                    currentUser.google?.accessToken
                                        ? 'Disconnect Google Account'
                                        : 'Connect Google account'
                                }
                                onClick={
                                    currentUser.google
                                        ? unlinkGoogleMutation
                                        : linkGoogleAccount
                                }
                            />
                        )}
                        {currentUser.facebook?.accessToken &&
                            (currentUser?.facebook?.email !==
                                currentUser?.email ||
                                currentUser?.google?.email ===
                                    currentUser?.email ||
                                currentUser?.password) && (
                                <Button
                                    className={`${styles.socialBtn} ${styles.socialBtnActive}`}
                                    icon={facebook}
                                    type={FACEBOOK}
                                    text={'Disconnect Facebook Account'}
                                    onClick={unlinkFacebookMutation}
                                />
                            )}
                        {!currentUser.facebook?.accessToken && (
                            <FacebookLogin
                                appId={process.env.REACT_APP_FB_APP_ID}
                                fields="id,name,email,picture"
                                scope="public_profile,email,ads_read,ads_management"
                                callback={handleFacebookResponse}
                                render={(renderProps) => (
                                    <Button
                                        onClick={renderProps.onClick}
                                        className={styles.socialBtn}
                                        icon={facebook}
                                        type={FACEBOOK}
                                        text={'Connect Facebook Account'}
                                    />
                                )}
                            />
                        )}
                        {/* <Button
              className={styles.socialBtn}
              icon={tiktok}
              type={TIKTOK}
              text={'Sign in with TikTok'}
            /> */}
                    </div>

                    <Button
                        onClick={handleLogOut}
                        icon={quit}
                        className={`${styles.logOutButton} onlyMobileAndTablet`}
                        text={'Log out'}
                        type={SOLID}
                    />
                </div>
            </>
        );
    };

    const _renderSecurity = () => {
        return (
            <>
                {_renderDeleteModal()}
                <div className={styles.content}>
                    <Card className={styles.card} title={'Change Password'}>
                        {currentUser.password && (
                            <div className={`formControls d-flex col `}>
                                <label>Current password</label>
                                <input
                                    type="password"
                                    name="currentPassword"
                                    onChange={formik.handleChange}
                                />
                            </div>
                        )}
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.newPassword &&
                                Boolean(formik.errors.newPassword) &&
                                'error'
                            }`}
                        >
                            <label>New password</label>
                            <input
                                type="password"
                                name="newPassword"
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div
                            className={`formControls d-flex col ${
                                formik.touched.confirmPassword &&
                                Boolean(formik.errors.confirmPassword) &&
                                'error'
                            }`}
                        >
                            <label>Re-enter password</label>
                            <input
                                type="password"
                                name="confirmPassword"
                                onChange={formik.handleChange}
                            />
                        </div>
                        <Button
                            className={styles.saveBtn}
                            text={
                                currentUser.password
                                    ? 'Change password'
                                    : 'Set password'
                            }
                            type={GLOSSY}
                            onClick={formik.handleSubmit}
                        />
                    </Card>
                    <Card
                        className={`${styles.card} ${styles.smallCard}`}
                        title={'Delete account'}
                    >
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Id itaque consequuntur ex error iusto dicta,
                            possimus quaerat, atque eligendi ea nesciunt eius
                            commodi nostrum. Distinctio cum repellat aperiam
                            quis voluptatibus.
                        </p>
                        <button
                            onClick={() => setModalOpen(true)}
                            className={styles.deactivateBtn}
                        >
                            <img src={delete_icon} alt="" />
                            <span>Deactivate Account</span>
                        </button>
                    </Card>
                    <Button
                        onClick={handleLogOut}
                        icon={quit}
                        className={`${styles.logOutButton} onlyMobileAndTablet`}
                        text={'Log out'}
                        type={SOLID}
                    />
                </div>
            </>
        );
    };

    const _renderNotifications = () => {
        return (
            <>
                <div
                    className={`${styles.content} ${styles.contentNotifications}`}
                >
                    <Card
                        className={`${styles.card} ${styles.bigCard}`}
                        title={'Subscription Preferences'}
                    >
                        <span className={styles.notificationSpan}>
                            I would like to receive
                        </span>
                        <div
                            className={`formControls d-flex ${styles.formControls}`}
                        >
                            <Toggle
                                className={styles.toggle}
                                knobColor="white"
                                name={`toggle-1`}
                                borderColor="transparent"
                                leftBackgroundColor="#E2E8F0"
                                rightBackgroundColor="#8C6CED"
                                checked="checked"
                            />
                            <label className={styles.toggleLabel}>
                                Lorem ipsum doler sit alet
                            </label>
                        </div>

                        <div
                            className={`formControls d-flex ${styles.formControls}`}
                        >
                            <Toggle
                                className={styles.toggle}
                                knobColor="white"
                                name={`toggle-2`}
                                borderColor="transparent"
                                leftBackgroundColor="#E2E8F0"
                                rightBackgroundColor="#8C6CED"
                                checked="checked"
                            />
                            <label className={styles.toggleLabel}>
                                Lorem ipsum doler sit alet
                            </label>
                        </div>

                        <div
                            className={`formControls d-flex ${styles.formControls}`}
                        >
                            <Toggle
                                className={styles.toggle}
                                knobColor="white"
                                name={`toggle-3`}
                                borderColor="transparent"
                                leftBackgroundColor="#E2E8F0"
                                rightBackgroundColor="#8C6CED"
                                checked="checked"
                            />
                            <label className={styles.toggleLabel}>
                                Lorem ipsum doler sit alet
                            </label>
                        </div>
                    </Card>
                    <Card
                        className={`${styles.card} ${styles.smallCard}`}
                        title={'Opt me out instead'}
                    >
                        <CheckBox
                            label={'Unsubscribe me from all of the above'}
                            className={styles.checkBox}
                        />
                        <Button
                            className={styles.notificationBtn}
                            text={'Update my preferences'}
                            type={GLOSSY}
                        />
                    </Card>
                    <Button
                        onClick={handleLogOut}
                        icon={quit}
                        className={`${styles.logOutButton} onlyMobileAndTablet`}
                        text={'Log out'}
                        type={SOLID}
                    />
                </div>
            </>
        );
    };

    const [facebookData, setFacebook] = useState({
        adsAccount: currentUser.facebook?.adsAccount || '',
    });
    const [googleData, setGoogle] = useState({
        adsAccount: currentUser.google?.adsAccount || '',
    });
    const [g_loggedInAccounts, setLoggedInAccounts] = useState([]); // google accounts

    const { mutate: updateUserAdsData } = useMutation(
        (data) => editUser(data, currentUser.token),
        {
            onSuccess: (res) => {
                // add health check
                dispatch(getCurrentUser(currentUser.token));
                toast.success('Successfully updated');
            },
        }
    );

    const { isLoading } = useQuery({
        queryFn: () => {
            return {
                g_accounts: AddService.get_g_accounts(
                    currentUser.google?.adsAccount || '',
                    currentUser.google?.adsAccount,
                    currentUser.google?.refreshToken,
                    currentUser.token
                ),
            };
        },
        onSuccess: async (res) => {
            const g_accounts = await res.g_accounts;

            setLoggedInAccounts(g_accounts.data.data.ids);
        },
    });

    const _renderConfiguration = () => {
        const getDataByCompanyName = (companyName) => {
            return companyName === 'Facebook' ? facebookData : googleData;
        };

        const onSectionDataSave = async (companyName) => {
            const _data = getDataByCompanyName(companyName);
            if (companyName === 'Facebook') {
                try {
                    const health = await AddService.f_health(
                        {
                            f_id: currentUser.id,
                            f_account: _data.adsAccount || '',
                        },
                        currentUser.token
                    );
                } catch (e) {
                    toast.error('Invalid Facebook Ads Account');
                    toast.info(
                        'Make sure it is your own account or you have access to it'
                    );
                    return;
                }
                updateUserAdsData(
                    {
                        ...currentUser,
                        facebook: {
                            ...currentUser.facebook,
                            adsAccount: _data.adsAccount || '',
                        },
                    },
                    currentUser.token
                );
            } else if (companyName === 'Google') {
                try {
                    const health = await AddService.g_health(
                        {
                            g_customer_id: _data.adsAccount || '',
                            g_login_customer_id: _data.adsAccount || '',
                            g_refresh_token: currentUser.google?.refreshToken,
                        },
                        currentUser.token
                    );
                } catch (e) {
                    toast.error('Invalid Google Ads Account');
                    toast.info(
                        'Make sure it is your own account or you have access to it'
                    );
                    return;
                }

                updateUserAdsData(
                    {
                        ...currentUser,
                        google: {
                            ...currentUser.google,
                            adsAccount: _data.adsAccount || '',
                        },
                    },
                    currentUser.token
                );
            }
        };

        return (
            <div className={cn(styles.content, styles.configuration)}>
                <ConfigurationSection
                    companyName={'Facebook'}
                    companyLogo={facebook}
                    isButtonDisabled={!facebookData.adsAccount}
                    callback={onSectionDataSave}
                >
                    {!currentUser.facebook?.accessToken && (
                        <div className={styles.signIn}>
                            <FacebookLogin
                                appId={process.env.REACT_APP_FB_APP_ID}
                                fields="id,name,email,picture"
                                scope="public_profile,email,ads_read,ads_management"
                                callback={handleFacebookResponse}
                                render={(renderProps) => (
                                    <Button
                                        onClick={renderProps.onClick}
                                        className={styles.socialBtn}
                                        icon={facebook}
                                        type={FACEBOOK}
                                        text={'Sign in with Facebook'}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className={styles.configuration__column}>
                        <ConfigurationField
                            title={'Ads Account Id'}
                            hint={
                                'This field can be found on AdsManager ➝Account Overview (below you name)'
                            }
                            label={currentUser.facebook?.adsAccount}
                            callbackValue={setFacebook}
                        />
                    </div>
                </ConfigurationSection>
                <ConfigurationSection
                    companyName={'Google'}
                    companyLogo={google}
                    isButtonDisabled={!googleData.adsAccount}
                    callback={onSectionDataSave}
                >
                    {!currentUser.google?.accessToken && (
                        <div className={styles.signIn}>
                            <p className={styles.signIn__title}>
                                You need to sign in if you want to see more
                                information
                            </p>
                            <Button
                                onClick={linkGoogleAccount}
                                icon={google}
                                type={GOOGLE}
                                text={'Sign in with Google'}
                            />
                        </div>
                    )}
                    <div className={styles.configuration__column}>
                        <ConfigurationField
                            title={'Ads Account Id'}
                            hint={
                                'Choose your Google Ads account, you can find it on googleAds after you sign in.'
                            }
                            labels={g_loggedInAccounts}
                            label={currentUser.google?.adsAccount}
                            type={'multiple'}
                            callbackValue={setGoogle}
                        />
                    </div>
                </ConfigurationSection>
                <Button
                    onClick={handleLogOut}
                    icon={quit}
                    className={`${styles.logOutButton} onlyMobileAndTablet`}
                    text={'Log out'}
                    type={SOLID}
                />
            </div>
        );
    };

    const _renderBody = () => {
        switch (currentTab) {
            case 0:
                return _renderProfile();
            case 1:
                return _renderSecurity();
            case 2:
                return _renderNotifications();
            case 3:
                return _renderConfiguration();
            default:
                break;
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h2>User profile</h2>
            </div>
            <div className={`border `}>
                <div className={`backCard d-flex wrap`}>
                    <div className={`whiteBg w-100 ${styles.body}`}>
                        <div className={styles.header}>
                            <div>
                                {tabs.map((t, i) => {
                                    const isActive = i === currentTab;
                                    return (
                                        <div
                                            key={i}
                                            onClick={() => handleTabClick(i)}
                                            className={`${styles.switch} ${
                                                isActive ? styles.active : null
                                            }`}
                                        >
                                            <img
                                                src={
                                                    isActive
                                                        ? t.imgActive
                                                        : t.img
                                                }
                                                alt=""
                                            />
                                            <span>{t.title}</span>
                                        </div>
                                    );
                                })}
                                <div
                                    onClick={handleLogOut}
                                    className={`onlyLg ${styles.logoutContainer}`}
                                >
                                    <img src={quit} alt="" />
                                    <span>Log out</span>
                                </div>
                            </div>
                        </div>
                        {_renderBody()}
                    </div>
                </div>
            </div>
        </div>
    );
}
