import purple_circle from "../media/icons/purple_circle.svg";
import yellow_circle from "../media/icons/yellow_circle.svg";
import priority_low from "../media/icons/priority_low.svg";
import priority_medium from "../media/icons/priority_medium.svg";
import priority_high from "../media/icons/priority_high.svg";

export const LOGIN = "login";
export const REGISTER = "register";
export const WEEK_DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
export const PREVIOUS_DATE = "previous_date";
export const CURRENT_DATE = "current_date";
export const POST_DATE = "post_date";
export const GLOSSY = "glossy";
export const SOLID = "solid";
export const PLUS = "plus";
export const BACK = "back";
export const TRANSPARENT = "transparent";
export const RED = "red";
export const YELLOW = "yellow";
export const INDEX_BASED = "index_based";
export const URL_BASED = "url_based";
export const PURPLE = "purple";
export const GRAY = "gray";
export const HOURS_IN_DAY = [
    {label: "1:00 am", time: "0"},
    {label: "2:00 am", time: "1"},
    {label: "3:00 am", time: "2"},
    {label: "4:00 am", time: "3"},
    {label: "5:00 am", time: "4"},
    {label: "6:00 am", time: "5"},
    {label: "7:00 am", time: "6"},
    {label: "8:00 am", time: "7"},
    {label: "9:00 am", time: "8"},
    {label: "10:00 am", time: "9"},
    {label: "11:00 am", time: "10"},
    {label: "12:00 pm", time: "11"},
    {label: "1:00 pm", time: "12"},
    {label: "2:00 pm", time: "13"},
    {label: "3:00 pm", time: "14"},
    {label: "4:00 pm", time: "15"},
    {label: "5:00 pm", time: "16"},
    {label: "6:00 pm", time: "17"},
    {label: "7:00 pm", time: "18"},
    {label: "8:00 pm", time: "19"},
    {label: "9:00 pm", time: "20"},
    {label: "10:00 pm", time: "21"},
    {label: "11:00 pm", time: "22"},
    {label: "12:00 am", time: "23"},
];
export const DAILY_EVENTS = [
    {
        id: 1,
        name: "Daily call",
        startTime: "2022-07-12T01:03:29+0000",
        endTime: "2022-07-12T02:33:29+0000",
        weekDay: "Monday",
    },
    {
        id: 1,
        name: "Daily call",
        startTime: "2022-07-12T05:05:29+0000",
        endTime: "2022-07-12T07:55:29+0000",
        weekDay: "Tuesday",
    },
    {
        id: 1,
        name: "Daily call",
        startTime: "2022-07-12T06:05:29+0000",
        endTime: "2022-07-12T06:50:29+0000",
        weekDay: "Friday",
    },
];
export const FULL = "full";
export const DATE_ONLY = "date_only";
export const EMPTY = "empty";
export const DATA_TABLE_COLUMNS = [
    {label: "CPM"},
    {label: "CTR"},
    {label: "Clicks"},
    {label: "CPC"},
    {label: "Total Cost"},
    {label: "Total Hours"},
];
export const DATA_TABLE_CAMPAIGNS_COLUMNS = [
    {label: "NAME"},
    {label: "impressions"},
    {label: "cpm"},
    {label: "ctr"},
    {label: "clicks"},
    {label: "cpc"},
    {label: "pur"},
    {label: "cpp"},
    {label: "totalCost"},
    {label: "totalRevenue"},
    {label: "roas"},
];
export const TIKTOK = "tiktok";
export const FACEBOOK = "facebook";
export const GOOGLE = "google";
// export const PORTAL = "portal";
// export const NON_PORTAL = "non_portal";
export const STATUSES = [
    {value: "todo", label: "To do"},
    {value: "inProgress", label: "In progress"},
    {value: "qualityCheck", label: "Quality check"},
    {value: "done", label: "Done"},
];
export const TAGS = [
    {value: "back-end", label: "back-end", color: "#5B7CF2"},
    {value: "internal", label: "internal", color: "#697683"},
    {value: "research", label: "research", color: "#FFCF70"},
    {value: "test", label: "test", color: "#f33"},
];
export const CATEGORIES = [
    {value: "development", label: "Development", icon: purple_circle},
    {value: "management", label: "Management", icon: yellow_circle},
];
export const PRIORITIES = [
    {value: "high", label: "High", icon: priority_high, color: '#ff0000'},
    {value: "medium", label: "Medium", icon: priority_medium, color: '#f0bb0c'},
    {value: "low", label: "Low", icon: priority_low, color: '#10B521'},
];
export const MONTH = [
    {value: 0, label: "January"},
    {value: 1, label: "February"},
    {value: 2, label: "March"},
    {value: 3, label: "April"},
    {value: 4, label: "May"},
    {value: 5, label: "June"},
    {value: 6, label: "July"},
    {value: 7, label: "August"},
    {value: 8, label: "September"},
    {value: 9, label: "October"},
    {value: 10, label: "November"},
    {value: 11, label: "December"},
];
export const YEAR = [
    {value: 2019, label: "2019"},
    {value: 2020, label: "2020"},
    {value: 2021, label: "2021"},
    {value: 2022, label: "2022"},
    {value: 2023, label: "2023"},
];
export const generateYears = () => {
    let currentYear = new Date().getFullYear()
    let years = []
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
        years.push({value: i, label: i.toString()})
    }
    return years
}
