import React, { useCallback, useState } from "react";
import Button from "../../global/button/button";
import styles from "./forumBodyAll.module.scss";
import chat from "../../../media/icons/forum_chat.svg";
import close from "../../../media/icons/close_icon.svg";
import { GLOSSY } from "../../../const";
import ForumPostCard from "../forumPostCard/forumPostCard";
import { useNavigate } from "react-router-dom";
import Modal from "../../global/modal/modal";
import Select from "../../global/select/select";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FORUMS, FORUMS_COUNT } from "../../../rquery/queryKeys";
import { useSelector } from "react-redux";
import ForumService from "../../../services/ForumService";
import profile from "../../../media/icons/user.svg";
import moment from "moment";
import { useFormik } from "formik";
import discussionPostValidation from "../../../validations/forums/discussionPostValidation";
import plus from "../../../media/icons/plus_violet.svg";
import useFileUpload from "../../../hooks/useFileUpload";
import art from "../../../media/mock/art.jpeg";
import red_close from "../../../media/icons/red_close_icon.svg";
import file_icon from "../../../media/icons/file.svg";

export const ForumBodyAll = React.memo(
    ({
        tab,
        categories,
        setCount,
        itemOffset,
        itemsPerPage,
        setItemOffset,
    }) => {
        const navigate = useNavigate();

        const handlePostClick = (id) => {
            navigate(`${id}`);
        };

        const { token, id: userId } = useSelector(
            (state) => state?.Authentication
        );

        const currentPage = Math.floor(itemOffset / itemsPerPage + 1);

        const [isSearchActive] = useState(false);

        const [isModalOpen, setIsModalOpen] = useState(false);

        const handleCloseModal = useCallback(() => {
            setIsModalOpen(false);
        }, []);

        const queryClient = useQueryClient();

        const { mutate } = useMutation(
            (data) => ForumService.post(data, token),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([FORUMS]);
                    queryClient.invalidateQueries([FORUMS_COUNT]);
                    handleCloseModal();
                },
            }
        );

        // const {mutateFilePost} = useMutation(data => ForumService.postFile(data, token), {
        //     onSuccess: ()
        // })

        useQuery(
            [FORUMS_COUNT, token, currentPage, itemOffset, tab],
            () => ForumService.getCount(tab, token),
            {
                enabled: tab != null,
                onSuccess: (data) => {
                    setCount(data?.data.count);
                },
            }
        );

        const { data } = useQuery(
            [FORUMS, token, currentPage, itemOffset, tab],
            () => ForumService.get(tab, currentPage, itemsPerPage, token),
            {
                enabled: tab != null,
            }
        );

        const formik = useFormik({
            enableReinitialize: true,
            initialValues: {
                title: "",
                user: userId,
                category: categories[0]?._id,
                description: "dsada",
            },
            validationSchema: discussionPostValidation,
            onSubmit: (values) => {
                mutate(values);
            },
        });

        const { files, inputRef, handleInputClick, handleInputChange, reset } =
            useFileUpload(null, false);

        const handleFileUpload = (_files) => {
            let formData = new FormData();

            _files.forEach((f) => {
                formData.append("files", f);
            });

            if (formData.getAll("files").length <= 0) return;

            mutate(formData);
            reset();
        };

        const _renderDiscussionModal = () => {
            return (
                <Modal
                    className={styles.modal}
                    onClose={handleCloseModal}
                    open={isModalOpen}
                >
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalTitleContainer}>
                            <p className={styles.modalTitle}>New Discussion</p>
                            <button
                                onClick={handleCloseModal}
                                className="modalClose"
                            >
                                <img src={close} alt="" />
                            </button>
                        </div>
                        <div className={styles.modalDivider} />
                        <div className={styles.modalBodyContainer}>
                            <div
                                className={`formControls d-flex col ${styles.textInput}`}
                            >
                                <label>Category</label>
                                <Select
                                    touched={formik.touched.category}
                                    error={formik.errors.category}
                                    className={styles.select}
                                    options={categories.map((c) => ({
                                        label: c.name,
                                        value: c._id,
                                    }))}
                                    defaultValue={{
                                        label: categories.find(
                                            (c) =>
                                                c._id ===
                                                formik.initialValues.category
                                        )?.name,
                                        value: formik.initialValues.category,
                                    }}
                                    onChange={(c) => {
                                        formik.setFieldValue(
                                            "category",
                                            c.value
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className={`formControls d-flex col ${
                                    formik.touched.title &&
                                    Boolean(formik.errors.title) &&
                                    "error"
                                } ${styles.textInput}`}
                            >
                                <label>Title</label>
                                <input
                                    name="title"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                <span className={styles.validationHelper}>
                                    {formik.touched.title &&
                                        formik.errors.title}
                                </span>
                            </div>
                            <div
                                className={`formControls d-flex col ${styles.textInput}`}
                            >
                                <label>Attachments</label>
                                <div className={styles.attachments}>
                                    {files.map((f, i) =>
                                        f.isLoaded ? (
                                            <div
                                                key={i}
                                                className={styles.imgContainer}
                                            >
                                                <img
                                                    src={URL.createObjectURL(f)}
                                                    alt=""
                                                />
                                                <div
                                                    className={
                                                        styles.description
                                                    }
                                                >
                                                    <span>{f.name}</span>
                                                </div>
                                                <button
                                                    type="button"
                                                    className={
                                                        styles.deleteContainer
                                                    }
                                                >
                                                    <img
                                                        src={red_close}
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                key={i}
                                                className={
                                                    styles.loadingContainer
                                                }
                                            >
                                                <div className={styles.info}>
                                                    <div
                                                        className={
                                                            styles.fileInfo
                                                        }
                                                    >
                                                        <img
                                                            src={file_icon}
                                                            alt=""
                                                        />
                                                        <span>{f.name}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.empty}>
                                                    <span>40%</span>
                                                    <div
                                                        className={
                                                            styles.loaded
                                                        }
                                                        style={{ width: "40%" }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div
                                    onClick={handleInputClick}
                                    className={styles.fileZone}
                                >
                                    <input
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onChange={handleInputChange}
                                        hidden
                                        type="file"
                                        ref={inputRef}
                                    />
                                    <img src={plus} alt="" />
                                </div>
                            </div>

                            <Button
                                type={GLOSSY}
                                text={"Create"}
                                onClick={formik.handleSubmit}
                            />
                        </div>
                    </div>
                </Modal>
            );
        };

        return (
            <div
                className={`border border-xs d-flex ai-end ${styles.borderForum}`}
            >
                {_renderDiscussionModal()}
                <div className={`backCard d-flex wrap ${styles.container}`}>
                    <div className={styles.top}>
                        {/* <Search
              onClick={() => setIsSearchActive(true)}
              className={styles.searchContainer}
              onFocusOut={() => setIsSearchActive(false)}
            /> */}
                        {(!isSearchActive || window.innerWidth > 991) && (
                            <Button
                                onClick={() => setIsModalOpen(true)}
                                className={styles.chatBtn}
                                type={GLOSSY}
                                icon={chat}
                                text={"+ New Thread"}
                            />
                        )}
                    </div>
                    <div className={styles.postContainer}>
                        {data?.data.map((d, i) => {
                            return (
                                <ForumPostCard
                                    key={i}
                                    length={data?.data.length}
                                    setItemOffset={setItemOffset}
                                    currentPage={currentPage}
                                    itemsPerPage={itemsPerPage}
                                    categories={categories}
                                    onClick={() => handlePostClick(d._id)}
                                    title={d.title}
                                    badge={categories?.find((x) => x.isActive)?.name}
                                    id={d._id}
                                    categoryId={d.category._id}
                                    img={d.user.picture?.url || d.files[0]?.path}
                                    replies={d.replies.length}
                                    by={d.user?.name}
                                    // participants={324}
                                    // participantsImg={[
                                    //   { img: profile },
                                    //   { img: profile },
                                    //   { img: profile },
                                    // ]}
                                    ago={moment().diff(d.createdAt, "days")}
                                    byImg={d.user.picture?.url || profile}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
);

export default ForumBodyAll;
