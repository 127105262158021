import React, {Fragment} from "react";
import {INDEX_BASED, TRANSPARENT, URL_BASED} from "../../../const";
import styles from "./switchTab.module.scss";
import Button from "../button/button";

export default function SwitchTab({
                                      labels,
                                      currentTab,
                                      handleTabClick,
                                      type,
                                      className,
                                      path,
                                      routingType = INDEX_BASED,
                                  }) {
    const _switchLogic = () => {
        switch (routingType) {
            case INDEX_BASED:
                return labels.map((l, i) => {
                    return (
                        <Fragment key={i}>
                            <Button
                                key={i}
                                className={currentTab === i ? styles.active : ""}
                                icon={l.icon}
                                text={l.label}
                                type={currentTab === i ? type : TRANSPARENT}
                                onClick={() => handleTabClick(i)}
                            />
                        </Fragment>
                    );
                });
            case URL_BASED:
                return labels.map((l, i) => {
                    return (
                        <Fragment key={i}>
                            <Button
                                key={i}
                                className={path.includes(l?.index) ? styles.active : ""}
                                icon={l.icon}
                                text={l.label}
                                type={path.includes(l?.index) ? type : TRANSPARENT}
                                onClick={() => handleTabClick(l.index)}
                            />
                        </Fragment>
                    );
                });

            default:
                break;
        }
    };

    return (
        <div className={`${styles.container} ${className}`}>{_switchLogic()}</div>
    );
}
