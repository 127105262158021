import styles from './configurationField.module.scss';
import { useEffect, useRef, useState, forwardRef } from 'react';
import cn from 'classnames';

// import icons
import doneIcon from '../../../../media/icons/done.png';
import questionIcon from '../../../../media/icons/question.svg';

const BasicInput = ({ label, callbackValue }) => {
    const [value, setValue] = useState(label || '');

    // Set value for input
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        callbackValue({
            // callback for parent component
            adsAccount: value,
        });
    }, [value]);

    return (
        <input
            className={styles.field}
            type="text"
            value={value}
            onChange={handleChange}
        />
    );
};

const MultiInput = forwardRef(({ labels, callbackValue }, ref) => {
    const [value, setValue] = useState(labels[0] || '');
    const [isOpened, setIsOpened] = useState(false);

    const closeRestLabels = () => {
        const inputs = ref.current.parentNode.querySelectorAll(
            `.${styles.opened}`
        );

        inputs.forEach(
            (
                input // close all opened inputs
            ) => {
                input.classList.remove(styles.opened);
            }
        );
    };

    const onToggle = () =>
        // toggle opened input
        {
            closeRestLabels();
            setIsOpened(!isOpened);
        };

    useEffect(() => {
        const inputs = ref.current.parentNode.querySelectorAll('input');

        // callback for parent component
        value &&
            callbackValue({
                adsAccount: inputs[0].value,
            });
    }, [value]);

    return (
        <div
            className={cn(styles.multiInput, {
                [styles.opened]: isOpened,
            })}
            onClick={onToggle}
        >
            <input
                className={styles.field}
                type="text"
                placeholder={value}
                value={value}
            />
            <div className={styles.multiInput__arrow} />
            <div className={styles.multiInput__labels}>
                {labels.length &&
                    labels.map((label, index) => {
                        return (
                            <div
                                key={index}
                                className={styles.multiInput__labels__label}
                                onClick={() => {
                                    setValue(label);
                                    setIsOpened(false);
                                }}
                            >
                                {label}
                                <img
                                    className={cn(
                                        styles.multiInput__labels__label__icon,
                                        {
                                            [styles.active]: label === value,
                                        }
                                    )}
                                    src={doneIcon}
                                    alt="icon"
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
});

const ConfigurationField = ({
    title,
    hint,
    label,
    labels,
    type,
    callbackValue,
}) => {
    const parent = useRef(null);
    const [filedValue, setFiledValue] = useState(label || '');

    return (
        <div className={styles.container} ref={parent}>
            <div className={styles.top}>
                <div className={styles.top__title}>{title}</div>
                <div className={styles.top__question}>
                    <div className={styles.top__question__icon}>
                        <img src={questionIcon} alt="icon" />
                    </div>
                    <div className={styles.top__question__hint}>
                        <p>{hint}</p>
                    </div>
                </div>
            </div>
            {type === 'single' ? (
                <BasicInput label={filedValue} callbackValue={callbackValue} />
            ) : (
                <MultiInput
                    labels={labels}
                    callbackValue={callbackValue}
                    ref={parent}
                />
            )}
        </div>
    );
};

ConfigurationField.defaultProps = {
    type: 'single',
};

export default ConfigurationField;
