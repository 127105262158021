import * as Yup from 'yup';

const profileValidationSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Required!')
    .typeError('Required!')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!'),

  newPassword: Yup.string()
    .min(3, 'Password is too short - should be at least 3 chars!')
    .max(255, 'Password is too long - should not exceed 255 chars!')
    .oneOf([Yup.ref('confirmPassword'), null], 'Passwords must match!')
    .required('Required!')
    .typeError('Required!'),
});

export default profileValidationSchema;
