import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "./routes";
import styles from "./breadCrumb.module.scss";
import { useNavigate } from "react-router-dom";

export default function BreadCrumb({
  className,
  hasDefaultRoute = true,
  additionalBreadCrumbs,
  blackList,
}) {
  const breadcrumbs = useBreadcrumbs(routes);
  let path = window.location.pathname.split("/").pop();
  const navigate = useNavigate();
  return (
    <div className={`${styles.container} ${className}`}>
      {[...breadcrumbs, ...(additionalBreadCrumbs ?? [])].map(
        ({ breadcrumb, match }, index) => {
          if (
            (match.pathname === "/" && !hasDefaultRoute) ||
            (blackList && blackList.some((b) => b === index))
          ) {
            return <></>;
          }
          let active = match.pathname.includes(path) || match?.isActive;
          return (
            <span
              key={index}
              onClick={() => !active && navigate(-1)}
              className={active ? styles.active : null}
            >
              {index > !hasDefaultRoute && " / "} {match.route?.breadcrumb}
            </span>
          );
        }
      )}
    </div>
  );
}
