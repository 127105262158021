import React, {useState, useCallback, useEffect, useRef} from 'react';
import styles from './attachment.module.scss';

import {FILES} from '../../../rquery/queryKeys';
import {GLOSSY} from '../../../const';
import FileService from '../../../services/FileService';

import cn from 'classnames';
import Button from '../../global/button/button';
import Preloader from '../../preloader/preloader';

import Modal from '../../global/modal/modal';
import {toast} from 'react-toastify';
import {useTitle} from '../../../hooks/useTitle';
import {useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import useFileUpload from '../../../hooks/useFileUpload';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import {useMutation, useQuery} from 'react-query';

// ICONS
import close from '../../../media/icons/close_icon.svg';
import fileIcon from '../../../media/icons/file_icon.svg';
import createFolderIcon from '../../../media/icons/create_folder_icon.svg';

import Folders from './folders/folders';
import Settings from './settings/settings';
import Modals from '../createFolderModal/createFolderModal';

const Actions = ({setEditId, setModalType}) => {
    return (
        <div className={styles.actions}>
            <div className={styles.action} onClick={() => {
                setModalType('create')
                setEditId(0)
            }}>
                <img src={createFolderIcon} alt='' className={styles.icon}/>
                <span className={styles.action__name}>Add folder</span>
            </div>
        </div>
    )
}

const BreadCrumbs = ({currentPath, setCurrentPath, setCurrentFolderId}) => {
    const isHavePrevFolder = currentPath.length > 1
    const breadcrumbsRef = useRef(null);

    return (
        <div className={styles.breadcrumbs} ref={breadcrumbsRef}>
            {
                isHavePrevFolder &&
                <div className={styles.breadcrumbs__backBtn} onClick={() => {
                    setCurrentPath(currentPath.slice(0, currentPath.length - 1))
                    setCurrentFolderId(currentPath[currentPath.length - 2].id || false)
                }}>
                    <div className={styles.breadcrumbs__backBtn__icon}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='30'
                             height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='#373D59' fill='none'
                             strokeLinecap='round' strokeLinejoin='round'>
                            <path stroke='none' d='M0 0h24v24H0z' fill='none'/>
                            <line x1='5' y1='12' x2='19' y2='12'/>
                            <line x1='5' y1='12' x2='11' y2='18'/>
                            <line x1='5' y1='12' x2='11' y2='6'/>
                        </svg>
                    </div>
                    <div className={styles.breadcrumbs__backBtn__name}>
                        Back
                    </div>
                </div>
            }
            {
                currentPath.map((p, i) => {
                    return (
                        <>
                            <span className={cn(styles.breadcrumbs__link, {
                                [styles.breadcrumbs__link__active]: i === currentPath.length - 1
                            })}
                                  onClick={() => {
                                      setCurrentPath(currentPath.slice(0, i + 1))
                                      setCurrentFolderId(p.id || false)
                                  }}
                                  key={i}>
                                {p.name === 'attachments' ? 'All attachments' : p.name}
                            </span>
                            {
                                i !== currentPath.length - 1 &&
                                <span className={styles.breadcrumbs__separator}> / </span>
                            }
                        </>
                    )
                })
            }
        </div>
    )
}


export default function Attachment() {
    useTitle('Attachments');

    const {token} = useSelector(state => state.Authentication)

    const currentProjectId = useParams().id;
    const [searchParams] = useSearchParams({isEmpty: false});
    const isEmpty = searchParams.get('isEmpty') === 'true';

    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [currentPath, setCurrentPath] = useState([{name: 'attachments', id: null}])
    const [currentFolderId, setCurrentFolderId] = useState(null)

    const [isPreloaderActive, setPreloaderActive] = useState(false)
    const [isAttachModalOpen, setAttachModalOpen] = useState(false);

    const [activeFile, setActiveFile] = useState()
    const [editId, setEditId] = useState(null)
    const [modalType, setModalType] = useState(null)

    const {mutate: getAttacmentsForCurrentFolder} = useMutation((data) => {
        const _currentFolderId = data?.folderId ?? currentFolderId
        return FileService.get(currentProjectId, token, _currentFolderId)
    }, {
        onSuccess: (data) => {
            setFolders(data.data.folders)
            setFiles(data.data.files)
        },
        onError: () => {
            toast.error('Something went wrong')
        },
    });


    const {mutate} = useMutation((data) => {
        return FileService.post(currentProjectId, data.formData, data.currentFolderId, token)
    }, {
        onSuccess: (data) => {
            getAttacmentsForCurrentFolder()
            setPreloaderActive(false)
        },
        onError: () => {
            toast.error('Something went wrong')
            setPreloaderActive(false)
        }
    });

    const {mutate: mutateDelete} = useMutation((data) => {
        return FileService.deleteById(currentProjectId, data.id, token)
    }, {
        onSuccess: (data) => {
            toast.success('File deleted successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
            setFiles(data.data.files)
        }
    });

    const {mutate: uploadFolder} = useMutation((data) => {
        return FileService.createFolder(currentProjectId, currentFolderId, data, token)
    }, {
        onSuccess: () => {
            toast.success('Folder created successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
        },
        enabled: !!currentFolderId
    });

    const {mutate: updateFolder} = useMutation((data) => {
        const _data = {
            name: data.name,
            color: data.color,
            category: data.category,
        }
        return FileService.updateFolder(currentProjectId, data.folderId, _data, token)
    }, {
        onSuccess: () => {
            toast.success('Folder updated successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
        }
    })

    const {mutate: renameFile} = useMutation((data) => {
        return FileService.renameFile(currentProjectId, data.fileId, data.name, token)
    }, {
        onSuccess: () => {
            toast.success('File renamed successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
        },
    })

    const {mutate: _deleteFolder} = useMutation((folderId) => {
        return FileService.deleteFolder(currentProjectId, folderId, token)
    }, {
        onSuccess: () => {
            toast.success('Folder deleted successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
        }
    });


    const handleDeleteClick = (id, cb) => {
        mutateDelete({id, cb})
    }

    const handleFileUpload = (_files) => {
        setPreloaderActive(true);
        let formData = new FormData();

        _files.forEach(f => {
            formData.append('files', f)
        })

        if (formData.getAll('files').length <= 0) return;

        mutate({
            formData,
            currentFolderId,
        })
        reset();
    }

    const handleCloseModal = useCallback(() => {
        setAttachModalOpen(false)
    }, []);

    const handleOpenModal = (file) => {
        setAttachModalOpen(true);
        setActiveFile(file)
    }

    const createFolder = ({data}) => {
        const model = {
            name: data.name || 'New folder',
            color: data.color || '#fff',
            category: data.category || 'folder',
        }
        uploadFolder(model)
    }

    const editFolder = (id, data) => {
        const model = {
            name: data.name || 'New folder',
            color: data.color || '#fff',
            category: data.category || 'folder',
        }
        uploadFolder(model)
    }

    const {mutate: _moveFile} = useMutation((data) => {
        return FileService.moveFile(currentProjectId, data, token)
    }, {
        onSuccess: () => {
            toast.success('File moved successfully')
            getAttacmentsForCurrentFolder({
                folderId: currentFolderId || false
            })
        },
        onError: () => {
            toast.error('Something went wrong')
            setFiles(data.data.files)
        }
    })

    const {
        inputRef,
        reset,
        handleInputChange,
    } = useFileUpload(handleFileUpload);

    const {data, refetch: refetchAttacments} = useQuery([FILES, currentProjectId, token], () => {
        return FileService.get(currentProjectId, token)
    }, {
        onSuccess: (data) => {
            setFolders(data.data.folders)
            setFiles(data.data.files)
        },
        refetchOnWindowFocus: false,
        saleTime: Infinity,
    });


    const openFolder = (folderId, folderName) => {
        setCurrentPath(prev => [...prev, {name: folderName, id: folderId}])
        setCurrentFolderId(folderId)
    }

    useEffect(() => {
        getAttacmentsForCurrentFolder({
            folderId: currentFolderId || false // if currentFolderId is null, then we need to get files from root folder
        })
    }, [currentFolderId])

    const _renderAttachModal = () => {
        return (
            <Modal
                onClose={handleCloseModal}
                open={isAttachModalOpen}
                className={styles.modalAttach}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <span>{activeFile?.name}</span>
                        <button onClick={handleCloseModal}>
                            <img src={close} alt=''/>
                        </button>
                    </div>
                    <div className={styles.modalBody}>
                        <img src={activeFile?.path} alt={activeFile?.name}/>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderWhenEmpty = () => {
        return (
            <div className={styles.containerEmpty}>
                <Button
                    className={styles.centralButton}
                    text={'Add new attachments'}
                    type={GLOSSY}
                />
            </div>
        );
    };
    const imageFormats = ['png', 'jpeg', 'jpg', 'gif', 'webp'];
    const _renderAttachment = () => {
        const [settingsId, setSettingsId] = useState(-1);
        const settingsRef = React.useRef();

        const onDotsClick = (id) => {
            if (settingsId === id) return setSettingsId(null);
            setSettingsId(id)
        }

        useOnClickOutside(settingsRef, (e) => {
            if (e.target.closest(`.${styles.attachment__dots}`)) return;
            if (setSettingsId) setSettingsId(-1);
        })

        return files?.length ? files.map((d, idx) => {
            const imageFormat = d.path.split('.').pop();
            const isImage = imageFormats.includes(imageFormat);
            return (
                <div key={d._id} className={styles.attachment} style={{order: idx + 1}}>
                    <div className={styles.attachment__dots} onClick={(e) => {
                        onDotsClick(d._id)
                    }}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                    <Settings
                        settingsId={settingsId}
                        onDelete={() => {
                            handleDeleteClick(d._id)
                        }}
                        setModalType={setModalType}
                        setEditId={setEditId}
                        setSettingsId={setSettingsId}
                        currentId={d._id}
                        type={'file'}
                    />
                    <div onClick={() => handleOpenModal({name: d.name, path: d.path})} className={styles.contentImg}>
                        {
                            isImage ? <img src={d.path} alt={d.name}/> : <img src={fileIcon} alt={d.name}/>
                        }
                    </div>
                    <div className={styles.attachmentFooter}>
                        <p>{d.name}</p>
                    </div>
                </div>
            );
        }) : null;
    };

    const _renderAttachments = () => {
        return (
            <div className={styles.wrapp}>
                <div className={styles.top}>
                    <BreadCrumbs currentPath={currentPath}
                                 setCurrentPath={setCurrentPath}
                                 refetchAttacments={refetchAttacments}
                                 getAttacmentsForCurrentFolder={getAttacmentsForCurrentFolder}
                                 setCurrentFolderId={setCurrentFolderId}
                    />
                    <Actions
                        setEditId={setEditId}
                        setModalType={setModalType}
                        uploadFile={() => inputRef.current.click()}
                    />
                </div>

                <div className={styles.container}>
                    {_renderAttachModal()}
                    {_renderAttachment()}
                    <Folders folders={folders}
                             setEditId={setEditId}
                             setModalType={setModalType}
                             deleteFolder={_deleteFolder}
                             openFolder={openFolder}
                             styles={styles}
                    />
                    <Modals editId={editId}
                            setEditId={setEditId}
                            modalType={modalType}
                            setModalType={setModalType}
                            folders={folders}
                            files={files}
                            createFolder={createFolder}
                            editFolder={editFolder}
                            updateFolder={updateFolder}
                            deleteFolder={_deleteFolder}
                            moveFile={_moveFile}
                            renameFile={renameFile}
                            currentFolderId={currentFolderId}
                            getAttacmentsForCurrentFolder={getAttacmentsForCurrentFolder}
                    />
                    <div className={`${styles.attachment} ${styles.new}`}>
                        <Preloader isActive={isPreloaderActive}/>
                        <input hidden multiple ref={inputRef} onChange={(e) => {
                            handleInputChange(e)
                        }} type='file'/>
                        <div className={styles.plus}>
                            <span className={styles.lineOne}></span>
                            <span className={styles.lineTwo}></span>
                        </div>
                        <div className={styles.text}>
                            <div className={styles.title}>Add file</div>
                            <div className={styles.description}>jpeg, png, pdf, doc less than 20MB</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return <>{isEmpty ? _renderWhenEmpty() : _renderAttachments()}</>;
}
