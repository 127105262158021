import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from "react-query";
import MessageService from "../../../services/MessageService";
import Preloader from "../../preloader/preloader";


// preloader - boolean, includes a preloader in the component and makes it dependent on getting the user's message from the server
function WelcomeMessage( { preloader } ) {

    const { token } = useSelector((state) => state.Authentication); // get user token from store

    const LOCAL_WElCOME_MESSAGE = "Welcome to Brandflow!🎉\nLet's get started."; // default message

    const [welcomeMessage, setWelcomeMessage] = React.useState('')

    const { isLoading, data } = useQuery([token], {
        queryFn: () => {
            if (welcomeMessage) { return }
            return MessageService.get(token)
        },
    });

    const { mutate } = useMutation( // mutation for updating the user's message
        (message) => MessageService.update(message, token),
        {
            // onSuccess: (res) => {
            //     console.log(res);
            // },
            // onError: (err) => {
            //     console.log(err);
            // }
        }
    );

    useEffect(() => {
        if (!isLoading && !welcomeMessage) { // if the message has been received from the server, then we write it to the state
            setWelcomeMessage(data?.data.message);
            if (data === undefined)
            { // if the server did not return a message, then we write the default message to the state
                setWelcomeMessage(LOCAL_WElCOME_MESSAGE);
            }
        }
    }, [isLoading])


    const handleTextAreaChange = (e) => {
        setWelcomeMessage(e.target.value);
    }

    const updateWelcomeMessage = async () => {
        if (welcomeMessage !== data?.data.message && welcomeMessage !== "") // if the message has changed, then we send a request to the server
        {
            mutate(welcomeMessage);
        }
    }

    return (
        <div className="box welcomemessage d-flex col">
            {preloader && <Preloader isActive={isLoading} borderRadius/>}
            <div className="boxHeader">

            </div>
            <div className="boxBody">
        <textarea
            className="greeting"
            value={welcomeMessage}
            onBlur={updateWelcomeMessage}
            onChange={handleTextAreaChange}
        />
            </div>

        </div>
    );
}

WelcomeMessage.defaultProps = {
    preloader: false,
}

export default WelcomeMessage;
