import React from "react";
import { GLOSSY } from "../../../const";
import useSwitchTab from "../../../hooks/useSwitchTab";
import SwitchTab from "../../global/switchTab/switchTab";
import styles from "./results.module.scss";
import calendar_static from "../../../media/icons/calendar_static.svg";
import right_arrow from "../../../media/icons/right_arrow.svg";
import left_arrow from "../../../media/icons/left_arrow.svg";
import Chart from "../../global/chart/chart";

export default function Results() {
  const { currentTab, handleTabClick } = useSwitchTab(0);
  return (
    <div className={`box d-flex col ${styles.container}`}>
      <div className={styles.header}>
        <div className={styles.up}>
          <h4 className="boxTitle">Stats</h4>
          <SwitchTab
            type={GLOSSY}
            labels={[
              { label: "Weekly" },
              { label: "Monthly" },
              { label: "Yearly" },
            ]}
            currentTab={currentTab}
            handleTabClick={handleTabClick}
            className={styles.SwitchTab}
          />
        </div>
        <div className={`${styles.down} ${styles.nav}`}>
            <div className={styles.sphere}>
              <img src={left_arrow} alt="" />
            </div>
            <div className={styles.date}>
              <img src={calendar_static} alt="" />
              <p>January 2022</p>
            </div>
            <div className={styles.sphere}>
              <img src={right_arrow} alt="" />
            </div>
        </div>
      </div>
      <div className="boxBody">
        <div className={styles.chart}>
          <div className={styles.chartHeader}>
            <div className={styles.total}>
              <span>Total spent:</span>
              <div className={styles.sum}>$250.000</div>
            </div>
            <div className={styles.total}>
              <span>Total revenue:</span>
              <div className={styles.sum}>$250.000</div>
            </div>
            <div className={styles.total}>
              <span>Profit:</span>
              <div className={styles.sum}>$250.000</div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <div className={styles.chartBody}>
              <Chart />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
