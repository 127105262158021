import React from "react";
import PlusIcon from "../../../media/icons/pluse_white.svg";
import styles from "./adminParts.module.scss";

export const ButtonPlus = ({ className = " " }) => {
  return (
    <button className={styles.addBtn + " " + className}>
      Add new {" "}
      <img src={PlusIcon} alt="" />
    </button>
  );
};

export const Checkbox = ({ className, onChange, onClick, value }) => {
  return (
    <div className={"customCheckbox " + styles.checkBox + " " + className}>
      <label>
        {" "}
        <input
          onClick={onClick}
          onChange={onChange}
          checked={value}
          type="checkbox"
        />{" "}
        <span></span>{" "}
      </label>
    </div>
  );
};
