import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import AuthenticationService from '../../services/AuthenticationService';

const initialState = {
    token: null,
    resetPasswordToken: null,
    toResetUserId: null,
    google: {
        accessToken: null,
        refreshGoogleToken: null,
    },
    facebook: {
        accessToken: null,
    },
    email: null,
    id: null,
    isAdmin: null,
    name: null,
    password: null,
    active: null,
    __v: null,
    _id: null,
};

export const login = createAsyncThunk('login', async (data) => {
    const login_res = await AuthenticationService.login(data);
    console.log('login', login_res)
    const get_current_user_res = await AuthenticationService.getCurrentUser(
        login_res.data.token
    );
    return {...login_res.data, ...get_current_user_res.data.user};
});

export const refreshGoogleToken = createAsyncThunk(
    'refreshGoogleToken',
    async (data) => {
        const refresh_token_res =
            await AuthenticationService.refreshGoogleToken(data);
        return {
            google: {access_token: refresh_token_res.data.access_token},
        };
    }
);

export const loginWithSocial = createAsyncThunk(
    'loginWithSocial',
    async (data) => {
        if (data.service === 'google') {
            const get_current_user_res =
                await AuthenticationService.getCurrentUser(data.token);
            return {token: data.token, ...get_current_user_res.data.user};
        } else if (data.service === 'facebook') {
            const get_current_user_res =
                await AuthenticationService.getCurrentUser(data.token);
            return {token: data.token, ...get_current_user_res.data.user};
        }
    }
);

export const getCurrentUser = createAsyncThunk(
    'getCurrentUser',
    async (token) => {
        const res = await AuthenticationService.getCurrentUser(token);
        return res.data;
    }
);

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: {
        logOut: () => initialState,
        setResetPasswordToken: (state, action) => ({
            ...state,
            resetPasswordToken: action.payload.verificationCode,
            toResetUserId: action.payload.userId,
        }),
        clearResetPasswordToken: (state) => ({
            ...state,
            resetPasswordToken: null,
            toResetUserId: null,
        }),
        updateToken: (state, action) => {
            return {
                ...state,
                token: action.payload.token,
                google: {
                    ...state.google,
                    accessToken: action.payload.access_token
                }
            }
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            return {...state, ...action.payload};
        },
        [loginWithSocial.fulfilled]: (state, action) => {
            return {...state, ...action.payload};
        },

        [refreshGoogleToken.fulfilled]: (state, action) => {
            return {...state, ...action.payload};
        },
        [getCurrentUser.fulfilled]: (state, action) => {
            const {
                email,
                id,
                isAdmin,
                name,
                password,
                __v,
                _id,
                google,
                facebook,
                picture,
                active,
                company,
                country,
                birthday,
                phone,
            } = action.payload.user;
            return {
                ...state,
                email,
                id,
                isAdmin,
                name,
                password,
                __v,
                _id,
                google,
                facebook,
                picture,
                active,
                company,
                birthday,
                country,
                phone,
            };
        },
    },
});

export const {logOut, setResetPasswordToken, clearResetPasswordToken, updateToken} =
    authenticationSlice.actions;
export default authenticationSlice.reducer;
