import React, {useEffect} from "react";
import styles from "./task.module.scss";
import TaskContainer from "./taskContainer/taskContainer";
import {DragDropContext} from "react-beautiful-dnd";
import {useState} from "react";
import {useLayoutEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination, Controller, Thumbs} from "swiper";
import {useTitle} from "../../../hooks/useTitle";
import {useQuery, useMutation, useQueryClient} from "react-query";
import {PROJECT, PROJECTS, TASK, TASKS} from "../../../rquery/queryKeys";
import TaskService from "../../../services/TaskService";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import ProjectService from "../../../services/ProjectService";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

export default function Task() {
    useTitle("Tasks");
    const {id: projectId} = useParams();

    const initialColumns = {
        todo: {
            id: "todo",
            title: "To do",
            list: [],
        },
        inProgress: {
            id: "inProgress",
            title: "In progress",
            list: [],
        },
        qualityCheck: {
            id: "qualityCheck",
            title: "Quality Check",
            list: [],
        },
        done: {
            id: "done",
            title: "Done",
            list: [],
        },
    };

    const [columns, setColumns] = useState(initialColumns);
    const {token} = useSelector((state) => state.Authentication);
    const queryClient = useQueryClient();

    const {data, refetch} = useQuery([TASKS, token, projectId], () => TaskService.get(projectId, token),
        {
            onSuccess: (data) => {
                const _modifiedColumns = initialColumns;
                Object.values(_modifiedColumns).forEach((c) => {
                    const _matches = data.data.filter((t) => t.status === c.id);
                    if (!_matches.length) return;
                    c.list = _matches;
                });
                setColumns(_modifiedColumns);
            },
            refetchOnWindowFocus: false,
        }
    );

    const {mutate: updateProjectTasks} = useMutation(
        (data) => {
            return ProjectService.updateProjectTasks(projectId, { tasks: data }, token)
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([PROJECTS]);
                queryClient.invalidateQueries([PROJECT]);
            },
        }
    );


    const {mutate} = useMutation(
        ({data, taskId}) => {
            return TaskService.put(taskId, data, token)
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([TASKS]);
                queryClient.invalidateQueries([TASK]);
            },
        }
    );

    const [isMobile, setMobile] = useState();

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 576) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useLayoutEffect(() => {
        handleResize();
    }, []);


    useEffect(() => {
        const tasks = Object.values(columns).reduce((acc, col) => {
            return [...acc, ...col.list];
        }   , []);

        updateProjectTasks(tasks);
    }, [columns])

    const _renderTaskContainers = () => {
        window.addEventListener("resize", function () {
            handleResize();
        });

        return (
            <>
                {!isMobile ? (
                    <div className={styles.container}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {Object.values(columns).map((column, i) => {
                                return (
                                    <TaskContainer
                                        key={i}
                                        id={column.id}
                                        cards={column.list.sort((a, b) => a.order - b.order)}
                                        title={column.title}
                                        color={column.color}
                                        refetch={refetch}
                                    />
                                );
                            })}
                        </DragDropContext>
                    </div>
                ) : (
                    <div className={`sliderTask ${styles.container}`}>
                        <Swiper
                            centeredSlides={true}
                            pagination
                            spaceBetween={10}
                            slidesPerView={1}
                        >
                            {Object.values(columns).map((column, i) => {
                                return (
                                    <SwiperSlide className={styles.swiperSlide} key={i}>
                                        <TaskContainer
                                            isDnd={false}
                                            key={i}
                                            id={column.id}
                                            cards={column.list}
                                            title={column.title}
                                            color={column.color}
                                            refetch={refetch}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                )}
            </>
        );
    };

    const onDragEnd = ({source, destination, draggableId}) => {

        if (destination === undefined || destination === null) return null;


        if (
            source.droppableId === destination.droppableId &&
            destination.index === source.index
        ) return null;


        const start = columns[source.droppableId];
        const end = columns[destination.droppableId];

        if (start === end) {
            const newList = start.list.filter((_, idx) => idx !== source.index);
            newList.splice(destination.index, 0, start.list[source.index]);
            newList.forEach((t, idx) => {
                t.order = idx;
            })

            const newCol = {
                id: start.id,
                list: newList,
                title: start.title,
            };

            setColumns((state) => ({...state, [newCol.id]: newCol}));
            return null;
        } else {
            const newStartList = start.list.filter((_, idx) => idx !== source.index);
            newStartList.forEach((t, idx) => {
                t.order = idx;
            });

            const newStartCol = {
                id: start.id,
                list: newStartList,
                title: start.title,
            };

            const newEndList = end.list;

            newEndList.splice(destination.index, 0, start.list[source.index]);
            newEndList.forEach((t, idx) => {
                t.order = idx;
            });

            const newEndCol = {
                id: end.id,
                list: newEndList,
                title: end.title,
            };

            setColumns((state) => ({
                ...state,
                [newStartCol.id]: newStartCol,
                [newEndCol.id]: newEndCol,
            }));

            mutate({
                data: {status: destination.droppableId},
                taskId: draggableId,
            });
            return null;
        }
    };

    return _renderTaskContainers();
}
