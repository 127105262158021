import http from "../http-common";

const get = (token) => {
    return http.get("/forum/category", {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const post = (data, token) => {
    return http.post("/forum/category", data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const put = (data, id, token) => {
    return http.put(`/forum/category/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deleteById = (id, token) => {
    return http.delete(`/forum/category/${id}`);
};

const CategoryService = {
    get,
    post,
    put,
    deleteById
};

export default CategoryService;
