import styles from './fileTree.module.scss';
import React, { useEffect, useState, memo }  from 'react';
import FileService from '../../../../services/FileService';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import {useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import cn from 'classnames';
import Button from '../../../global/button/button';

// Icons
import arrowIcon from '../../../../media/icons/arrow_right.svg';
import arrowUpIcon from '../../../../media/icons/arrow_up.svg';
import folderIcon from '../../../../media/icons/folder_icon.svg';

const Breadcrumbs = ({ currentFolderId, setCurrentFolderId, allFolders, getCurrentFolders }) => {

    const [currentFolder, setCurrenFolder] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([{
        name: 'All attachments',
        _id: null,
    }]);

    useEffect(() => {
        const currentFolder = allFolders.find(folder => folder._id === currentFolderId)
        setCurrenFolder(currentFolder)
    }, [allFolders, currentFolderId])

    useEffect(() => {
        if (!currentFolder) return;

        const breadcrumbs = [
            {
                name: 'All attachments',
                _id: null,
            },
            ...currentFolder.location.map(location => ({
                name: location.name,
                _id: location.id,
            })),
            {
                name: currentFolder.name,
                _id: currentFolder._id,
            }
        ];

        setBreadcrumbs(breadcrumbs.slice(0, 1).concat({name: '...', _id: null}, breadcrumbs.slice(-1)));
    }, [currentFolder]);

    return (
        <div className={styles.breadcrumbs}>
            {
                breadcrumbs.length > 1 &&
                <img className={styles.breadcrumbs__levelUp} src={arrowUpIcon} alt='arrowUpIcon' onClick={() => {
                    setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.length - 1))
                    getCurrentFolders({folderId: currentFolder.parent_folder || null, force: true})
                    setCurrentFolderId(currentFolder.parent_folder || null)
                }}/>
            }
            {breadcrumbs.map((folder, idx) => (
                <>
                    <div className={styles.breadcrumbs__folder} onClick={() => {
                        setBreadcrumbs(breadcrumbs.slice(0, idx + 1))
                        getCurrentFolders({folderId: folder._id || null, force: true})
                        setCurrentFolderId(folder._id || null)
                    }}>
                        <span className={cn(styles.breadcrumbs__folder__name, {
                            [styles.breadcrumbs__folder__name__active]: idx === breadcrumbs.length - 1
                        })}>
                            {folder.name}
                        </span>
                    </div>

                    {
                        idx !== breadcrumbs.length - 1 &&
                        <div className={styles.breadcrumbs__divider}>/</div>
                    }
                </>
            ))}
        </div>
    )
}

const FileTree = ({ closeModal, currentFolderId, currentFileId, getAttacmentsForCurrentFolder}) => {

    const currentProjectId = useParams().id;
    const {token} = useSelector(state => state.Authentication)

    const [allFolders, setAllFolders] = useState([]);
    const [currentFolders, setCurrentFolders] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [_currentFolderId, setCurrentFolderId] = useState(currentFolderId);
    const [isButtonDisable, setButtonDisable] = useState(false)


    const {mutate: getCurrentFolders} = useMutation((data) => {
        let _currentFolderId = data.folderId || currentFolderId;
        if (data.force) _currentFolderId = data.folderId; // force to get folders by id
        return FileService.get(currentProjectId, token, _currentFolderId)
    }, {
        onSuccess: (data) => {
            setCurrentFolders(data.data?.folders || [])
        },
        onError: () => {
            toast.error('Something went wrong')
        },
    });

    const {mutate: getAllFolders} = useMutation(() => {
        return FileService.getAllAttachments(currentProjectId, token)
    }, {
        onSuccess: (data) => {

            setAllFolders(data.data?.folders || [])
        },
    });

    const {mutate: moveFile} = useMutation((data) => {
        setButtonDisable(true)
        return FileService.moveFile(currentProjectId, data, token)
    }, {
        onSuccess: () => {
            toast.success('File moved successfully')
            getCurrentFolders({
                folderId: _currentFolderId || false,
                force: true
            })
            getAttacmentsForCurrentFolder()
            setButtonDisable(false)
            closeModal()
        },
        onError: () => {
            toast.error('Something went wrong')
            setButtonDisable(false)
        }
    })

    useEffect(() => {
            getCurrentFolders({folderId: currentFolderId || null})
            getAllFolders()
    }, [currentFolderId])

    useEffect(() => {
        const rootFolder = {
            name: 'All attachments',
            _id: null,
        }
        const currentLocation = allFolders.find(folder => folder._id === currentFolderId) || rootFolder;
        setCurrentLocation(currentLocation)
    }, [allFolders])

    return (
        <div className={styles.fileTree}>
            <div className={styles.currentLocation}>
                <div className={styles.currentLocation__label}>
                    Current location:
                </div>
                <div className={styles.currentLocation__group}>
                    <img src={folderIcon} alt='folder icon'/>
                    {currentLocation?.name}
                </div>
            </div>
            <div className={styles.title}>
                Choose new location:
            </div>
            <Breadcrumbs currentFolderId={_currentFolderId}
                         setCurrentFolderId={setCurrentFolderId}
                         allFolders={allFolders}
                         getCurrentFolders={getCurrentFolders}
            />
            <div className={styles.folders}>
                {currentFolders.map(folder => (
                    <div className={styles.folders__folder} onClick={() => {
                        getCurrentFolders({folderId: folder._id})
                        setCurrentFolderId(folder._id)
                    }}>
                        <img className={styles.folders__folder__folderIcon} src={folderIcon} alt='folder icon'/>
                        <span className={styles.folders__folder__name}>
                            {folder.name}
                        </span>
                        <img className={styles.folders__folder__arrowIcon} src={arrowIcon} alt='arrow icon'/>
                    </div>
                ))}
            </div>
            <Button
                className={cn(styles.submitBtn, {
                    [styles.submitBtn__disabled]: isButtonDisable
                })}
                text={'Move'}
                onClick={() => {
                    moveFile({
                        fileId: currentFileId,
                        folderId: _currentFolderId
                    })
                }}
            />
        </div>
    )
}

export default memo(FileTree);
