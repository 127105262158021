import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { loginWithSocial } from '../redux/slices/authentication';
import { useDispatch } from 'react-redux';

export const useGoogleLoginWrapper = () => {
  const dispatch = useDispatch();
  const googleLogin = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/calendar.events',
    onSuccess: async ({ code }) => {
      //  let access_token = tokenResponse.access_token;

      const tokens = await axios.post(
        process.env.REACT_APP_BACK_END + '/users/sso',
        {
          googleCode: code,
        }
      );
      // JWT TOKEN && GOOGLE ACCES TOKEN

      const jwtToken = tokens.data.jwtToken;

      dispatch(loginWithSocial({ token: jwtToken, service: 'google' }));
      // dispatch(login({ token: jwtToken }));

      // fetching userinfo can be done on the client or the server
      // const userInfo = await axios
      //     .get(
      //         "https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=2013-03-01T00:00:00-04:00&timeMax=2023-03-28T23:59:59-04:00",
      //         {
      //             headers: {Authorization: `Bearer ${access_token}`},
      //         }
      //     )
      //     .then((res) => res.data);
    },
    flow: 'auth-code',
  });

  return googleLogin;
};
