import http from '../http-common';

const getAll = () => {
    return http.get('/todo');
};

const get = (id) => {
    return http.get(`/todo/${id}`);
};

const create = (data) => {
    return http.post('/todo', data);
};

const remove = (id) => {
    return http.delete(`/todo/${id}`);
};
const toggle = (id) => {
    return http.patch(`/todo/${id}/complete`);
};

const TodoListService = {
    getAll,
    get,
    create,
    remove,
    toggle,
};

export default TodoListService;
