import { useState } from 'react'
const data = [
    {
        name: 'Kenya',
        capital: 'Nairobi',
        area: 580367,
        coordinates: [1, 38],
        currencies: [{ name: 'Kenyan shilling', symbol: 'Sh' }],
        languages: ['English', 'Swahili'],
        maps: {
            googleMaps: 'https://goo.gl/maps/Ni9M7wcCxf8bJHLX8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192798'
        },
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ke.png',
            svg: 'https://flagcdn.com/ke.svg'
        },
        population: 53771300,
        emoji: '🇰🇪',
        countryCallingCode: '+254'
    },
    {
        name: 'San Marino',
        capital: 'City of San Marino',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Italian'],
        coordinates: [43.76666666, 12.41666666],
        area: 61,
        maps: {
            googleMaps: 'https://goo.gl/maps/rxCVJjm8dVY93RPY8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/54624'
        },
        population: 33938,
        postalCode: { format: '4789#', regex: '^(4789\\d)$' },
        flags: {
            png: 'https://flagcdn.com/w320/sm.png',
            svg: 'https://flagcdn.com/sm.svg'
        },
        emoji: '🇸🇲',
        countryCallingCode: '+378'
    },
    {
        name: 'French Polynesia',
        capital: 'Papeetē',
        currencies: [{ name: 'CFP franc', symbol: '₣' }],
        languages: ['French'],
        coordinates: [-15, -140],
        area: 4167,
        maps: {
            googleMaps: 'https://goo.gl/maps/xgg6BQTRyeQg4e1m6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/3412620'
        },
        population: 280904,
        postalCode: { format: '#####', regex: '^((97|98)7\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pf.png',
            svg: 'https://flagcdn.com/pf.svg'
        },
        emoji: '🇵🇫',
        countryCallingCode: '+689'
    },
    {
        name: 'Sierra Leone',
        capital: 'Freetown',
        currencies: [{ name: 'Sierra Leonean leone', symbol: 'Le' }],
        languages: ['English'],
        coordinates: [8.5, -11.5],
        area: 71740,
        maps: {
            googleMaps: 'https://goo.gl/maps/jhacar85oq9QaeKB7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192777'
        },
        population: 7976985,
        flags: {
            png: 'https://flagcdn.com/w320/sl.png',
            svg: 'https://flagcdn.com/sl.svg'
        },
        emoji: '🇸🇱',
        countryCallingCode: '+232'
    },
    {
        name: 'Madagascar',
        capital: 'Antananarivo',
        currencies: [{ name: 'Malagasy ariary', symbol: 'Ar' }],
        languages: ['French', 'Malagasy'],
        coordinates: [-20, 47],
        area: 587041,
        maps: {
            googleMaps: 'https://goo.gl/maps/AHQh2ABBaFW6Ngj26',
            openStreetMaps: 'https://www.openstreetmap.org/relation/447325'
        },
        population: 27691019,
        postalCode: { format: '###', regex: '^(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mg.png',
            svg: 'https://flagcdn.com/mg.svg'
        },
        emoji: '🇲🇬',
        countryCallingCode: '+261'
    },
    {
        name: 'Nigeria',
        capital: 'Abuja',
        currencies: [{ name: 'Nigerian naira', symbol: '₦' }],
        languages: ['English'],
        coordinates: [10, 8],
        area: 923768,
        maps: {
            googleMaps: 'https://goo.gl/maps/LTn417qWwBPFszuV9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192787'
        },
        population: 206139587,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ng.png',
            svg: 'https://flagcdn.com/ng.svg'
        },
        emoji: '🇳🇬',
        countryCallingCode: '+234'
    },
    {
        name: 'Jordan',
        capital: 'Amman',
        currencies: [{ name: 'Jordanian dinar', symbol: 'د.ا' }],
        languages: ['Arabic'],
        coordinates: [31, 36],
        area: 89342,
        maps: {
            googleMaps: 'https://goo.gl/maps/ko1dzSDKg8Gsi9A98',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184818'
        },
        population: 10203140,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/jo.png',
            svg: 'https://flagcdn.com/jo.svg'
        },
        emoji: '🇯🇴',
        countryCallingCode: '+962'
    },
    {
        name: 'Libya',
        capital: 'Tripoli',
        currencies: [{ name: 'Libyan dinar', symbol: 'ل.د' }],
        languages: ['Arabic'],
        coordinates: [25, 17],
        area: 1759540,
        maps: {
            googleMaps: 'https://goo.gl/maps/eLgGnaQWcJEdYRMy5',
            openStreetMaps: 'openstreetmap.org/relation/192758'
        },
        population: 6871287,
        flags: {
            png: 'https://flagcdn.com/w320/ly.png',
            svg: 'https://flagcdn.com/ly.svg'
        },
        emoji: '🇱🇾',
        countryCallingCode: '+218'
    },
    {
        name: 'Guyana',
        capital: 'Georgetown',
        currencies: [{ name: 'Guyanese dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [5, -59],
        area: 214969,
        maps: {
            googleMaps: 'https://goo.gl/maps/DFsme2xEeugUAsCx5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287083'
        },
        population: 786559,
        flags: {
            png: 'https://flagcdn.com/w320/gy.png',
            svg: 'https://flagcdn.com/gy.svg'
        },
        emoji: '🇬🇾',
        countryCallingCode: '+592'
    },
    {
        name: 'Mexico',
        capital: 'Mexico City',
        currencies: [{ name: 'Mexican peso', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [23, -102],
        area: 1964375,
        maps: {
            googleMaps: 'https://goo.gl/maps/s5g7imNPMDEePxzbA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/114686'
        },
        population: 128932753,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mx.png',
            svg: 'https://flagcdn.com/mx.svg'
        },
        emoji: '🇲🇽',
        countryCallingCode: '+52'
    },
    {
        name: 'Turkmenistan',
        capital: 'Ashgabat',
        currencies: [{ name: 'Turkmenistan manat', symbol: 'm' }],
        languages: ['Russian', 'Turkmen'],
        coordinates: [40, 60],
        area: 488100,
        maps: {
            googleMaps: 'https://goo.gl/maps/cgfUcaQHSWKuqeKk9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/223026'
        },
        population: 6031187,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/tm.png',
            svg: 'https://flagcdn.com/tm.svg'
        },
        emoji: '🇹🇲',
        countryCallingCode: '+993'
    },
    {
        name: 'Christmas Island',
        capital: 'Flying Fish Cove',
        currencies: [{ name: 'Australian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-10.5, 105.66666666],
        area: 135,
        maps: {
            googleMaps: 'https://goo.gl/maps/ZC17hHsQZpShN5wk9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/6365444'
        },
        population: 2072,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cx.png',
            svg: 'https://flagcdn.com/cx.svg'
        },
        emoji: '🇨🇽',
        countryCallingCode: '+61'
    },
    {
        name: 'Panama',
        capital: 'Panama City',
        currencies: [
            { name: 'Panamanian balboa', symbol: 'B/.' },
            { name: 'United States dollar', symbol: '$' }
        ],
        languages: ['Spanish'],
        coordinates: [9, -80],
        area: 75417,
        maps: {
            googleMaps: 'https://goo.gl/maps/sEN7sKqeawa5oPNLA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287668'
        },
        population: 4314768,
        flags: {
            png: 'https://flagcdn.com/w320/pa.png',
            svg: 'https://flagcdn.com/pa.svg'
        },
        emoji: '🇵🇦',
        countryCallingCode: '+507'
    },
    {
        name: 'Vatican City',
        capital: 'Vatican City',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Italian', 'Latin'],
        coordinates: [41.9, 12.45],
        area: 0.44,
        maps: {
            googleMaps: 'https://goo.gl/maps/DTKvw5Bd1QZaDZmE8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/36989'
        },
        population: 451,
        flags: {
            png: 'https://flagcdn.com/w320/va.png',
            svg: 'https://flagcdn.com/va.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Seychelles',
        capital: 'Victoria',
        currencies: [{ name: 'Seychellois rupee', symbol: '₨' }],
        languages: ['Seychellois Creole', 'English', 'French'],
        coordinates: [-4.58333333, 55.66666666],
        area: 452,
        maps: {
            googleMaps: 'https://goo.gl/maps/aqCcy2TKh5TV5MAX8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536765'
        },
        population: 98462,
        flags: {
            png: 'https://flagcdn.com/w320/sc.png',
            svg: 'https://flagcdn.com/sc.svg'
        },
        emoji: '🇸🇨',
        countryCallingCode: '+248'
    },
    {
        name: 'Algeria',
        capital: 'Algiers',
        currencies: [{ name: 'Algerian dinar', symbol: 'د.ج' }],
        languages: ['Arabic'],
        coordinates: [28, 3],
        area: 2381741,
        maps: {
            googleMaps: 'https://goo.gl/maps/RsAyAfyaiNVb8DpW8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192756'
        },
        population: 510713,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/dz.png',
            svg: 'https://flagcdn.com/dz.svg'
        },
        emoji: '🇩🇿',
        countryCallingCode: '+213'
    },
    {
        name: 'Guam',
        capital: 'Hagåtña',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['Chamorro', 'English', 'Spanish'],
        coordinates: [13.46666666, 144.78333333],
        area: 549,
        maps: {
            googleMaps: 'https://goo.gl/maps/Xfnq2i279b18cH3C9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/306001'
        },
        population: 168783,
        postalCode: { format: '969##', regex: '^(969\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gu.png',
            svg: 'https://flagcdn.com/gu.svg'
        },
        emoji: '🇬🇺',
        countryCallingCode: '+1 671'
    },
    {
        name: 'Sweden',
        capital: 'Stockholm',
        currencies: [{ name: 'Swedish krona', symbol: 'kr' }],
        languages: ['Swedish'],
        coordinates: [62, 15],
        area: 450295,
        maps: {
            googleMaps: 'https://goo.gl/maps/iqygE491ADVgnBW39',
            openStreetMaps: 'https://www.openstreetmap.org/relation/52822'
        },
        population: 10353442,
        postalCode: { format: 'SE-### ##', regex: '^(?:SE)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/se.png',
            svg: 'https://flagcdn.com/se.svg'
        },
        emoji: '🇸🇪',
        countryCallingCode: '+46'
    },
    {
        name: 'Antarctica',
        capital: 'Unknown',
        coordinates: [-90, 0],
        area: 14000000,
        maps: {
            googleMaps: 'https://goo.gl/maps/kyBuJriu4itiXank7',
            openStreetMaps: 'https://www.openstreetmap.org/node/36966060'
        },
        population: 1000,
        flags: {
            png: 'https://flagcdn.com/w320/aq.png',
            svg: 'https://flagcdn.com/aq.svg'
        },
        emoji: '🇦🇶',
        countryCallingCode: '+672',
        currencies: [],
        languages: []
    },
    {
        name: 'Switzerland',
        capital: 'Bern',
        currencies: [{ name: 'Swiss franc', symbol: 'Fr.' }],
        languages: ['French', 'Swiss German', 'Italian', 'Romansh'],
        coordinates: [47, 8],
        area: 41284,
        maps: {
            googleMaps: 'https://goo.gl/maps/uVuZcXaxSx5jLyEC9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/51701'
        },
        population: 17500657,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ch.png',
            svg: 'https://flagcdn.com/ch.svg'
        },
        emoji: '🇨🇭',
        countryCallingCode: '+41'
    },
    {
        name: 'Ethiopia',
        capital: 'Addis Ababa',
        currencies: [{ name: 'Ethiopian birr', symbol: 'Br' }],
        languages: ['Amharic'],
        coordinates: [8, 38],
        area: 1104300,
        maps: {
            googleMaps: 'https://goo.gl/maps/2Q4hQWCbhuZLj3fG6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192800'
        },
        population: 114963583,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/et.png',
            svg: 'https://flagcdn.com/et.svg'
        },
        emoji: '🇪🇹',
        countryCallingCode: '+251'
    },
    {
        name: 'Somalia',
        capital: 'Mogadishu',
        currencies: [{ name: 'Somali shilling', symbol: 'Sh' }],
        languages: ['Arabic', 'Somali'],
        coordinates: [10, 49],
        area: 637657,
        maps: {
            googleMaps: 'https://goo.gl/maps/8of8q7D1a8p7R6Fc9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192799'
        },
        population: 15893219,
        postalCode: { format: '@@  #####', regex: '^([A-Z]{2}\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/so.png',
            svg: 'https://flagcdn.com/so.svg'
        },
        emoji: '🇸🇴',
        countryCallingCode: '+252'
    },
    {
        name: 'France',
        capital: 'Paris',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [46, 2],
        area: 551695,
        maps: {
            googleMaps: 'https://goo.gl/maps/g7QxxSFsWyTPKuzd7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1403916'
        },
        population: 67391582,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/fr.png',
            svg: 'https://flagcdn.com/fr.svg'
        },
        emoji: '🇫🇷',
        countryCallingCode: '+33'
    },
    {
        name: 'Russia',
        capital: 'Moscow',
        currencies: [{ name: 'Russian ruble', symbol: '₽' }],
        languages: ['Russian'],
        coordinates: [60, 100],
        area: 17098242,
        maps: {
            googleMaps: 'https://goo.gl/maps/4F4PpDhGJgVvLby57',
            openStreetMaps:
                'https://www.openstreetmap.org/relation/60189#map=3/65.15/105.29'
        },
        population: 144104080,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ru.png',
            svg: 'https://flagcdn.com/ru.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Western Sahara',
        capital: 'El Aaiún',
        currencies: [
            { name: 'Algerian dinar', symbol: 'دج' },
            { name: 'Moroccan dirham', symbol: 'DH' },
            { name: 'Mauritanian ouguiya', symbol: 'UM' }
        ],
        languages: ['Berber', 'Hassaniya', 'Spanish'],
        coordinates: [24.5, -13],
        area: 266000,
        maps: {
            googleMaps: 'https://goo.gl/maps/7nU3mB69vP6zQp7A8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/5441968'
        },
        population: 510713,
        flags: {
            png: 'https://flagcdn.com/w320/eh.png',
            svg: 'https://flagcdn.com/eh.svg'
        },
        emoji: '🇪🇭',
        countryCallingCode: '+212'
    },
    {
        name: 'Åland Islands',
        capital: 'Mariehamn',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Swedish'],
        coordinates: [60.116667, 19.9],
        area: 1580,
        maps: {
            googleMaps: 'https://goo.gl/maps/ewFb3vYsfUmVCoSb8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1650407'
        },
        population: 29458,
        flags: {
            png: 'https://flagcdn.com/w320/ax.png',
            svg: 'https://flagcdn.com/ax.svg'
        },
        emoji: '🇦🇽',
        countryCallingCode: '+358'
    },
    {
        name: 'Tokelau',
        capital: 'Fakaofo',
        currencies: [{ name: 'New Zealand dollar', symbol: '$' }],
        languages: ['English', 'Samoan', 'Tokelauan'],
        coordinates: [-9, -172],
        area: 12,
        maps: {
            googleMaps: 'https://goo.gl/maps/Ap5qN8qien6pT9UN6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2186600'
        },
        population: 1411,
        flags: {
            png: 'https://flagcdn.com/w320/tk.png',
            svg: 'https://flagcdn.com/tk.svg'
        },
        emoji: '🇹🇰',
        countryCallingCode: '+690'
    },
    {
        name: 'Chad',
        capital: "N'Djamena",
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['Arabic', 'French'],
        coordinates: [15, 19],
        area: 1284000,
        maps: {
            googleMaps: 'https://goo.gl/maps/ziUdAZ8skuNfx5Hx7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2361304'
        },
        population: 16425859,
        flags: {
            png: 'https://flagcdn.com/w320/td.png',
            svg: 'https://flagcdn.com/td.svg'
        },
        emoji: '🇹🇩',
        countryCallingCode: '+235'
    },
    {
        name: 'Trinidad and Tobago',
        capital: 'Port of Spain',
        currencies: [{ name: 'Trinidad and Tobago dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [11, -61],
        area: 5130,
        maps: {
            googleMaps: 'https://goo.gl/maps/NrRfDEWoG8FGZqWY7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/555717'
        },
        population: 1399491,
        flags: {
            png: 'https://flagcdn.com/w320/tt.png',
            svg: 'https://flagcdn.com/tt.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Central African Republic',
        capital: 'Bangui',
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['French', 'Sango'],
        coordinates: [7, 21],
        area: 622984,
        maps: {
            googleMaps: 'https://goo.gl/maps/51V8dsi2rGYC9n3c9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192790'
        },
        population: 4829764,
        flags: {
            png: 'https://flagcdn.com/w320/cf.png',
            svg: 'https://flagcdn.com/cf.svg'
        },
        emoji: '🇨🇫',
        countryCallingCode: '+236'
    },
    {
        name: 'North Macedonia',
        capital: 'Skopje',
        currencies: [{ name: 'denar', symbol: 'den' }],
        languages: ['Macedonian'],
        coordinates: [41.83333333, 22],
        area: 25713,
        maps: {
            googleMaps: 'https://goo.gl/maps/55Q8MEnF6ACdu3q79',
            openStreetMaps: 'https://www.openstreetmap.org/relation/53293'
        },
        population: 2077132,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mk.png',
            svg: 'https://flagcdn.com/mk.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'El Salvador',
        capital: 'San Salvador',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [13.83333333, -88.91666666],
        area: 21041,
        maps: {
            googleMaps: 'https://goo.gl/maps/cZnCEi5sEMQtKKcB7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1520612'
        },
        population: 6486201,
        postalCode: { format: 'CP ####', regex: '^(?:CP)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sv.png',
            svg: 'https://flagcdn.com/sv.svg'
        },
        emoji: '🇸🇻',
        countryCallingCode: '+503'
    },
    {
        name: 'Turks and Caicos Islands',
        capital: 'Cockburn Town',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [21.75, -71.58333333],
        area: 948,
        maps: {
            googleMaps: 'https://goo.gl/maps/R8VUDQfwZiFtvmyn8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/547479'
        },
        population: 38718,
        postalCode: { format: 'TKCA 1ZZ', regex: '^(TKCA 1ZZ)$' },
        flags: {
            png: 'https://flagcdn.com/w320/tc.png',
            svg: 'https://flagcdn.com/tc.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Kosovo',
        capital: 'Pristina',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Albanian', 'Serbian'],
        coordinates: [42.666667, 21.166667],
        area: 10908,
        maps: {
            googleMaps: 'https://goo.gl/maps/CSC4Yc8SWPgburuD9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2088990'
        },
        population: 1775378,
        flags: {
            png: 'https://flagcdn.com/w320/xk.png',
            svg: 'https://flagcdn.com/xk.svg'
        },
        emoji: '',
        countryCallingCode: '+383'
    },
    {
        name: 'Colombia',
        capital: 'Bogotá',
        currencies: [{ name: 'Colombian peso', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [4, -72],
        area: 1141748,
        maps: {
            googleMaps: 'https://goo.gl/maps/RdwTG8e7gPwS62oR6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/120027'
        },
        population: 50882884,
        flags: {
            png: 'https://flagcdn.com/w320/co.png',
            svg: 'https://flagcdn.com/co.svg'
        },
        emoji: '🇨🇴',
        countryCallingCode: '+57'
    },
    {
        name: 'Palau',
        capital: 'Ngerulmud',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English', 'Palauan'],
        coordinates: [7.5, 134.5],
        area: 459,
        maps: {
            googleMaps: 'https://goo.gl/maps/MVasQBbUkQP7qQDR9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571805'
        },
        population: 18092,
        postalCode: { format: '96940', regex: '^(96940)$' },
        flags: {
            png: 'https://flagcdn.com/w320/pw.png',
            svg: 'https://flagcdn.com/pw.svg'
        },
        emoji: '🇵🇼',
        countryCallingCode: '+680'
    },
    {
        name: 'Iran',
        capital: 'Tehran',
        currencies: [{ name: 'Iranian rial', symbol: '﷼' }],
        languages: ['Persian (Farsi)'],
        coordinates: [32, 53],
        area: 1648195,
        maps: {
            googleMaps: 'https://goo.gl/maps/dMgEGuacBPGYQnjY7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/304938'
        },
        population: 83992953,
        postalCode: { format: '##########', regex: '^(\\d{10})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ir.png',
            svg: 'https://flagcdn.com/ir.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'French Southern and Antarctic Lands',
        capital: 'Port-aux-Français',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [-49.25, 69.167],
        area: 7747,
        maps: {
            googleMaps: 'https://goo.gl/maps/6ua6CX1m4w1xF2Em7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2186658'
        },
        population: 400,
        flags: {
            png: 'https://flagcdn.com/w320/tf.png',
            svg: 'https://flagcdn.com/tf.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Bouvet Island',
        capital: 'Unknown',
        languages: ['Norwegian'],
        coordinates: [-54.43333333, 3.4],
        area: 49,
        maps: {
            googleMaps: 'https://goo.gl/maps/7WRQAEKZb4uK36yi9',
            openStreetMaps: 'https://www.openstreetmap.org/way/174996681'
        },
        population: 0,
        flags: {
            png: 'https://flagcdn.com/w320/bv.png',
            svg: 'https://flagcdn.com/bv.svg'
        },
        emoji: '🇧🇻',
        countryCallingCode: '',
        currencies: []
    },
    {
        name: 'British Virgin Islands',
        capital: 'Road Town',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [18.431383, -64.62305],
        area: 151,
        maps: {
            googleMaps: 'https://goo.gl/maps/49C9cSesNVAR9DQk8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/285454'
        },
        population: 30237,
        flags: {
            png: 'https://flagcdn.com/w320/vg.png',
            svg: 'https://flagcdn.com/vg.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'United Arab Emirates',
        capital: 'Abu Dhabi',
        currencies: [{ name: 'United Arab Emirates dirham', symbol: 'د.إ' }],
        languages: ['Arabic'],
        coordinates: [24, 54],
        area: 83600,
        maps: {
            googleMaps: 'https://goo.gl/maps/AZZTDA6GzVAnKMVd8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307763'
        },
        population: 9890400,
        flags: {
            png: 'https://flagcdn.com/w320/ae.png',
            svg: 'https://flagcdn.com/ae.svg'
        },
        emoji: '🇦🇪',
        countryCallingCode: '+971'
    },
    {
        name: 'South Africa',
        capital: 'Pretoria',
        currencies: [{ name: 'South African rand', symbol: 'R' }],
        languages: [
            'Afrikaans',
            'English',
            'Southern Ndebele',
            'Northern Sotho',
            'Southern Sotho',
            'Swazi',
            'Tswana',
            'Tsonga',
            'Venda',
            'Xhosa',
            'Zulu'
        ],
        coordinates: [-29, 24],
        area: 1221037,
        maps: {
            googleMaps: 'https://goo.gl/maps/CLCZ1R8Uz1KpYhRv6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/87565'
        },
        population: 59308690,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/za.png',
            svg: 'https://flagcdn.com/za.svg'
        },
        emoji: '🇿🇦',
        countryCallingCode: '+27'
    },
    {
        name: 'Czechia',
        capital: 'Prague',
        currencies: [{ name: 'Czech koruna', symbol: 'Kč' }],
        languages: ['Czech', 'Slovak'],
        coordinates: [49.75, 15.5],
        area: 78865,
        maps: {
            googleMaps: 'https://goo.gl/maps/47dmgeXMZyhDHyQW8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/51684'
        },
        population: 10698896,
        postalCode: { format: '### ##', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cz.png',
            svg: 'https://flagcdn.com/cz.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Hungary',
        capital: 'Budapest',
        currencies: [{ name: 'Hungarian forint', symbol: 'Ft' }],
        languages: ['Hungarian'],
        coordinates: [47, 20],
        area: 93028,
        maps: {
            googleMaps: 'https://goo.gl/maps/9gfPupm5bffixiFJ6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/21335'
        },
        population: 9749763,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/hu.png',
            svg: 'https://flagcdn.com/hu.svg'
        },
        emoji: '🇭🇺',
        countryCallingCode: '+36'
    },
    {
        name: 'Peru',
        capital: 'Lima',
        currencies: [{ name: 'Peruvian sol', symbol: 'S/ ' }],
        languages: ['Aymara', 'Quechua', 'Spanish'],
        coordinates: [-10, -76],
        area: 1285216,
        maps: {
            googleMaps: 'https://goo.gl/maps/uDWEUaXNcZTng1fP6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/288247'
        },
        population: 32971846,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pe.png',
            svg: 'https://flagcdn.com/pe.svg'
        },
        emoji: '🇵🇪',
        countryCallingCode: '+51'
    },
    {
        name: 'Benin',
        capital: 'Porto-Novo',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [9.5, 2.25],
        area: 112622,
        maps: {
            googleMaps: 'https://goo.gl/maps/uMw1BsHEXQYgVFFu6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192784'
        },
        population: 12123198,
        flags: {
            png: 'https://flagcdn.com/w320/bj.png',
            svg: 'https://flagcdn.com/bj.svg'
        },
        emoji: '🇧🇯',
        countryCallingCode: '+229'
    },
    {
        name: 'South Sudan',
        capital: 'Juba',
        currencies: [{ name: 'South Sudanese pound', symbol: '£' }],
        languages: ['English'],
        coordinates: [7, 30],
        area: 619745,
        maps: {
            googleMaps: 'https://goo.gl/maps/Zm1AYCXb9HSNF1P27',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1656678'
        },
        population: 11193729,
        flags: {
            png: 'https://flagcdn.com/w320/ss.png',
            svg: 'https://flagcdn.com/ss.svg'
        },
        emoji: '🇸🇸',
        countryCallingCode: '+211'
    },
    {
        name: 'Heard Island and McDonald Islands',
        capital: 'Unknown',
        languages: ['English'],
        coordinates: [-53.1, 72.51666666],
        area: 412,
        maps: {
            googleMaps: 'https://goo.gl/maps/k5FBAiVaVyozuYeA7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2177227'
        },
        population: 0,
        flags: {
            png: 'https://flagcdn.com/w320/hm.png',
            svg: 'https://flagcdn.com/hm.svg'
        },
        emoji: '',
        countryCallingCode: '',
        currencies: []
    },
    {
        name: 'Solomon Islands',
        capital: 'Honiara',
        currencies: [{ name: 'Solomon Islands dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-8, 159],
        area: 28896,
        maps: {
            googleMaps: 'https://goo.gl/maps/JbPkx86Ywjv8C1n8A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1857436'
        },
        population: 686878,
        flags: {
            png: 'https://flagcdn.com/w320/sb.png',
            svg: 'https://flagcdn.com/sb.svg'
        },
        emoji: '🇸🇧',
        countryCallingCode: '+677'
    },
    {
        name: 'Sint Maarten',
        capital: 'Philipsburg',
        currencies: [{ name: 'Netherlands Antillean guilder', symbol: 'ƒ' }],
        languages: ['English', 'French', 'Dutch'],
        coordinates: [18.033333, -63.05],
        area: 34,
        maps: {
            googleMaps: 'https://goo.gl/maps/DjvcESy1a1oGEZuNA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1231790'
        },
        population: 40812,
        flags: {
            png: 'https://flagcdn.com/w320/sx.png',
            svg: 'https://flagcdn.com/sx.svg'
        },
        emoji: '🇸🇽',
        countryCallingCode: '+1 721'
    },
    {
        name: 'Turkey',
        capital: 'Ankara',
        currencies: [{ name: 'Turkish lira', symbol: '₺' }],
        languages: ['Turkish'],
        coordinates: [39, 35],
        area: 783562,
        maps: {
            googleMaps: 'https://goo.gl/maps/dXFFraiUDfcB6Quk6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/174737'
        },
        population: 84339067,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/tr.png',
            svg: 'https://flagcdn.com/tr.svg'
        },
        emoji: '🇹🇷',
        countryCallingCode: '+90'
    },
    {
        name: 'Ireland',
        capital: 'Dublin',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['English', 'Irish'],
        coordinates: [53, -8],
        area: 70273,
        maps: {
            googleMaps: 'https://goo.gl/maps/hxd1BKxgpchStzQC6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/62273'
        },
        population: 4994724,
        flags: {
            png: 'https://flagcdn.com/w320/ie.png',
            svg: 'https://flagcdn.com/ie.svg'
        },
        emoji: '🇮🇪',
        countryCallingCode: '+353'
    },
    {
        name: 'Botswana',
        capital: 'Gaborone',
        currencies: [{ name: 'Botswana pula', symbol: 'P' }],
        languages: ['English', 'Tswana'],
        coordinates: [-22, 24],
        area: 582000,
        maps: {
            googleMaps: 'https://goo.gl/maps/E364KeLy6N4JwxwQ8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1889339'
        },
        population: 2351625,
        flags: {
            png: 'https://flagcdn.com/w320/bw.png',
            svg: 'https://flagcdn.com/bw.svg'
        },
        emoji: '🇧🇼',
        countryCallingCode: '+267'
    },
    {
        name: 'Haiti',
        capital: 'Port-au-Prince',
        currencies: [{ name: 'Haitian gourde', symbol: 'G' }],
        languages: ['French', 'Haitian Creole'],
        coordinates: [19, -72.41666666],
        area: 27750,
        maps: {
            googleMaps: 'https://goo.gl/maps/9o13xtjuUdqFnHbn9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307829'
        },
        population: 11402533,
        postalCode: { format: 'HT####', regex: '^(?:HT)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ht.png',
            svg: 'https://flagcdn.com/ht.svg'
        },
        emoji: '🇭🇹',
        countryCallingCode: '+509'
    },
    {
        name: 'Japan',
        capital: 'Tokyo',
        currencies: [{ name: 'Japanese yen', symbol: '¥' }],
        languages: ['Japanese'],
        coordinates: [36, 138],
        area: 377930,
        maps: {
            googleMaps: 'https://goo.gl/maps/NGTLSCSrA8bMrvnX9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/382313'
        },
        population: 125836021,
        postalCode: { format: '###-####', regex: '^(\\d{7})$' },
        flags: {
            png: 'https://flagcdn.com/w320/jp.png',
            svg: 'https://flagcdn.com/jp.svg'
        },
        emoji: '🇯🇵',
        countryCallingCode: '+81'
    },
    {
        name: 'Norfolk Island',
        capital: 'Kingston',
        currencies: [{ name: 'Australian dollar', symbol: '$' }],
        languages: ['English', 'Norfuk'],
        coordinates: [-29.03333333, 167.95],
        area: 36,
        maps: {
            googleMaps: 'https://goo.gl/maps/pbvtm6XYd1iZbjky5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2574988'
        },
        population: 2302,
        flags: {
            png: 'https://flagcdn.com/w320/nf.png',
            svg: 'https://flagcdn.com/nf.svg'
        },
        emoji: '🇳🇫',
        countryCallingCode: '+672'
    },
    {
        name: 'Sudan',
        capital: 'Khartoum',
        currencies: [{ name: 'Sudanese pound' }],
        languages: ['Arabic', 'English'],
        coordinates: [15, 30],
        area: 1886068,
        maps: {
            googleMaps: 'https://goo.gl/maps/bNW7YUJCaqR8zcXn7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192789'
        },
        population: 43849269,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sd.png',
            svg: 'https://flagcdn.com/sd.svg'
        },
        emoji: '🇸🇩',
        countryCallingCode: '+249'
    },
    {
        name: 'Uganda',
        capital: 'Kampala',
        currencies: [{ name: 'Ugandan shilling', symbol: 'Sh' }],
        languages: ['English', 'Swahili'],
        coordinates: [1, 32],
        area: 241550,
        maps: {
            googleMaps: 'https://goo.gl/maps/Y7812hFiGa8LD9N68',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192796'
        },
        population: 45741000,
        flags: {
            png: 'https://flagcdn.com/w320/ug.png',
            svg: 'https://flagcdn.com/ug.svg'
        },
        emoji: '🇺🇬',
        countryCallingCode: '+256'
    },
    {
        name: 'Kazakhstan',
        capital: 'Nur-Sultan',
        currencies: [{ name: 'Kazakhstani tenge', symbol: '₸' }],
        languages: ['Kazakh', 'Russian'],
        coordinates: [48, 68],
        area: 2724900,
        maps: {
            googleMaps: 'https://goo.gl/maps/8VohJGu7ShuzZYyeA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/214665'
        },
        population: 18754440,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/kz.png',
            svg: 'https://flagcdn.com/kz.svg'
        },
        emoji: '🇰🇿',
        countryCallingCode: '+7'
    },
    {
        name: 'Northern Mariana Islands',
        capital: 'Saipan',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['Carolinian', 'Chamorro', 'English'],
        coordinates: [15.2, 145.75],
        area: 464,
        maps: {
            googleMaps: 'https://goo.gl/maps/cpZ67knoRAcfu1417',
            openStreetMaps: 'https://www.openstreetmap.org/relation/306004'
        },
        population: 57557,
        flags: {
            png: 'https://flagcdn.com/w320/mp.png',
            svg: 'https://flagcdn.com/mp.svg'
        },
        emoji: '🇲🇵',
        countryCallingCode: '+1 670'
    },
    {
        name: 'Moldova',
        capital: 'Chișinău',
        currencies: [{ name: 'Moldovan leu', symbol: 'L' }],
        languages: ['Moldavian'],
        coordinates: [47, 29],
        area: 33846,
        maps: {
            googleMaps: 'https://goo.gl/maps/JjmyUuULujnDeFPf7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/58974'
        },
        population: 2617820,
        postalCode: { format: 'MD-####', regex: '^(?:MD)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/md.png',
            svg: 'https://flagcdn.com/md.svg'
        },
        emoji: '🇲🇩',
        countryCallingCode: '+373'
    },
    {
        name: 'Paraguay',
        capital: 'Asunción',
        currencies: [{ name: 'Paraguayan guaraní', symbol: '₲' }],
        languages: ['Guaraní', 'Spanish'],
        coordinates: [-23, -58],
        area: 406752,
        maps: {
            googleMaps: 'https://goo.gl/maps/JtnqG73WJn1Gx6mz6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287077'
        },
        population: 7132530,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/py.png',
            svg: 'https://flagcdn.com/py.svg'
        },
        emoji: '🇵🇾',
        countryCallingCode: '+595'
    },
    {
        name: 'Estonia',
        capital: 'Tallinn',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Estonian'],
        coordinates: [59, 26],
        area: 45227,
        maps: {
            googleMaps: 'https://goo.gl/maps/6SsynwGUodL1sDvq8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/79510'
        },
        population: 1331057,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ee.png',
            svg: 'https://flagcdn.com/ee.svg'
        },
        emoji: '🇪🇪',
        countryCallingCode: '+372'
    },
    {
        name: 'Greenland',
        capital: 'Nuuk',
        currencies: [{ name: 'krone', symbol: 'kr.' }],
        languages: ['Greenlandic'],
        coordinates: [72, -40],
        area: 2166086,
        maps: {
            googleMaps: 'https://goo.gl/maps/j3289UPEQXt1ceSy8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2184073'
        },
        population: 56367,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gl.png',
            svg: 'https://flagcdn.com/gl.svg'
        },
        emoji: '🇬🇱',
        countryCallingCode: '+299'
    },
    {
        name: 'Palestine',
        capital: 'Ramallah',
        currencies: [
            { name: 'Egyptian pound', symbol: 'E£' },
            { name: 'Israeli new shekel', symbol: '₪' },
            { name: 'Jordanian dinar', symbol: 'JD' }
        ],
        languages: ['Arabic'],
        coordinates: [31.9, 35.2],
        area: 6220,
        maps: {
            googleMaps: 'https://goo.gl/maps/QvTbkRdmdWEoYAmt5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1703814'
        },
        population: 4803269,
        flags: {
            png: 'https://flagcdn.com/w320/ps.png',
            svg: 'https://flagcdn.com/ps.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Finland',
        capital: 'Helsinki',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Finnish', 'Swedish'],
        coordinates: [64, 26],
        area: 338424,
        maps: {
            googleMaps: 'https://goo.gl/maps/HjgWDCNKRAYHrkMn8',
            openStreetMaps: 'openstreetmap.org/relation/54224'
        },
        population: 5530719,
        postalCode: { format: '#####', regex: '^(?:FI)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/fi.png',
            svg: 'https://flagcdn.com/fi.svg'
        },
        emoji: '🇫🇮',
        countryCallingCode: '+358'
    },
    {
        name: 'São Tomé and Príncipe',
        capital: 'São Tomé',
        currencies: [{ name: 'São Tomé and Príncipe dobra', symbol: 'Db' }],
        languages: ['Portuguese'],
        coordinates: [1, 7],
        area: 964,
        maps: {
            googleMaps: 'https://goo.gl/maps/9EUppm13RtPX9oF46',
            openStreetMaps: 'https://www.openstreetmap.org/relation/535880'
        },
        population: 219161,
        flags: {
            png: 'https://flagcdn.com/w320/st.png',
            svg: 'https://flagcdn.com/st.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Honduras',
        capital: 'Tegucigalpa',
        currencies: [{ name: 'Honduran lempira', symbol: 'L' }],
        languages: ['Spanish'],
        coordinates: [15, -86.5],
        area: 112492,
        maps: {
            googleMaps: 'https://goo.gl/maps/BbeJK8Sk2VkMHbdF8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287670'
        },
        population: 9904608,
        postalCode: { format: '@@####', regex: '^([A-Z]{2}\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/hn.png',
            svg: 'https://flagcdn.com/hn.svg'
        },
        emoji: '🇭🇳',
        countryCallingCode: '+504'
    },
    {
        name: 'Dominican Republic',
        capital: 'Santo Domingo',
        currencies: [{ name: 'Dominican peso', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [19, -70.66666666],
        area: 48671,
        maps: {
            googleMaps: 'https://goo.gl/maps/soxooTHxEeiAbn3UA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307828'
        },
        population: 10847904,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/do.png',
            svg: 'https://flagcdn.com/do.svg'
        },
        emoji: '🇩🇴',
        countryCallingCode: '+1 809'
    },
    {
        name: 'British Indian Ocean Territory',
        capital: 'Diego Garcia',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-6, 71.5],
        area: 60,
        maps: {
            googleMaps: 'https://goo.gl/maps/bheNucgekVEYozoi6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1993867'
        },
        population: 3000,
        flags: {
            png: 'https://flagcdn.com/w320/io.png',
            svg: 'https://flagcdn.com/io.svg'
        },
        emoji: '🇮🇴',
        countryCallingCode: '+246'
    },
    {
        name: 'Rwanda',
        capital: 'Kigali',
        currencies: [{ name: 'Rwandan franc', symbol: 'Fr' }],
        languages: ['English', 'French', 'Kinyarwanda'],
        coordinates: [-2, 30],
        area: 26338,
        maps: {
            googleMaps: 'https://goo.gl/maps/j5xb5r7CLqjYbyP86',
            openStreetMaps: 'https://www.openstreetmap.org/relation/171496'
        },
        population: 12952209,
        flags: {
            png: 'https://flagcdn.com/w320/rw.png',
            svg: 'https://flagcdn.com/rw.svg'
        },
        emoji: '🇷🇼',
        countryCallingCode: '+250'
    },
    {
        name: 'Comoros',
        capital: 'Moroni',
        currencies: [{ name: 'Comorian franc', symbol: 'Fr' }],
        languages: ['Arabic', 'French', 'Comorian'],
        coordinates: [-12.16666666, 44.25],
        area: 1862,
        maps: {
            googleMaps: 'https://goo.gl/maps/eas4GP28C1GyStnu6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/535790'
        },
        population: 869595,
        flags: {
            png: 'https://flagcdn.com/w320/km.png',
            svg: 'https://flagcdn.com/km.svg'
        },
        emoji: '🇰🇲',
        countryCallingCode: '+269'
    },
    {
        name: 'Oman',
        capital: 'Muscat',
        currencies: [{ name: 'Omani rial', symbol: 'ر.ع.' }],
        languages: ['Arabic'],
        coordinates: [21, 57],
        area: 309500,
        maps: {
            googleMaps: 'https://goo.gl/maps/L2BoXoAwDDwWecnw5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/305138'
        },
        population: 5106622,
        postalCode: { format: '###', regex: '^(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/om.png',
            svg: 'https://flagcdn.com/om.svg'
        },
        emoji: '🇴🇲',
        countryCallingCode: '+968'
    },
    {
        name: 'Portugal',
        capital: 'Lisbon',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Portuguese'],
        coordinates: [39.5, -8],
        area: 92090,
        maps: {
            googleMaps: 'https://goo.gl/maps/BaTBSyc4GWMmbAKB8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/295480'
        },
        population: 10305564,
        postalCode: { format: '####-###', regex: '^(\\d{7})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pt.png',
            svg: 'https://flagcdn.com/pt.svg'
        },
        emoji: '🇵🇹',
        countryCallingCode: '+351'
    },
    {
        name: 'American Samoa',
        capital: 'Pago Pago',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English', 'Samoan'],
        coordinates: [-14.33333333, -170],
        area: 199,
        maps: {
            googleMaps: 'https://goo.gl/maps/Re9ePMjwP1sFCBFA6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2177187'
        },
        population: 55197,
        flags: {
            png: 'https://flagcdn.com/w320/as.png',
            svg: 'https://flagcdn.com/as.svg'
        },
        emoji: '🇦🇸',
        countryCallingCode: '+1 684'
    },
    {
        name: 'Thailand',
        capital: 'Bangkok',
        currencies: [{ name: 'Thai baht', symbol: '฿' }],
        languages: ['Thai'],
        coordinates: [15, 100],
        area: 513120,
        maps: {
            googleMaps: 'https://goo.gl/maps/qeU6uqsfW4nCCwzw9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2067731'
        },
        population: 69799978,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/th.png',
            svg: 'https://flagcdn.com/th.svg'
        },
        emoji: '🇹🇭',
        countryCallingCode: '+66'
    },
    {
        name: 'Timor-Leste',
        capital: 'Dili',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['Portuguese', 'Tetum'],
        coordinates: [-8.83333333, 125.91666666],
        area: 14874,
        maps: {
            googleMaps: 'https://goo.gl/maps/sFqBC9zjgUXPR1iTA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/305142'
        },
        population: 1318442,
        flags: {
            png: 'https://flagcdn.com/w320/tl.png',
            svg: 'https://flagcdn.com/tl.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Armenia',
        capital: 'Yerevan',
        currencies: [{ name: 'Armenian dram', symbol: '֏' }],
        languages: ['Armenian'],
        coordinates: [40, 45],
        area: 29743,
        maps: {
            googleMaps: 'https://goo.gl/maps/azWUtK9bUQYEyccbA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/364066'
        },
        population: 2963234,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/am.png',
            svg: 'https://flagcdn.com/am.svg'
        },
        emoji: '🇦🇲',
        countryCallingCode: '+374'
    },
    {
        name: 'Kuwait',
        capital: 'Kuwait City',
        currencies: [{ name: 'Kuwaiti dinar', symbol: 'د.ك' }],
        languages: ['Arabic'],
        coordinates: [29.5, 45.75],
        area: 17818,
        maps: {
            googleMaps: 'https://goo.gl/maps/aqr3aNQjS1BAvksJ7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/305099'
        },
        population: 4270563,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/kw.png',
            svg: 'https://flagcdn.com/kw.svg'
        },
        emoji: '🇰🇼',
        countryCallingCode: '+965'
    },
    {
        name: 'South Georgia',
        capital: 'King Edward Point',
        currencies: [{ name: 'Saint Helena pound', symbol: '£' }],
        languages: ['English'],
        coordinates: [-54.5, -37],
        area: 3903,
        maps: {
            googleMaps: 'https://goo.gl/maps/mJzdaBwKBbm2B81q9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1983629'
        },
        population: 30,
        flags: {
            png: 'https://flagcdn.com/w320/gs.png',
            svg: 'https://flagcdn.com/gs.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Saint Martin',
        capital: 'Marigot',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [18.08333333, -63.95],
        area: 53,
        maps: {
            googleMaps: 'https://goo.gl/maps/P9ho9QuJ9EAR28JEA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/63064'
        },
        population: 38659,
        postalCode: { format: '### ###' },
        flags: {
            png: 'https://flagcdn.com/w320/mf.png',
            svg: 'https://flagcdn.com/mf.svg'
        },
        emoji: '🇲🇫',
        countryCallingCode: '+590'
    },
    {
        name: 'Georgia',
        capital: 'Tbilisi',
        currencies: [{ name: 'lari', symbol: '₾' }],
        languages: ['Georgian'],
        coordinates: [42, 43.5],
        area: 69700,
        maps: {
            googleMaps: 'https://goo.gl/maps/bvCaGBePR1ZEDK5cA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/28699'
        },
        population: 3714000,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ge.png',
            svg: 'https://flagcdn.com/ge.svg'
        },
        emoji: '🇬🇪',
        countryCallingCode: '+995'
    },
    {
        name: 'Burundi',
        capital: 'Gitega',
        currencies: [{ name: 'Burundian franc', symbol: 'Fr' }],
        languages: ['French', 'Kirundi'],
        coordinates: [-3.5, 30],
        area: 27834,
        maps: {
            googleMaps: 'https://goo.gl/maps/RXPWoRrB9tfrJpUG7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195269'
        },
        population: 11890781,
        flags: {
            png: 'https://flagcdn.com/w320/bi.png',
            svg: 'https://flagcdn.com/bi.svg'
        },
        emoji: '🇧🇮',
        countryCallingCode: '+257'
    },
    {
        name: 'Anguilla',
        capital: 'The Valley',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [18.25, -63.16666666],
        area: 91,
        maps: {
            googleMaps: 'https://goo.gl/maps/3KgLnEyN7amdno2p9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2177161'
        },
        population: 13452,
        flags: {
            png: 'https://flagcdn.com/w320/ai.png',
            svg: 'https://flagcdn.com/ai.svg'
        },
        emoji: '🇦🇮',
        countryCallingCode: '+1 264'
    },
    {
        name: 'Cambodia',
        capital: 'Phnom Penh',
        currencies: [
            { name: 'Cambodian riel', symbol: '៛' },
            { name: 'United States dollar', symbol: '$' }
        ],
        languages: ['Khmer'],
        coordinates: [13, 105],
        area: 181035,
        maps: {
            googleMaps: 'https://goo.gl/maps/nztQtFSrUXZymJaW8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/49898'
        },
        population: 16718971,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/kh.png',
            svg: 'https://flagcdn.com/kh.svg'
        },
        emoji: '🇰🇭',
        countryCallingCode: '+855'
    },
    {
        name: 'Lesotho',
        capital: 'Maseru',
        currencies: [
            { name: 'Lesotho loti', symbol: 'L' },
            { name: 'South African rand', symbol: 'R' }
        ],
        languages: ['English', 'Sotho'],
        coordinates: [-29.5, 28.5],
        area: 30355,
        maps: {
            googleMaps: 'https://goo.gl/maps/H8gJi5mL4Cmd1SF28',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2093234'
        },
        population: 2142252,
        postalCode: { format: '###', regex: '^(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ls.png',
            svg: 'https://flagcdn.com/ls.svg'
        },
        emoji: '🇱🇸',
        countryCallingCode: '+266'
    },
    {
        name: 'Cayman Islands',
        capital: 'George Town',
        currencies: [{ name: 'Cayman Islands dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [19.5, -80.5],
        area: 264,
        maps: {
            googleMaps: 'https://goo.gl/maps/P3ZVXX3LH63t91hL8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/7269765'
        },
        population: 65720,
        flags: {
            png: 'https://flagcdn.com/w320/ky.png',
            svg: 'https://flagcdn.com/ky.svg'
        },
        emoji: '🇰🇾',
        countryCallingCode: '+1 345'
    },
    {
        name: 'Vanuatu',
        capital: 'Port Vila',
        currencies: [{ name: 'Vanuatu vatu', symbol: 'Vt' }],
        languages: ['Bislama', 'English', 'French'],
        coordinates: [-16, 167],
        area: 12189,
        maps: {
            googleMaps: 'https://goo.gl/maps/hwAjehcT7VfvP5zJ8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2177246'
        },
        population: 307150,
        flags: {
            png: 'https://flagcdn.com/w320/vu.png',
            svg: 'https://flagcdn.com/vu.svg'
        },
        emoji: '🇻🇺',
        countryCallingCode: '+678'
    },
    {
        name: 'Bolivia',
        capital: 'Sucre',
        currencies: [{ name: 'Bolivian boliviano', symbol: 'Bs.' }],
        languages: ['Aymara', 'Guaraní', 'Quechua', 'Spanish'],
        coordinates: [-17, -65],
        area: 1098581,
        maps: {
            googleMaps: 'https://goo.gl/maps/9DfnyfbxNM2g5U9b9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/252645'
        },
        population: 11673029,
        flags: {
            png: 'https://flagcdn.com/w320/bo.png',
            svg: 'https://flagcdn.com/bo.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'United Kingdom',
        capital: 'London',
        currencies: [{ name: 'British pound', symbol: '£' }],
        languages: ['English'],
        coordinates: [54, -2],
        area: 242900,
        maps: {
            googleMaps: 'https://goo.gl/maps/FoDtc3UKMkFsXAjHA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/62149'
        },
        population: 67215293,
        postalCode: {
            format: '@# #@@|@## #@@|@@# #@@|@@## #@@|@#@ #@@|@@#@ #@@|GIR0AA',
            regex:
                '^(([A-Z]\\d{2}[A-Z]{2})|([A-Z]\\d{3}[A-Z]{2})|([A-Z]{2}\\d{2}[A-Z]{2})|([A-Z]{2}\\d{3}[A-Z]{2})|([A-Z]\\d[A-Z]\\d[A-Z]{2})|([A-Z]{2}\\d[A-Z]\\d[A-Z]{2})|(GIR0AA))$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/gb.png',
            svg: 'https://flagcdn.com/gb.svg'
        },
        emoji: '🇬🇧',
        countryCallingCode: '+44'
    },
    {
        name: 'Tonga',
        capital: "Nuku'alofa",
        currencies: [{ name: 'Tongan paʻanga', symbol: 'T$' }],
        languages: ['English', 'Tongan'],
        coordinates: [-20, -175],
        area: 747,
        maps: {
            googleMaps: 'https://goo.gl/maps/p5YALBY2QdEzswRo7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2186665'
        },
        population: 105697,
        flags: {
            png: 'https://flagcdn.com/w320/to.png',
            svg: 'https://flagcdn.com/to.svg'
        },
        emoji: '🇹🇴',
        countryCallingCode: '+676'
    },
    {
        name: 'Spain',
        capital: 'Madrid',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Spanish'],
        coordinates: [40, -4],
        area: 505992,
        maps: {
            googleMaps: 'https://goo.gl/maps/138JaXW8EZzRVitY9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1311341'
        },
        population: 47351567,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/es.png',
            svg: 'https://flagcdn.com/es.svg'
        },
        emoji: '🇪🇸',
        countryCallingCode: '+34'
    },
    {
        name: 'Israel',
        capital: 'Jerusalem',
        currencies: [{ name: 'Israeli new shekel', symbol: '₪' }],
        languages: ['Arabic', 'Hebrew'],
        coordinates: [31.47, 35.13],
        area: 20770,
        maps: {
            googleMaps: 'https://goo.gl/maps/6UY1AH8XeafVwdC97',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1473946'
        },
        population: 9216900,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/il.png',
            svg: 'https://flagcdn.com/il.svg'
        },
        emoji: '🇮🇱',
        countryCallingCode: '+972'
    },
    {
        name: 'Malaysia',
        capital: 'Kuala Lumpur',
        currencies: [{ name: 'Malaysian ringgit', symbol: 'RM' }],
        languages: ['English', 'Malay'],
        coordinates: [2.5, 112.5],
        area: 330803,
        maps: {
            googleMaps: 'https://goo.gl/maps/qrY1PNeUXGyXDcPy6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2108121'
        },
        population: 32365998,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/my.png',
            svg: 'https://flagcdn.com/my.svg'
        },
        emoji: '🇲🇾',
        countryCallingCode: '+60'
    },
    {
        name: 'Curaçao',
        capital: 'Willemstad',
        currencies: [{ name: 'Netherlands Antillean guilder', symbol: 'ƒ' }],
        languages: ['English', 'Dutch', 'Papiamento'],
        coordinates: [12.116667, -68.933333],
        area: 444,
        maps: {
            googleMaps: 'https://goo.gl/maps/9D3hTeA3qKaRT7S16',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1216719'
        },
        population: 155014,
        flags: {
            png: 'https://flagcdn.com/w320/cw.png',
            svg: 'https://flagcdn.com/cw.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'DR Congo',
        capital: 'Kinshasa',
        currencies: [{ name: 'Congolese franc', symbol: 'FC' }],
        languages: ['French', 'Kikongo', 'Lingala', 'Tshiluba', 'Swahili'],
        coordinates: [0, 25],
        area: 2344858,
        maps: {
            googleMaps: 'https://goo.gl/maps/KfhNVn6VqdZXWu8n9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192795'
        },
        population: 89561404,
        flags: {
            png: 'https://flagcdn.com/w320/cd.png',
            svg: 'https://flagcdn.com/cd.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Cuba',
        capital: 'Havana',
        currencies: [
            { name: 'Cuban convertible peso', symbol: '$' },
            { name: 'Cuban peso', symbol: '$' }
        ],
        languages: ['Spanish'],
        coordinates: [21.5, -80],
        area: 109884,
        maps: {
            googleMaps: 'https://goo.gl/maps/1dDw1QfZspfMUTm99',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307833'
        },
        population: 11326616,
        postalCode: { format: 'CP #####', regex: '^(?:CP)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cu.png',
            svg: 'https://flagcdn.com/cu.svg'
        },
        emoji: '🇨🇺',
        countryCallingCode: '+53'
    },
    {
        name: 'Djibouti',
        capital: 'Djibouti',
        currencies: [{ name: 'Djiboutian franc', symbol: 'Fr' }],
        languages: ['Arabic', 'French'],
        coordinates: [11.5, 43],
        area: 23200,
        maps: {
            googleMaps: 'https://goo.gl/maps/V1HWfzN3bS1kwf4C6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192801'
        },
        population: 988002,
        flags: {
            png: 'https://flagcdn.com/w320/dj.png',
            svg: 'https://flagcdn.com/dj.svg'
        },
        emoji: '🇩🇯',
        countryCallingCode: '+253'
    },
    {
        name: 'Chile',
        capital: 'Santiago',
        currencies: [{ name: 'Chilean peso', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [-30, -71],
        area: 756102,
        maps: {
            googleMaps: 'https://goo.gl/maps/XboxyNHh2fAjCPNn9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/167454'
        },
        population: 19116209,
        postalCode: { format: '#######', regex: '^(\\d{7})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cl.png',
            svg: 'https://flagcdn.com/cl.svg'
        },
        emoji: '🇨🇱',
        countryCallingCode: '+56'
    },
    {
        name: 'Bosnia and Herzegovina',
        capital: 'Sarajevo',
        currencies: [{ name: 'Bosnia and Herzegovina convertible mark' }],
        languages: ['Bosnian', 'Croatian', 'Serbian'],
        coordinates: [44, 18],
        area: 51209,
        maps: {
            googleMaps: 'https://www.google.com/maps/place/Bosnia+and+Herzegovina',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2528142'
        },
        population: 3280815,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ba.png',
            svg: 'https://flagcdn.com/ba.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Singapore',
        capital: 'Singapore',
        currencies: [{ name: 'Singapore dollar', symbol: '$' }],
        languages: ['Chinese', 'English', 'Malay', 'Tamil'],
        coordinates: [1.36666666, 103.8],
        area: 710,
        maps: {
            googleMaps: 'https://goo.gl/maps/QbQt9Y9b5KFzsahV6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536780'
        },
        population: 5685807,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sg.png',
            svg: 'https://flagcdn.com/sg.svg'
        },
        emoji: '🇸🇬',
        countryCallingCode: '+65'
    },
    {
        name: 'French Guiana',
        capital: 'Cayenne',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [4, -53],
        area: 83534,
        maps: {
            googleMaps: 'https://goo.gl/maps/NJawFwMzG7YtCrVP7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2502058'
        },
        population: 254541,
        postalCode: { format: '#####', regex: '^((97|98)3\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gf.png',
            svg: 'https://flagcdn.com/gf.svg'
        },
        emoji: '🇬🇫',
        countryCallingCode: '+594'
    },
    {
        name: 'Suriname',
        capital: 'Paramaribo',
        currencies: [{ name: 'Surinamese dollar', symbol: '$' }],
        languages: ['Dutch'],
        coordinates: [4, -56],
        area: 163820,
        maps: {
            googleMaps: 'https://goo.gl/maps/iy7TuQLSi4qgoBoG7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287082'
        },
        population: 586634,
        flags: {
            png: 'https://flagcdn.com/w320/sr.png',
            svg: 'https://flagcdn.com/sr.svg'
        },
        emoji: '🇸🇷',
        countryCallingCode: '+597'
    },
    {
        name: 'Eswatini',
        capital: 'Mbabane',
        currencies: [
            { name: 'Swazi lilangeni', symbol: 'L' },
            { name: 'South African rand', symbol: 'R' }
        ],
        languages: ['English', 'Swazi'],
        coordinates: [-26.5, 31.5],
        area: 17364,
        maps: {
            googleMaps: 'https://goo.gl/maps/cUY79eqQihFSE8hV6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/88210'
        },
        population: 1160164,
        postalCode: { format: '@###', regex: '^([A-Z]\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sz.png',
            svg: 'https://flagcdn.com/sz.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Belgium',
        capital: 'Brussels',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['German', 'French', 'Dutch'],
        coordinates: [50.83333333, 4],
        area: 30528,
        maps: {
            googleMaps: 'https://goo.gl/maps/UQQzat85TCtPRXAL8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/52411'
        },
        population: 11555997,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/be.png',
            svg: 'https://flagcdn.com/be.svg'
        },
        emoji: '🇧🇪',
        countryCallingCode: '+32'
    },
    {
        name: 'China',
        capital: 'Beijing',
        currencies: [{ name: 'Chinese yuan', symbol: '¥' }],
        languages: ['Chinese'],
        coordinates: [35, 105],
        area: 9706961,
        maps: {
            googleMaps: 'https://goo.gl/maps/p9qC6vgiFRRXzvGi7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/270056'
        },
        population: 1402112000,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cn.png',
            svg: 'https://flagcdn.com/cn.svg'
        },
        emoji: '🇨🇳',
        countryCallingCode: '+86'
    },
    {
        name: 'Saint Vincent and the Grenadines',
        capital: 'Kingstown',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [13.25, -61.2],
        area: 389,
        maps: {
            googleMaps: 'https://goo.gl/maps/wMbnMqjG37FMnrwf7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/550725'
        },
        population: 110947,
        flags: {
            png: 'https://flagcdn.com/w320/vc.png',
            svg: 'https://flagcdn.com/vc.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Nicaragua',
        capital: 'Managua',
        currencies: [{ name: 'Nicaraguan córdoba', symbol: 'C$' }],
        languages: ['Spanish'],
        coordinates: [13, -85],
        area: 130373,
        maps: {
            googleMaps: 'https://goo.gl/maps/P77LaEVkKJKXneRC6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287666'
        },
        population: 6624554,
        postalCode: { format: '###-###-#', regex: '^(\\d{7})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ni.png',
            svg: 'https://flagcdn.com/ni.svg'
        },
        emoji: '🇳🇮',
        countryCallingCode: '+505'
    },
    {
        name: 'Canada',
        capital: 'Ottawa',
        currencies: [{ name: 'Canadian dollar', symbol: '$' }],
        languages: ['English', 'French'],
        coordinates: [60, -95],
        area: 9984670,
        maps: {
            googleMaps: 'https://goo.gl/maps/jmEVLugreeqiZXxbA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1428125'
        },
        population: 38005238,
        postalCode: {
            format: '@#@ #@#',
            regex:
                '^([ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\\d[ABCEGHJKLMNPRSTVWXYZ]\\d)$ '
        },
        flags: {
            png: 'https://flagcdn.com/w320/ca.png',
            svg: 'https://flagcdn.com/ca.svg'
        },
        emoji: '🇨🇦',
        countryCallingCode: '+1'
    },
    {
        name: 'Togo',
        capital: 'Lomé',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [8, 1.16666666],
        area: 56785,
        maps: {
            googleMaps: 'https://goo.gl/maps/jzAa9feXuXPrKVb89',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192782'
        },
        population: 8278737,
        flags: {
            png: 'https://flagcdn.com/w320/tg.png',
            svg: 'https://flagcdn.com/tg.svg'
        },
        emoji: '🇹🇬',
        countryCallingCode: '+228'
    },
    {
        name: 'Ivory Coast',
        capital: 'Yamoussoukro',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [8, -5],
        area: 322463,
        maps: {
            googleMaps: 'https://goo.gl/maps/wKsmN7f5qAeNtGjP6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192779'
        },
        population: 26378275,
        flags: {
            png: 'https://flagcdn.com/w320/ci.png',
            svg: 'https://flagcdn.com/ci.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Slovenia',
        capital: 'Ljubljana',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Slovene'],
        coordinates: [46.11666666, 14.81666666],
        area: 20273,
        maps: {
            googleMaps: 'https://goo.gl/maps/7zgFmswcCJh5L5D49',
            openStreetMaps: 'https://www.openstreetmap.org/relation/218657'
        },
        population: 2100126,
        postalCode: { format: 'SI- ####', regex: '^(?:SI)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/si.png',
            svg: 'https://flagcdn.com/si.svg'
        },
        emoji: '🇸🇮',
        countryCallingCode: '+386'
    },
    {
        name: 'Sri Lanka',
        capital: 'Sri Jayawardenepura Kotte',
        currencies: [{ name: 'Sri Lankan rupee', symbol: 'Rs  රු' }],
        languages: ['Sinhala', 'Tamil'],
        coordinates: [7, 81],
        area: 65610,
        maps: {
            googleMaps: 'https://goo.gl/maps/VkPHoeFSfgzRQCDv8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536807'
        },
        population: 21919000,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/lk.png',
            svg: 'https://flagcdn.com/lk.svg'
        },
        emoji: '🇱🇰',
        countryCallingCode: '+94'
    },
    {
        name: 'Greece',
        capital: 'Athens',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Greek'],
        coordinates: [39, 22],
        area: 131990,
        maps: {
            googleMaps: 'https://goo.gl/maps/LHGcAvuRyD2iKECC6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192307'
        },
        population: 10715549,
        postalCode: { format: '### ##', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gr.png',
            svg: 'https://flagcdn.com/gr.svg'
        },
        emoji: '🇬🇷',
        countryCallingCode: '+30'
    },
    {
        name: 'Jamaica',
        capital: 'Kingston',
        currencies: [{ name: 'Jamaican dollar', symbol: '$' }],
        languages: ['English', 'Jamaican Patois'],
        coordinates: [18.25, -77.5],
        area: 10991,
        maps: {
            googleMaps: 'https://goo.gl/maps/Z8mQ6jxnRQKFwJy9A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/555017'
        },
        population: 2961161,
        flags: {
            png: 'https://flagcdn.com/w320/jm.png',
            svg: 'https://flagcdn.com/jm.svg'
        },
        emoji: '🇯🇲',
        countryCallingCode: '+1 876'
    },
    {
        name: 'Italy',
        capital: 'Rome',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Italian'],
        coordinates: [42.83333333, 12.83333333],
        area: 301336,
        maps: {
            googleMaps: 'https://goo.gl/maps/8M1K27TDj7StTRTq8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/365331'
        },
        population: 59554023,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/it.png',
            svg: 'https://flagcdn.com/it.svg'
        },
        emoji: '🇮🇹',
        countryCallingCode: '+39'
    },
    {
        name: 'Croatia',
        capital: 'Zagreb',
        currencies: [{ name: 'Croatian kuna', symbol: 'kn' }],
        languages: ['Croatian'],
        coordinates: [45.16666666, 15.5],
        area: 56594,
        maps: {
            googleMaps: 'https://goo.gl/maps/qSG6xTKUmrYpwmGQ6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/214885'
        },
        population: 4047200,
        postalCode: { format: 'HR-#####', regex: '^(?:HR)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/hr.png',
            svg: 'https://flagcdn.com/hr.svg'
        },
        emoji: '🇭🇷',
        countryCallingCode: '+385'
    },
    {
        name: 'New Caledonia',
        capital: 'Nouméa',
        currencies: [{ name: 'CFP franc', symbol: '₣' }],
        languages: ['French'],
        coordinates: [-21.5, 165.5],
        area: 18575,
        maps: {
            googleMaps: 'https://goo.gl/maps/cBhtCeMdob4U7FRU9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/3407643'
        },
        population: 271960,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/nc.png',
            svg: 'https://flagcdn.com/nc.svg'
        },
        emoji: '🇳🇨',
        countryCallingCode: '+687'
    },
    {
        name: 'Pakistan',
        capital: 'Islamabad',
        currencies: [{ name: 'Pakistani rupee', symbol: '₨' }],
        languages: ['English', 'Urdu'],
        coordinates: [30, 70],
        area: 881912,
        maps: {
            googleMaps: 'https://goo.gl/maps/5LYujdfR5yLUXoERA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307573'
        },
        population: 220892331,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pk.png',
            svg: 'https://flagcdn.com/pk.svg'
        },
        emoji: '🇵🇰',
        countryCallingCode: '+92'
    },
    {
        name: 'Hong Kong',
        capital: 'City of Victoria',
        currencies: [{ name: 'Hong Kong dollar', symbol: '$' }],
        languages: ['English', 'Chinese'],
        coordinates: [22.267, 114.188],
        area: 1104,
        maps: {
            googleMaps: 'https://goo.gl/maps/1sEnNmT47ffrC8MU8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/913110'
        },
        population: 7500700,
        flags: {
            png: 'https://flagcdn.com/w320/hk.png',
            svg: 'https://flagcdn.com/hk.svg'
        },
        emoji: '🇭🇰',
        countryCallingCode: '+852'
    },
    {
        name: 'Latvia',
        capital: 'Riga',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Latvian'],
        coordinates: [57, 25],
        area: 64559,
        maps: {
            googleMaps: 'https://goo.gl/maps/iQpUkH7ghq31ZtXe9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/72594'
        },
        population: 1901548,
        postalCode: { format: 'LV-####', regex: '^(?:LV)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/lv.png',
            svg: 'https://flagcdn.com/lv.svg'
        },
        emoji: '🇱🇻',
        countryCallingCode: '+371'
    },
    {
        name: 'Lebanon',
        capital: 'Beirut',
        currencies: [{ name: 'Lebanese pound', symbol: 'ل.ل' }],
        languages: ['Arabic', 'French'],
        coordinates: [33.83333333, 35.83333333],
        area: 10452,
        maps: {
            googleMaps: 'https://goo.gl/maps/Sz5VCU8UFBqMyTdc9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184843'
        },
        population: 6825442,
        postalCode: {
            format: '#### ####|####',
            regex: '^(\\d{4}(\\d{4})?)$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/lb.png',
            svg: 'https://flagcdn.com/lb.svg'
        },
        emoji: '🇱🇧',
        countryCallingCode: '+961'
    },
    {
        name: 'Mauritius',
        capital: 'Port Louis',
        currencies: [{ name: 'Mauritian rupee', symbol: '₨' }],
        languages: ['English', 'French', 'Mauritian Creole'],
        coordinates: [-20.28333333, 57.55],
        area: 2040,
        maps: {
            googleMaps: 'https://goo.gl/maps/PpKtZ4W3tir5iGrz7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/535828'
        },
        population: 1265740,
        flags: {
            png: 'https://flagcdn.com/w320/mu.png',
            svg: 'https://flagcdn.com/mu.svg'
        },
        emoji: '🇲🇺',
        countryCallingCode: '+230'
    },
    {
        name: 'Guinea',
        capital: 'Conakry',
        currencies: [{ name: 'Guinean franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [11, -10],
        area: 245857,
        maps: {
            googleMaps: 'https://goo.gl/maps/8J5oM5sA4Ayr1ZYGA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192778'
        },
        population: 13132792,
        flags: {
            png: 'https://flagcdn.com/w320/gn.png',
            svg: 'https://flagcdn.com/gn.svg'
        },
        emoji: '🇬🇳',
        countryCallingCode: '+224'
    },
    {
        name: 'Republic of the Congo',
        capital: 'Brazzaville',
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['French', 'Kikongo', 'Lingala'],
        coordinates: [-1, 15],
        area: 342000,
        maps: {
            googleMaps: 'https://goo.gl/maps/Phf5dDDKdfCtuBTb6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192794'
        },
        population: 89561404,
        flags: {
            png: 'https://flagcdn.com/w320/cg.png',
            svg: 'https://flagcdn.com/cg.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Grenada',
        capital: "St. George's",
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [12.11666666, -61.66666666],
        area: 344,
        maps: {
            googleMaps: 'https://goo.gl/maps/rqWyfUAt4xhvk1Zy9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/550727'
        },
        population: 112519,
        flags: {
            png: 'https://flagcdn.com/w320/gd.png',
            svg: 'https://flagcdn.com/gd.svg'
        },
        emoji: '🇬🇩',
        countryCallingCode: '+473'
    },
    {
        name: 'Eritrea',
        capital: 'Asmara',
        currencies: [{ name: 'Eritrean nakfa', symbol: 'Nfk' }],
        languages: ['Arabic', 'English', 'Tigrinya'],
        coordinates: [15, 39],
        area: 117600,
        maps: {
            googleMaps: 'https://goo.gl/maps/HRyqUpnPwwG6jY5j6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/296961'
        },
        population: 5352000,
        flags: {
            png: 'https://flagcdn.com/w320/er.png',
            svg: 'https://flagcdn.com/er.svg'
        },
        emoji: '🇪🇷',
        countryCallingCode: '+291'
    },
    {
        name: 'Barbados',
        capital: 'Bridgetown',
        currencies: [{ name: 'Barbadian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [13.16666666, -59.53333333],
        area: 430,
        maps: {
            googleMaps: 'https://goo.gl/maps/2m36v8STvbGAWd9c7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/547511'
        },
        population: 287371,
        postalCode: { format: 'BB#####', regex: '^(?:BB)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/bb.png',
            svg: 'https://flagcdn.com/bb.svg'
        },
        emoji: '🇧🇧',
        countryCallingCode: '+1 246'
    },
    {
        name: 'Tajikistan',
        capital: 'Dushanbe',
        currencies: [{ name: 'Tajikistani somoni', symbol: 'ЅМ' }],
        languages: ['Russian', 'Tajik'],
        coordinates: [39, 71],
        area: 143100,
        maps: {
            googleMaps: 'https://goo.gl/maps/8rQgW88jEXijhVb58',
            openStreetMaps: 'https://www.openstreetmap.org/relation/214626'
        },
        population: 9537642,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/tj.png',
            svg: 'https://flagcdn.com/tj.svg'
        },
        emoji: '🇹🇯',
        countryCallingCode: '+992'
    },
    {
        name: 'Burkina Faso',
        capital: 'Ouagadougou',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [13, -2],
        area: 272967,
        maps: {
            googleMaps: 'https://goo.gl/maps/rKRmpcMbFher2ozb7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192783'
        },
        population: 20903278,
        flags: {
            png: 'https://flagcdn.com/w320/bf.png',
            svg: 'https://flagcdn.com/bf.svg'
        },
        emoji: '🇧🇫',
        countryCallingCode: '+226'
    },
    {
        name: 'Macau',
        capital: 'Unknown',
        currencies: [{ name: 'Macanese pataca', symbol: 'P' }],
        languages: ['Portuguese', 'Chinese'],
        coordinates: [22.16666666, 113.55],
        area: 30,
        maps: {
            googleMaps: 'https://goo.gl/maps/whymRdk3dZFfAAs4A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1867188'
        },
        population: 649342,
        flags: {
            png: 'https://flagcdn.com/w320/mo.png',
            svg: 'https://flagcdn.com/mo.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Marshall Islands',
        capital: 'Majuro',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English', 'Marshallese'],
        coordinates: [9, 168],
        area: 181,
        maps: {
            googleMaps: 'https://goo.gl/maps/A4xLi1XvcX88gi3W8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571771'
        },
        population: 59194,
        flags: {
            png: 'https://flagcdn.com/w320/mh.png',
            svg: 'https://flagcdn.com/mh.svg'
        },
        emoji: '🇲🇭',
        countryCallingCode: '+692'
    },
    {
        name: 'Belarus',
        capital: 'Minsk',
        currencies: [{ name: 'Belarusian ruble', symbol: 'Br' }],
        languages: ['Belarusian', 'Russian'],
        coordinates: [53, 28],
        area: 207600,
        maps: {
            googleMaps: 'https://goo.gl/maps/PJUDU3EBPSszCQcu6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/59065'
        },
        population: 9398861,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/by.png',
            svg: 'https://flagcdn.com/by.svg'
        },
        emoji: '🇧🇾',
        countryCallingCode: '+375'
    },
    {
        name: 'Mayotte',
        capital: 'Mamoudzou',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [-12.83333333, 45.16666666],
        area: 374,
        maps: {
            googleMaps: 'https://goo.gl/maps/1e7MXmfBwQv3TQGF7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1259885'
        },
        population: 226915,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/yt.png',
            svg: 'https://flagcdn.com/yt.svg'
        },
        emoji: '🇾🇹',
        countryCallingCode: '+262'
    },
    {
        name: 'Zambia',
        capital: 'Lusaka',
        currencies: [{ name: 'Zambian kwacha', symbol: 'ZK' }],
        languages: ['English'],
        coordinates: [-15, 30],
        area: 752612,
        maps: {
            googleMaps: 'https://goo.gl/maps/mweBcqvW8TppZW6q9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195271'
        },
        population: 18383956,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/zm.png',
            svg: 'https://flagcdn.com/zm.svg'
        },
        emoji: '🇿🇲',
        countryCallingCode: '+260'
    },
    {
        name: 'Iceland',
        capital: 'Reykjavik',
        currencies: [{ name: 'Icelandic króna', symbol: 'kr' }],
        languages: ['Icelandic'],
        coordinates: [65, -18],
        area: 103000,
        maps: {
            googleMaps: 'https://goo.gl/maps/WxFWSQuc3oamNxoE6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/299133'
        },
        population: 366425,
        postalCode: { format: '###', regex: '^(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/is.png',
            svg: 'https://flagcdn.com/is.svg'
        },
        emoji: '🇮🇸',
        countryCallingCode: '+354'
    },
    {
        name: 'Saint Lucia',
        capital: 'Castries',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [13.88333333, -60.96666666],
        area: 616,
        maps: {
            googleMaps: 'https://goo.gl/maps/4HhJ2jkPdSL9BPRcA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/550728'
        },
        population: 183629,
        flags: {
            png: 'https://flagcdn.com/w320/lc.png',
            svg: 'https://flagcdn.com/lc.svg'
        },
        emoji: '🇱🇨',
        countryCallingCode: '+1 758'
    },
    {
        name: 'Vietnam',
        capital: 'Hanoi',
        currencies: [{ name: 'Vietnamese đồng', symbol: '₫' }],
        languages: ['Vietnamese'],
        coordinates: [16.16666666, 107.83333333],
        area: 331212,
        maps: {
            googleMaps: 'https://goo.gl/maps/PCpVt9WzdJ9A9nEZ9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/49915'
        },
        population: 97338583,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/vn.png',
            svg: 'https://flagcdn.com/vn.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Brazil',
        capital: 'Brasília',
        currencies: [{ name: 'Brazilian real', symbol: 'R$' }],
        languages: ['Portuguese'],
        coordinates: [-10, -55],
        area: 8515767,
        maps: {
            googleMaps: 'https://goo.gl/maps/waCKk21HeeqFzkNC9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/59470'
        },
        population: 212559409,
        postalCode: { format: '#####-###', regex: '^(\\d{8})$' },
        flags: {
            png: 'https://flagcdn.com/w320/br.png',
            svg: 'https://flagcdn.com/br.svg'
        },
        emoji: '🇧🇷',
        countryCallingCode: '+55'
    },
    {
        name: 'Myanmar',
        capital: 'Naypyidaw',
        currencies: [{ name: 'Burmese kyat', symbol: 'Ks' }],
        languages: ['Burmese'],
        coordinates: [22, 98],
        area: 676578,
        maps: {
            googleMaps: 'https://goo.gl/maps/4jrZyJkDERUfHyp26',
            openStreetMaps: 'https://www.openstreetmap.org/relation/50371'
        },
        population: 54409794,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mm.png',
            svg: 'https://flagcdn.com/mm.svg'
        },
        emoji: '🇲🇲',
        countryCallingCode: '+95'
    },
    {
        name: 'Senegal',
        capital: 'Dakar',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [14, -14],
        area: 196722,
        maps: {
            googleMaps: 'https://goo.gl/maps/o5f1uD5nyihCL3HCA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192775'
        },
        population: 16743930,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sn.png',
            svg: 'https://flagcdn.com/sn.svg'
        },
        emoji: '🇸🇳',
        countryCallingCode: '+221'
    },
    {
        name: 'Slovakia',
        capital: 'Bratislava',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Slovak'],
        coordinates: [48.66666666, 19.5],
        area: 49037,
        maps: {
            googleMaps: 'https://goo.gl/maps/uNSH2wW4bLoZVYJj7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/14296'
        },
        population: 5458827,
        postalCode: { format: '###  ##', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sk.png',
            svg: 'https://flagcdn.com/sk.svg'
        },
        emoji: '🇸🇰',
        countryCallingCode: '+421'
    },
    {
        name: 'Philippines',
        capital: 'Manila',
        currencies: [{ name: 'Philippine peso', symbol: '₱' }],
        languages: ['English', 'Filipino'],
        coordinates: [13, 122],
        area: 342353,
        maps: {
            googleMaps: 'https://goo.gl/maps/k8T2fb5VMUfsWFX6A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/443174'
        },
        population: 109581085,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ph.png',
            svg: 'https://flagcdn.com/ph.svg'
        },
        emoji: '🇵🇭',
        countryCallingCode: '+63'
    },
    {
        name: 'Albania',
        capital: 'Tirana',
        currencies: [{ name: 'Albanian lek', symbol: 'L' }],
        languages: ['Albanian'],
        coordinates: [41, 20],
        area: 28748,
        maps: {
            googleMaps: 'https://goo.gl/maps/BzN9cTuj68ZA8SyZ8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/53292'
        },
        population: 2837743,
        flags: {
            png: 'https://flagcdn.com/w320/al.png',
            svg: 'https://flagcdn.com/al.svg'
        },
        emoji: '🇦🇱',
        countryCallingCode: '+355'
    },
    {
        name: 'Montenegro',
        capital: 'Podgorica',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Montenegrin'],
        coordinates: [42.5, 19.3],
        area: 13812,
        maps: {
            googleMaps: 'https://goo.gl/maps/4THX1fM7WqANuPbB8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/53296'
        },
        population: 621718,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/me.png',
            svg: 'https://flagcdn.com/me.svg'
        },
        emoji: '🇲🇪',
        countryCallingCode: '+382'
    },
    {
        name: 'Gabon',
        capital: 'Libreville',
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [-1, 11.75],
        area: 267668,
        maps: {
            googleMaps: 'https://www.openstreetmap.org/relation/192793',
            openStreetMaps: 'https://goo.gl/maps/vyRSkqw1H1fnq4ry6'
        },
        population: 2225728,
        flags: {
            png: 'https://flagcdn.com/w320/ga.png',
            svg: 'https://flagcdn.com/ga.svg'
        },
        emoji: '🇬🇦',
        countryCallingCode: '+241'
    },
    {
        name: 'Qatar',
        capital: 'Doha',
        currencies: [{ name: 'Qatari riyal', symbol: 'ر.ق' }],
        languages: ['Arabic'],
        coordinates: [25.5, 51.25],
        area: 11586,
        maps: {
            googleMaps: 'https://goo.gl/maps/ZV76Y49z7LLUZ2KQ6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/305095'
        },
        population: 2881060,
        flags: {
            png: 'https://flagcdn.com/w320/qa.png',
            svg: 'https://flagcdn.com/qa.svg'
        },
        emoji: '🇶🇦',
        countryCallingCode: '+974'
    },
    {
        name: 'Venezuela',
        capital: 'Caracas',
        currencies: [{ name: 'Venezuelan bolívar soberano', symbol: 'Bs.S.' }],
        languages: ['Spanish'],
        coordinates: [8, -66],
        area: 916445,
        maps: {
            googleMaps: 'https://goo.gl/maps/KLCwDN8sec7z2kse9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/272644'
        },
        population: 28435943,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ve.png',
            svg: 'https://flagcdn.com/ve.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Gibraltar',
        capital: 'Gibraltar',
        currencies: [{ name: 'Gibraltar pound', symbol: '£' }],
        languages: ['English'],
        coordinates: [36.13333333, -5.35],
        area: 6,
        maps: {
            googleMaps: 'https://goo.gl/maps/CEoHAs1t6byCBhHFA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1278736'
        },
        population: 33691,
        flags: {
            png: 'https://flagcdn.com/w320/gi.png',
            svg: 'https://flagcdn.com/gi.svg'
        },
        emoji: '🇬🇮',
        countryCallingCode: '+350'
    },
    {
        name: 'Niue',
        capital: 'Alofi',
        currencies: [{ name: 'New Zealand dollar', symbol: '$' }],
        languages: ['English', 'Niuean'],
        coordinates: [-19.03333333, -169.86666666],
        area: 260,
        maps: {
            googleMaps: 'https://goo.gl/maps/xFgdzs3E55Rk1y8P9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1558556'
        },
        population: 1470,
        flags: {
            png: 'https://flagcdn.com/w320/nu.png',
            svg: 'https://flagcdn.com/nu.svg'
        },
        emoji: '🇳🇺',
        countryCallingCode: '+683'
    },
    {
        name: 'Samoa',
        capital: 'Apia',
        currencies: [{ name: 'Samoan tālā', symbol: 'T' }],
        languages: ['English', 'Samoan'],
        coordinates: [-13.58333333, -172.33333333],
        area: 2842,
        maps: {
            googleMaps: 'https://goo.gl/maps/CFC9fEFP9cfkYUBF9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1872673'
        },
        population: 198410,
        flags: {
            png: 'https://flagcdn.com/w320/ws.png',
            svg: 'https://flagcdn.com/ws.svg'
        },
        emoji: '🇼🇸',
        countryCallingCode: '+685'
    },
    {
        name: 'Antigua and Barbuda',
        capital: "Saint John's",
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [17.05, -61.8],
        area: 442,
        maps: {
            googleMaps: 'https://goo.gl/maps/fnye4wGJ1RzC9jpX9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536900'
        },
        population: 97928,
        flags: {
            png: 'https://flagcdn.com/w320/ag.png',
            svg: 'https://flagcdn.com/ag.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Liberia',
        capital: 'Monrovia',
        currencies: [{ name: 'Liberian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [6.5, -9.5],
        area: 111369,
        maps: {
            googleMaps: 'https://goo.gl/maps/4VsHsc2oeGeRL3wg6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192780'
        },
        population: 5057677,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/lr.png',
            svg: 'https://flagcdn.com/lr.svg'
        },
        emoji: '🇱🇷',
        countryCallingCode: '+231'
    },
    {
        name: 'Belize',
        capital: 'Belmopan',
        currencies: [{ name: 'Belize dollar', symbol: '$' }],
        languages: ['Belizean Creole', 'English', 'Spanish'],
        coordinates: [17.25, -88.75],
        area: 22966,
        maps: {
            googleMaps: 'https://goo.gl/maps/jdCccpdLodm1uTmo9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287827'
        },
        population: 397621,
        flags: {
            png: 'https://flagcdn.com/w320/bz.png',
            svg: 'https://flagcdn.com/bz.svg'
        },
        emoji: '🇧🇿',
        countryCallingCode: '+501'
    },
    {
        name: 'Equatorial Guinea',
        capital: 'Malabo',
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['French', 'Portuguese', 'Spanish'],
        coordinates: [2, 10],
        area: 28051,
        maps: {
            googleMaps: 'https://goo.gl/maps/ucWfFd8aW1FbGMva9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192791'
        },
        population: 1402985,
        flags: {
            png: 'https://flagcdn.com/w320/gq.png',
            svg: 'https://flagcdn.com/gq.svg'
        },
        emoji: '🇬🇶',
        countryCallingCode: '+240'
    },
    {
        name: 'Yemen',
        capital: "Sana'a",
        currencies: [{ name: 'Yemeni rial', symbol: '﷼' }],
        languages: ['Arabic'],
        coordinates: [15, 48],
        area: 527968,
        maps: {
            googleMaps: 'https://goo.gl/maps/WCmE76HKcLideQQw7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/305092'
        },
        population: 29825968,
        flags: {
            png: 'https://flagcdn.com/w320/ye.png',
            svg: 'https://flagcdn.com/ye.svg'
        },
        emoji: '🇾🇪',
        countryCallingCode: '+967'
    },
    {
        name: 'Tanzania',
        capital: 'Dodoma',
        currencies: [{ name: 'Tanzanian shilling', symbol: 'Sh' }],
        languages: ['English', 'Swahili'],
        coordinates: [-6, 35],
        area: 945087,
        maps: {
            googleMaps: 'https://goo.gl/maps/NWYMqZYXte4zGZ2Q8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195270'
        },
        population: 59734213,
        flags: {
            png: 'https://flagcdn.com/w320/tz.png',
            svg: 'https://flagcdn.com/tz.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Australia',
        capital: 'Canberra',
        currencies: [{ name: 'Australian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-27, 133],
        area: 7692024,
        maps: {
            googleMaps: 'https://goo.gl/maps/DcjaDa7UbhnZTndH6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/80500'
        },
        population: 25687041,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/au.png',
            svg: 'https://flagcdn.com/au.svg'
        },
        emoji: '🇦🇺',
        countryCallingCode: '+61'
    },
    {
        name: 'Bermuda',
        capital: 'Hamilton',
        currencies: [{ name: 'Bermudian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [32.33333333, -64.75],
        area: 54,
        maps: {
            googleMaps: 'https://goo.gl/maps/NLsRGNjTzDghTtAJA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1993208'
        },
        population: 63903,
        postalCode: { format: '@@ ##', regex: '^([A-Z]{2}\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/bm.png',
            svg: 'https://flagcdn.com/bm.svg'
        },
        emoji: '🇧🇲',
        countryCallingCode: '+1 441'
    },
    {
        name: 'Saint Pierre and Miquelon',
        capital: 'Saint-Pierre',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [46.83333333, -56.33333333],
        area: 242,
        maps: {
            googleMaps: 'https://goo.gl/maps/bUM8Yc8pA8ghyhmt6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/3406826'
        },
        population: 6069,
        postalCode: { format: '#####', regex: '^(97500)$' },
        flags: {
            png: 'https://flagcdn.com/w320/pm.png',
            svg: 'https://flagcdn.com/pm.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Mongolia',
        capital: 'Ulan Bator',
        currencies: [{ name: 'Mongolian tögrög', symbol: '₮' }],
        languages: ['Mongolian'],
        coordinates: [46, 105],
        area: 1564110,
        maps: {
            googleMaps: 'https://goo.gl/maps/A1X7bMCKThBDNjzH6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/161033'
        },
        population: 3278292,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mn.png',
            svg: 'https://flagcdn.com/mn.svg'
        },
        emoji: '🇲🇳',
        countryCallingCode: '+976'
    },
    {
        name: 'Malta',
        capital: 'Valletta',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['English', 'Maltese'],
        coordinates: [35.83333333, 14.58333333],
        area: 316,
        maps: {
            googleMaps: 'https://goo.gl/maps/skXCqguxDxxEKVk47',
            openStreetMaps: 'https://www.openstreetmap.org/relation/365307'
        },
        population: 525285,
        postalCode: {
            format: '@@@ ###|@@@ ##',
            regex: '^([A-Z]{3}\\d{2}\\d?)$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/mt.png',
            svg: 'https://flagcdn.com/mt.svg'
        },
        emoji: '🇲🇹',
        countryCallingCode: '+356'
    },
    {
        name: 'Luxembourg',
        capital: 'Luxembourg',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['German', 'French', 'Luxembourgish'],
        coordinates: [49.75, 6.16666666],
        area: 2586,
        maps: {
            googleMaps: 'https://goo.gl/maps/L6b2AgndgHprt2Ko9',
            openStreetMaps:
                'https://www.openstreetmap.org/relation/2171347#map=10/49.8167/6.1335'
        },
        population: 632275,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/lu.png',
            svg: 'https://flagcdn.com/lu.svg'
        },
        emoji: '🇱🇺',
        countryCallingCode: '+352'
    },
    {
        name: 'Liechtenstein',
        capital: 'Vaduz',
        currencies: [{ name: 'Swiss franc', symbol: 'Fr' }],
        languages: ['German'],
        coordinates: [47.26666666, 9.53333333],
        area: 160,
        maps: {
            googleMaps: 'https://goo.gl/maps/KNuHeiJzAPodwM7y6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1155955'
        },
        population: 38137,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/li.png',
            svg: 'https://flagcdn.com/li.svg'
        },
        emoji: '🇱🇮',
        countryCallingCode: '+423'
    },
    {
        name: 'Poland',
        capital: 'Warsaw',
        currencies: [{ name: 'Polish złoty', symbol: 'zł' }],
        languages: ['Polish'],
        coordinates: [52, 20],
        area: 312679,
        maps: {
            googleMaps: 'https://goo.gl/maps/gY9Xw4Sf4415P4949',
            openStreetMaps: 'https://www.openstreetmap.org/relation/49715'
        },
        population: 37950802,
        postalCode: { format: '##-###', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pl.png',
            svg: 'https://flagcdn.com/pl.svg'
        },
        emoji: '🇵🇱',
        countryCallingCode: '+48'
    },
    {
        name: 'Brunei',
        capital: 'Bandar Seri Begawan',
        currencies: [
            { name: 'Brunei dollar', symbol: '$' },
            { name: 'Singapore dollar', symbol: '$' }
        ],
        languages: ['Malay'],
        coordinates: [4.5, 114.66666666],
        area: 5765,
        maps: {
            googleMaps: 'https://goo.gl/maps/4jb4CqBXhr8vNh579',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2103120'
        },
        population: 437483,
        postalCode: { format: '@@####', regex: '^([A-Z]{2}\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/bn.png',
            svg: 'https://flagcdn.com/bn.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Nepal',
        capital: 'Kathmandu',
        currencies: [{ name: 'Nepalese rupee', symbol: '₨' }],
        languages: ['Nepali'],
        coordinates: [28, 84],
        area: 147181,
        maps: {
            googleMaps: 'https://goo.gl/maps/UMj2zpbQp7B5c3yT7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184633'
        },
        population: 29136808,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/np.png',
            svg: 'https://flagcdn.com/np.svg'
        },
        emoji: '🇳🇵',
        countryCallingCode: '+977'
    },
    {
        name: 'Argentina',
        capital: 'Buenos Aires',
        currencies: [{ name: 'Argentine peso', symbol: '$' }],
        languages: ['Guaraní', 'Spanish'],
        coordinates: [-34, -64],
        area: 2780400,
        maps: {
            googleMaps: 'https://goo.gl/maps/Z9DXNxhf2o93kvyc6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/286393'
        },
        population: 45376763,
        postalCode: { format: '@####@@@', regex: '^([A-Z]\\d{4}[A-Z]{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ar.png',
            svg: 'https://flagcdn.com/ar.svg'
        },
        emoji: '🇦🇷',
        countryCallingCode: '+54'
    },
    {
        name: 'Guernsey',
        capital: 'St. Peter Port',
        currencies: [
            { name: 'British pound', symbol: '£' },
            { name: 'Guernsey pound', symbol: '£' }
        ],
        languages: ['English', 'French', 'Guernésiais'],
        coordinates: [49.46666666, -2.58333333],
        area: 78,
        maps: {
            googleMaps: 'https://goo.gl/maps/6kXnQU5QvEZMD9VB7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/270009'
        },
        population: 62999,
        postalCode: {
            format: '@# #@@|@## #@@|@@# #@@|@@## #@@|@#@ #@@|@@#@ #@@|GIR0AA',
            regex:
                '^(([A-Z]\\d{2}[A-Z]{2})|([A-Z]\\d{3}[A-Z]{2})|([A-Z]{2}\\d{2}[A-Z]{2})|([A-Z]{2}\\d{3}[A-Z]{2})|([A-Z]\\d[A-Z]\\d[A-Z]{2})|([A-Z]{2}\\d[A-Z]\\d[A-Z]{2})|(GIR0AA))$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/gg.png',
            svg: 'https://flagcdn.com/gg.svg'
        },
        emoji: '🇬🇬',
        countryCallingCode: '+44'
    },
    {
        name: 'Maldives',
        capital: 'Malé',
        currencies: [{ name: 'Maldivian rufiyaa', symbol: '.ރ' }],
        languages: ['Maldivian'],
        coordinates: [3.25, 73],
        area: 300,
        maps: {
            googleMaps: 'https://goo.gl/maps/MNAWGq9vEdbZ9vUV7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536773'
        },
        population: 540542,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mv.png',
            svg: 'https://flagcdn.com/mv.svg'
        },
        emoji: '🇲🇻',
        countryCallingCode: '+960'
    },
    {
        name: 'Malawi',
        capital: 'Lilongwe',
        currencies: [{ name: 'Malawian kwacha', symbol: 'MK' }],
        languages: ['English', 'Chewa'],
        coordinates: [-13.5, 34],
        area: 118484,
        maps: {
            googleMaps: 'https://goo.gl/maps/mc6z83pW9m98X2Ef6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195290'
        },
        population: 19129955,
        flags: {
            png: 'https://flagcdn.com/w320/mw.png',
            svg: 'https://flagcdn.com/mw.svg'
        },
        emoji: '🇲🇼',
        countryCallingCode: '+265'
    },
    {
        name: 'Nauru',
        capital: 'Yaren',
        currencies: [{ name: 'Australian dollar', symbol: '$' }],
        languages: ['English', 'Nauru'],
        coordinates: [-0.53333333, 166.91666666],
        area: 21,
        maps: {
            googleMaps: 'https://goo.gl/maps/kyAGw6XEJgjSMsTK7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571804'
        },
        population: 10834,
        flags: {
            png: 'https://flagcdn.com/w320/nr.png',
            svg: 'https://flagcdn.com/nr.svg'
        },
        emoji: '🇳🇷',
        countryCallingCode: '+674'
    },
    {
        name: 'Syria',
        capital: 'Damascus',
        currencies: [{ name: 'Syrian pound', symbol: '£' }],
        languages: ['Arabic'],
        coordinates: [35, 38],
        area: 185180,
        maps: {
            googleMaps: 'https://goo.gl/maps/Xe3VnFbwdb4nv2SM9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184840'
        },
        population: 17500657,
        flags: {
            png: 'https://flagcdn.com/w320/sy.png',
            svg: 'https://flagcdn.com/sy.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Kiribati',
        capital: 'South Tarawa',
        currencies: [
            { name: 'Australian dollar', symbol: '$' },
            { name: 'Kiribati dollar', symbol: '$' }
        ],
        languages: ['English', 'Gilbertese'],
        coordinates: [1.41666666, 173],
        area: 811,
        maps: {
            googleMaps: 'https://goo.gl/maps/NBfYvrndW4skAimw9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571178'
        },
        population: 119446,
        flags: {
            png: 'https://flagcdn.com/w320/ki.png',
            svg: 'https://flagcdn.com/ki.svg'
        },
        emoji: '🇰🇮',
        countryCallingCode: '+686'
    },
    {
        name: 'Martinique',
        capital: 'Fort-de-France',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [14.666667, -61],
        area: 1128,
        maps: {
            googleMaps: 'https://goo.gl/maps/87ER7sDAFU7JjcvR6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2473088'
        },
        population: 378243,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mq.png',
            svg: 'https://flagcdn.com/mq.svg'
        },
        emoji: '🇲🇶',
        countryCallingCode: '+596'
    },
    {
        name: 'Kyrgyzstan',
        capital: 'Bishkek',
        currencies: [{ name: 'Kyrgyzstani som', symbol: 'с' }],
        languages: ['Kyrgyz', 'Russian'],
        coordinates: [41, 75],
        area: 199951,
        maps: {
            googleMaps: 'https://goo.gl/maps/SKG8BSMMQVvxkRkB7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/178009'
        },
        population: 6591600,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/kg.png',
            svg: 'https://flagcdn.com/kg.svg'
        },
        emoji: '🇰🇬',
        countryCallingCode: '+996'
    },
    {
        name: 'Saint Kitts and Nevis',
        capital: 'Basseterre',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [17.33333333, -62.75],
        area: 261,
        maps: {
            googleMaps: 'https://goo.gl/maps/qiaVwcLVTXX3eoTNA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/536899'
        },
        population: 53192,
        flags: {
            png: 'https://flagcdn.com/w320/kn.png',
            svg: 'https://flagcdn.com/kn.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Uzbekistan',
        capital: 'Tashkent',
        currencies: [{ name: 'Uzbekistani soʻm', symbol: "so'm" }],
        languages: ['Russian', 'Uzbek'],
        coordinates: [41, 64],
        area: 447400,
        maps: {
            googleMaps: 'https://goo.gl/maps/AJpo6MjMx23qSWCz8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/196240'
        },
        population: 34232050,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/uz.png',
            svg: 'https://flagcdn.com/uz.svg'
        },
        emoji: '🇺🇿',
        countryCallingCode: '+998'
    },
    {
        name: 'Netherlands',
        capital: 'Amsterdam',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Dutch'],
        coordinates: [52.5, 5.75],
        area: 41850,
        maps: {
            googleMaps: 'https://goo.gl/maps/Hv6zQswGhFxoVVBm6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/47796'
        },
        population: 16655799,
        postalCode: { format: '#### @@', regex: '^(\\d{4}[A-Z]{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/nl.png',
            svg: 'https://flagcdn.com/nl.svg'
        },
        emoji: '🇳🇱',
        countryCallingCode: '+31'
    },
    {
        name: 'United States Minor Outlying Islands',
        capital: 'Unknown',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [19.3, 166.633333],
        area: 34.2,
        maps: {
            googleMaps: 'https://goo.gl/maps/hZKnrzgeK69dDyPF8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/6430384'
        },
        population: 300,
        flags: {
            png: 'https://flagcdn.com/w320/um.png',
            svg: 'https://flagcdn.com/um.svg'
        },
        emoji: '🇺🇲',
        countryCallingCode: '+1'
    },
    {
        name: 'Niger',
        capital: 'Niamey',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [16, 8],
        area: 1267000,
        maps: {
            googleMaps: 'https://goo.gl/maps/VKNU2TLsZcgxM49c8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192786'
        },
        population: 24206636,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ne.png',
            svg: 'https://flagcdn.com/ne.svg'
        },
        emoji: '🇳🇪',
        countryCallingCode: '+227'
    },
    {
        name: 'Bahrain',
        capital: 'Manama',
        currencies: [{ name: 'Bahraini dinar', symbol: '.د.ب' }],
        languages: ['Arabic'],
        coordinates: [26, 50.55],
        area: 765,
        maps: {
            googleMaps: 'https://goo.gl/maps/5Zue99Zc6vFBHxzJ7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/378734'
        },
        population: 1701583,
        postalCode: { format: '####|###', regex: '^(\\d{3}\\d?)$' },
        flags: {
            png: 'https://flagcdn.com/w320/bh.png',
            svg: 'https://flagcdn.com/bh.svg'
        },
        emoji: '🇧🇭',
        countryCallingCode: '+973'
    },
    {
        name: 'Indonesia',
        capital: 'Jakarta',
        currencies: [{ name: 'Indonesian rupiah', symbol: 'Rp' }],
        languages: ['Indonesian'],
        coordinates: [-5, 120],
        area: 1904569,
        maps: {
            googleMaps: 'https://goo.gl/maps/9gfPupm5bffixiFJ6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/21335'
        },
        population: 273523621,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/id.png',
            svg: 'https://flagcdn.com/id.svg'
        },
        emoji: '🇮🇩',
        countryCallingCode: '+62'
    },
    {
        name: 'Guadeloupe',
        capital: 'Basse-Terre',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [16.25, -61.583333],
        area: 1628,
        maps: {
            googleMaps: 'https://goo.gl/maps/Dy9R2EufJtoWm8UN9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/7109289'
        },
        population: 400132,
        postalCode: { format: '#####', regex: '^((97|98)\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gp.png',
            svg: 'https://flagcdn.com/gp.svg'
        },
        emoji: '🇬🇵',
        countryCallingCode: '+590'
    },
    {
        name: 'Réunion',
        capital: 'Saint-Denis',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [-21.15, 55.5],
        area: 2511,
        maps: {
            googleMaps: 'https://goo.gl/maps/wWpBrXsp8UHVbah29',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1785276'
        },
        population: 840974,
        postalCode: { format: '#####', regex: '^((97|98)(4|7|8)\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/re.png',
            svg: 'https://flagcdn.com/re.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Pitcairn Islands',
        capital: 'Adamstown',
        currencies: [{ name: 'New Zealand dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-25.06666666, -130.1],
        area: 47,
        maps: {
            googleMaps: 'https://goo.gl/maps/XGJMnMAigXjXcxSa7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2185375'
        },
        population: 56,
        flags: {
            png: 'https://flagcdn.com/w320/pn.png',
            svg: 'https://flagcdn.com/pn.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Aruba',
        capital: 'Oranjestad',
        currencies: [{ name: 'Aruban florin', symbol: 'ƒ' }],
        languages: ['Dutch', 'Papiamento'],
        coordinates: [12.5, -69.96666666],
        area: 180,
        maps: {
            googleMaps: 'https://goo.gl/maps/8hopbQqifHAgyZyg8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1231749'
        },
        population: 106766,
        flags: {
            png: 'https://flagcdn.com/w320/aw.png',
            svg: 'https://flagcdn.com/aw.svg'
        },
        emoji: '🇦🇼',
        countryCallingCode: '+297'
    },
    {
        name: 'Bangladesh',
        capital: 'Dhaka',
        currencies: [{ name: 'Bangladeshi taka', symbol: '৳' }],
        languages: ['Bengali'],
        coordinates: [24, 90],
        area: 147570,
        maps: {
            googleMaps: 'https://goo.gl/maps/op6gmLbHcvv6rLhH6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184640'
        },
        population: 164689383,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/bd.png',
            svg: 'https://flagcdn.com/bd.svg'
        },
        emoji: '🇧🇩',
        countryCallingCode: '+880'
    },
    {
        name: 'Guatemala',
        capital: 'Guatemala City',
        currencies: [{ name: 'Guatemalan quetzal', symbol: 'Q' }],
        languages: ['Spanish'],
        coordinates: [15.5, -90.25],
        area: 108889,
        maps: {
            googleMaps: 'https://goo.gl/maps/JoRAbem4Hxb9FYbVA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1521463'
        },
        population: 16858333,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gt.png',
            svg: 'https://flagcdn.com/gt.svg'
        },
        emoji: '🇬🇹',
        countryCallingCode: '+502'
    },
    {
        name: 'Bahamas',
        capital: 'Nassau',
        currencies: [
            { name: 'Bahamian dollar', symbol: '$' },
            { name: 'United States dollar', symbol: '$' }
        ],
        languages: ['English'],
        coordinates: [24.25, -76],
        area: 13943,
        maps: {
            googleMaps: 'https://goo.gl/maps/1YzRs1BZrG8p8pmVA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/547469'
        },
        population: 393248,
        flags: {
            png: 'https://flagcdn.com/w320/bs.png',
            svg: 'https://flagcdn.com/bs.svg'
        },
        emoji: '🇧🇸',
        countryCallingCode: '+1 242'
    },
    {
        name: 'Uruguay',
        capital: 'Montevideo',
        currencies: [{ name: 'Uruguayan peso', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [-33, -56],
        area: 181034,
        maps: {
            googleMaps: 'https://goo.gl/maps/tiQ9Baekb1jQtDSD9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287072'
        },
        population: 3473727,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/uy.png',
            svg: 'https://flagcdn.com/uy.svg'
        },
        emoji: '🇺🇾',
        countryCallingCode: '+598'
    },
    {
        name: 'Morocco',
        capital: 'Rabat',
        currencies: [{ name: 'Moroccan dirham', symbol: 'د.م.' }],
        languages: ['Arabic', 'Berber'],
        coordinates: [32, -5],
        area: 446550,
        maps: {
            googleMaps: 'https://goo.gl/maps/6oMv3dyBZg3iaXQ5A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/3630439'
        },
        population: 36910558,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ma.png',
            svg: 'https://flagcdn.com/ma.svg'
        },
        emoji: '🇲🇦',
        countryCallingCode: '+212'
    },
    {
        name: 'Germany',
        capital: 'Berlin',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['German'],
        coordinates: [51, 9],
        area: 357114,
        maps: {
            googleMaps: 'https://goo.gl/maps/mD9FBMq1nvXUBrkv6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/51477'
        },
        population: 83240525,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/de.png',
            svg: 'https://flagcdn.com/de.svg'
        },
        emoji: '🇩🇪',
        countryCallingCode: '+49'
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        capital: 'Jamestown',
        currencies: [
            { name: 'Pound sterling', symbol: '£' },
            { name: 'Saint Helena pound', symbol: '£' }
        ],
        languages: ['English'],
        coordinates: [-15.95, -5.72],
        area: 394,
        maps: {
            googleMaps: 'https://goo.gl/maps/iv4VxnPzHkjLCJuc6',
            openStreetMaps:
                'https://www.openstreetmap.org/relation/4868269#map=13/-15.9657/-5.7120'
        },
        population: 53192,
        postalCode: { format: 'STHL 1ZZ', regex: '^(STHL1ZZ)$' },
        flags: {
            png: 'https://flagcdn.com/w320/sh.png',
            svg: 'https://flagcdn.com/sh.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Montserrat',
        capital: 'Plymouth',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [16.75, -62.2],
        area: 102,
        maps: {
            googleMaps: 'https://goo.gl/maps/CSbe7UmxPmiwQB7GA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/537257'
        },
        population: 4922,
        flags: {
            png: 'https://flagcdn.com/w320/ms.png',
            svg: 'https://flagcdn.com/ms.svg'
        },
        emoji: '🇲🇸',
        countryCallingCode: '+1 664'
    },
    {
        name: 'United States',
        capital: 'Washington, D.C.',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [38, -97],
        area: 9372610,
        maps: {
            googleMaps: 'https://goo.gl/maps/e8M246zY4BSjkjAv6',
            openStreetMaps:
                'https://www.openstreetmap.org/relation/148838#map=2/20.6/-85.8'
        },
        population: 329484123,
        postalCode: { format: '#####-####', regex: '^\\d{5}(-\\d{4})?$' },
        flags: {
            png: 'https://flagcdn.com/w320/us.png',
            svg: 'https://flagcdn.com/us.svg'
        },
        emoji: '🇺🇸',
        countryCallingCode: '+1'
    },
    {
        name: 'Falkland Islands',
        capital: 'Stanley',
        currencies: [{ name: 'Falkland Islands pound', symbol: '£' }],
        languages: ['English'],
        coordinates: [-51.75, -59],
        area: 12173,
        maps: {
            googleMaps: 'https://goo.gl/maps/TZH1x7AGanQKifNk7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2185374'
        },
        population: 2563,
        flags: {
            png: 'https://flagcdn.com/w320/fk.png',
            svg: 'https://flagcdn.com/fk.svg'
        },
        emoji: '🇫🇰',
        countryCallingCode: '+500'
    },
    {
        name: 'Bulgaria',
        capital: 'Sofia',
        currencies: [{ name: 'Bulgarian lev', symbol: 'лв' }],
        languages: ['Bulgarian'],
        coordinates: [43, 25],
        area: 110879,
        maps: {
            googleMaps: 'https://goo.gl/maps/F5uAhDGWzc3BrHfm9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/186382'
        },
        population: 6927288,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/bg.png',
            svg: 'https://flagcdn.com/bg.svg'
        },
        emoji: '🇧🇬',
        countryCallingCode: '+359'
    },
    {
        name: 'Papua New Guinea',
        capital: 'Port Moresby',
        currencies: [{ name: 'Papua New Guinean kina', symbol: 'K' }],
        languages: ['English', 'Hiri Motu', 'Tok Pisin'],
        coordinates: [-6, 147],
        area: 462840,
        maps: {
            googleMaps: 'https://goo.gl/maps/ChGmzZBjZ3vnBwR2A',
            openStreetMaps: 'https://goo.gl/maps/ChGmzZBjZ3vnBwR2A'
        },
        population: 8947027,
        postalCode: { format: '###', regex: '^(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pg.png',
            svg: 'https://flagcdn.com/pg.svg'
        },
        emoji: '🇵🇬',
        countryCallingCode: '+675'
    },
    {
        name: 'Costa Rica',
        capital: 'San José',
        currencies: [{ name: 'Costa Rican colón', symbol: '₡' }],
        languages: ['Spanish'],
        coordinates: [10, -84],
        area: 51100,
        maps: {
            googleMaps: 'https://goo.gl/maps/RFiwytjvNrpfKN7k6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/287667'
        },
        population: 5094114,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cr.png',
            svg: 'https://flagcdn.com/cr.svg'
        },
        emoji: '🇨🇷',
        countryCallingCode: '+506'
    },
    {
        name: 'Ecuador',
        capital: 'Quito',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['Spanish'],
        coordinates: [-2, -77.5],
        area: 276841,
        maps: {
            googleMaps: 'https://goo.gl/maps/TbX8hUW4gcbRPZiK7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/108089'
        },
        population: 17643060,
        postalCode: { format: '@####@', regex: '^([a-zA-Z]\\d{4}[a-zA-Z])$' },
        flags: {
            png: 'https://flagcdn.com/w320/ec.png',
            svg: 'https://flagcdn.com/ec.svg'
        },
        emoji: '🇪🇨',
        countryCallingCode: '+593'
    },
    {
        name: 'Svalbard and Jan Mayen',
        capital: 'Longyearbyen',
        currencies: [{ name: 'krone', symbol: 'kr' }],
        languages: ['Norwegian'],
        coordinates: [78, 20],
        area: -1,
        maps: {
            googleMaps: 'https://goo.gl/maps/L2wyyn3cQ16PzQ5J8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1337397'
        },
        population: 2562,
        flags: {
            png: 'https://flagcdn.com/w320/sj.png',
            svg: 'https://flagcdn.com/sj.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Fiji',
        capital: 'Suva',
        currencies: [{ name: 'Fijian dollar', symbol: '$' }],
        languages: ['English', 'Fijian', 'Fiji Hindi'],
        coordinates: [-18, 175],
        area: 18272,
        maps: {
            googleMaps: 'https://goo.gl/maps/r9fhDqoLZdg1zmE99',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571747'
        },
        population: 896444,
        flags: {
            png: 'https://flagcdn.com/w320/fj.png',
            svg: 'https://flagcdn.com/fj.svg'
        },
        emoji: '🇫🇯',
        countryCallingCode: '+679'
    },
    {
        name: 'South Korea',
        capital: 'Seoul',
        currencies: [{ name: 'South Korean won', symbol: '₩' }],
        languages: ['Korean'],
        coordinates: [37, 127.5],
        area: 100210,
        maps: {
            googleMaps: 'https://goo.gl/maps/7ecjaJXefjAQhxjGA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307756'
        },
        population: 51780579,
        postalCode: {
            format: 'SEOUL ###-###',
            regex: '^(?:SEOUL)*(\\d{6})$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/kr.png',
            svg: 'https://flagcdn.com/kr.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Puerto Rico',
        capital: 'San Juan',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English', 'Spanish'],
        coordinates: [18.25, -66.5],
        area: 8870,
        maps: {
            googleMaps: 'https://goo.gl/maps/sygfDbtwn389wu8x5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/4422604'
        },
        population: 3194034,
        postalCode: { format: '#####-####', regex: '^(\\d{9})$' },
        flags: {
            png: 'https://flagcdn.com/w320/pr.png',
            svg: 'https://flagcdn.com/pr.svg'
        },
        emoji: '🇵🇷',
        countryCallingCode: '+1 787'
    },
    {
        name: 'Wallis and Futuna',
        capital: 'Mata-Utu',
        currencies: [{ name: 'CFP franc', symbol: '₣' }],
        languages: ['French'],
        coordinates: [-13.3, -176.2],
        area: 142,
        maps: {
            googleMaps: 'https://goo.gl/maps/CzVqK74QYtbHv65r5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/3412448'
        },
        population: 11750,
        postalCode: { format: '#####', regex: '^(986\\d{2})$' },
        flags: {
            png: 'https://flagcdn.com/w320/wf.png',
            svg: 'https://flagcdn.com/wf.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'North Korea',
        capital: 'Pyongyang',
        currencies: [{ name: 'North Korean won', symbol: '₩' }],
        languages: ['Korean'],
        coordinates: [40, 127],
        area: 120538,
        maps: {
            googleMaps: 'https://goo.gl/maps/9q5T2DMeH5JL7Tky6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192734'
        },
        population: 25778815,
        postalCode: { format: '###-###', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/kp.png',
            svg: 'https://flagcdn.com/kp.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Taiwan',
        capital: 'Taipei',
        currencies: [{ name: 'New Taiwan dollar', symbol: '$' }],
        languages: ['Chinese'],
        coordinates: [23.5, 121],
        area: 36193,
        maps: {
            googleMaps: 'https://goo.gl/maps/HgMKFQjNadF3Wa6B6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/449220'
        },
        population: 23503349,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/tw.png',
            svg: 'https://flagcdn.com/tw.svg'
        },
        emoji: '🇹🇼',
        countryCallingCode: '+886'
    },
    {
        name: 'Isle of Man',
        capital: 'Douglas',
        currencies: [
            { name: 'British pound', symbol: '£' },
            { name: 'Manx pound', symbol: '£' }
        ],
        languages: ['English', 'Manx'],
        coordinates: [54.25, -4.5],
        area: 572,
        maps: {
            googleMaps: 'https://goo.gl/maps/4DqVHDgVaFgnh8ZV8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/62269'
        },
        population: 85032,
        postalCode: {
            format: '@# #@@|@## #@@|@@# #@@|@@## #@@|@#@ #@@|@@#@ #@@|GIR0AA',
            regex:
                '^(([A-Z]\\d{2}[A-Z]{2})|([A-Z]\\d{3}[A-Z]{2})|([A-Z]{2}\\d{2}[A-Z]{2})|([A-Z]{2}\\d{3}[A-Z]{2})|([A-Z]\\d[A-Z]\\d[A-Z]{2})|([A-Z]{2}\\d[A-Z]\\d[A-Z]{2})|(GIR0AA))$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/im.png',
            svg: 'https://flagcdn.com/im.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'United States Virgin Islands',
        capital: 'Charlotte Amalie',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [18.35, -64.933333],
        area: 347,
        maps: {
            googleMaps: 'https://goo.gl/maps/mBfreywj8dor6q4m9',
            openStreetMaps: 'openstreetmap.org/relation/286898'
        },
        population: 106290,
        flags: {
            png: 'https://flagcdn.com/w320/vi.png',
            svg: 'https://flagcdn.com/vi.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Lithuania',
        capital: 'Vilnius',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Lithuanian'],
        coordinates: [56, 24],
        area: 65300,
        maps: {
            googleMaps: 'https://goo.gl/maps/dd1s9rrLjrK2G8yY6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/72596'
        },
        population: 2794700,
        postalCode: { format: 'LT-#####', regex: '^(?:LT)*(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/lt.png',
            svg: 'https://flagcdn.com/lt.svg'
        },
        emoji: '🇱🇹',
        countryCallingCode: '+370'
    },
    {
        name: 'Angola',
        capital: 'Luanda',
        currencies: [{ name: 'Angolan kwanza', symbol: 'Kz' }],
        languages: ['Portuguese'],
        coordinates: [-12.5, 18.5],
        area: 1246700,
        maps: {
            googleMaps: 'https://goo.gl/maps/q42Qbf1BmQL3fuZg9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195267'
        },
        population: 32866268,
        flags: {
            png: 'https://flagcdn.com/w320/ao.png',
            svg: 'https://flagcdn.com/ao.svg'
        },
        emoji: '🇦🇴',
        countryCallingCode: '+244'
    },
    {
        name: 'Tunisia',
        capital: 'Tunis',
        currencies: [{ name: 'Tunisian dinar', symbol: 'د.ت' }],
        languages: ['Arabic'],
        coordinates: [34, 9],
        area: 163610,
        maps: {
            googleMaps: 'https://goo.gl/maps/KgUmpZdUuNRaougs8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192757'
        },
        population: 11818618,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/tn.png',
            svg: 'https://flagcdn.com/tn.svg'
        },
        emoji: '🇹🇳',
        countryCallingCode: '+216'
    },
    {
        name: 'Faroe Islands',
        capital: 'Tórshavn',
        currencies: [
            { name: 'Danish krone', symbol: 'kr' },
            { name: 'Faroese króna', symbol: 'kr' }
        ],
        languages: ['Danish', 'Faroese'],
        coordinates: [62, -7],
        area: 1393,
        maps: {
            googleMaps: 'https://goo.gl/maps/6sTru4SmHdEVcNkM6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/52939'
        },
        population: 48865,
        postalCode: { format: 'FO-###', regex: '^(?:FO)*(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/fo.png',
            svg: 'https://flagcdn.com/fo.svg'
        },
        emoji: '🇫🇴',
        countryCallingCode: '+298'
    },
    {
        name: 'Ghana',
        capital: 'Accra',
        currencies: [{ name: 'Ghanaian cedi', symbol: '₵' }],
        languages: ['English'],
        coordinates: [8, -2],
        area: 238533,
        maps: {
            googleMaps: 'https://goo.gl/maps/Avy5RSmdsXFBaiXq8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192781'
        },
        population: 31072945,
        flags: {
            png: 'https://flagcdn.com/w320/gh.png',
            svg: 'https://flagcdn.com/gh.svg'
        },
        emoji: '🇬🇭',
        countryCallingCode: '+233'
    },
    {
        name: 'Iraq',
        capital: 'Baghdad',
        currencies: [{ name: 'Iraqi dinar', symbol: 'ع.د' }],
        languages: ['Arabic', 'Aramaic', 'Sorani'],
        coordinates: [33, 44],
        area: 438317,
        maps: {
            googleMaps: 'https://goo.gl/maps/iL8Bmy1sUCW9fUk18',
            openStreetMaps: 'https://www.openstreetmap.org/relation/304934'
        },
        population: 40222503,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/iq.png',
            svg: 'https://flagcdn.com/iq.svg'
        },
        emoji: '🇮🇶',
        countryCallingCode: '+964'
    },
    {
        name: 'New Zealand',
        capital: 'Wellington',
        currencies: [{ name: 'New Zealand dollar', symbol: '$' }],
        languages: ['English', 'Māori', 'New Zealand Sign Language'],
        coordinates: [-41, 174],
        area: 270467,
        maps: {
            googleMaps: 'https://goo.gl/maps/xXiDQo65dwdpw9iu8',
            openStreetMaps:
                'https://www.openstreetmap.org/relation/556706#map=5/-46.710/172.046'
        },
        population: 5084300,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/nz.png',
            svg: 'https://flagcdn.com/nz.svg'
        },
        emoji: '🇳🇿',
        countryCallingCode: '+64'
    },
    {
        name: 'Serbia',
        capital: 'Belgrade',
        currencies: [{ name: 'Serbian dinar', symbol: 'дин.' }],
        languages: ['Serbian'],
        coordinates: [44, 21],
        area: 88361,
        maps: {
            googleMaps: 'https://goo.gl/maps/2Aqof7aV2Naq8YEK8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1741311'
        },
        population: 6908224,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/rs.png',
            svg: 'https://flagcdn.com/rs.svg'
        },
        emoji: '🇷🇸',
        countryCallingCode: '+381'
    },
    {
        name: 'Bhutan',
        capital: 'Thimphu',
        currencies: [
            { name: 'Bhutanese ngultrum', symbol: 'Nu.' },
            { name: 'Indian rupee', symbol: '₹' }
        ],
        languages: ['Dzongkha'],
        coordinates: [27.5, 90.5],
        area: 38394,
        maps: {
            googleMaps: 'https://goo.gl/maps/VEfXXBftTFLUpNgp8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/184629'
        },
        population: 771612,
        flags: {
            png: 'https://flagcdn.com/w320/bt.png',
            svg: 'https://flagcdn.com/bt.svg'
        },
        emoji: '🇧🇹',
        countryCallingCode: '+975'
    },
    {
        name: 'Romania',
        capital: 'Bucharest',
        currencies: [{ name: 'Romanian leu', symbol: 'lei' }],
        languages: ['Romanian'],
        coordinates: [46, 25],
        area: 238391,
        maps: {
            googleMaps: 'https://goo.gl/maps/845hAgCf1mDkN3vr7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/90689'
        },
        population: 19286123,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ro.png',
            svg: 'https://flagcdn.com/ro.svg'
        },
        emoji: '🇷🇴',
        countryCallingCode: '+40'
    },
    {
        name: 'Afghanistan',
        capital: 'Kabul',
        currencies: [{ name: 'Afghan afghani', symbol: '؋' }],
        languages: ['Dari', 'Pashto', 'Turkmen'],
        coordinates: [33, 65],
        area: 652230,
        maps: {
            googleMaps: 'https://goo.gl/maps/BXBGw7yUUFknCfva9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/303427'
        },
        population: 2837743,
        flags: {
            png: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
            svg: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg'
        },
        emoji: '🇦🇫',
        countryCallingCode: '+93'
    },
    {
        name: 'India',
        capital: 'New Delhi',
        currencies: [{ name: 'Indian rupee', symbol: '₹' }],
        languages: ['English', 'Hindi', 'Tamil'],
        coordinates: [20, 77],
        area: 3287590,
        maps: {
            googleMaps: 'https://goo.gl/maps/WSk3fLwG4vtPQetp7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/304716'
        },
        population: 1380004385,
        postalCode: { format: '######', regex: '^(\\d{6})$' },
        flags: {
            png: 'https://flagcdn.com/w320/in.png',
            svg: 'https://flagcdn.com/in.svg'
        },
        emoji: '🇮🇳',
        countryCallingCode: '+91'
    },
    {
        name: 'Denmark',
        capital: 'Copenhagen',
        currencies: [{ name: 'Danish krone', symbol: 'kr' }],
        languages: ['Danish'],
        coordinates: [56, 10],
        area: 43094,
        maps: {
            googleMaps: 'https://goo.gl/maps/UddGPN7hAyrtpFiT6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/50046'
        },
        population: 5831404,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/dk.png',
            svg: 'https://flagcdn.com/dk.svg'
        },
        emoji: '🇩🇰',
        countryCallingCode: '+45'
    },
    {
        name: 'Jersey',
        capital: 'Saint Helier',
        currencies: [
            { name: 'British pound', symbol: '£' },
            { name: 'Jersey pound', symbol: '£' }
        ],
        languages: ['English', 'French', 'Jèrriais'],
        coordinates: [49.25, -2.16666666],
        area: 116,
        maps: {
            googleMaps: 'https://goo.gl/maps/rXG8GZZtsqK92kTCA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/367988'
        },
        population: 100800,
        postalCode: {
            format: '@# #@@|@## #@@|@@# #@@|@@## #@@|@#@ #@@|@@#@ #@@|GIR0AA',
            regex:
                '^(([A-Z]\\d{2}[A-Z]{2})|([A-Z]\\d{3}[A-Z]{2})|([A-Z]{2}\\d{2}[A-Z]{2})|([A-Z]{2}\\d{3}[A-Z]{2})|([A-Z]\\d[A-Z]\\d[A-Z]{2})|([A-Z]{2}\\d[A-Z]\\d[A-Z]{2})|(GIR0AA))$'
        },
        flags: {
            png: 'https://flagcdn.com/w320/je.png',
            svg: 'https://flagcdn.com/je.svg'
        },
        emoji: '🇯🇪',
        countryCallingCode: '+44'
    },
    {
        name: 'Saudi Arabia',
        capital: 'Riyadh',
        currencies: [{ name: 'Saudi riyal', symbol: 'ر.س' }],
        languages: ['Arabic'],
        coordinates: [25, 45],
        area: 2149690,
        maps: {
            googleMaps: 'https://goo.gl/maps/5PSjvdJ1AyaLFRrG9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307584'
        },
        population: 34813867,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/sa.png',
            svg: 'https://flagcdn.com/sa.svg'
        },
        emoji: '🇸🇦',
        countryCallingCode: '+966'
    },
    {
        name: 'Saint Barthélemy',
        capital: 'Gustavia',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [18.5, -63.41666666],
        area: 21,
        maps: {
            googleMaps: 'https://goo.gl/maps/Mc7GqH466S7AAk297',
            openStreetMaps: 'https://www.openstreetmap.org/relation/7552779'
        },
        population: 4255,
        postalCode: { format: '### ###' },
        flags: {
            png: 'https://flagcdn.com/w320/bl.png',
            svg: 'https://flagcdn.com/bl.svg'
        },
        emoji: '🇧🇱',
        countryCallingCode: '+590'
    },
    {
        name: 'Guinea-Bissau',
        capital: 'Bissau',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['Portuguese', 'Upper Guinea Creole'],
        coordinates: [12, -15],
        area: 36125,
        maps: {
            googleMaps: 'https://goo.gl/maps/5Wyaz17miUc1zLc67',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192776'
        },
        population: 1967998,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/gw.png',
            svg: 'https://flagcdn.com/gw.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Norway',
        capital: 'Oslo',
        currencies: [{ name: 'Norwegian krone', symbol: 'kr' }],
        languages: ['Norwegian Nynorsk', 'Norwegian Bokmål', 'Sami'],
        coordinates: [62, 10],
        area: 323802,
        maps: {
            googleMaps: 'https://goo.gl/maps/htWRrphA7vNgQNdSA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2978650'
        },
        population: 5379475,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/no.png',
            svg: 'https://flagcdn.com/no.svg'
        },
        emoji: '🇳🇴',
        countryCallingCode: '+47'
    },
    {
        name: 'Monaco',
        capital: 'Monaco',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['French'],
        coordinates: [43.73333333, 7.4],
        area: 2.02,
        maps: {
            googleMaps: 'https://goo.gl/maps/DGpndDot28bYdXYn7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1124039'
        },
        population: 39244,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mc.png',
            svg: 'https://flagcdn.com/mc.svg'
        },
        emoji: '🇲🇨',
        countryCallingCode: '+377'
    },
    {
        name: 'Cocos (Keeling) Islands',
        capital: 'West Island',
        currencies: [{ name: 'Australian dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [-12.5, 96.83333333],
        area: 14,
        maps: {
            googleMaps: 'https://goo.gl/maps/3eCdKVpVfMcZyKcK6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/82636'
        },
        population: 544,
        flags: {
            png: 'https://flagcdn.com/w320/cc.png',
            svg: 'https://flagcdn.com/cc.svg'
        },
        emoji: '🇨🇨',
        countryCallingCode: '+61'
    },
    {
        name: 'Egypt',
        capital: 'Cairo',
        currencies: [{ name: 'Egyptian pound', symbol: '£' }],
        languages: ['Arabic'],
        coordinates: [27, 30],
        area: 1002450,
        maps: {
            googleMaps: 'https://goo.gl/maps/uoDRhXbsqjG6L7VG7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1473947'
        },
        population: 102334403,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/eg.png',
            svg: 'https://flagcdn.com/eg.svg'
        },
        emoji: '🇪🇬',
        countryCallingCode: '+20'
    },
    {
        name: 'Cyprus',
        capital: 'Nicosia',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Greek', 'Turkish'],
        coordinates: [35, 33],
        area: 9251,
        maps: {
            googleMaps: 'https://goo.gl/maps/77hPBRdLid8yD5Bm7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307787'
        },
        population: 1207361,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cy.png',
            svg: 'https://flagcdn.com/cy.svg'
        },
        emoji: '🇨🇾',
        countryCallingCode: '+357'
    },
    {
        name: 'Dominica',
        capital: 'Roseau',
        currencies: [{ name: 'Eastern Caribbean dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [15.41666666, -61.33333333],
        area: 751,
        maps: {
            googleMaps: 'https://goo.gl/maps/HSKdHYpFC8oHHuyV7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/307823'
        },
        population: 71991,
        flags: {
            png: 'https://flagcdn.com/w320/dm.png',
            svg: 'https://flagcdn.com/dm.svg'
        },
        emoji: '🇩🇲',
        countryCallingCode: '+1 767'
    },
    {
        name: 'Azerbaijan',
        capital: 'Baku',
        currencies: [{ name: 'Azerbaijani manat', symbol: '₼' }],
        languages: ['Azerbaijani', 'Russian'],
        coordinates: [40.5, 47.5],
        area: 86600,
        maps: {
            googleMaps: 'https://goo.gl/maps/az3Zz7ar2aoB9AUc6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/364110'
        },
        population: 10110116,
        postalCode: { format: 'AZ ####', regex: '^(?:AZ)*(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/az.png',
            svg: 'https://flagcdn.com/az.svg'
        },
        emoji: '🇦🇿',
        countryCallingCode: '+994'
    },
    {
        name: 'Zimbabwe',
        capital: 'Harare',
        currencies: [{ name: 'Zimbabwean dollar', symbol: '$' }],
        languages: [
            'Chibarwe',
            'English',
            'Kalanga',
            'Khoisan',
            'Ndau',
            'Northern Ndebele',
            'Chewa',
            'Shona',
            'Sotho',
            'Tonga',
            'Tswana',
            'Tsonga',
            'Venda',
            'Xhosa',
            'Zimbabwean Sign Language'
        ],
        coordinates: [-20, 30],
        area: 390757,
        maps: {
            googleMaps: 'https://goo.gl/maps/M26BqdwQctqxXS65A',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195272'
        },
        population: 14862927,
        flags: {
            png: 'https://flagcdn.com/w320/zw.png',
            svg: 'https://flagcdn.com/zw.svg'
        },
        emoji: '🇿🇼',
        countryCallingCode: '+263'
    },
    {
        name: 'Tuvalu',
        capital: 'Funafuti',
        currencies: [
            { name: 'Australian dollar', symbol: '$' },
            { name: 'Tuvaluan dollar', symbol: '$' }
        ],
        languages: ['English', 'Tuvaluan'],
        coordinates: [-8, 178],
        area: 26,
        maps: {
            googleMaps: 'https://goo.gl/maps/LbuUxtkgm1dfN1Pn6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2177266'
        },
        population: 11792,
        flags: {
            png: 'https://flagcdn.com/w320/tv.png',
            svg: 'https://flagcdn.com/tv.svg'
        },
        emoji: '🇹🇻',
        countryCallingCode: '+688'
    },
    {
        name: 'Mali',
        capital: 'Bamako',
        currencies: [{ name: 'West African CFA franc', symbol: 'Fr' }],
        languages: ['French'],
        coordinates: [17, -4],
        area: 1240192,
        maps: {
            googleMaps: 'https://goo.gl/maps/u9mYJkCB19wyuzh27',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192785'
        },
        population: 20250834,
        flags: {
            png: 'https://flagcdn.com/w320/ml.png',
            svg: 'https://flagcdn.com/ml.svg'
        },
        emoji: '🇲🇱',
        countryCallingCode: '+223'
    },
    {
        name: 'Mauritania',
        capital: 'Nouakchott',
        currencies: [{ name: 'Mauritanian ouguiya', symbol: 'UM' }],
        languages: ['Arabic'],
        coordinates: [20, -12],
        area: 1030700,
        maps: {
            googleMaps: 'https://goo.gl/maps/im2MmQ5jFjzxWBks5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192763'
        },
        population: 4649660,
        flags: {
            png: 'https://flagcdn.com/w320/mr.png',
            svg: 'https://flagcdn.com/mr.svg'
        },
        emoji: '🇲🇷',
        countryCallingCode: '+222'
    },
    {
        name: 'Ukraine',
        capital: 'Kyiv',
        currencies: [{ name: 'Ukrainian hryvnia', symbol: '₴' }],
        languages: ['Ukrainian'],
        coordinates: [49, 32],
        area: 603500,
        maps: {
            googleMaps: 'https://goo.gl/maps/DvgJMiPJ7aozKFZv7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/60199'
        },
        population: 44134693,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ua.png',
            svg: 'https://flagcdn.com/ua.svg'
        },
        emoji: '🇺🇦',
        countryCallingCode: '+380'
    },
    {
        name: 'Cameroon',
        capital: 'Yaoundé',
        currencies: [{ name: 'Central African CFA franc', symbol: 'Fr' }],
        languages: ['English', 'French'],
        coordinates: [6, 12],
        area: 475442,
        maps: {
            googleMaps: 'https://goo.gl/maps/JqiipHgFboG3rBJh9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192830'
        },
        population: 26545864,
        flags: {
            png: 'https://flagcdn.com/w320/cm.png',
            svg: 'https://flagcdn.com/cm.svg'
        },
        emoji: '🇨🇲',
        countryCallingCode: '+237'
    },
    {
        name: 'Caribbean Netherlands',
        capital: 'Kralendijk',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English', 'Dutch', 'Papiamento'],
        coordinates: [12.18, -68.25],
        area: 328,
        maps: {
            googleMaps: 'https://goo.gl/maps/4XVes1P6uEDTz77WA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/1216720'
        },
        population: 25987,
        flags: {
            png: 'https://flagcdn.com/w320/bq.png',
            svg: 'https://flagcdn.com/bq.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Micronesia',
        capital: 'Palikir',
        currencies: [{ name: 'United States dollar', symbol: '$' }],
        languages: ['English'],
        coordinates: [6.91666666, 158.25],
        area: 702,
        maps: {
            googleMaps: 'https://goo.gl/maps/LLcnofC5LxZsJXTo8',
            openStreetMaps: 'https://www.openstreetmap.org/relation/571802'
        },
        population: 115021,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/fm.png',
            svg: 'https://flagcdn.com/fm.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Mozambique',
        capital: 'Maputo',
        currencies: [{ name: 'Mozambican metical', symbol: 'MT' }],
        languages: ['Portuguese'],
        coordinates: [-18.25, 35],
        area: 801590,
        maps: {
            googleMaps: 'https://goo.gl/maps/xCLcY9fzU6x4Pueu5',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195273'
        },
        population: 31255435,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/mz.png',
            svg: 'https://flagcdn.com/mz.svg'
        },
        emoji: '🇲🇿',
        countryCallingCode: '+258'
    },
    {
        name: 'Namibia',
        capital: 'Windhoek',
        currencies: [
            { name: 'Namibian dollar', symbol: '$' },
            { name: 'South African rand', symbol: 'R' }
        ],
        languages: [
            'Afrikaans',
            'German',
            'English',
            'Herero',
            'Khoekhoe',
            'Kwangali',
            'Lozi',
            'Ndonga',
            'Tswana'
        ],
        coordinates: [-22, 17],
        area: 825615,
        maps: {
            googleMaps: 'https://goo.gl/maps/oR1i8BFEYX3EY83WA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/195266'
        },
        population: 2540916,
        flags: {
            png: 'https://flagcdn.com/w320/na.png',
            svg: 'https://flagcdn.com/na.svg'
        },
        emoji: '🇳🇦',
        countryCallingCode: '+264'
    },
    {
        name: 'Gambia',
        capital: 'Banjul',
        currencies: [{ name: 'dalasi', symbol: 'D' }],
        languages: ['English'],
        coordinates: [13.46666666, -16.56666666],
        area: 10689,
        maps: {
            googleMaps: 'https://goo.gl/maps/bbGBCxxtfD2A9Z4m6',
            openStreetMaps: 'https://www.openstreetmap.org/relation/192774'
        },
        population: 2416664,
        flags: {
            png: 'https://flagcdn.com/w320/gm.png',
            svg: 'https://flagcdn.com/gm.svg'
        },
        emoji: '🇬🇲',
        countryCallingCode: '+220'
    },
    {
        name: 'Cook Islands',
        capital: 'Avarua',
        currencies: [
            { name: 'Cook Islands dollar', symbol: '$' },
            { name: 'New Zealand dollar', symbol: '$' }
        ],
        languages: ['English', 'Cook Islands Māori'],
        coordinates: [-21.23333333, -159.76666666],
        area: 236,
        maps: {
            googleMaps: 'https://goo.gl/maps/nrGZrvWRGB4WHgDC9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/2184233'
        },
        population: 18100,
        flags: {
            png: 'https://flagcdn.com/w320/ck.png',
            svg: 'https://flagcdn.com/ck.svg'
        },
        emoji: '🇨🇰',
        countryCallingCode: '+682'
    },
    {
        name: 'Cape Verde',
        capital: 'Praia',
        currencies: [{ name: 'Cape Verdean escudo', symbol: 'Esc' }],
        languages: ['Portuguese'],
        coordinates: [16, -24],
        area: 4033,
        maps: {
            googleMaps: 'https://goo.gl/maps/Kc9vy5ChjuiAgMfXA',
            openStreetMaps: 'https://www.openstreetmap.org/relation/535774'
        },
        population: 555988,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/cv.png',
            svg: 'https://flagcdn.com/cv.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Laos',
        capital: 'Vientiane',
        currencies: [{ name: 'Lao kip', symbol: '₭' }],
        languages: ['Lao'],
        coordinates: [18, 105],
        area: 236800,
        maps: {
            googleMaps: 'https://goo.gl/maps/F3asVB7sRKgSnwbE7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/49903'
        },
        population: 7275556,
        postalCode: { format: '#####', regex: '^(\\d{5})$' },
        flags: {
            png: 'https://flagcdn.com/w320/la.png',
            svg: 'https://flagcdn.com/la.svg'
        },
        emoji: '',
        countryCallingCode: ''
    },
    {
        name: 'Austria',
        capital: 'Vienna',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Austro-Bavarian German'],
        coordinates: [47.33333333, 13.33333333],
        area: 83871,
        maps: {
            googleMaps: 'https://goo.gl/maps/pCWpWQhznHyRzQcu9',
            openStreetMaps: 'https://www.openstreetmap.org/relation/16239'
        },
        population: 8917205,
        postalCode: { format: '####', regex: '^(\\d{4})$' },
        flags: {
            png: 'https://flagcdn.com/w320/at.png',
            svg: 'https://flagcdn.com/at.svg'
        },
        emoji: '🇦🇹',
        countryCallingCode: '+43'
    },
    {
        name: 'Andorra',
        capital: 'Andorra la Vella',
        currencies: [{ name: 'Euro', symbol: '€' }],
        languages: ['Catalan'],
        coordinates: [42.5, 1.5],
        area: 468,
        maps: {
            googleMaps: 'https://goo.gl/maps/JqAnacWE2qEznKgw7',
            openStreetMaps: 'https://www.openstreetmap.org/relation/9407'
        },
        population: 77265,
        postalCode: { format: 'AD###', regex: '^(?:AD)*(\\d{3})$' },
        flags: {
            png: 'https://flagcdn.com/w320/ad.png',
            svg: 'https://flagcdn.com/ad.svg'
        },
        emoji: '🇦🇩',
        countryCallingCode: '+376'
    }
];

const defaultCountries = data;

const alphabeticallySort = (countries) => { // Sort countries alphabetically
    return countries.sort((a, b) => a.name.localeCompare(b.name))
}

export const useCountries = ( sort = false ) => {  // Hook to get countries
    let [countries, setCountries] = useState(() => {
        return sort ? alphabeticallySort(defaultCountries) : defaultCountries
    })

    return { countries, setCountries }
}
