import { useRoutes } from "react-router-dom";
import useDecodeToken from "../hooks/useDecodeToken";
import authenticationRoutes from "./authenticationRoutes";
import mainRoutes from "./mainRoutes";

export function Routes() {
    const { isLoggedIn } = useDecodeToken();
    return useRoutes([
        authenticationRoutes(isLoggedIn),
        mainRoutes(isLoggedIn),
    ]);
}
