export const LEADERBOARD_ITEMS = 'leaderboard_items';
export const LEADERBOARD_ITEM = 'leaderboard_item';
export const LEABERBOARD_ITEMS_COUNT = 'leaderboard_items_count';
export const TIMEZONE_VALUES = 'timezone_values';
export const MY_TIMEZONE_VALUES = 'my_timezone_values';
export const PROJECTS = 'projects';
export const PROJECT = 'project';
export const TASKS = 'tasks';
export const TASK = 'task';
export const TAGS = 'tags';
export const NOTE = 'note';
export const NOTES = 'notes';
export const FILES = 'files';
export const ALL_TASKS = 'all_tasks';
export const CATEGORIES = 'categories';
export const FORUMS = 'forums';
export const FORUMS_COUNT = 'forums_count';
export const FORUM = 'forum';
export const REPLIES = 'replies';
export const REPLY = 'reply';
export const EVENTS = 'events';
export const EVENT = 'event';
export const GOOGLE_CAMPAIGNS = 'google_campaigns';
export const GOOGLE_ACCOUNTS = 'google_accounts';
export const FACEBOOK_CAMPAIGNS = 'facebook_campaigns';
