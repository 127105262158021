import axios from "axios";
// import {toast} from "react-toastify";
// import {logOut} from "./redux/slices/authentication";
// import store from "./redux/store";

const baseURL = process.env.REACT_APP_GOOGLE_CALENDAR_API;

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    },
});

// axiosInstance.interceptors.response.use(
//     (resp) => {
//         return resp;
//     },
//     (error) => {
//         if (error.response?.status === 401) {
//             store.dispatch(logOut());
//         } else {
//             toast.error(error.response?.data?.message);
//         }
//         return Promise.reject(error);
//     }
// );

export default axiosInstance;
