import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CheckBox from "../global/checkBox/checkBox";
import ForumBodyAll from "./forumBodyAll/forumBodyAll";
import styles from "./forums.module.scss";
import ForumBodyOne from "./forumBodyOne/forumBodyOne";
import Button from "../global/button/button";
import settings from "../../media/icons/settings.svg";
import close from "../../media/icons/close_icon.svg";
import {BACK, CATEGORIES, GLOSSY, SOLID} from "../../const";
import Modal from "../global/modal/modal";
import {Toggle} from "react-toggle-component";
import ActionButtonsGroup from "../dashboard/actionButtonsGroup/actionButtonsGroup";
import Plus from "../../media/icons/plus.png";
import dropdown_arrow from "../../media/icons/dropdown_arrow.svg";
import {Pagination} from "../global/pagination/pagination";
import pencil from "../../media/icons/pencil.svg";
import delete_icon from "../../media/icons/Delete.svg";
import warning from "../../media/icons/red_warning.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import back from "../../media/icons/arrow_circle.svg";
import {useTitle} from "../../hooks/useTitle";
import {useSelector} from "react-redux";
import {useMutation, useQuery, useQueryClient} from "react-query";
import CategoryService from "../../services/CategoryService";
import {useFormik} from "formik";
import categoryPostValidation from "../../validations/forums/categoryPostValidation";

export default function Forums() {
    useTitle("Forums");
    const {token, isAdmin} = useSelector((state) => state?.Authentication);
    const [currentEditableCategory, setCurrentEditableCateogory] = useState();
    const ref = useRef();
    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const [categories, setCategories] = useState([]);
    const [count, setCount] = useState();
    let navigate = useNavigate();
    let {category, id} = useParams();
    let location = useLocation();
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteableCategoryId, setDeleteableCategoryId] = useState();
    const [isMobileCategoryModalOpen, setMobileCategoryModalOpen] =
        useState(false);
    const [isMobile, setMobile] = useState(false);

    const handleCloseSettingsModal = useCallback(() => {
        setSettingsModalOpen(false);
    }, []);

    const handleCloseCategoryModal = useCallback(() => {
        setCategoryModalOpen(false);
    }, []);

    const handleCloseDeleteModal = useCallback(() => {
        setDeleteModalOpen(false);
        setDeleteableCategoryId(null);
    }, []);

    const handleCloseMobileCategoryModal = useCallback(() => {
        setMobileCategoryModalOpen(false);
    }, []);

    const handleCateogoryEditClick = (category) => {
        setCurrentEditableCateogory(category);
    };

    const setCountCallback = useCallback(
        (data) => {
            setCount(data);
            // eslint-disable-next-line
        }, [count]
    );

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 992) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    const handleCategoryClick = (i) => {
        let path = location.pathname.split("/").pop();
        let _newPath = categories[i].name.replaceAll(/\s/g, "");
        if (path === "forums") {
            navigate(`../${path}/${_newPath}`);
        } else {
            navigate(`../forums/${_newPath}`);
        }
        setCategories(
            categories.map((c, index) =>
                i === index ? {...c, isActive: true} : {...c, isActive: false}
            )
        );
    };

    const queryClient = useQueryClient();

    useQuery([CATEGORIES, token], () => CategoryService.get(token), {
        onSuccess: (data) => {
            const existingActiveIndex = categories?.findIndex((c) => c.isActive);
            let _path = location.pathname.split("/");
            if (_path.length === 4) {
                _path.pop();
                _path = _path.pop();
                setCategories(
                    data?.data.map((c) => {
                        c.isActive = c.name.replaceAll(/\s/g, "") === _path;
                        return c;
                    })
                );
            } else {
                setCategories(
                    data?.data.map((c, i) => {
                        if (existingActiveIndex > -1) {
                            if (i !== existingActiveIndex) {
                                c.isActive = false;
                                return c;
                            }
                        } else if (i !== 0) return c;
                        c.isActive = true;
                        return c;
                    })
                );
            }
        },
    });

    useOnClickOutside(ref, () => {
        if (!currentEditableCategory) return
        mutateCategoryPut({
            data: {name: currentEditableCategory?.value},
            id: currentEditableCategory?.id,
        });
    });

    const {mutate: mutateCategoryPost} = useMutation(
        (data) => CategoryService.post(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CATEGORIES]);
                handleCloseCategoryModal();
            },
        }
    );

    const {mutate: mutateCategoryPut} = useMutation(
        ({data, id}) => CategoryService.put(data, id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CATEGORIES]);
                setCurrentEditableCateogory(null);
            },
        }
    );

    const {mutate: mutateCategoryDelete} = useMutation(
        (id) => CategoryService.deleteById(id, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CATEGORIES]);
                setDeleteModalOpen(false);
            },
        }
    );

    const handleDeleteClick = (id) => {
        mutateCategoryDelete(id);
    };

    const formikPostCategory = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        validationSchema: categoryPostValidation,
        onSubmit: (values) => {
            mutateCategoryPost(values);
        },
    });
    const _renderCategory = () => {
        return (
            <>
                {_renderDeleteModal()}
                {isMobile && id == null ? (
                    <div
                        className={`${styles.categoryBar} ${styles.dropdown} ${
                            isMobileCategoryModalOpen ? styles.open : ""
                        }`}
                    >
                        <div className={styles.dropdownWrapper}>
                            <div

                                className={styles.dropownTitle}
                            >
                                <span onClick={() => setMobileCategoryModalOpen(prev => !prev)}>Categories</span>
                                <img className={styles.arrow} src={dropdown_arrow}
                                     onClick={() => setMobileCategoryModalOpen(prev => !prev)} alt=""/>
                            </div>
                            {isMobileCategoryModalOpen && (
                                <div className={styles.dropdownBody}>
                                    <div className={styles.categories}>
                                        {categories?.map((c, i) => {
                                            let isCurrentEditable =
                                                currentEditableCategory?.id === c._id;
                                            return (
                                                <div key={i} className={styles.mobileCheckBoxContainer}>
                                                    <div className={styles.mobileCheckBoxLeft}>
                                                        <CheckBox
                                                            onChange={() => handleCategoryClick(i)}
                                                            className={`${styles.checkBox} ${
                                                                c.isActive ? styles.active : null
                                                            }`}
                                                            label={!isCurrentEditable ? c.name : ""}
                                                            checked={c.isActive}
                                                        />
                                                        {isCurrentEditable && isAdmin && (
                                                            <input
                                                                ref={ref}
                                                                defaultValue={c.name}
                                                                className={styles.categoryInput}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    setCurrentEditableCateogory((prev) => ({
                                                                        ...prev,
                                                                        value: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    {isAdmin &&
                                                        (!isCurrentEditable ? (
                                                            <div className={styles.mobileAdminBtnGroup}>
                                                                <Button
                                                                    icon={pencil}
                                                                    onClick={() =>
                                                                        handleCateogoryEditClick({
                                                                            id: c._id,
                                                                            value: c.name,
                                                                        })
                                                                    }
                                                                />
                                                                <Button
                                                                    icon={delete_icon}
                                                                    onClick={() => {
                                                                        setDeleteableCategoryId(c._id);
                                                                        setDeleteModalOpen(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <Button
                                                                icon={close}
                                                                onClick={() =>
                                                                    handleCateogoryEditClick({
                                                                        id: c._id,
                                                                        value: c.name,
                                                                    })
                                                                }
                                                                className={styles.mobileBtnHidd}
                                                            />
                                                        ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {isAdmin && (
                                        <button
                                            onClick={() => setCategoryModalOpen(true)}
                                            type="button"
                                            className={styles.dropdownBtnAdd}
                                        >
                                            <img src={Plus} alt=""/>
                                            <span>Add category</span>
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            onClick={handleCloseMobileCategoryModal}
                            className={styles.dropdownBg}
                        ></div>
                    </div>
                ) : !isMobile && !id ? ( // if id is not undefined, it means that we are on the thread page, so we don't need to show the categories
                    <div className={styles.categoryBar}>
                        <div className={styles.cateogoryTitle}>
                            {isAdmin ? (
                                <span>Manage Categories</span>
                            ) : (
                                <span>Categories</span>
                            )}
                        </div>
                        <div className={styles.categories}>
                            {categories?.map((c, i) => {
                                let isCurrentEditable = currentEditableCategory?.id === c._id;
                                return (
                                    <div
                                        key={i}
                                        onClick={() => handleCategoryClick(i)}
                                        ref={ref}
                                        className={styles.checkBoxContainer}
                                    >
                                        <div className={styles.checkBoxWrapper}>
                                            <CheckBox
                                                onChange={() => handleCategoryClick(i)}
                                                className={`${styles.checkBox} ${
                                                    c.isActive ? styles.active : null
                                                }`}
                                                label={!isCurrentEditable ? c.name : ""}
                                                checked={c.isActive}
                                            />
                                            {isCurrentEditable && isAdmin && (
                                                <input
                                                    defaultValue={c.name}
                                                    ref={ref}
                                                    className={styles.categoryInput}
                                                    type="text"
                                                    onChange={(e) =>
                                                        setCurrentEditableCateogory((prev) => ({
                                                            ...prev,
                                                            value: e.target.value,
                                                        }))
                                                    }
                                                />
                                            )}
                                        </div>
                                        {isAdmin &&
                                            (!isCurrentEditable ? (
                                                <span ref={ref}>
                          Actions
                          <div ref={ref} className={styles.adminBtnGroup}>
                            <Button
                                icon={pencil}
                                onClick={() =>
                                    handleCateogoryEditClick({
                                        id: c._id,
                                        value: c.name,
                                    })
                                }
                            />
                            <Button
                                icon={delete_icon}
                                onClick={() => {
                                    setDeleteModalOpen(true);
                                    setDeleteableCategoryId(c._id);
                                }}
                            />
                          </div>
                        </span>
                                            ) : (
                                                <Button
                                                    icon={close}
                                                    onClick={() =>
                                                        handleCateogoryEditClick({
                                                            id: c._id,
                                                            value: c.name,
                                                        })
                                                    }
                                                    className={styles.mobileBtnHidd}
                                                />
                                            ))}
                                    </div>
                                );
                            })}
                        </div>
                        {isAdmin && (
                            <ActionButtonsGroup
                                className={styles.actionButtonsGroup}
                                buttons={[
                                    {
                                        icon: Plus,
                                        cb: () => setCategoryModalOpen(true),
                                    },
                                ]}
                            />
                        )}
                    </div>
                ) : (
                    !isMobile
                )}
            </>
        );
    };

    const _renderDeleteModal = () => {
        return (
            <Modal
                onClose={handleCloseDeleteModal}
                open={isDeleteModalOpen}
                className={styles.modalDelete}
            >
                <div className={styles.deleteModalWrapper}>
                    <div className={styles.deleteModalTitleContainer}>
                        <button onClick={handleCloseDeleteModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.deleteModalBody}>
                        <img src={warning} alt=""/>
                        <p>
                            <b>Are you sure that you want to delete this category?</b>
                        </p>
                        <span>
              After deleting, it will be impossible to restore user data
            </span>
                        <div className={styles.btnContainer}>
                            <Button
                                className={`${styles.deleteBtnModal}`}
                                icon={delete_icon}
                                text={"Delete"}
                                onClick={() => handleDeleteClick(deleteableCategoryId)}
                                type={SOLID}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderSettingsModal = () => {
        return (
            <Modal
                onClose={handleCloseSettingsModal}
                open={isSettingsModalOpen}
                className={styles.modalSettings}
            >
                <div className={styles.modalWrapper}>
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>Discussion settings</p>
                        <button onClick={handleCloseSettingsModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div className={styles.up}>
                            <div className={`formControls d-flex col ${styles.textArea}`}>
                                <label>Blacklist character words</label>
                                <textarea
                                    placeholder="!, @, #, $, %, ^, &, *, (,), 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, have, has, were, aren't"></textarea>
                            </div>
                        </div>
                        <div className={styles.down}>
                            <div className={`formControls d-flex col ${styles.textInput}`}>
                                <label>Minimum length*</label>
                                <input type="text" placeholder=""/>
                            </div>
                            <div className={`formControls d-flex col ${styles.textInput}`}>
                                <label>Maximum length*</label>
                                <input type="text" placeholder=""/>
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.toggleWrapper}>
                                <Toggle
                                    knobColor="white"
                                    name={`toggle-1`}
                                    borderColor="transparent"
                                    leftBackgroundColor="#E2E8F0"
                                    rightBackgroundColor="#8C6CED"
                                />
                                <span>Links in messages</span>
                            </div>
                            <Button
                                text={"Save settings"}
                                className={styles.modalSaveBtn}
                                type={GLOSSY}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    const _renderCategoryModal = () => {
        return (
            <Modal
                className={styles.modal}
                onClose={handleCloseCategoryModal}
                open={isCategoryModalOpen}
            >
                <div
                    className={`${styles.modalWrapper} ${styles.categoryModalWrapper}`}
                >
                    <div className={styles.modalTitleContainer}>
                        <p className={styles.modalTitle}>Create Category</p>
                        <button onClick={handleCloseCategoryModal}>
                            <img src={close} alt=""/>
                        </button>
                    </div>
                    <div className={styles.modalDivider}/>
                    <div className={styles.modalBodyContainer}>
                        <div
                            className={`formControls d-flex col ${
                                formikPostCategory.touched.name &&
                                Boolean(formikPostCategory.errors.name) &&
                                "error"
                            }  ${styles.textInput} ${styles.textInputCategory}`}
                        >
                            <label>Name</label>
                            <input
                                name="name"
                                onChange={formikPostCategory.handleChange}
                                onBlur={formikPostCategory.handleBlur}
                                type="text"
                            />
                            <span className={styles.validationHelper}>
                {formikPostCategory.touched.name &&
                    formikPostCategory.errors.name}
              </span>
                        </div>
                        <Button
                            onClick={formikPostCategory.handleSubmit}
                            className={styles.createBtn}
                            text={"Create"}
                            type={GLOSSY}
                        />
                    </div>
                </div>
            </Modal>
        );
    };

    window.addEventListener("resize", function () {
        handleResize();
    });

    useLayoutEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        let path = categories
            ?.find((c) => c.isActive === true)
            ?.name.replaceAll(/\s/g, "");
        if (path == null) return;
        if (location.pathname.split("/").length !== 4) {
            navigate(`../forums/${path}`);
        }
    }, [categories, location.pathname, navigate]);

    return (
        <>
            {_renderSettingsModal()}
            {_renderCategoryModal()}
            <div
                className={`${styles.wrapper} ${id != null ? styles.wrapperOne : ""}`}
            >
                {id == null ? (
                    <div className={styles.title}>
                        <h2>Discussions</h2>
                        {isAdmin && (
                            <Button
                                onClick={() => setSettingsModalOpen(true)}
                                className={styles.settingsBtn}
                                type={SOLID}
                                icon={settings}
                                text={"Settings"}
                            />
                        )}
                    </div>
                ) : (
                    <div className={styles.title}>
                        <div className={`  d-lg-none d-flex ai-center`}>
                            <Button
                                text={"Back to Discussions"}
                                type={BACK}
                                icon={back}
                                className={styles.backBtn}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className="d-none d-lg-flex ai-center jc-sb w-100">
                            <h2>Thread view</h2>
                            {isAdmin && (
                                <Button
                                    onClick={() => setSettingsModalOpen(true)}
                                    className={styles.settingsBtn}
                                    type={SOLID}
                                    icon={settings}
                                    text={"Settings"}
                                />
                            )}
                        </div>
                    </div>
                )}
                {id == null ? (
                    <span className={`d-lg-none ${styles.results}`}>
            <b>{count}</b> results -{" "}
                        <b>{categories?.find((c) => c.isActive)?.name}</b>
          </span>
                ) : (
                    <></>
                )}

                {_renderCategory()}
                <div className={styles.forumsBody}>
                    {id == null ? (
                        <ForumBodyAll
                            setItemOffset={setItemOffset}
                            itemOffset={itemOffset}
                            itemsPerPage={itemsPerPage}
                            setCount={setCountCallback}
                            categories={categories}
                            tab={categories?.find((c) => c.isActive)?._id}
                        />
                    ) : (
                        <ForumBodyOne categories={categories} tab={category} id={id}/>
                    )}
                </div>
            </div>
            <div className={styles.pagination}>
                <div className={styles.paginationContainer}>
                    <Pagination
                        {...{
                            itemsPerPage,
                            itemOffset,
                            setItemOffset,
                            length: count,
                        }}
                    />
                    <div className={`${styles.results} d-none d-lg-flex`}>
                        <span>{count}</span>
                        <span>{count === 1 ? "Result" : "Results"}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
